<form
  #addVarthaForm="ngForm"
  (ngSubmit)="saveNewsDetails(addVarthaForm)"
  novalidate
>
  <div class="p-shadow-3 form-layout-container">
    <div class="p-fluid p-container">
      <p-fileUpload
        multiple="true"
        name=""
        [showUploadButton]="false"
        (onSelect)="onUpload($event)"
        accept="image/*"
        maxFileSize="1000000"
        customUpload="true"
      >
        <ng-template pTemplate="content">
          <ul *ngIf="uploadedFiles.length">
            <li *ngFor="let file of uploadedFiles">
              {{ file.name }} - {{ file.size }} bytes
            </li>
          </ul>
        </ng-template>
      </p-fileUpload>
      <div class="p-field">
        <label for="templeName">Temple name</label>
        <input
          [(ngModel)]="templeObj.TEMPLE_NAME"
          id="templeName"
          name="templeName"
          type="text"
          #templeName="ngModel"
          aria-describedby="templeName-help"
          pInputText
          required
          minlength="10"
          maxlength="500"
        />
        <small
          id="templeName-help"
          *ngIf="templeName.errors?.required === true && templeName.touched"
          class="p-invalid">
            Temple name required
        </small>
        <small
          id="templeName-help"
          *ngIf="templeName.errors?.minlength  || templeName.errors?.maxlength"
          class="p-invalid">
            Temple name between 10 to 500 characters only
        </small>
      </div>
      <div class="p-field">
        <label for="aboutTemple">About temple</label>
        <textarea
          [(ngModel)]="templeObj.ABOUT_TEMPLE"
          id="aboutTemple"
          name="aboutTemple"
          type="text"
          rows="4"
          #aboutTemple="ngModel"
          aria-describedby="aboutTemple-help"
          pInputTextarea
          required
          minlength="10"
          maxlength="5000"
        ></textarea>
        <small
          id="aboutTemple-help"
          *ngIf="aboutTemple.errors?.required === true && aboutTemple.touched"
          class="p-invalid"
          >Please enter temple information</small
        >
        <small
          id="aboutTemple-help"
          *ngIf="aboutTemple.errors?.minlength || aboutTemple.errors?.maxlength"
          class="p-invalid"
          >About temple between 10 to 5000 characters</small
        >
      </div>
      <div class="p-field">
        <label for="templeHistory">History of temple</label>
        <textarea
          [(ngModel)]="templeObj.TEMPLE_HISTORY"
          id="templeHistory"
          name="templeHistory"
          type="text"
          rows="4"
          #templeHistory="ngModel"
          aria-describedby="templeHistory-help"
          pInputTextarea
          minlength="10"
          maxlength="5000"
          required
        ></textarea>
        <small
          id="templeHistory-help"
          *ngIf="templeHistory.errors?.required && templeHistory.touched"
          class="p-invalid"
          >Please enter history of temple</small
        >
        <small
          id="templeHistory-help"
          *ngIf="templeHistory.errors?.minlength || templeHistory.errors?.maxlength"
          class="p-invalid"
          >History of temple between 10 to 5000 characters</small
        >
      </div>
      <div class="p-field">
        <label for="celebrationsInTemple">Celebration in temple</label>
        <textarea
          [(ngModel)]="templeObj.TEMPLE_CELEBRATIONS"
          id="celebrationsInTemple"
          name="celebrationsInTemple"
          type="text"
          rows="4"
          #celebrationsInTemple="ngModel"
          aria-describedby="celebrationsInTemple-help"
          pInputTextarea
          required
          minlength="10"
          maxlength="5000"
        ></textarea>
        <small
          id="celebrationsInTemple-help"
          *ngIf="
            celebrationsInTemple.errors?.required && celebrationsInTemple.touched
          "
          class="p-invalid"
          >Please enter celebrations in temple</small
        >
        <small
          id="celebrationsInTemple-help"
          *ngIf="
            celebrationsInTemple.errors?.minlength || celebrationsInTemple.errors?.maxlength
          "
          class="p-invalid"
          >Celebrations between 10 to 5000 characters</small
        >
      </div>
      <div class="p-field">
        <label for="celebrationsInTemple">Services</label>
        <textarea
          [(ngModel)]="templeObj.SERVICES"
          id="servicesInTemple"
          name="servicesInTemple"
          type="text"
          rows="4"
          #servicesInTemple="ngModel"
          aria-describedby="servicesInTemple-help"
          pInputTextarea
          required
          minlength="10"
          maxlength="5000"
        ></textarea>
        <small
          id="servicesInTemple-help"
          *ngIf="
          servicesInTemple.errors?.required && servicesInTemple.touched
          "
          class="p-invalid"
          >Please enter services available in temple</small
        >
        <small
          id="servicesInTemple-help"
          *ngIf="
          servicesInTemple.errors?.minlength || servicesInTemple.errors?.maxlength
          "
          class="p-invalid"
          >Services between 10 to 5000 characters</small
        >
      </div>
      <!-- <div class="p-field">
        <label for="directionsToTemple">Directions to temple</label>
        <input
          [(ngModel)]="templeObj.DIRECTIONS_TO_TEMPLE"
          id="directionsToTemple"
          name="directionsToTemple"
          type="text"
          #directionsToTemple="ngModel"
          aria-describedby="directionsToTemple-help"
          pInputText
          required
        />
        <small
          id="directionsToTemple-help"
          *ngIf="
            directionsToTemple.valid === false && directionsToTemple.touched
          "
          class="p-invalid"
          >Please enter directions to temple</small
        >
      </div>  -->
      <div class="p-field">
        <label for="locationOfTemple">Location of temple</label>
        <!-- <input
          [(ngModel)]="templeObj.TEMPLE_GEO_LOC_ADDRESS"
          id="locationOfTemple"
          name="locationOfTemple"
          type="text"
          #locationOfTemple="ngModel"
          aria-describedby="locationOfTemple-help"
          pInputText
          required
        /> -->
        <app-autocomplete-location
          [address]="templeObj.TEMPLE_GEO_LOC_ADDRESS"
          (setAddress)="setTempleLocation($event)"
        >
        </app-autocomplete-location>
        <!-- <small
          id="locationOfTemple-help"
         
          class="p-invalid"
          >Please enter location of temple</small
        > -->
      </div>
      <div class="p-fluid p-grid p-form-grid">
        <div class="p-col-12">
          <h2>Temple visiting timings</h2>
        </div>
        <div class="p-col-12 p-lg-2">
          <h3>Morning</h3>
        </div>
        <div class="p-col-12 p-lg-5">
          <label for="mngOpenTime">Open Time</label>
          <p-calendar
            [ngModel]="templeObj.TEMPLE_OPENING_TIME_MORNING"
            [timeOnly]="true"
            name="mngOpenTime"
            inputId="mngOpenTime"
            [required]="true"
            (onSelect) = "handleTimePickerEvent($event, 1)"
            
          ></p-calendar>
        </div>
        <div class="p-col-12 p-lg-5">
          <label for="mngCloseTime">Close Time</label>
          <p-calendar
            [ngModel]="templeObj.TEMPLE_CLOSING_TIME_MORNING"
            [timeOnly]="true"
            name="mngCloseTime"
            inputId="mngCloseTime"
            [required]="true"
            (onSelect) = "handleTimePickerEvent($event, 2)"
          ></p-calendar>
        </div>
        <div class="p-col-12 p-lg-2">
          <h3>Evening</h3>
        </div>
        <div class="p-col-12 p-lg-5">
          <label for="evngOpenTime">Open Time</label>
          <p-calendar
            [ngModel]="templeObj.TEMPLE_OPENING_TIME_EVENING"
            [timeOnly]="true"
            name="evngOpenTime"
            inputId="evngOpenTime"
            [required]="true"
            (onSelect) = "handleTimePickerEvent($event, 3)"
          ></p-calendar>
        </div>
        <div class="p-col-12 p-lg-5">
          <label for="evngCloseTime">Close Time</label>
          <p-calendar
            [ngModel]="templeObj.TEMPLE_CLOSING_TIME_EVENING"
            [timeOnly]="true"
            name="evngCloseTime"
            inputId="evngCloseTime"
            [required]="true"
            (onSelect) = "handleTimePickerEvent($event, 4)"
          ></p-calendar>
        </div>
      </div>
      <div class="p-field">
        <label for="templeVideo">Temple Video(Youtbe link)</label>
        <input
          [(ngModel)]="templeObj.VIDEO_URL"
          id="templeVideo"
          name="templeVideo"
          type="url"
          #templeVideo="ngModel"
          aria-describedby="templeVideo-help"
          pInputText
        />
        <small
          id="templeVideo-help"
          *ngIf="templeVideo.valid === false && templeVideo.touched"
          class="p-invalid"
          >Invalid url</small
        >
      </div>
      <div class="p-fluid p-grid p-form-grid">
        <div class="p-col-12 p-lg-6">
          <label>Bus facility</label>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="busFacility"
              value="Yes"
              [(ngModel)]="templeObj.BUS_FACILITY"
            ></p-radioButton>
            <label for="">Yes</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="busFacility"
              value="No"
              [(ngModel)]="templeObj.BUS_FACILITY"
            ></p-radioButton>
            <label for="">No</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="busFacility"
              value="Others"
              [(ngModel)]="templeObj.BUS_FACILITY"
            ></p-radioButton>
            <label for="">Others</label>
          </div>
          <div
            class="p-field"
            *ngIf="
              templeObj.BUS_FACILITY !== 'Yes' &&
              templeObj.BUS_FACILITY !== 'No' &&
              templeObj.BUS_FACILITY !== 'undefined'
            "
          >
            <input
              [(ngModel)]="templeObj.BUS_FACILITY"
              id="busFacility"
              name="busFacility"
              type="text"
              #busFacility="ngModel"
              aria-describedby="busFacility-help"
              pInputText
              required
            />
            <small
              id="busFacility-help"
              *ngIf="busFacility.valid === false && busFacility.touched"
              class="p-invalid"
              >Please enter other infomration</small
            >
          </div>
        </div>

        <div class="p-col-12 p-lg-6">
          <label>Train facility</label>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="trainFacility"
              value="Yes"
              [(ngModel)]="templeObj.TRAIN_FACILITY"
            ></p-radioButton>
            <label for="">Yes</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="trainFacility"
              value="No"
              [(ngModel)]="templeObj.TRAIN_FACILITY"
            ></p-radioButton>
            <label for="">No</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="trainFacility"
              value="Others"
              [(ngModel)]="templeObj.TRAIN_FACILITY"
            ></p-radioButton>
            <label for="">Others</label>
          </div>
          <div
            class="p-field"
            *ngIf="
              templeObj.TRAIN_FACILITY !== 'Yes' &&
              templeObj.TRAIN_FACILITY !== 'No' &&
              templeObj.TRAIN_FACILITY !== 'undefined'
            "
          >
            <input
              [(ngModel)]="templeObj.TRAIN_FACILITY"
              id="trainFacility"
              name="trainFacility"
              type="text"
              #trainFacility="ngModel"
              aria-describedby="trainFacility-help"
              pInputText
              required
            />
            <small
              id="trainFacility-help"
              *ngIf="trainFacility.valid === false && trainFacility.touched"
              class="p-invalid"
              >Please enter other infomration</small
            >
          </div>
        </div>

        <div class="p-col-12 p-lg-6">
          <label>Flight facility</label>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="flightFacility"
              value="Yes"
              [(ngModel)]="templeObj.FLIGHT_FACILITY"
            ></p-radioButton>
            <label for="">Yes</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="flightFacility"
              value="No"
              [(ngModel)]="templeObj.FLIGHT_FACILITY"
            ></p-radioButton>
            <label for="">No</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="flightFacility"
              value="Others"
              [(ngModel)]="templeObj.FLIGHT_FACILITY"
            ></p-radioButton>
            <label for="">Others</label>
          </div>
          <div
            class="p-field"
            *ngIf="
              templeObj.FLIGHT_FACILITY !== 'Yes' &&
              templeObj.FLIGHT_FACILITY !== 'No' &&
              templeObj.FLIGHT_FACILITY !== 'undefined'
            "
          >
            <input
              [(ngModel)]="templeObj.FLIGHT_FACILITY"
              id="flightFacility"
              name="flightFacility"
              type="text"
              #flightFacility="ngModel"
              aria-describedby="flightFacility-help"
              pInputText
              required
            />
            <small
              id="flightFacility-help"
              *ngIf="flightFacility.valid === false && flightFacility.touched"
              class="p-invalid"
              >Please enter other infomration</small
            >
          </div>
        </div>
        <div class="p-col-12 p-lg-6">
          <label>Accommodattion</label>
          <div class="p-field-radiobutton">
            <p-checkbox
              name="acCottages"
              value="1"
              [(ngModel)]="templeObj.ACCOMMODATTION"
            ></p-checkbox>
            <label for="">Cottages</label>
          </div>
          <div class="p-field-radiobutton">
            <p-checkbox
              name="acHotels"
              value="2"
              [(ngModel)]="templeObj.ACCOMMODATTION"
            ></p-checkbox>
            <label for="">Hotels</label>
          </div>
          <div class="p-field-radiobutton">
            <p-checkbox
              name="acOthers"
              value="3"
              #templeOthers
              [(ngModel)]="templeObj.ACCOMMODATTION"
            ></p-checkbox>
            <label for="">Others</label>
          </div>
          <div
            class="p-field"
            *ngIf="templeOthers.checked === true"
          >
            <input
            
              [(ngModel)]="templeObj.ACCOMMODATTION_OTHERS"
              id="accmdtnOthers"
              name="accmdtnOthers"
              type="text"
              #accmdtnOthers="ngModel"
              aria-describedby="accmdtnOthers-help"
              pInputText
              required
            />
            <small
              id="accmdtnOthers-help"
              *ngIf="accmdtnOthers.valid === false && accmdtnOthers.touched"
              class="p-invalid"
              >Please enter other infomration</small
            >
          </div>
          <!-- <div
              class="p-field"
              *ngIf="
                templeObj.FLIGHT_FACILITY !== 'Yes' &&
                templeObj.FLIGHT_FACILITY !== 'No' &&
                templeObj.FLIGHT_FACILITY !== 'undefined'
              "
            >
              <input
                [(ngModel)]="templeObj.FLIGHT_FACILITY"
                id="flightFacility"
                name="flightFacility"
                type="text"
                #flightFacility="ngModel"
                aria-describedby="flightFacility-help"
                pInputText
                required
              />
              <small
                id="flightFacility-help"
                *ngIf="flightFacility.valid === false && flightFacility.touched"
                class="p-invalid"
                >Please enter other infomration</small
              >
            </div> -->
        </div>
      </div>
      <!--<div class="p-field">
          <label for="newsSubTitle">Temple Village/City</label>
          <input [(ngModel)]="templeObj.SUB_TITLE" id="newsSubTitle" name="newsSubTitle" type="text" #newsSubTitle="ngModel"
            aria-describedby="newsSubTitle-help" pInputText required />
          <small id="newsSubTitle-help" *ngIf="newsSubTitle.valid === false && newsSubTitle.touched"
            class="p-invalid">Invalid temple name</small>
        </div>
        <div class="p-field">
          <label for="newsContent">News Content</label>
          <textarea [(ngModel)]="templeObj.CONTENT" id="newsContent" name="newsContent" type="text" rows="4"
            #newsContent="ngModel" aria-describedby="newsContent-help" pInputTextarea required minlength="10"
            maxlength="5000"></textarea>
          <small id="newsContent-help" *ngIf="newsContent.valid === false && newsContent.touched" class="p-invalid">New
            content could not be empty</small>
        </div> -->
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-lg-6">
          <label for="newsPublishType">Publiish Type</label>
          <p-dropdown
            id="newsPublishType"
            name="newsPublishType"
            [(ngModel)]="templeObj.PUBLISH_TYPE"
            #newsPublishType="ngModel"
            [options]="PublishTypes"
            aria-describedby="newsPublishType-help"
            placeholder="Select publish type"
            [showClear]="false"
            required
          ></p-dropdown>
          <small
            id="newsPublishType-help"
            *ngIf="newsPublishType.valid === false && newsPublishType.touched"
            class="p-invalid"
            >Select publish type</small
          >
        </div>
        <div class="p-field p-col-12 p-lg-6" *ngIf="templeObj.PUBLISH_TYPE === 2">
          <label>Pickup date*</label>
          <p-calendar
            id="newsPublishDate"
            name="newsPublishDate"
            (ngModel)="(templeObj.RELEASE_DATE)"
            #newsPublishDate="ngModel"
            required
          ></p-calendar>
          <small
            id="newsPublishDate-help"
            *ngIf="newsPublishDate.valid === false && newsPublishDate.touched"
            class="p-invalid"
            >Pickup publish date</small
          >
        </div>
      </div>

      <div class="p-fluid p-form-grid p-grid">
        <div class="p-field p-col-12 p-lg-6">
          <label for="newsStatus">Post status</label>
          <p-dropdown
            id="newsStatus"
            name="newsStatus"
            [(ngModel)]="templeObj.TEMPLE_STATUS"
            #newsStatus="ngModel"
            [options]="PostStatus"
            placeholder="Select post status"
            [showClear]="false"
            required
          >
          </p-dropdown>
          <small
            id="newsStatus-help"
            *ngIf="newsStatus.valid === false && newsStatus.touched"
            class="p-invalid"
            >Pickup news status</small
          >
        </div>
        <div *ngIf="templeObj.TEMPLE_STATUS === 3" class="p-field p-col-12 p-lg-6">
          <label for="rejectedRemarks">Remarks</label>
          <input
            id="rejectedRemarks"
            name="rejectedRemarks"
            [(ngModel)]="templeObj.REJECTED_REMARKS"
            #rejectedRemarks="ngModel"
            type="text"
            pInputText
          />
        </div>
      </div>

      <div class="p-fluid p-grid p-form-grid">
        <div class="p-field p-col-12 p-lg-6">
          <label for="newsPoint">Points</label>
          <input
            id="newsPoints"
            name="newsPoints"
            type="number"
            #newsPoints="ngModel"
            [(ngModel)]="templeObj.CREDIT_POINTS"
            aria-describedby="newsPoints-help"
            pInputText
            required
          />
          <small
            id="newsPoints-help"
            *ngIf="newsPoints.valid === false && newsPoints.touched"
            class="p-invalid"
            >News points required</small
          >
        </div>
      </div>
      <div class="p-fluid p-grid p-form-grid">
        <div class="p-field p-col-12 p-lg-6">
          <label for="targetAudience">Target Audience</label>
          <p-dropdown
            id="targetAudience"
            name="targetAudience"
            #targetAudience="ngModel"
            [(ngModel)]="templeObj.AUD_TYPE"
            [options]="TargetAudience"
            placeholder="Target Audience"
            [showClear]="false"
            required
          ></p-dropdown>
          <small
            id="targetAudience-help"
            *ngIf="targetAudience.valid === false && targetAudience.touched"
            class="p-invalid"
            >News points required</small
          >
        </div>
        <div
          *ngIf="templeObj.AUD_TYPE === 2"
          class="p-field p-col-12 p-lg-6"
        >
          <label for="">Audience Location</label>
          <app-autocomplete-location
            [address]="templeObj.GEO_LOC_ADDRESS"
            [isRequired]="isLocationRequired"
            adressType="geocode"
            (setAddress)="setAudienceLocation($event)"

          >
          </app-autocomplete-location>
        </div>
      </div>

      <button
        pButton
        pRipple
        type="submit"
        [label]="submitting === true ? 'Please wait' : 'Save'"
        style="max-width: 150px"
        class="p-button-raised btn-theme p-button-text"
        [disabled]="addVarthaForm.valid === false || submitting === true"
      ></button>
    </div>
  </div>
</form>

