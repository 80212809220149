import { Component, OnDestroy, OnInit } from '@angular/core';
import { LOGINPAGESLIDER } from '../constants';
import { NewsVM, YatraVM, QuestionVM, Category, PurposeType, ResultType, TempleVM } from '../models/avbhakthi.model';
import { ThrowStmt } from '@angular/compiler';
import { MenuService } from '../common/menu.service';
import { MenuCategories } from '../common/menu.model';
import { NewsService } from '../common/news.service';
import { YatraService } from '../common/yatra.service';
import { QuestionService } from '../common/question.service';
import { error } from '@angular/compiler/src/util';
import { TempleService } from '../common/temple.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  private serviceSubscriptions: Subscription;
  readonly homeNavItems = LOGINPAGESLIDER;
  latestNews: NewsVM[];
  latestYatras: YatraVM[];
  latestTemples: TempleVM[];
  latestQuestion: QuestionVM;
  constructor(
    private menuService: MenuService,
    private newsService: NewsService,
    private yatraService: YatraService,
    private questionService: QuestionService,
    private templeService: TempleService
  ) {
    this.serviceSubscriptions = new Subscription();
    this.menuService.setMenuItems(MenuCategories.all);
    this.latestNews = [];
    this.latestYatras = [];

    // for loading news
    this.serviceSubscriptions.add(
    this.newsService
      .getNews(4, Category.bhakthiNews, PurposeType.forMainScreen, false, ResultType.after)
      .subscribe(data => {

        if (data.length > 0) {
          this.latestNews = data;
        }
      }, error => {
        // console.log(error);
      })
      );

    this.serviceSubscriptions.add(
    this.yatraService
      .getYatras(4, Category.bhakthiNews, PurposeType.forMainScreen, false, ResultType.after)
      .subscribe(data => {

        if (data.length > 0) {
          this.latestYatras = data;
        }
      }, error => {
        // console.log(error);
      })
    );

    this.latestQuestion = new QuestionVM();

    this.serviceSubscriptions.add(
    this.questionService
      .getQuestions(1, Category.bhakthiNews, PurposeType.forMainScreen, false, ResultType.after)
      .subscribe(data => {

        if (data.length > 0) {
          this.latestQuestion = data[0];
        }
      }, error => {
        // console.log(error);
      })
    );

      // Fetching latest 4 alayadarshini cards
    this.serviceSubscriptions.add(
      this.templeService.getTemples(4, Category.alayaDarshini, PurposeType.forMainScreen, false, ResultType.after)
      .subscribe(data => {
        // console.log(data);
        this.latestTemples = data;
      })
    );

  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
  }

}
