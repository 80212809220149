import { Injectable } from '@angular/core';
import { AppHttpService } from '../common/http.service';
import { BookmarkVM, LikeDislikeVM } from '../models/avbhakthi.model';

@Injectable({
  providedIn: 'root'
})
export class CommonHttpService {

  constructor(
    private http: AppHttpService
  ) { }
  postLikeDislikeStatus(request: LikeDislikeVM) {
    return this.http.postLikeDisLike(request);
  }
  bookmarkStatus(request: BookmarkVM) {
    return this.http.postBookmarkStatus(request);
  }

}
