<div class="p-fluid p-text-center">
  <app-loader [enable]="isProgress"> </app-loader>
  <div class="order-items-container p-card" *ngIf="!isProgress">
    <div class="p-grid">
      <!-- Left panel -->
      <div class="p-col-12 p-md-7 p-lg-8 left-panel">
        <div class="p-grid" *ngFor="let item of orderTotal.ITEMS">
          <div class="p-col-3 p-text-center">
            <img [src]="getImageURL(item.ITEM_IMAGES_PATH)" class="img-fluid" [title]="item.ITEM_NAME" [alt]="item.ITEM_NAME" />
          </div>
          <div class="p-col-6">
            <h2 class="theme-color p-text-bold p-my-0 line-height-1">
              {{ item.ITEM_NAME }}
            </h2>
            <!-- <h4 class="line-height-1 p-my-0 p-text-light">Item category</h4> -->
            <h4 class="line-height-1 p-my-1 p-text-light">
              Quantity: {{ item.QUANTITY }}
            </h4>
            <p class="item-price p-my-0">
              <span class="final-price theme-color"
                >&#8377;{{ item.ITEM_PRICE_INFO.ITEM_TOTAL_AMOUNT | number }}/-</span
              ><span class="actual-price">&#8377;{{ item.ITEM_PRICE_INFO.ITEM_AMOUNT | number}}/-</span>
            </p>
          </div>
          <div class="p-col-3">
            <p class="line-height-1">
              Delivery charges <br />
              <span
                [ngClass]="item.DELIVERY ? 'p-text-success p-text-bold' : ''"
              >
                {{
                  item.DELIVERY
                    ? "FREE"
                    : "&#8377;" + (item.ITEM_PRICE_INFO.ITEM_DELIVERY_CHARGES | number) + "/-"
                }}</span
              >
            </p>
          </div>
        </div>
      </div>
      <!-- Right panel -->
      <div class="p-col-12 p-md-5 p-lg-4 right-panel">
        <div class="price-details">
          <p class="price-details-head">PRICE DETAILS</p>
          <div class="p-grid">
            <div class="p-col">
              <p class="p-grid-text p-text-left">
                Price({{ orderTotal.ITEMS?.length }} items)
              </p>
            </div>
            <div class="p-col">
              <p class="p-grid-text p-text-right">
                &#8377;{{ orderTotal.PRICE_SUMMARY_INFO.ITEMS_TOTAL_AMOUNT | number}}/-
              </p>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col">
              <p class="p-grid-text p-text-left">Discount</p>
            </div>
            <div class="p-col">
              <p class="p-grid-text p-text-right">
                -&#8377;{{ orderTotal.PRICE_SUMMARY_INFO.ITEMS_DISCOUNT_TOTAL_AMOUNT | number}}/-
              </p>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col">
              <p class="p-grid-text p-text-left">Delivery Charges</p>
            </div>
            <div class="p-col">
              <p class="p-grid-text p-text-right">
                &#8377;{{ orderTotal.PRICE_SUMMARY_INFO.DELIVERY_CHARGES_TOTAL_AMOUNT | number}}/-
              </p>
            </div>
          </div>
          <div class="p-grid total-row">
            <div class="p-col">
              <p class="p-grid-text p-text-left p-text-bold">Total Amount</p>
            </div>
            <div class="p-col">
              <p class="p-grid-text p-text-right p-text-bold">
                &#8377;{{ orderTotal.PRICE_SUMMARY_INFO.ORDER_TOTAL_AMOUNT | number}}/-
              </p>
            </div>
            <div class="p-grid">
                <div class="p-col p-text-success">
                    You will save &#8377;{{orderTotal.PRICE_SUMMARY_INFO.ITEMS_DISCOUNT_TOTAL_AMOUNT | number}} on this order.
                </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /End of right panel -->
    </div>
    <!-- End of Grid -->
    <div class="p-grid">
        <div class="p-col p-text-left">
          <button
          pButton
          pRipple
          type="button"
          icon="pi pi-chevron-left"
          label="BACK"
          [routerLink]="['/app/check-out/pick-address']"
          class="p-button-raised p-button-text btn-add-category"
          
        ></button>
        <!-- style="max-width: 150px" -->
        </div>
        <div class="p-col p-text-right">
          <button
          pButton
          pRipple
          type="button"
          icon="pi pi-chevron-right"
          label="PAYMENT"
          [routerLink]="['/app/check-out/init-payment']"
          class="p-button-raised p-button-text btn-add-category p-ml-auto"
          
          iconPos="right"
        ></button>
        </div>
      </div>
  </div>
  <!-- End of order items container -->
</div>
