import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { MenuCategories } from '../common/menu.model';
import { MenuService } from '../common/menu.service';
import { TempleService } from '../common/temple.service';
import { Category, PurposeType, ResultType, TempleVM } from '../models/avbhakthi.model';

@Component({
  selector: 'app-alaya-darshini',
  templateUrl: './alaya-darshini.component.html',
  styleUrls: ['./alaya-darshini.component.css']
})
export class AlayaDarshiniComponent implements OnInit, OnDestroy {
  serviceSubscriptions: Subscription;
  contentLoading: boolean = true;
  noMoreNews: boolean = false;
  temples: TempleVM[];
  contentFetching: boolean = false;

  constructor(
    private templeService: TempleService,
    private menuservice: MenuService,
  ) {
    this.serviceSubscriptions = new Subscription();
    this.temples = [];

    menuservice.setMenuItems(MenuCategories.alayaDarshini);
    this.contentFetching = true;
    this.contentLoading = true;
    this.serviceSubscriptions.add(this.templeService.getTemples(10, Category.alayaDarshini, PurposeType.forMainScreen, false, ResultType.after)
      .subscribe(data => {
        // // console.log(data);
        this.temples = data;
        this.contentFetching = false;
        this.contentLoading = false;
      }, error => {
        this.contentFetching = false;
        this.contentLoading = false;
      } ));
  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
  }


  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {

    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !this.noMoreNews) {
      // console.log('You have reached bottome of page');
      let lastNewsId = '';
      // this.contentLoading = true;
      if (this.temples.length > 0 && this.temples.length > 0)
        lastNewsId = this.temples[this.temples.length - 1].TEMPLE_EXTERNAL_ID;
      if(!this.contentLoading) {
        this.contentLoading = true;
        this.serviceSubscriptions.add(this.templeService
          .getTemples(10, Category.alayaDarshini, PurposeType.forMainScreen, false, ResultType.after, lastNewsId)
          .subscribe(data => {
            this.contentLoading = false;
            if (data.length > 0) {
              // this.latestNews = data;
              this.temples = [...this.temples, ...data];
            } else {
              this.noMoreNews = true;
            }
          }, error => {
            // console.log(error);
            this.contentLoading = false;
          }));
      }
      
    }
  }
}
