<div class="page-header full-bg">
  <div class="blur-bg page-header-content">
    <h1 class="p-text-center p-text-white page-title p-my-1">
      యాత్ర సందర్శిని
    </h1>
    <hr class="special-line" />
    <!-- <h2 class="p-text-center page-sub-title p-my-1">ఇందులో మీరు దేవాలయాలలో జరిగే పూజ కార్యక్రమాలను, ఉత్సవాల సమాచారంను. భక్తి ఆధ్యాత్మిక విశేషాలను తెలుసుకోవచ్చు. మీరు కోరుకున్న రోజున ఆ సమాచారాన్ని మళ్ళిచూడవచ్చు,మీ ఆత్మీయులతో పంచుకోవచ్చును.</h2> -->
  </div>
</div>
<p-card styleClass="p-card-shadow yatra-details p-mb-4">
  <h1 class="p-text-light theme-color p-card-title">
    {{ yatra.PACKAGE_NAME }}
  </h1>
  <div class="full-bg card-header-bg">
    <img [src]="getImageURL()" class="img-fluid" />
  </div>
  <div class="card-description p-datatable">
    <table class="p-table" role="grid">
      <thead>
        <tr>
          <th class="theme-color" width="30%">Category</th>
          <th class="theme-color">Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="theme-color">Travel places</td>
          <td>{{ yatra.PLACES }}</td>
        </tr>
        <tr>
          <td class="theme-color">Boarding point</td>
          <td class="p-text-italic p-text-bold">{{ yatra.BOARDING_POINT }}</td>
        </tr>
        <tr>
          <td class="theme-color">Boarding time</td>
          <td class="p-text-italic p-text-bold">{{ yatra.JOURNEY_DATES }}</td>
        </tr>
        <tr>
          <td class="theme-color">Price</td>
          <td class="p-text-italic p-text-bold">{{ yatra.PRICE }}/-</td>
        </tr>
      </tbody>
    </table>
    <div class="p-grid">
      <div class="p-col-12 p-py-1 p-text-center">
        <button class="btn book-now-btn btn-theme p-font-weight-light"
        (click)="saveYatraInterest(yatra.INTREST_STATUS)"
        >
          {{(yatra.INTREST_STATUS == InterestStatus.notResponded || yatra.INTREST_STATUS == InterestStatus.notIntrested) ? 'BOOK' : 'BOOKED' }}
        </button>
        <a
          href="tel:9505858565"
          class="btn book-now-btn btn-theme p-font-weight-light"
          >CALL NOW</a
        >
      </div>
    </div>
    <div class="p-card-footer">
      <app-card-footer
      [category]="newsCategory.yatraSandarshini"
      [externalId]="yatra.YATRA_EXTERNAL_ID"
      [summeryInfo]="yatra.SUMMARY_INFO"
      [isDetailed] = "true"
    >

    </app-card-footer>
    </div>

    <div class="p-text-center next-prev-container">
      <button
        pButton
        pRipple
        (click)="prevYatra()"
        type="button"
        label="PREV"
        class="p-button-outlined p-button-warning prev-button"
      ></button>
      <button
        pButton
        pRipple
        (click)="nextYatra()"
        type="button"
        label="NEXT"
        class="p-button-outlined p-button-warning next-button"
      ></button>
    </div>
  </div>
</p-card>
