import { Component, OnInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { YatraService } from '../common/yatra.service';
import { MESSAGE_SERVICE_KEY, SOCIALICONS } from '../constants';
import { BookmarkVM, Category, InterestStatusType, LikeDislikeVM, LikeStatusType, YatraInterestRequest, YatraVM } from '../models/avbhakthi.model';
import { CommonHttpService } from '../services/common-http.service';
import {MessageService} from 'primeng/api';
import { AppConfigService } from '../common/app-config.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-yatra-card',
  templateUrl: './yatra-card.component.html',
  styleUrls: ['./yatra-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class YatraCardComponent implements OnInit, OnDestroy{
  private serviceSubscriptions: Subscription;
  @Input() yatraObj: YatraVM;
  readonly baseURL: string = AppConfigService.settings.serverURL;
  readonly socialIcons = SOCIALICONS;
  readonly detailRouterLink: string = 'app/yatra-sandharshini/details/';
  constructor(
    private router: Router,
    private commonHttp: CommonHttpService,
    private yatraService: YatraService,
    private messageService: MessageService
  ) {
    this.serviceSubscriptions = new Subscription();
    
  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
  }
  getImageURL(): string {
    return this.baseURL + this.yatraObj.IMAGE_URL.replace(/\\/g, '/');
  }

  get newsCategory(): typeof Category {
    return Category;
  }

  cardClicked() {
    // console.log('Card clicked');
    this.router.navigateByUrl(this.detailRouterLink + this.yatraObj.YATRA_EXTERNAL_ID);
  }


  saveYatraInterest(currentStatus: InterestStatusType) {
    
    let req: YatraInterestRequest = {
      REF_ID: this.yatraObj.YATRA_EXTERNAL_ID,
      CATEGORY: Category.yatraSandarshini,
      INTEREST_STATUS: currentStatus === InterestStatusType.notIntrested || currentStatus === InterestStatusType.notResponded ? InterestStatusType.intrested : InterestStatusType.notIntrested,
      POSTED_ON : moment().format('YYYY-MM-DD HH:mm:ss')
    };
    this.yatraObj.INTREST_STATUS = req.INTEREST_STATUS;
    this.serviceSubscriptions.add(
    this.yatraService.saveYatraStatus(req).subscribe(data => {
      if(data.CODE === 100) {
        this.yatraObj.INTREST_STATUS = req.INTEREST_STATUS;
        if(this.yatraObj.INTREST_STATUS === InterestStatusType.intrested) {
          this.messageService.add({severity:'success', summary:'Thank you. Our team will contact you soon.', detail:'AVBhakthi.com', key: MESSAGE_SERVICE_KEY});
        }
      } else {
        this.yatraObj.INTREST_STATUS= currentStatus;
      }
    })
    );

  }

  get InterestStatus(): typeof InterestStatusType {
    return InterestStatusType;
  }
}
