import { ThrowStmt } from '@angular/compiler';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { timestamp } from 'rxjs/operators';
import { AppConfigService } from 'src/app/common/app-config.service';
import { PoojaStoreService } from 'src/app/common/pooja-store.service';
import { MESSAGE_SERVICE_KEY } from 'src/app/constants';
import { EPoojaStoreService } from 'src/app/e-store/e-pooja-store.service';
import { GetOrderItemsVM, GetOrdersAdminResponseVM, PoojaItemCategory, UpdateOrderStatusVM } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'app-confirm-update-orders',
  templateUrl: './confirm-update-orders.component.html',
  styleUrls: ['./confirm-update-orders.component.css']
})
export class ConfirmUpdateOrdersComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription;
  orderedItemsList: GetOrdersAdminResponseVM;
  poojaItemCategories: PoojaItemCategory[] = [];
  readonly baseURL: string = AppConfigService.settings.serverURL;
  isProgress: boolean = false;
  noOfRowsPage: number = 10;
  totalRecords: number = 0;
  filter: GetOrderItemsVM = new GetOrderItemsVM();
  readonly orderStatusList: any[] = [
    {
      "ORDER_STATUS_CODE_ID": -1,
      "STATUS_NAME": "Created",
      "ISACTIVE": 1
    },
    {
      "ORDER_STATUS_CODE_ID": 0,
      "STATUS_NAME": "Failed",
      "ISACTIVE": 1
    },
    {
      "ORDER_STATUS_CODE_ID": 1,
      "STATUS_NAME": "Processing",
      "ISACTIVE": 1
    },
    {
      "ORDER_STATUS_CODE_ID": 2,
      "STATUS_NAME": "Accepted",
      "ISACTIVE": 1
    },
    {
      "ORDER_STATUS_CODE_ID": 3,
      "STATUS_NAME": "Cancelled",
      "ISACTIVE": 1
    },
    {
      "ORDER_STATUS_CODE_ID": 4,
      "STATUS_NAME": "Packing",
      "ISACTIVE": 1
    },
    {
      "ORDER_STATUS_CODE_ID": 5,
      "STATUS_NAME": "Shipped",
      "ISACTIVE": 1
    },
    {
      "ORDER_STATUS_CODE_ID": 6,
      "STATUS_NAME": "Delivered",
      "ISACTIVE": 1
    },
    {
      "ORDER_STATUS_CODE_ID": 7,
      "STATUS_NAME": "Returned",
      "ISACTIVE": 1
    },
    {
      "ORDER_STATUS_CODE_ID": 8,
      "STATUS_NAME": "Refund",
      "ISACTIVE": 1
    }
  ]

  constructor(
    private eCartService: EPoojaStoreService,
    private poojaStoreService: PoojaStoreService,
    private messageService: MessageService,
  ) {
    this.orderedItemsList = new GetOrdersAdminResponseVM();
    this.orderedItemsList.ITEMS = [];
    this.subscriptions = new Subscription();
    this.filter.ORDER_STATUS_CODES = [];
    this.filter.SKIP = 0;
    this.filter.TAKE = this.noOfRowsPage;
    this.isProgress = false;
    this.fetchOrders();
    // Fetching poojaItemCategories
    this.subscriptions.add(
      this.poojaStoreService.poojaItemCategories().subscribe(data => {
        this.poojaItemCategories = data;
      })
    );
   }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
  }

  // To get ImageURL
  getImageURL(imgPath: string): string {
    let imagePath = '';
    if (imgPath) {
      let imagesArray = imgPath.split('#');
      if (imagesArray.length > 0) {
        imagePath = imagesArray[0];
      }
    }
    return this.baseURL + imagePath;
  }

  // Fetch orders items
  fetchOrders() {
    this.isProgress = true;
    this.subscriptions.add(
      this.eCartService.getOrderedItems(this.filter).subscribe(data => {
        this.isProgress = false;
        this.orderedItemsList.ITEMS = [...data.ITEMS];
        this.totalRecords = data.TOTAL_RECORDS;
      }, error => {
        this.isProgress = false;
      })
      );
  }
  // Get CategoryName
  getCategoryName(id: number): string {
    let categoriesFiltered = this.poojaItemCategories.filter(item => item.CATEGORY_ID === id);
    return categoriesFiltered.length > 0 ? categoriesFiltered[0].CATEGORY_NAME : '';
  }

  // Changes status
  updateItemStatus(status: any, comment: any, itemExternalId: string) {
    let orderStatus = {
      ORDER_ITEM_EXTERNAL_ID: itemExternalId,
      STATUS: status.value,
      COMMENTS: comment.value
    }
    this.subscriptions.add(
      this.eCartService.updateOrderStatus(orderStatus).subscribe(data => {
        if (data.CODE === 100) {
          this.messageService.add({ severity: 'success', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
          this.reloadItemDetails(itemExternalId);
        } else {
          this.messageService.add({ severity: 'error', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
        }
      }, error => {
        this.messageService.add({ severity: 'error', summary: error.error.DESC, key: MESSAGE_SERVICE_KEY});
      })
    )
  }

  // To reload Item stats after changesing status
  reloadItemDetails(itemExternalId: string) {
    this.subscriptions.add(
      this.eCartService.getOrderedItemById(itemExternalId).subscribe(data => {
        for(let i=0; i < this.orderedItemsList.ITEMS.length; i++) {
          let item = this.orderedItemsList.ITEMS[i];
          if(item.ORDER_ITEM_EXTERNAL_ID === itemExternalId) {
            this.orderedItemsList.ITEMS.splice(i, 1, data);
            this.orderedItemsList.ITEMS = [...this.orderedItemsList.ITEMS]
            break;
          }
        }
        
      })
    );
  }

  // Lazy loading
  lazyLoadData(event: any) {
    if(event.first === 0){
      this.filter.TAKE = event.rows;
      this.filter.SKIP = event.first;
      this.fetchOrders();
    }
    else  {
      this.filter.TAKE = event.rows;
      this.filter.SKIP = event.first/this.noOfRowsPage;
      this.fetchOrders();
    }
    
  }
}
