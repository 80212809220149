<div class="page-header full-bg">
  <div class="blur-bg page-header-content">
    <h1 class="p-text-center p-text-white page-title p-my-1">ఆలయ దర్శిని</h1>
    <hr class="special-line" />
    <!-- <h2 class="p-text-center page-sub-title p-my-1">మన దేవాలయాలు ఏక్కడున్నాయి, ఎలా వెళ్ళాలి. వాటి యొక్క ప్రాముఖ్యత, ఫోటోస్, వీడియోలు, ఆలయాల చిరునామా, పూజ, అర్చన, అభిషేక కార్యక్రమాలు.</h2> -->
  </div>
</div>
<p-card styleClass="p-card-shadow temple-details p-mb-4">
  <div
    class="p-card-title theme-color p-text-center"
    [innerHtml]="temple.TEMPLE_NAME"
  >
    <!-- {{newsObj.TITLE}} -->
  </div>
  <p-carousel
    [value]="templeImages"
    [circular]="true"
    [autoplayInterval]="5000"
  >
    <ng-template let-img pTemplate="item">
      <div class="carousel-item">
        <div class="carousel-item-content">
          <div class="p-mb-3">
            <img [src]="img" class="carousel-image" />
          </div>
        </div>
      </div>
    </ng-template>
  </p-carousel>
  <div class="p-grid">
    <div class="p-col-12 p-md-6">
      <div class="p-grid p-form-grid">
        <div class="p-col-4 p-text-center">
          <i class="pi pi-map-marker theme-color" style="font-size: 4rem"></i>
        </div>
        <div class="p-col-8">
          <p class="p-mt-1 p-mb-1">
            {{temple.TEMPLE_GEO_LOC_ADDRESS}}
          </p>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-6">
      <div class="p-grid p-form-grid">
        <div class="p-col-4 p-text-center">
          <i class="pi pi-clock theme-color" style="font-size: 4rem"></i>
        </div>
        <div class="p-col-8">
          <p class="p-mt-1 p-mb-1">
            Sunday - Saturday <br />
            {{ temple.TEMPLE_OPENING_TIME_MORNING }} -
            {{ temple.TEMPLE_CLOSING_TIME_MORNING }} <br />
            {{ temple.TEMPLE_OPENING_TIME_EVENING }} -
            {{ temple.TEMPLE_CLOSING_TIME_EVENING }} <br />
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-text-center">
      <button
        class="btn btn-theme btn-modal-trigger"
        (click)="servicesModal = true"
        label="SERVICES"
        pButton
      ></button>
    </div>
    <div class="p-col-12 p-md-6 p-text-center">
      <button
        class="btn btn-theme btn-modal-trigger"
        (click)="accommodattionModal = true"
        label="ACCOMMODATTION"
        pButton
      ></button>
    </div>
    <div class="p-col-12 p-md-6 p-text-center">
      <button
        class="btn btn-theme btn-modal-trigger"
        (click)="trvelModal = true"
        label="TRAVEL"
        pButton
      ></button>
    </div>
    <div class="p-col-12 p-md-6 p-text-center">
      <a
        class="btn btn-theme btn-modal-trigger"
        [href]="getTempleDirectionLink()"
        target="blank"
        label="GET DIRECTIONS"
        pButton
      ></a>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <h2 class="theme-color p-mb-1">About</h2>
      <p class="p-mt-1 p-mb-1 desc" [innerHtml]="temple.ABOUT_TEMPLE"></p>
    </div>
    <div class="p-col-12">
      <h2 class="theme-color p-mb-1">History</h2>
      <p class="p-mt-1 p-mb-1 desc" [innerHtml]="temple.TEMPLE_HISTORY"></p>
    </div>
    <div class="p-col-12">
      <h2 class="theme-color p-mb-1">Celebrations</h2>
      <p
        class="p-mt-1 p-mb-1 desc"
        [innerHtml]="temple.TEMPLE_CELEBRATIONS"
      ></p>
    </div>
    <div class="p-col-12">
      <iframe class="embeded-video" [src]="temple.VIDEO_URL | safePipe">
      </iframe>
    </div>
  </div>
  <div class="p-card-footer">
    <app-card-footer
      [category]="newsCategory.alayaDarshini"
      [externalId]="temple.TEMPLE_EXTERNAL_ID"
      [summeryInfo]="temple.SUMMARY_INFO"
      [isDetailed] = "true"
    >

    </app-card-footer>
    <!-- <p-button
      class="p-button-text p-mr-3 p-ml-3"
      [label]="temple.SUMMARY_INFO.NO_OF_LIKES.toString()"
      styleClass="p-button-text theme-color"
    >
      <img
        class="social-icon"
        [src]="socialIcons['LIKED'][temple.SUMMARY_INFO.LIKE_STATUS]"
      />
    </p-button>
    <p-button
      class="p-button-text p-mr-3 p-ml-3"
      [label]="temple.SUMMARY_INFO.NO_OF_DIS_LIKES.toString()"
      styleClass="p-button-text theme-color"
    >
      <img
        class="social-icon"
        [src]="socialIcons['DISLIKED'][temple.SUMMARY_INFO.LIKE_STATUS]"
      />
    </p-button>
    <p-button class="p-button-text p-mr-3 p-ml-3" styleClass="p-button-text">
      <img class="social-icon" [src]="socialIcons['SHARE']" />
    </p-button>
    <p-button class="p-button-text p-mr-3 p-ml-3" styleClass="p-button-text">
      <img
        class="social-icon"
        [src]="
          socialIcons['BOOKMARKED'][
            temple.SUMMARY_INFO.IS_BOOKMARKED.toString()
          ]
        "
      />
    </p-button> -->
  </div>

  <div class="p-text-center next-prev-container">
    <button
      pButton
      pRipple
      (click)="prevTemple()"
      type="button"
      label="PREV"
      class="p-button-outlined p-button-warning prev-button"
    ></button>
    <button
      pButton
      pRipple
      (click)="nextTemple()"
      type="button"
      label="NEXT"
      class="p-button-outlined p-button-warning next-button"
    ></button>
  </div>
</p-card>

<p-dialog
  header="Services"
  [(visible)]="servicesModal"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
>
  <p [innerHtml]="temple.SERVICES"></p>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="servicesModal = false"
      label="Close"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  header="Accommodattion"
  [(visible)]="accommodattionModal"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
>
  <p>Hotels: {{ temple.ACCOMMODATTION?.includes("1") ? "Yes" : "No" }}</p>
  <p>Cottages: {{ temple.ACCOMMODATTION?.includes("2") ? "Yes" : "No" }}</p>
  <p>Others: {{ temple.ACCOMMODATTION?.includes("3") ? "Yes" : "No" }}</p>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="accommodattionModal = false"
      label="Close"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  header="Travel"
  [(visible)]="trvelModal"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
>
  <p>Bus facility: {{ temple.BUS_FACILITY }}</p>
  <p>Train facility: {{ temple.TRAIN_FACILITY }}</p>
  <p>Flight facility: {{ temple.FLIGHT_FACILITY }}</p>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="trvelModal = false"
      label="Close"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  header="Directions"
  [(visible)]="directionsModal"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
>
  <p>{{ temple.DIRECTIONS_TO_TEMPLE }}</p>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="directionsModal = false"
      label="Close"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>

<section class="p-px-5" *ngIf="nearestTemples.length > 0">
  <h2 class="theme-color p-text-bold p-text-light">NearBy temples</h2>
  <app-temple-card
    *ngFor="let temple of nearestTemples"
    [templeObj]="temple"
    [isNearestTemple]="true"
  ></app-temple-card>
</section>