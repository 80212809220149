import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { PurohitDarshiniService } from 'src/app/common/purohit-darshini.service';
import { MESSAGE_SERVICE_KEY } from 'src/app/constants';
import { PurohitListVM } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'app-purohit-approve',
  templateUrl: './purohit-approve.component.html',
  styleUrls: ['./purohit-approve.component.css']
})
export class PurohitApproveComponent implements OnInit, OnDestroy {

  purohitList: PurohitListVM[];
  private serviceSubscriptions: Subscription;

  constructor(
    private purohitService: PurohitDarshiniService,
    private messageService: MessageService
  ) { 
    this.serviceSubscriptions = new Subscription();
    this.purohitList = [];
    this.fetchPurohitPendingList()
  }

  fetchPurohitPendingList() {
    this.serviceSubscriptions.add(
      this.purohitService.purohitApprovalList().subscribe(data => {
        this.purohitList = data;
      })
    );
  }

  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
  }

  approvePurohit(purohitId: string) {
    this.serviceSubscriptions.add(
      this.purohitService.approvePurohit(purohitId).subscribe(data => {
        if (data.CODE === 100) {
          this.messageService.add({ severity: 'success', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
          this.fetchPurohitPendingList();
        } else {
          this.messageService.add({ severity: 'error', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
        }
      }, error => {
        this.messageService.add({ severity: 'error', summary: error.error, key: MESSAGE_SERVICE_KEY});
      })
    )
  }
}
