import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MESSAGE_SERVICE_KEY } from '../constants';
import { ApiResponse, Category, GetTemplesVM, PurposeType, ResultType } from '../models/avbhakthi.model';
import { AppHttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class TempleService {

  constructor(
    private http: AppHttpService,
    private messageService: MessageService
  ) { }

  getTemples(count: number, cat: Category, purpose: PurposeType, include: boolean, resultType: ResultType, lastNewsId: string = ""): Observable<any[]> {

    let getNewsReq = new GetTemplesVM();
    getNewsReq.CATEGORY = cat;
    getNewsReq.NO_OF_TEMPLES = count;
    getNewsReq.DISPLAY_PURPOSE = purpose;
    getNewsReq.IS_INCLUDE = include;
    getNewsReq.RESULT_TYPE = resultType;
    getNewsReq.LAST_TEMPLE_ID = lastNewsId;
    return this.http.getTemples(getNewsReq).pipe(
      catchError(this.handleError)
    );
  }

  getNearestTemples(templeExternalId: string) {
    return this.http.getNearestTemples(templeExternalId).pipe(
      catchError(this.handleError)
    );
  }

  postTemple(formData: FormData, isNew: boolean)
    : Observable<ApiResponse> {
    return this.http.postTemple(formData, isNew);
  }

  handleError(error: HttpErrorResponse) {
    // debugger;
    if (error.error instanceof ErrorEvent) {
      this.messageService.add({ severity: 'error', summary: error.error.message, key: MESSAGE_SERVICE_KEY})
    } else {
      // console.log(error);
      this.messageService.add({ severity: 'error', summary: 'Something bad happened please try later', key: MESSAGE_SERVICE_KEY})
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

}
