import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppHttpService } from 'src/app/common/http.service';
import { MenuCategories } from 'src/app/common/menu.model';
import { MenuService } from 'src/app/common/menu.service';
import { ProfileService } from 'src/app/common/profile.service';
import { MESSAGE_SERVICE_KEY } from 'src/app/constants';
import { ImageCategory, Place, Role_Types, UserVM } from 'src/app/models/avbhakthi.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {

  private serviceSubscriptions: Subscription;
  @ViewChild('gmap') map: any;
  user: UserVM;
  options: any;
  uploadedFiles: any[] = [];
  submitting: any = false;
  overlays: any[];

  constructor(
    private http: AppHttpService,
    private messageService: MessageService,
    public afAuth: AngularFireAuth,
    private router: Router,
    private menuService: MenuService,
    private profileService: ProfileService
  ) {
    this.serviceSubscriptions = new Subscription();
this.profileService.profileInfo.subscribe(user => {
  if(user.Role_Type === Role_Types.user){
    this.menuService.setMenuItems(MenuCategories.removeAll); 
  } else  {
    this.menuService.setMenuItems(MenuCategories.all); 
  }
})
    

    this.user = new UserVM();
    this.http.getProfile().subscribe(data => {
      // console.log('User profile', data);
      this.user = data;
      //  this.options = {
      //     center: {lat: data.LATITUDE, lng: data.LONGITUDE},
      //     zoom: 16
      // };
      let n = new Place();
      n.lat = data.LATITUDE;
      n.lng = data.LONGITUDE;
      n.formattedAddress = data.GEO_LOC_ADDRESS;
      this.setOverlays(n);
    });

    this.afAuth.authState.subscribe(user => {
      if (user) { // If authstate exist
        // console.log('Firebase user subscription from Profile service: ', user);
        this.user.PHONENO = user.phoneNumber;
        this.user.FIREBASE_UID = user.uid;
      } else {
      //  this._isAuthenticated$.next(false); // Update user not logged in
      }
    }, error => {
      console.error('Firbease error: ', error);
      // this._isAuthenticated$.next(false);
    });
  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.options = {
      center: { lat: 36.890257, lng: 30.707417 },
      zoom: 16
    };
  }
  onUpload(event) {
    this.uploadedFiles = [];
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }
  saveUserDetails(form: NgForm) {
    var formData = new FormData();

    if (form.valid) {
      var isNew: boolean = false;
      for (let key in this.user) {
        formData.append(key, this.user[key]);
      }
      if (this.uploadedFiles.length > 0) {
        formData.append('imgFileObj', this.uploadedFiles[0])
      }

      formData.append('imageInfo.IMAGE_CATEGORY', ImageCategory.imgProfile.toString());
      if (this.user.USER_ID) {
        formData.append('imageInfo.UID', this.user.USER_ID);
      }
      else {
        formData.append('imageInfo.UID', uuidv4());
        isNew = true;
      }
      this.submitting = true;
      this.serviceSubscriptions.add(
      this.http.saveUserDetails(formData, isNew).subscribe(data => {
        // console.log('User details update successfully', data);
        this.messageService.add({ severity: 'success', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
        this.profileService.refreshUserPofile();
        this.submitting = false;
        this.router.navigateByUrl('app');
      })
      );
    }
  }
  userLocationChanged(event: Place) {
    // // console.log('User location changed', event);
    this.user.GEO_LOC_PLACE_ID = event.placeId;
    this.user.LATITUDE = event.lat;
    this.user.LONGITUDE = event.lng;
    this.user.GEO_LOC_ADDRESS = event.formattedAddress;
    this.setOverlays(event);
  }

  setOverlays(place: Place) {
    // if (!this.overlays||!this.overlays.length) {
    this.overlays = [
      new google.maps.Marker({ position: { lat: place.lat, lng: place.lng }, title: place.formattedAddress }),
    ];
    this.map.getMap().setCenter({ lat: place.lat, lng: place.lng });
    // }
  }
}
