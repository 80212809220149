import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MESSAGE_SERVICE_KEY, USER_ROLES } from 'src/app/constants';
import { UpdateUserRoleMGR, UserVM } from 'src/app/models/avbhakthi.model';
import { AppHttpService } from '../../common/http.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, OnDestroy {
  private serviceSubscriptions: Subscription;
  users: UserVM[] = [];
  userRoles = USER_ROLES;
  userOptions = [];
  constructor(
    private http: AppHttpService,
    private messageService: MessageService
  ) {
    this.serviceSubscriptions = new Subscription();
    this.serviceSubscriptions.add(
      this.http.getUsers().subscribe(data => {
      //  // console.log('Users', data);
      this.users = data;
      this.userOptions = [];
      this.users.forEach(u => {
        this.userOptions.push({ 
          label: u.FIRST_NAME + ' ' + u.LAST_NAME,
          value: u.USER_ID
        });
      });
    })
    );
  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
  }

  changeUserRole(user: UserVM, evnt) {
    // // console.log('User role changed');
    // console.log(evnt);
    this.serviceSubscriptions.add(
    this.http.updateUserRole({
      USER_ID: user.USER_ID,
      ROLE_TYPE: user.Role_Type,
      MGR_ID: user.MGR_ID
    } as UpdateUserRoleMGR).subscribe(data => {
      // // console.log('User role update status', data);
      if (data.CODE === 100) {
        this.messageService.add({ severity: 'success', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
      } else {
        this.messageService.add({ severity: 'error', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
      }

    },error => {
      this.messageService.add({ severity: 'error', summary: error.error.DESC, key: MESSAGE_SERVICE_KEY});
    })
    );
  }

  
}
