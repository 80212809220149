import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';
import { MESSAGE_SERVICE_KEY } from '../constants';
import { Category, GetQuestionsVM, PurposeType, QuestionAnswerVM, QuestionVM, ResultType } from '../models/avbhakthi.model';
import { AppHttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(
    private http: AppHttpService,
    private messageService: MessageService
  ) { }

  getQuestions(count: number, cat: Category, purpose: PurposeType, include: boolean, resultType: ResultType, lastNewsId: string = ""): Observable<QuestionVM[]> {

    let getQuestionsReq = new GetQuestionsVM();
    getQuestionsReq.CATEGORY = cat;
    getQuestionsReq.NO_OF_QTNS = count;
    getQuestionsReq.DISPLAY_PURPOSE = purpose;
    getQuestionsReq.IS_INCLUDE = include;
    getQuestionsReq.RESULT_TYPE = resultType;
    getQuestionsReq.LAST_QTN_ID = lastNewsId;
    return this.http.getQuestions(getQuestionsReq);
  }

  postQuestion(formData: FormData, isNew: boolean = false) {
    if (isNew) {
      return this.http.postQuestion(formData).pipe(
        catchError(this.handleError)
      );
    } else {
      return this.http.updateQuestion(formData).pipe(
        catchError(this.handleError)
      );
    }
  }

  saveQuestionAnswer(request: QuestionAnswerVM) {
    return this.http.saveQuestionAnswer(request);
  }

  handleError(error: HttpErrorResponse) {
    // debugger;
    if (error.error instanceof ErrorEvent) {
      this.messageService.add({ severity: 'error', summary: error.error.message, key: MESSAGE_SERVICE_KEY})
    } else {
      // console.log(error);
      this.messageService.add({ severity: 'error', summary: 'Something bad happened please try later', key: MESSAGE_SERVICE_KEY})
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
}
