import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { BhakthiVarthaComponent } from '../bhakthi-vartha/bhakthi-vartha.component';
import { PrasnaVinodhiniComponent } from '../prasna-vinodhini/prasna-vinodhini.component';
import { YatraSandharshiniComponent } from '../yatra-sandharshini/yatra-sandharshini.component';
import { GuruSubhashiniComponent } from '../guru-subhashini/guru-subhashini.component';
import { BhakthiDetailsComponent } from '../bhakthi-details/bhakthi-details.component';
import { LoginComponent } from '../login/login.component';
import { YatraDetailsComponent } from '../yatra-details/yatra-details.component';
import { DashboardComponent } from '../dashboard/dashboard/dashboard.component';
import { AddModifyBhakthiVarthaComponent } from '../dashboard/add-modify-bhakthi-vartha/add-modify-bhakthi-vartha.component';
import { BhakthiVarthaDashboardComponent } from '../dashboard/bhakthi-vartha-dashboard/bhakthi-vartha-dashboard.component';
import { AddModifyPrasnaVinodhiniComponent } from '../dashboard/add-modify-prasna-vinodhini/add-modify-prasna-vinodhini.component';
import { PrasnaVinodhiniDashboardComponent } from '../dashboard/prasna-vinodhini-dashboard/prasna-vinodhini-dashboard.component';
import { AddModifyYatraSandharshiniComponent } from '../dashboard/add-modify-yatra-sandharshini/add-modify-yatra-sandharshini.component';
import { YatraSandharshiniDashboardComponent } from '../dashboard/yatra-sandharshini-dashboard/yatra-sandharshini-dashboard.component';
import { GuruSubhashiniDashboardComponent } from '../dashboard/guru-subhashini-dashboard/guru-subhashini-dashboard.component';
import { AddModifyGuruSubhashiniComponent } from '../dashboard/add-modify-guru-subhashini/add-modify-guru-subhashini.component';
import { ProfileComponent } from '../dashboard/profile/profile.component';
import { UsersComponent } from '../dashboard/users/users.component';
import { AlayaDarshiniComponent } from '../alaya-darshini/alaya-darshini.component';
import { TempleDetailsComponent } from '../temple-details/temple-details.component';
import { AlayaDarshiniDashboardComponent } from '../dashboard/alaya-darshini-dashboard/alaya-darshini-dashboard.component';
import { AddModifyAlayaDarshiniComponent } from '../dashboard/add-modify-alaya-darshini/add-modify-alaya-darshini.component';
import { MainComponent } from '../main/main.component';
import { AuthGaurdService as AuthGaurd } from '../common/auth-gaurd.service';
import { RoleGaurd } from '../common/role.guard';
import { AdminGuard } from '../common/admin.guard';
import { StoreGaurdGuard } from '../common/store-gaurd.guard';
import { RegisterPurohitComponent } from '../dashboard/register-purohit/register-purohit.component';
import { PurohitDarshiniComponent } from '../purohit-darshini/purohit-darshini.component';
import { PurohitDetailsComponent } from '../purohit-details/purohit-details.component';
import { PurohitApproveComponent } from '../dashboard/purohit-approve/purohit-approve.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from '../terms-of-service/terms-of-service.component';
import { RefundPolicyComponent } from '../refund-policy/refund-policy.component';
import { AddModifyPoojaItemComponent } from '../dashboard/add-modify-pooja-item/add-modify-pooja-item.component';
import { PoojaStoreComponent } from '../dashboard/pooja-store/pooja-store.component';
import { OtherInfoComponent } from '../other-info/other-info.component';
import { EPoojaStoreComponent } from '../e-pooja-store/e-pooja-store.component';
import { PoojaItemDetailsComponent } from '../pooja-item-details/pooja-item-details.component';
import { CartComponent } from '../cart/cart.component';
import { UserAddressesComponent } from '../dashboard/user-addresses/user-addresses.component';
import { AddModifyUserAddressComponent } from '../dashboard/add-modify-user-address/add-modify-user-address.component';
import { CheckOutComponent } from '../check-out/check-out.component';
import { PickAddressComponent } from '../check-out/pick-address/pick-address.component';
import { InitPaymentComponent } from '../check-out/init-payment/init-payment.component';
import { PlaceOrderComponent } from '../check-out/place-order/place-order.component';
import { OrderConfirmationComponent } from '../check-out/order-confirmation/order-confirmation.component';
import { UserOrdersComponent } from '../dashboard/user-orders/user-orders.component';
import { ConfirmUpdateOrdersComponent } from '../dashboard/confirm-update-orders/confirm-update-orders.component';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'other-info', component:OtherInfoComponent,
    children:[
      {
        path: 'privacy-policy', component: PrivacyPolicyComponent
      },
      {
        path: 'terms-of-service', component: TermsOfServiceComponent
      },
      {
        path: 'refund-policy', component: RefundPolicyComponent
      },
      {
        path: '**', component: PrivacyPolicyComponent
      },
    ]
  },
  {
    path: 'app', component: MainComponent, canActivate: [AuthGaurd],
    children: [
      {
        path: 'bhakhivartha-viseshini/details/:id', component: BhakthiDetailsComponent
      },
      {
        path: 'bhakhivartha-viseshini', component: BhakthiVarthaComponent
      },
      {
        path: 'prasna-vinodhini', component: PrasnaVinodhiniComponent
      },
      {
        path: 'yatra-sandharshini/details/:id', component: YatraDetailsComponent
      },
      {
        path: 'yatra-sandharshini', component: YatraSandharshiniComponent
      },
      {
        path: 'guru-subhashini', component: GuruSubhashiniComponent
      },
      {
        path: 'alaya-darshini', component: AlayaDarshiniComponent
      },
      {
        path: 'alaya-darshini/details/:id', component: TempleDetailsComponent
      },
      {
        path: 'purohit-darshini', component: PurohitDarshiniComponent
      },
      {
        path: 'purohit-darshini/details', component: PurohitDetailsComponent
      },
      {
        path: 'pooja-store', component: EPoojaStoreComponent
      },
      {
        path: 'pooja-store/details/:id', component: PoojaItemDetailsComponent
      },
      {
        path: 'pooja-store/cart', component: CartComponent
      },
      {
        path: 'privacy-policy', component: PrivacyPolicyComponent
      },
      {
        path: 'terms-of-service', component: TermsOfServiceComponent
      },
      {
        path: 'refund-policy', component: RefundPolicyComponent
      },
      {
        path: 'check-out', component: CheckOutComponent,
        children: [
          {
            path: 'cart', component: CartComponent
          },
          {
            path: 'pick-address', component: PickAddressComponent
          },
          {
            path: 'place-order', component: PlaceOrderComponent
          },
          {
            path: 'init-payment',  component: InitPaymentComponent
          },
          {
            path: 'confirmation',  component: OrderConfirmationComponent
          },
          {
            path: '',  redirectTo: '/app/check-out/cart', pathMatch: 'full'
          },
          
        ]
      },
      {
        path: '**', component: HomeComponent
      }
    ]
  },
  {
    path: 'dashboard',
    component: DashboardComponent, canActivate: [AuthGaurd],
    children: [
      {
        path: 'register-purohit', component: RegisterPurohitComponent
      },
      {
        path: 'user-addresses', component: UserAddressesComponent
      },
      {
        path: 'user-addresses/add/:id', component: AddModifyUserAddressComponent
      },
      {
        path: 'user-addresses/add', component: AddModifyUserAddressComponent
      },
      {
        path: 'bhakhivartha-viseshini/add/:id', component: AddModifyBhakthiVarthaComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'bhakhivartha-viseshini/add', component: AddModifyBhakthiVarthaComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'bhakhivartha-viseshini', component: BhakthiVarthaDashboardComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'prasna-vinodhini/add/:id', component: AddModifyPrasnaVinodhiniComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'prasna-vinodhini/add', component: AddModifyPrasnaVinodhiniComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'prasna-vinodhini', component: PrasnaVinodhiniDashboardComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'yatra-sandharshini/add/:id', component: AddModifyYatraSandharshiniComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'yatra-sandharshini/add', component: AddModifyYatraSandharshiniComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'yatra-sandharshini', component: YatraSandharshiniDashboardComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'guru-subhashini/add/:id', component: AddModifyGuruSubhashiniComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'guru-subhashini/add', component: AddModifyGuruSubhashiniComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'guru-subhashini', component: GuruSubhashiniDashboardComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'alaya-darshini/add/:id', component: AddModifyAlayaDarshiniComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'alaya-darshini/add', component: AddModifyAlayaDarshiniComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'alaya-darshini', component: AlayaDarshiniDashboardComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'pooja-store/add/:id', component: AddModifyPoojaItemComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'pooja-store/add', component: AddModifyPoojaItemComponent,
        canActivate: [RoleGaurd]
      },
      {
        path: 'pooja-store', component: PoojaStoreComponent,
        canActivate: [StoreGaurdGuard]
      },
      {
        path: 'purohit-darshini', component: PurohitApproveComponent,
        canActivate: [RoleGaurd,AdminGuard]
      },
      {
        path: 'confirm-orders', component: ConfirmUpdateOrdersComponent,
        canActivate: [StoreGaurdGuard]
      },
      {
        path: 'profile', component: ProfileComponent
      },
      {
        path: 'orders', component: UserOrdersComponent
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [RoleGaurd, AdminGuard]
      },
      {
        path: '**', component: ProfileComponent
      }
    ]
  },
  {
    path: 'profile', component: ProfileComponent
  },
  {
    path: '', redirectTo: '/app', canActivate: [AuthGaurd],
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot((routes))
  ],
  exports: [RouterModule]
})
export class RoutingModule { }
