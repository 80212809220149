import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { LOGINPAGESLIDER } from '../constants';
import { WindowService } from '../common/window.service';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { PhoneNumber } from '../models/avbhakthi.model';
import { MessageService } from 'primeng/api';
import { AuthService } from '../auth-service/auth.service';
import { Subscription } from 'rxjs';
import { Router, RouterModule } from '@angular/router';

/**
 * https://fireship.io/lessons/firebase-phone-authentication-with-angular-4-tutorial/
 * https://angular-templates.io/tutorials/about/firebase-authentication-with-angular
 * https://www.positronx.io/full-angular-7-firebase-authentication-system/
 * https://medium.com/@sunnysyed007/how-to-use-phone-authentication-in-angular-and-ionic-b7584eaa3236
 */


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  windowRef: any;
  readonly sliderContent = LOGINPAGESLIDER;
  phoneNumber = new PhoneNumber();
  verificationCode: string;
  submitting: boolean = false;
  recaptchaVerifier: any;
  confirmationResult: any;
  loginCodeSent : boolean = false;
  userData: any;
  authSubscription: Subscription;

  constructor(
    private win: WindowService,
    public afAuth: AngularFireAuth,
    private msgService: MessageService,
    private authService: AuthService,
    private router: Router,
  ) {

    this.afAuth.authState.subscribe(user => {
      if (user) { // If authstate exist
        this.userData = user;
        user.getIdToken().then(token => {
          // this.authToken = token;

          this.authService.setSessionToken(token);

          this.authService._isAuthenticated$.next(true); // then update user as logged in
          router.navigate(['app']);
        })
      } else {
        this.authService._isAuthenticated$.next(false); // Update user not logged in
      }
    }, error => {
      console.error('Firbease error: ', error);
      this.authService._isAuthenticated$.next(false);
    });
    // this.authSubscription = this.authService.isAuthenticated.subscribe(data => {
    //   if (data) {
    //     router.navigate(['app'])
    //     // this.router.events.subscribe(data => {
    //     //   // console.log(data)
    //     // })
    //   }
    // });
  }

  ngOnInit(): void {
    // this.windowRef = this.win.windowRef;
    // this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    // this.windowRef.recaptchaVerifier.render();
    let provider = new firebase.auth.PhoneAuthProvider();

    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible'
    });

  }
  sendLoginCode() {
    this.submitting = true;
    // const appVerifier = this.windowRef.recaptchaVerifier;
    //  // console.log(appVerifier);
    firebase.auth().signInWithPhoneNumber(this.phoneNumber.e164, this.recaptchaVerifier)
      .then(result => {
        this.confirmationResult = result;

        this.loginCodeSent = true;
        
        this.submitting = false;
      })
      .catch(
        error => {
          // console.log(error)
          this.submitting = false;
          this.msgService.add({ severity: 'error', summary: error.message, detail: 'Avbhakthi.com' })
        }
      );
  }

  verifyLoginCode() {
    this.confirmationResult
      .confirm(this.verificationCode)
      .then(result => {

        // console.log('Result', result);

      })
      .catch(error => console.log(error, "Incorrect code entered?"));
  }

  ngAfterViewInit(): void {
    // firebase.auth().currentUser.getIdToken(false).then(function (idToken) {
    //   // console.log(idToken);
    // });
  }

  ngOnDestroy() {
    // this.authSubscription.unsubscribe();
  }
  getYear(): number {
    return new Date().getFullYear();
  }
}
