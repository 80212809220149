<p-overlayPanel #op>
  <ng-template pTemplate>
    <share-buttons  
      [include]="['facebook','twitter','whatsapp','pinterest']"
      [url]="getShareLink()"
      ></share-buttons>
  </ng-template>
</p-overlayPanel>
<div class="p-d-flex" [ngClass]="isDetailed === true ? 'social-details':''" style="justify-content: center;">
  <p-button
    (click)="likeDisLike(true)"
    class="p-button-text"
    [label]="summeryInfo.NO_OF_LIKES.toString()"
    styleClass="p-button-text theme-color"
  >
    <img
      class="social-icon"
      [src]="socialIcons['LIKED'][summeryInfo.LIKE_STATUS]"
    />
  </p-button>
  <p-button
    (click)="likeDisLike(false)"
    class="p-button-text"
    [label]="summeryInfo.NO_OF_DIS_LIKES.toString()"
    styleClass="p-button-text theme-color"
  >
    <img
      class="social-icon"
      [src]="socialIcons['DISLIKED'][summeryInfo.LIKE_STATUS]"
    />
  </p-button>
  <p-button class="p-button-text" styleClass="p-button-text" (click)="op.toggle($event)">
    <img class="social-icon" [src]="socialIcons['SHARE']" />
  </p-button>
  <p-button
    (click)="bookmark()"
    class="p-button-text"
    styleClass="p-button-text"
  >
    <img
      class="social-icon"
      [src]="socialIcons['BOOKMARKED'][bookmarkIcon(summeryInfo.IS_BOOKMARKED)]"
    />
  </p-button>
</div>

