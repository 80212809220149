<div class="page-header full-bg">
  <div class="blur-bg page-header-content">
    <h1 class="p-text-center p-text-white page-title p-my-1">
      పురోహిత దర్శిని
    </h1>
    <hr class="special-line" />
    <h2 class="p-text-center page-sub-title p-my-1">
      మీరు ఇచ్చట గృహము మరియు దేవాలయమునందు జరుగు భక్తి, ఆధ్యాత్మిక, పూజా
      కార్యక్రమాలు చేయుటకు మీ సమీపంలో ఉన్న పురోహితులను తెలుసుకుని, వారిని నేరుగా
      సంప్రదించవచ్చును.
    </h2>
  </div>
</div>
<p-overlayPanel #op>
  <ng-template pTemplate>
    <share-buttons  
      [include]="['facebook','twitter','whatsapp','pinterest']"
      
      ></share-buttons>
  </ng-template>
</p-overlayPanel>
<div class="p-fluid purohit-details p-shadow-1">
  <div class="p-grid">
    <div class="p-col-12 p-text-center">
      <p-avatar
        styleClass="purohit-avatar"
        [image]="baseURL + purohit.IMAGE_PATH"
        size="xlarge"
        shape="circle"
      >
      </p-avatar>
      <h2 class="theme-color p-my-0 line-height-1 p-text-light">
        {{ purohit.PUROHIT_PUBLIC_ID }}
      </h2>
    </div>
    <div class="p-col-12">
      <h2 class="theme-color p-mb-0 line-height-1">Purohit Details</h2>
      <hr />
      <div class="p-grid">
        <div class="p-col-6">Name</div>
        <div class="p-col-6">
          {{ purohit.FIRST_NAME + " " + purohit.LAST_NAME }}
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-6">Experience</div>
        <div class="p-col-6">{{ purohit.EXPERIENCE }}</div>
      </div>
      <div class="p-grid">
        <div class="p-col-6">Qualification</div>
        <div class="p-col-6">{{ purohit.QUALIFICATION }}</div>
      </div>
      <div class="p-grid">
        <div class="p-col-6">Languages known</div>
        <div class="p-col-6">{{ purohit.KNOWN_LANGUAGES }}</div>
      </div>
    </div>
    <div class="p-col-12">
      <h2 class="theme-color p-mb-0 line-height-1">Category</h2>
      <hr />
      <div class="p-grid">
        <div class="p-col-12">
          <p-chips [(ngModel)]="purohitCategories" disabled="true" styleClass="disabled-chips"></p-chips>
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <h2 class="theme-color p-mb-0 line-height-1">Address</h2>
      <hr />
      <div class="p-grid">
        <div class="p-col-12">
          {{ purohit.CITY.toUpperCase()+', '+purohit.DISTRICT.toUpperCase() +', '+purohit.STATE.toUpperCase() +'-'+purohit.PINCODE }}
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <h2 class="theme-color p-mb-0 line-height-1">About</h2>
      <hr />
      <div class="p-grid">
        <div class="p-col-12">
          {{ purohit.ABOUT }}
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <h2 class="theme-color p-mb-0 line-height-1">Communication</h2>
      <hr />
      <div class="p-grid">
        <div class="p-col-3">
          <p-button styleClass="p-px-3 btn-theme">
            <ng-template pTemplate="content">
              <a [href]="'mailto:'+ purohit.EMAIL" style="color: inherit" class="p-mx-auto"
                ><i class="pi pi-envelope"></i
              ></a>
            </ng-template>
          </p-button>
        </div>
        <div class="p-col-3">
          <p-button styleClass="p-px-3 btn-theme">
            <ng-template pTemplate="content">
              <a [href]="'tel:'+purohit.EMAIL" style="color: inherit" class="p-mx-auto"
                ><i class="pi pi-mobile"></i
              ></a>
            </ng-template>
          </p-button>
        </div>
        <div class="p-col-3">
          <p-button styleClass="p-px-3 btn-theme">
            <ng-template pTemplate="content">
              <a [href]="getPurohitDirections()" target="_blank" style="color: inherit" class="p-mx-auto"
                ><i class="pi pi-directions"></i
              ></a>
            </ng-template>
          </p-button>
        </div>
        <div class="p-col-3">
          <p-button styleClass="p-px-3 btn-theme" (click)="op.toggle($event)">
            <ng-template pTemplate="content">
              <a href="#" style="color: inherit" class="p-mx-auto"
                ><i class="pi pi-share-alt"></i
              ></a>
            </ng-template>
          </p-button>
        </div>
      </div>
    </div>
  </div>
</div>
