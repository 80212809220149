<div class="card">
  <h2 class="p-text-center theme-color">Pending Purohit List</h2>
  <p-table
    #userDt
    [value]="purohitList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[10, 25, 50]"
    [globalFilterFields]="['PUROHIT_PUBLIC_ID', 'FIRST_NAME', 'LAST_NAME', 'QUALIFICATION','SELECTED_CATEGORY', 'PHONENO']"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-ml-auto" style="max-width: 250px">
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            style="padding-left: 40px;"
            (input)="userDt.filterGlobal($event.target.value, 'contains')"
            placeholder="Search keyword"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>PUBLIC NAME</th>
        <th>NAME</th>
        <th>ABOUT</th>
        <th>QUALIFICATION</th>
        <th>CATEGORIES</th>
        <th>PHONE</th>
        <th>ADDRESS</th>
        <th>ACTION</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr>
        <td>{{ user.PUROHIT_PUBLIC_ID }}</td>
        <td>{{ user.FIRST_NAME + " " + user.LAST_NAME }}</td>
        <td>{{ user.ABOUT }}</td>
        <td>{{ user.QUALIFICATION }}</td>
        <td>{{ user.SELECTED_CATEGORY }}</td>
        <td>{{ user.PHONENO }}</td>
        <td>{{ user.CITY }}, {{ user.DISTRICT }}, {{ user.STATE }}</td>

        <td>
          <button
            pButton
            pRipple
            type="button"
            label="Approve"
            style="max-width: 150px"
            icon="pi pi-check"
            class="p-button-raised btn-theme p-button-text"
            (click)="approvePurohit(user.PUROHIT_ID)"
          ></button>
        </td>
      </tr>
    </ng-template>
    <!-- <ng-template pTemplate="paginatorleft">
              <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
          </ng-template> -->
    <!-- <ng-template pTemplate="paginatorright">
              <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
          </ng-template> -->
  </p-table>
</div>
