import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MESSAGE_SERVICE_KEY } from 'src/app/constants';
import { EPoojaStoreService } from 'src/app/e-store/e-pooja-store.service';
import { CheckoutItemsResponseVM, CheckoutOrderInfoVM, ICustomWindow, PAYMENTDATA, PAYMENTRESPONSE, RazorPayPaymentFailureResponse, RazorPayResponse, UserVM } from 'src/app/models/avbhakthi.model';
import { WindowRefService } from 'src/app/common/window-ref.service';
import { ProfileService } from 'src/app/common/profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-init-payment',
  templateUrl: './init-payment.component.html',
  styleUrls: ['./init-payment.component.css']
})
export class InitPaymentComponent implements OnInit, OnDestroy {
  isProgress: boolean = true;
  private userDetails: UserVM;
  private _window: ICustomWindow;
  private checkoutItemsResponse: CheckoutItemsResponseVM;
  public rzp: any;
  public options: any = {
    key: '', // Razor pay key
    name: '',
    description: '',
    amount: 0,
    order_id: '',
    prefill: {
      name: '',
      email: '',
      contact: ''
    },
    notes: {},
    theme: {
      color: '#ff5722'
    },
    handler: this.paymentHandler.bind(this),
    modal: {
      confirm_close: true,
      ondismiss: ((res) => {
        this.zone.run(() => {
          //TODO: add current page routing if payment fails
          // console.log('Payment failure response: ', res);
          window.location.reload();
        })
      })
    },
    retry: {
      enabled: true,
      max_count: 1
    }
  }
  private serviceSubscriptions: Subscription;
  constructor(
    private cartService: EPoojaStoreService,
    private messageService: MessageService,
    private zone: NgZone,
    private winRef: WindowRefService,
    private profileService: ProfileService,
    private router: Router
  ) {
    this.serviceSubscriptions = new Subscription();
    this.checkoutItemsResponse = new CheckoutItemsResponseVM();
    this._window = this.winRef.nativeWindow;
    this.userDetails = new UserVM();
    this.serviceSubscriptions.add(
      this.profileService.profileInfo.subscribe(data => {
        this.userDetails = data;
      })
    );
  }


  ngOnInit(): void {
    this.serviceSubscriptions.add(
      this.cartService.initPaymentApi().subscribe(data => {
        this.isProgress = false;
        this.checkoutItemsResponse = data;
        this.initPay(data.GATEWAY_ORDER_INFO.AMOUNT_IN_SUBUNITS, data.GATEWAY_ORDER_INFO.KEY_ID, data.ORDER_NUMBER, data.GATEWAY_ORDER_INFO.ORDER_ID)
      },
        error => {
          this.isProgress = false;
          this.messageService.add({ severity: 'error', summary: error.error.DESC, key: MESSAGE_SERVICE_KEY })
        })
    );
  }

  // Initialize razor pay
  initPay(totalAmount, razorPayKey, internalOrderId, razorPayOrderId): void {

    // Fix to razorpay not working after one payment
    let scriptTag = this.winRef.nativeWindow.document.getElementById('razorPayLink');
    let razorPayLink = scriptTag.getAttribute('src');
    scriptTag.remove();
    let scriptTagCreated = this.winRef.nativeWindow.document.createElement('script') as any;
    scriptTagCreated.id = 'razorPayLink';
    scriptTagCreated.src = razorPayLink;
    this.winRef.nativeWindow.document.getElementsByTagName('body')[0].append(scriptTagCreated);
    scriptTag.setAttribute('src', scriptTag.getAttribute('src'));

    this.options.key = razorPayKey;
    this.options.amount = totalAmount;
    this.options.description = internalOrderId;
    this.options.name = 'avbhakthi.com'
    this.options.order_id = razorPayOrderId;
    this.options.prefill.contact = this.userDetails.PHONENO;
    this.options.prefill.name = this.userDetails.FIRST_NAME + ' ' + this.userDetails.LAST_NAME;
    this.options.prefill.email = this.userDetails.EMAIL;
    this.rzp = new this.winRef.nativeWindow['Razorpay'](this.options);
    this.rzp.open();

    let _this = this;
    this.rzp.on('payment.failed', res => {
      _this.paymentFailureHandler(res);
    });
  
  }


  handleModalDismiss() {
    this.router.navigate['/app/pooja-store/cart'];
  }


  paymentFailureHandler(res: RazorPayPaymentFailureResponse) {
    this.zone.run(() => {
      // console.log('Payment failed Response: ', res);
      // Initializing payment object
      if(this.rzp)
        this.rzp.close();
      let checkoutOrderResponse = new CheckoutOrderInfoVM();
      checkoutOrderResponse.CHECKOUT_ITEM_EXTERNAL_ID = this.checkoutItemsResponse.CHECKOUT_ITEM_EXTERNAL_ID;
      checkoutOrderResponse.INTERNAL_ORDER_ID = this.checkoutItemsResponse.ORDER_NUMBER;
      checkoutOrderResponse.IS_SUCCESS = false; // false for failure
      checkoutOrderResponse.ORDER_TOTAL_AMOUNT = +this.checkoutItemsResponse.GATEWAY_ORDER_INFO.AMOUNT_IN_SUBUNITS;
  
      let paymentResponse = new PAYMENTRESPONSE();
      paymentResponse.GATEWAY_RESPONSE = JSON.stringify(res);
  
      // Payment data for success information
      let paymentData = new PAYMENTDATA();
      paymentData.userContact = this.userDetails.PHONENO;
      paymentData.userEmail = this.userDetails.EMAIL;
  
      // Payment error information
      paymentResponse.ERROR_INFO = res;
  
      paymentResponse.PAYMENT_DATA = paymentData;
  
      checkoutOrderResponse.PAYMENT_RESPONSE = paymentResponse;
  
      this.sendPaymentStatus(checkoutOrderResponse); // Sending data to server
    });
    
  }

  // paymentHandlers
  paymentHandler(res: RazorPayResponse) {
    this.zone.run(() => {

      // console.log('Pay Success response: ', res);

      // Initializing payment object
      let checkoutOrderResponse = new CheckoutOrderInfoVM();
      checkoutOrderResponse.CHECKOUT_ITEM_EXTERNAL_ID = this.checkoutItemsResponse.CHECKOUT_ITEM_EXTERNAL_ID;
      checkoutOrderResponse.INTERNAL_ORDER_ID = this.checkoutItemsResponse.ORDER_NUMBER;
      checkoutOrderResponse.IS_SUCCESS = true; // True for success
      checkoutOrderResponse.ORDER_TOTAL_AMOUNT = +this.checkoutItemsResponse.GATEWAY_ORDER_INFO.AMOUNT_IN_SUBUNITS;

      let paymentResponse = new PAYMENTRESPONSE();
      paymentResponse.GATEWAY_RESPONSE = JSON.stringify(res);

      // Payment data for success information
      let paymentData = new PAYMENTDATA();
      paymentData.orderId = res.razorpay_order_id;
      paymentData.paymentId = res.razorpay_payment_id;
      paymentData.signature = res.razorpay_signature;
      paymentData.userContact = this.userDetails.PHONENO;
      paymentData.userEmail = this.userDetails.EMAIL;

      paymentResponse.PAYMENT_DATA = paymentData;

      checkoutOrderResponse.PAYMENT_RESPONSE = paymentResponse;

      // Call post object information here
      this.sendPaymentStatus(checkoutOrderResponse); // sending data to server
    })
  }

  sendPaymentStatus(request: CheckoutOrderInfoVM) {
    this.isProgress = true;
    this.serviceSubscriptions.add(
      this.cartService.confirmPayment(request).subscribe(response => {
        // console.log('Payment response from server', response);
        this.router.navigate(['/app/check-out/confirmation']);
      }, error => {

      })
    );
  }

  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
    this.rzp = null;
  }

}
