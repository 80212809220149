<div class="card p-mx-auto p-px-lg-4" style="max-width: 1100px">
    <h1 class="theme-color p-mb-1 line-height-1 p-text-light">Order History</h1>
    <div class="p-text-center p-p-4" *ngIf="orderedItemsList.ITEMS.length === 0">
        <h1>You have no order history information</h1>
    </div>
    <p-dataView #dv [value]="orderedItemsList.ITEMS" [paginator]="false" filterBy="ITEM_NAME" layout="grid"
      [paginator]="true" [rows]="noOfRowsPage" *ngIf="orderedItemsList.ITEMS.length > 0" [lazy]="true" [totalRecords]="totalRecords"
      (onLazyLoad)="lazyLoadData($event)">
      <ng-template pTemplate="header">
        <!-- <h1 class="theme-color p-text-light p-mt-0 line-height-1">Your cart</h1> -->
      </ng-template>
      <ng-template let-product pTemplate="gridItem">
        <div class="p-col-12" [ngStyle]="
              product.ITEM_INFO.ITEM_STATUS === false
                ? { opacity: '0.6' }
                : ''
            ">
          <div class="product-list-item p-card p-my-1">
            <div class="p-grid">
              <div class="p-col-6 p-lg-3">
                <h2 class="header-heading  p-m-0 line-height-1">ORDER NUMBER</h2>
                <p class="header-text  p-m-0  font-family-roboto">{{product.ORDER_INFO.ORDER_NUMBER}}</p>
              </div>
              <div class="p-col-6 p-lg-3">
                <h2 class="header-heading  p-m-0 line-height-1">TOTAL</h2>
                <p class="header-text  p-m-0  font-family-roboto">
                  &#8377;{{product.ITEM_PRICE_INFO.ITEM_TOTAL_AMOUNT_WITH_DELIVERY_CHARGES | number}}/-</p>
              </div>
              <div class="p-col-6 p-lg-3 ">
                <h2 class="header-heading  p-m-0 line-height-1">ORDERED BY</h2>
                <p class="header-text  p-m-0  font-family-roboto">{{product.USER_NAME}}</p>
              </div>
              
              <div class="p-col-6 p-lg-3">
                <h2 class="header-heading p-m-0 line-height-1">ORDER PLACED</h2>
                <p class="header-text p-m-0  font-family-roboto">{{product.ORDER_INFO.DATE_ORDER_PLACED}}</p>
              </div>
            </div>
            <div class="p-grid">
              <!-- Details panel -->
              <div class="p-col-12 p-md-8 p-lg-7">
                <div class="p-grid p-no-glutter">
                  <div class="p-col-12 p-md-6">
                    <img [src]="getImageURL(product.ITEM_INFO.ITEM_IMAGES_PATH)" [alt]="product.ITEM_INFO.ITEM_NAME" />
                  </div>
                  <div class="p-col-12 p-md-6">
                    <div class="product-list-detail">
                      <div class="product-name line-height-1 theme-color">
                        {{ product.ITEM_INFO.ITEM_NAME }}
                      </div>
                      <i class="pi pi-tag product-category-icon"></i><span class="product-category">{{
                        getCategoryName(product.ITEM_INFO.CATEGORY_ID)
                        }}</span>
                        <br />
                      <span>Quantity: {{product.QUANTITY}}</span>
      
                    </div>
                  </div>
                  <div class="p-col-12 p-md-7">
                    <h3 class="p-my-0 line-height-1">Price Details</h3>
                    <div class="p-grid">
                      <div class="p-col-6 line-height-1">
                        List Price
                      </div>
                      <div class="p-col-6 p-text-right font-family-roboto line-height-1">
                        &#8377;{{product.ITEM_PRICE_INFO.ITEM_AMOUNT | number}}/-
                      </div>
                      <div class="p-col-6 line-height-1">
                        Selling Price
                      </div>
                      <div class="p-col-6 p-text-right font-family-roboto line-height-1">
                        &#8377;{{product.ITEM_PRICE_INFO.ITEM_TOTAL_AMOUNT | number}}/-
                      </div>
                      <div class="p-col-6 line-height-1"  >
                        Bag Savings
                      </div>
                      <div class="p-col-6 p-text-right font-family-roboto line-height-1">
                        &#8377;{{product.ITEM_PRICE_INFO.ITEM_DISCOUNT_AMOUNT | number}}/-
                      </div>
                      <div class="p-col-6 line-height-1">
                        Delivery charges
                      </div>
                      <div class="p-col-6 p-text-right font-family-roboto line-height-1">
                        &#8377;{{product.ITEM_PRICE_INFO.ITEM_DELIVERY_CHARGES | number}}/-
                      </div>
                      <div class="p-col-6 line-height-1 p-text-bold">
                        Total Amount
                      </div>
                      <div class="p-col-6 p-text-right font-family-roboto line-height-1 p-text-bold">
                        &#8377;{{product.ITEM_PRICE_INFO.ITEM_TOTAL_AMOUNT_WITH_DELIVERY_CHARGES | number}}/-
                      </div>
                    </div>
                  </div>
                  <div class="p-col-12 p-md-5">
                    <h3 class="p-my-0 line-height-1">Delivery Address</h3>
                    <p class="p-m-0 line-height-1">{{product.ORDER_INFO.DELIVERY_ADDRESS.FULL_NAME}}</p>
                    <p class="p-m-0 line-height-1">{{product.ORDER_INFO.DELIVERY_ADDRESS.FLAT_HOUSE_NO_BUILDING_APARTMENT}}</p>
                    <p class="p-m-0 line-height-1">{{product.ORDER_INFO.DELIVERY_ADDRESS.AREA_COLONY_STREET_SECTOR_VILLAGE}}</p>
                    <p class="p-m-0 line-height-1">{{product.ORDER_INFO.DELIVERY_ADDRESS.LANDMARK}} - {{product.ORDER_INFO.DELIVERY_ADDRESS.TOWN_CITY}}</p>
                    <p class="p-m-0 line-height-1">{{product.ORDER_INFO.DELIVERY_ADDRESS.STATE}} - {{product.ORDER_INFO.DELIVERY_ADDRESS.PINCODE}}</p>
                    <p class="p-m-0 line-height-1">{{product.ORDER_INFO.DELIVERY_ADDRESS.MOBILE_NUMBER}}</p>
                  </div>
                </div>
              </div>
              
              <!-- Item track -->
              <div class="p-col-12 p-md-4 p-lg-5">
                <p-timeline [value]="product.STATUS_TRACK" styleClass="orderStatusTrackTimeline">
                  <ng-template pTemplate="content" let-event>
                    <div class="p-pb-1" style="border-bottom: solid 0.25px #e8e8e894">
                      <h2 class="p-m-0 line-height-1 p-text-light">{{event.ORDER_STATUS_CODE_NAME}}</h2>
                      <p class="p-m-0 line-height-1 font-family-roboto" style="font-size: 0.6rem;">{{event.CHANGED_ON |
                        dateIso}}</p>
                      <!-- <p class="p-m-0 line-height-1">{{event.NOTES}} </p> -->
                    </div>
  
                  </ng-template>
                </p-timeline>
              </div>
              <div class="p-col-12">
                Payment Method: <span class="p-text-bold p-text-uppercase">{{product.ORDER_INFO.PAYMENT_METHOD}}</span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </div>