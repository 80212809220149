import { Component, OnInit, Input, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { QuestionVM, CorrectStatus, QuestionType, QuestionAnswerVM } from '../models/avbhakthi.model';
import {  SOCIALICONS } from '../constants';
import * as moment from 'moment';
import { QuestionService } from '../common/question.service';
import { ProfileService } from '../common/profile.service';
import { AppConfigService } from '../common/app-config.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-question-card',
  templateUrl: './question-card.component.html',
  styleUrls: ['./question-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class QuestionCardComponent implements OnInit, AfterViewInit, OnDestroy {

  private serviceSubscriptions: Subscription;
  @Input() questionObj: QuestionVM;
  readonly baseURL: string = AppConfigService.settings.serverURL;
  readonly socialIcons = SOCIALICONS;
  multiSelectAnswer: string[];
  // readonly correctStatus: CorrectStatus;
  // readonly questionType: QuestionType;

  constructor(
    private questionService: QuestionService,
    private profileService: ProfileService
  ) {
    this.serviceSubscriptions = new Subscription();
  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    if (this.questionObj.QT_TYPE === QuestionType.multiSelect && this.questionObj.CORRECT_STATUS !== CorrectStatus.notAttempted) {
      this.multiSelectAnswer = this.questionObj.USER_ANSWER.split(',');
    }
  }

  getDate(dateTime: string): string {
    return moment(dateTime).format('MM-DD-YYYY');
  }

  public get questionType(): typeof QuestionType {
    return QuestionType;
  }

  public get correctStatus(): typeof CorrectStatus {
    return CorrectStatus;
  }

  public get isQuestionAnswered(): boolean {
    return this.questionObj.CORRECT_STATUS === this.correctStatus.notAttempted ? false : true;
  }

  submitAnswer() {
    if (this.questionObj.USER_ANSWER === this.questionObj.ANSWER) {
      this.questionObj.CORRECT_STATUS = CorrectStatus.correct;
      this.profileService.updateUserPoints(true, this.questionObj.POINTS);
    } else {
      this.questionObj.CORRECT_STATUS = CorrectStatus.notCorrect;
      this.profileService.updateUserPoints(false, this.questionObj.POINTS);
    }
    let request = new QuestionAnswerVM();
    request.QUESTION_EXTERNAL_ID = this.questionObj.QUESTION_EXTERNAL_ID;
    request.ANSWER = this.questionObj.USER_ANSWER;
    request.RESPOND_ON = this.getRespondedDate();
    this.serviceSubscriptions.add(
    this.questionService.saveQuestionAnswer(request).subscribe(res => {
      // console.log(res);
    })
    );
  }

  multiCheck() {
    this.questionObj.USER_ANSWER = this.multiSelectAnswer.toString();
  }
  getRespondedDate(): string {
    let dt = new Date();
    return `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
  }

  multiCheckErrorClass(answer: number) {
    return this.questionObj.CORRECT_STATUS === CorrectStatus.correct &&
    this.questionObj.USER_ANSWER.includes(answer.toString())
      ? 'p-text-success p-text-bold'
      : this.questionObj.CORRECT_STATUS === CorrectStatus.notCorrect &&
      this.questionObj.USER_ANSWER.includes(answer.toString())
      ? 'p-text-danger p-text-bold'
      : this.questionObj.CORRECT_STATUS === CorrectStatus.notCorrect && this.questionObj.ANSWER.includes(answer.toString())
      ? 'p-text-success p-text-bold' : ''
  }

}
