<div class="page-header full-bg">
  <div class="blur-bg page-header-content">
    <h1 class="p-text-center p-text-white page-title p-my-1">
      భక్తి వార్త విశేషిణి
    </h1>
    <hr class="special-line" />
    <!-- <h2 class="p-text-center page-sub-title p-my-1">ఇందులో మీరు దేవాలయాలలో జరిగే పూజ కార్యక్రమాలను, ఉత్సవాల సమాచారంను. భక్తి ఆధ్యాత్మిక విశేషాలను తెలుసుకోవచ్చు. మీరు కోరుకున్న రోజున ఆ సమాచారాన్ని మళ్ళిచూడవచ్చు,మీ ఆత్మీయులతో పంచుకోవచ్చును.</h2> -->
  </div>
</div>
<p-card styleClass="p-card-shadow news-details p-mb-4">
  <div class="full-bg card-header-bg">
    <img [src]="getImageURL()" class="img-fluid" />
  </div>
  <div class="p-card-title" [innerHtml]="newsObj.TITLE">
    <!-- {{newsObj.TITLE}} -->
  </div>
  <div class="p-card-subtitle">
    <i class="fas fa-map-marked-alt p-mr-2"></i>{{ newsObj.SUB_TITLE }}
  </div>
  <div class="card-description p-text-justify">
    {{ newsObj.CONTENT }}
  </div>
  <ng-template pTemplate="footer">
    <app-card-footer
      [category]="newsCategory.bhakthiNews"
      [externalId]="newsObj.NEWS_EXTERNAL_ID"
      [summeryInfo]="newsObj.SUMMARY_INFO"
      [isDetailed] = "true"
    >

    </app-card-footer>
    <!-- <p-button
      class="p-button-text p-mr-3 p-ml-3"
      [label]="newsObj.SUMMARY_INFO.NO_OF_LIKES.toString()"
      styleClass="p-button-text theme-color"
    >
      <img
        class="social-icon"
        [src]="socialIcons['LIKED'][newsObj.SUMMARY_INFO.LIKE_STATUS]"
      />
    </p-button>
    <p-button
      class="p-button-text p-mr-3 p-ml-3"
      [label]="newsObj.SUMMARY_INFO.NO_OF_DIS_LIKES.toString()"
      styleClass="p-button-text theme-color"
    >
      <img
        class="social-icon"
        [src]="socialIcons['DISLIKED'][newsObj.SUMMARY_INFO.LIKE_STATUS]"
      />
    </p-button>
    <p-button class="p-button-text p-mr-3 p-ml-3" styleClass="p-button-text">
      <img class="social-icon" [src]="socialIcons['SHARE']" />
    </p-button>
    <p-button class="p-button-text p-mr-3 p-ml-3" styleClass="p-button-text">
      <img
        class="social-icon"
        [src]="socialIcons['BOOKMARKED'][newsObj.SUMMARY_INFO.IS_BOOKMARKED]"
      />
    </p-button> -->
    <div class="p-text-center next-prev-container">
      <button
        pButton
        pRipple
        type="button"
        (click)="previousNews()"
        label="PREV"
        class="p-button-outlined p-button-warning prev-button"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        (click)="nextNews()"
        label="NEXT"
        class="p-button-outlined p-button-warning next-button"
      ></button>
    </div>
  </ng-template>
</p-card>
<div class="p-fluid p-text-center">
  <app-loader [enable]="contentLoading"></app-loader>
</div>
