import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { MenuCategories } from '../common/menu.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private items$: BehaviorSubject<MenuItem[]>;
  public items: Observable<MenuItem[]>;
  constructor() {
    this.items$ = new BehaviorSubject<MenuItem[]>([]);
    this.items = this.items$.asObservable();
    // this.items$.next(this.setMenuItems(MenuCategories.all));
    this.setMenuItems(MenuCategories.all);
  }

  setMenuItems(cat: MenuCategories) {
    let menuItems: MenuItem[] = [
      {
        label: 'భక్తి వార్త విశేషిణి',
        icon: 'menu-item bhakthi-vartha',
        routerLink: 'bhakhivartha-viseshini',
        // styleClass: 'menu-item-selected'
      },
      {
        label: 'ఆలయ దర్శిని',
        icon: 'menu-item alaya-darshini',
        routerLink: 'alaya-darshini',
      },
      {
        label: 'ప్రశ్న వినోదిని',
        icon: 'menu-item prasna-vinodini',
        routerLink: 'prasna-vinodhini',
      },
      {
        label: 'యాత్ర సందర్శిని',
        icon: 'menu-item yatra-sandharshini',
        routerLink: 'yatra-sandharshini',
      },
      {
        label: 'గురు సుభాషిణి',
        icon: 'menu-item guru-subhashini',
        routerLink: 'guru-subhashini',
      },
      {
        label: 'పురోహిత దర్శిని',
        icon: 'menu-item purohit-darshini',
        routerLink: 'purohit-darshini',
      },
      {
        label: 'e-విక్రయని',
        icon: 'menu-item e-vikraini',
        routerLink: 'pooja-store',
      }
    ];

    switch (cat) {
      case MenuCategories.bhakthiNews:
        menuItems[0]['styleClass'] = 'menu-item-selected';
        break;
      case MenuCategories.alayaDarshini:
        menuItems[1]['styleClass'] = 'menu-item-selected';
        break;
      case MenuCategories.prasnaVinodini:
        menuItems[2]['styleClass'] = 'menu-item-selected';
        break;
      case MenuCategories.yatraSandharshini:
        menuItems[3]['styleClass'] = 'menu-item-selected';
        break;
      case MenuCategories.guruSubhashini:
        menuItems[4]['styleClass'] = 'menu-item-selected';
        break;
      case MenuCategories.purihitDarshini:
        menuItems[5]['styleClass'] = 'menu-item-selected';
        break;
      case MenuCategories.eVikrayini:
        menuItems[6]['styleClass'] = 'menu-item-selected';
        break;
      default:
        // console.log('All menu items selcted');
    }

    // Setting up role wise menu
    if (cat === MenuCategories.removeAll) { // Remove header Menu items for normal when dashboard visited
      menuItems = [];
    }

    this.items$.next(menuItems);
  }
}
