import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { AuthService } from '../auth-service/auth.service';
import { MESSAGE_SERVICE_KEY } from '../constants';
import { Role_Types } from '../models/avbhakthi.model';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(
    private profileService: ProfileService,
    private auth: AuthService,
    private messageService: MessageService,
    private router: Router
  ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.profileService.UserRole === Role_Types.admin 
        || this.profileService.UserRole === Role_Types.superAdmin
        ) {
          return true;
        } else {
          this.messageService.add({severity:'error', summary: 'Access denied', detail: 'You are not authorized to view this content.', key: MESSAGE_SERVICE_KEY});
          this.router.navigate(['app']);
        }
    return false;
  }
  
}
