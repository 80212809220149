import { Injectable } from '@angular/core';
import { PostPurohitVM, PurohitFilter } from '../models/avbhakthi.model';
import { AppHttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PurohitDarshiniService {

  constructor(
    private http: AppHttpService
  ) { }

  // To fetch purohit categories
  purohitCategories() {
    return this.http.getPurohitCategories();
  }

  // To fetch purohit details
  purohitDetails() {
    return this.http.getPurhoitDetails();
  }

  // Post purohitDetails
  postPurohitDetails(isNew: boolean, purohitDetails: PostPurohitVM){
    if(isNew)
      return this.http.registerPurohit(purohitDetails);
    else
      return this.http.updatePurohit(purohitDetails);
  }

  // Check for purohit public name uniqueness
  checkPurohitName(purohitName:string) {
    return this.http.checkForPurohitPublicName(purohitName);
  }

  // To fetch purohit qualifications
  purohitQualifications() {
    return this.http.getPurohitQualifications();
  }

  // Fetch list of filters
  purohitList(filter: PurohitFilter) {
    return this.http.getPurohitList(filter);
  }

  // Fethc list of Pending purohit list
  purohitApprovalList() {
    return this.http.getPurohitApproveList();
  }

  // Approve purohit
  approvePurohit(purohitId:string) {
    return this.http.aprovePurohit(purohitId);
  }
}
