<button
  pButton
  pRipple
  type="button"
  icon="pi pi-plus"
  label="ADD YATRA"
  routerLink="./add"
  class="p-button-raised p-button-text btn-add-category"
></button>
<div class="p-fluid p-text-center">
  <dashboard-yatra-card *ngFor="let yatra of yatraSandharshini" [yatra]="yatra">
  </dashboard-yatra-card>
  <app-loader [enable]="contentLoading"></app-loader>
</div>
