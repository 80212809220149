<div class="purohit-card p-shadow-1" (click)="clickedMe()">
  <div class="p-grid">
    <div class="p-col-3">
      <div
        class="purohit-avatar"
        [ngStyle]="{
          'background-image': 'url(' + baseURL + purohit.IMAGE_PATH + ')'
        }"
      ></div>
    </div>
    <div class="p-col-9">
      <div class="p-fluid p-grid">
        <div class="p-col-9 p-p-0">
          <h2
            class="theme-color purohit-name text-no-wrap line-height-1 p-my-0"
          >
            {{ purohit.PUROHIT_PUBLIC_ID }}
          </h2>
        </div>
        <div class="p-col-3 p-p-0">
          <h2
            class="
              theme-color
              purohit-experience
              text-no-wrap
              line-height-1
              p-my-0
            "
          >
            {{ purohit.EXPERIENCE + "yrs" }}
          </h2>
        </div>
      </div>
      <div class="purohit-extras qualification">
        <h4 class="text-no-wrap line-height-1 p-my-1">
          {{ purohit.QUALIFICATION }}
        </h4>
      </div>
      <div class="purohit-extras languages">
        <h4 class="text-no-wrap line-height-1 p-my-1">
          {{ purohit.KNOWN_LANGUAGES }}
        </h4>
      </div>
    </div>
  </div>
</div>
