export interface ICustomWindow extends Window {
  _custom_global_stuff: string;
}


export enum StatusType {
  post = 1,
  publish = 2,
  reject = 3,
  pending = 4,
  inActive = 5,
}

export class SUMMARYINFO {
  LIKE_STATUS: LikeStatusType;
  IS_BOOKMARKED: boolean;
  IS_REMAINDER: boolean;
  NO_OF_LIKES: number;
  NO_OF_DIS_LIKES: number;
}

export enum LikeStatusType {
  notResponded = 0,
  liked = 1,
  disliked = 2
}
export class LikeDislikeVM {
  public REF_ID: string;
  public LIKE_STATUS: LikeStatusType;
  public POSTED_ON: string;
  public CATEGORY: Category
  constructor() {
    let dt = new Date();
    this.POSTED_ON = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
  }
}

export class BookmarkVM {
  public REF_ID: string;
  public IS_BOOKMARKED: boolean;
  public POSTED_ON: string;
  public CATEGORY: Category
  constructor() {
    let dt = new Date();
    this.POSTED_ON = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
  }
}



export class NewsVM {
  POSTED_BY: string;
  POSTED_NAME: string;
  POSTED_ON: string;
  APPROVED_ON: string;
  SUMMARY_INFO: SUMMARYINFO;
  NEWS_EXTERNAL_ID: string;
  CATEGORY: number;
  IMAGE_URL: string;
  TITLE: string;
  SUB_TITLE: string;
  CONTENT: string;
  PUBLISH_TYPE: number;
  RELEASE_DATE: string;
  NEWS_STATUS: number;
  POINTS: number;
  AUD_TYPE_ID: number;
  AUD_LOC_LONG: number;
  AUD_LOC_LAT: number;
  GEO_LOC_ADDRESS: string;
  GEO_LOC_PLACE_ID: string;
  REJECTED_REMARKS: string;
  // imageInfo: ImageInfoVM;
  constructor() {
    // this.NEWS_EXTERNAL_ID = "-1";
    this.SUMMARY_INFO = new SUMMARYINFO();
    // this.imageInfo = new ImageInfoVM();
  }
}

export class YatraVM {
  POSTED_BY: string;
  POSTED_NAME: string;
  POSTED_ON: string;
  APPROVED_BY: string;
  APPROVED_NAME: string;
  APPROVED_ON: string;
  SUMMARY_INFO: SUMMARYINFO;
  CATEGORY: number;
  IMAGE_URL: string;
  PUBLISH_TYPE: number;
  RELEASE_DATE: string;
  AUD_TYPE_ID: number;
  AUD_LOC_LONG: number;
  AUD_LOC_LAT: number;
  GEO_LOC_ADDRESS: string;
  GEO_LOC_PLACE_ID: string;
  INTREST_STATUS: InterestStatusType;
  YATRA_EXTERNAL_ID: string;
  PACKAGE_NAME: string;
  PLACES: string;
  NO_OF_DAYS_TOUR: number;
  PRICE: number;
  BOARDING_POINT: string;
  CONTACT_NO: string;
  TRAVELS_NAME: string;
  TRAVELS_ADDRESS: string;
  JOURNEY_DATES: string;
  YATRA_STATUS: number;


  // NEWS_EXTERNAL_ID: string;
  constructor() {
    this.SUMMARY_INFO = new SUMMARYINFO();
  }
}

export enum CorrectStatus {
  notAttempted = 0,
  correct = 1,
  notCorrect = 2,
}

export enum QuestionType {
  fillInBlank = 101,
  multipleChoice = 102,
  multiSelect = 103,
}

export class QuestionVM {
  POSTED_BY: string;
  POSTED_NAME: string;
  POSTED_ON: string;
  APPROVED_BY: string;
  APPROVED_NAME: string;
  APPROVED_ON: string;
  SUMMARY_INFO: SUMMARYINFO;
  USER_ANSWER: string;
  CORRECT_STATUS: CorrectStatus;
  NO_OF_PERSONS_ATTEMPT: number;
  NO_OF_CORRECT: number;
  RESPONDED_ON: string;
  QUESTION_EXTERNAL_ID: string;
  CATEGORY: number;
  QUESTION: string;
  QT_TYPE: QuestionType;
  IMAGE_URL: string;
  OPTION1: string;
  OPTION2: string;
  OPTION3: string;
  OPTION4: string;
  ANSWER: string;
  QUESTION_STATUS: number;
  PUBLISH_TYPE: number;
  RELEASE_DATE: string;
  POINTS: number;
  constructor() {
    this.SUMMARY_INFO = new SUMMARYINFO();
    this.ANSWER = "";
    this.USER_ANSWER = "";
  }
  REJECTED_REMARKS: string;
}
export enum ImageCategory {
  imgProfile = 0,
  imgNews = 1,
  imgQuestions = 2,
  imgTemple = 3,
  imgPoojaStore = 4
}
export class ImageInfoVM {
  UID: string;
  IMAGE_CATEGORY: ImageCategory;
  IMAGE_FILE: any;
}
export class GuruVM {
  POSTED_BY: string;
  POSTED_NAME: string;
  POSTED_ON: string;
  APPROVED_BY: string;
  APPROVED_NAME: string;
  APPROVED_ON: string;
  SUMMARY_INFO: SUMMARYINFO;
  NEWS_EXTERNAL_ID: string;
  CATEGORY: number;
  IMAGE_URL: string;
  TITLE: string;
  SUB_TITLE: string;
  CONTENT: string;
  PUBLISH_TYPE: number;
  RELEASE_DATE: string;
  NEWS_STATUS: number;
  POINTS: number;
  AUD_TYPE_ID: number;
  AUD_LOC_LONG: number;
  AUD_LOC_LAT: number;
  GEO_LOC_ADDRESS: string;
  GEO_LOC_PLACE_ID: string;
  REJECTED_REMARKS: string;
  constructor() {
    this.SUMMARY_INFO = new SUMMARYINFO();
  }
}

export class TempleVM {
  POSTED_BY: string;
  POSTED_NAME: string;
  POSTED_ON: string;
  APPROVED_BY: string;
  APPROVED_NAME: string;
  CITY: string;
  STATE: string;
  DISTRICT: string;
  MANDAL: string;
  SUMMARY_INFO: SUMMARYINFO;
  TEMPLE_EXTERNAL_ID: string;
  IMAGE_URL: string;
  CATEGORY: number;
  TEMPLE_NAME: string;
  ABOUT_TEMPLE: string;
  TEMPLE_HISTORY: string;
  TEMPLE_CELEBRATIONS: string;
  DIRECTIONS_TO_TEMPLE: string;
  TEMPLE_OPENING_TIME_MORNING: string;
  TEMPLE_CLOSING_TIME_MORNING: string;
  TEMPLE_OPENING_TIME_EVENING: string;
  TEMPLE_CLOSING_TIME_EVENING: string;
  REJECTED_REMARKS?: string;
  BUS_FACILITY: string;
  TRAIN_FACILITY: string;
  FLIGHT_FACILITY: string;
  ACCOMMODATTION: string;
  CTY_ID: number;
  MND_ID: number;
  ST_ID: number;
  PINCODE: number;
  TEMPLE_STATUS: number;
  PUBLISH_TYPE: number;
  CREDIT_POINTS: number;
  AUD_TYPE: number;
  VIDEO_URL: string;
  DST_ID: number;
  RELEASE_DATE: string;
  AUD_LOC_LONG: number;
  AUD_LOC_LAT: number;
  GEO_LOC_ADDRESS: string;
  GEO_LOC_PLACE_ID: string;
  LATITUDE: number;
  LONGITUDE: number;
  TEMPLE_GEO_LOC_ADDRESS: string;

  // NEW Attributes as per customer request
  SERVICES: string;
  ACCOMMODATTION_OTHERS: string;
  constructor() {
    this.SUMMARY_INFO = new SUMMARYINFO();
  }
}

export class PhoneNumber {
  country: string;
  // area: string;
  // prefix: string;
  line: string;
  constructor() {
    this.country = '91';
  }
  // format phone numbers as E.164
  get e164() {
    // const num = this.country + this.area + this.prefix + this.line
    const num = this.country + this.line;
    return `+${num}`;
  }
}

export enum ResultType {
  after = 0,
  before = 1,
}

export enum Category {
  allNews = 0,
  bhakthiNews = 1111,
  alayaDarshini = 2222,
  prasnaVinodhini = 3333,
  yatraSandarshini = 4444,
  guruSubhashini = 5555,
  liveTV = 6666,
  poojari = 7777,
  poojaStore = 8888
}

export enum PurposeType {
  forMainScreen = 2,
  forShowList = 1,
}

export class GetNewsVM {
  LAST_NEWS_ID: string = "";
  RESULT_TYPE: ResultType = ResultType.after;
  CATEGORY: Category = Category.allNews;
  NO_OF_NEWS: number = 10;
  DISPLAY_PURPOSE: PurposeType = PurposeType.forMainScreen;
  IS_INCLUDE: boolean = false;
}

export class GetYatraVM {
  LAST_YATRA_ID: string = "";
  RESULT_TYPE: ResultType = ResultType.after;
  CATEGORY: Category = Category.allNews;
  NO_OF_NEWS: number = 10;
  DISPLAY_PURPOSE: PurposeType = PurposeType.forMainScreen;
  IS_INCLUDE: boolean = false;
}


export class GetQuestionsVM {
  LAST_QTN_ID: string = "";
  RESULT_TYPE: ResultType = ResultType.after;
  CATEGORY: Category = Category.allNews;
  NO_OF_QTNS: number = 10;
  DISPLAY_PURPOSE: PurposeType = PurposeType.forMainScreen;
  IS_INCLUDE: boolean = false;
}

export class GetTemplesVM {
  LAST_TEMPLE_ID: string = "";
  RESULT_TYPE: ResultType = ResultType.after;
  CATEGORY: Category = Category.allNews;
  NO_OF_TEMPLES: number = 10;
  DISPLAY_PURPOSE: PurposeType = PurposeType.forMainScreen;
  IS_INCLUDE: boolean = false;
}

export class CustomResponse {
  IS_SUCCESS: boolean = false;
  RESPONSE: any;
}

export class ApiResponse {

  CODE: number;
  DESC: string;
  UID: string;

}

export class QuestionAnswerVM {
  QUESTION_EXTERNAL_ID: string;
  ANSWER: string;
  RESPOND_ON: string;
}


export class UserVM {
  USER_ID: string;
  PHONENO: string;
  FIRST_NAME: string;
  FIREBASE_UID: string;
  LAST_NAME: string;
  CITY: string;
  DISTRICT: string;
  STATE: string;
  PINCODE: number;
  LONGITUDE: number;
  LATITUDE: number;
  EMAIL?: string;
  Role_Type: Role_Types;
  IMAGE_PATH?: string;
  GEO_LOC_ADDRESS: string;
  GEO_LOC_PLACE_ID: string;
  MGR_ID?: string;
  PUROHIT_STATUS: PurohitStatus;
  PUROHIT_PUBLIC_ID?: string;
}

export enum Role_Types {
  superAdmin = 1001,
  admin = 2002,
  supervisor = 3003,
  reporter = 4004,
  user = 5005,
  purohit = 6006,
  poojaStoreManager = 7007

}

export class UpdateUserRoleMGR {
  MGR_ID: string;
  USER_ID: string;
  ROLE_TYPE: number;
}

export class Place {
  lat: number;
  lng: number;
  formattedAddress: string;
  placeId: string;
}

export class ADMININFO {
  NO_OF_POSTS: number;
  TOTAL_POINTS: number;
}

export class UserPointsVM {
  USER_ID: string;
  NO_OF_QTNS_ATTEMPTED: number;
  NO_OF_CORRECT: number;
  NO_OF_INCORRECT: number;
  TOTAL_POINTS: number;
  ADMIN_INFO: ADMININFO;
  constructor() {
    this.ADMIN_INFO = new ADMININFO();
  }
}


export class YatraInterestRequest {
  REF_ID: string;
  INTEREST_STATUS: InterestStatusType;
  CATEGORY: Category;
  POSTED_ON: string;
}

export enum InterestStatusType {
  notResponded = 0,
  intrested = 1,
  notIntrested = 2
}

/***************************** */
/*
  * Purohit Darshini
  * April 13, 2021
*/
export enum PurohitStatus {
  notRegistered = 0,
  pendingApproval = 1,
  approved = 2
}

export class PostPurohitVM {
  PUROHIT_ID: string;
  USER_ID: string;
  PUROHIT_PUBLIC_ID: string;
  KNOWN_LANGUAGES: string;
  IS_APPROVED: PurohitStatus;
  QUALIFICATION: string;
  ABOUT: string;
  EXPERIENCE: string;
  DOB: string;
  UPDATE_ON: string;
  SELECTED_CATEGOIRES: number[];
}

export class PurohitCategory {
  PUROHIT_CATEGORY_ID: number;
  CATEGORY_NAME: string;
  IS_ACTIVE: number;
}

export class PurohitListVM {
  FIRST_NAME: string;
  LAST_NAME: string;
  CITY: string;
  DISTRICT: string;
  STATE: string;
  IS_ACTIVE: number;
  LONGITUDE: number;
  LATITUDE: number;
  PINCODE: number;
  IMAGE_PATH: string;
  PHONENO: string;
  GEO_LOC_PLACE_ID: string;
  GEO_LOC_ADDRESS: string;
  SELECTED_CATEGORY: string;
  SELECTED_CATEGORY_IDS: string;
  DISTANCE: number;
  EMAIL: string;
  APPROVED_BY: string;
  APPROVED_ON: string;
  PUROHIT_ID: string;
  USER_ID: string;
  PUROHIT_PUBLIC_ID: string;
  KNOWN_LANGUAGES: string;
  IS_APPROVED: number;
  QUALIFICATION: string;
  ABOUT: string;
  EXPERIENCE: string;
  DOB: string;
  UPDATE_ON: string;
}

export class PurohitFilter {
  LONGITUDE: number;
  LATITUDE: number;
  NO_OF_KILLOMETERS: number;
  CATEGORY_IDS: number[];
  QUALIFICATIONS: string[];
  NO_OF_ITEMS: number;
  constructor() {
    this.QUALIFICATIONS = [];
    this.CATEGORY_IDS = [];
    this.NO_OF_ITEMS = 10
    this.LONGITUDE = 0;
    this.LATITUDE = 0;
    this.NO_OF_KILLOMETERS = 10;
  }
}

/***************************** */
/*
  * Pooja store
  * May 08 2021
*/
export class PostPoojaStoreItemVM {
  ITEM_EXTERNAL_ID: string;
  CAT_ID: Category;
  ITEM_NAME: string;
  PRICE: number; // Double
  DISCOUNT_PRICE: number; // Double
  SINGLE_ITEM_WEIGHT: number; // Double
  ITEM_AVAILABILITY: boolean;
  ITEM_STATUS: boolean;
  DELIVERY: boolean;
  ITEM_DETAILS: string;
  ITEM_IMAGES_PATH: string;
  PUROHIT_PERCENTAGE: number; // Double
  CANCEL_ORDER_IN_DAYS_PRIOR: number;
  NO_OF_DAYS_TO_DELIVERY: number;
  CATEGORY_ID: number;
  FINAL_PRICE: number;
  DISCOUNT_PERCENTAGE: number;
  // public List<ImageInfoVM> imageInfo { get; set; }
  //       public bool IS_IMG_CHANGED { get; set; }
}

export class PoojaItemCategory {
  CATEGORY_ID: number;
  CATEGORY_NAME: string;
  CATEGORY_DESC: string;
}


export class PRICERANGE {
  UPPER: number;
  LOWER: number;
}

export class PoojaStoreFilter {
  CATEGORY_IDS: number[];
  ITEM_NAME: string;
  PRICE_RANGE: PRICERANGE;
  TAKE: number;
  SKIP: number;
  SORT_BY: number;
  DISPLAY_PURPOSE: PurposeType;
  constructor() {
    this.TAKE = 10;
    this.SKIP = 0;
    this.SORT_BY = 0;
    this.CATEGORY_IDS = [];
    this.ITEM_NAME = '';
    this.PRICE_RANGE = new PRICERANGE();
    this.PRICE_RANGE.LOWER = 0;
    this.PRICE_RANGE.UPPER = 0;
    this.DISPLAY_PURPOSE = PurposeType.forMainScreen;
  }
}

export class PoojaStoreResponse {
  ITEMS: PostPoojaStoreItemVM[];
  TOTAL_RECORDS: number;
  TAKE: number;
  SKIP: number;
  constructor() {
    this.ITEMS = [];
  }
}

export class ImgGaleria {
  path: string;
  alt: string;
  constructor(p: string, a: string) {
    this.path = p;
    this.alt = a;
  }
}

/*------------------------------------------------------------*/
/** 
 * MODELS related to cart
*/
/*------------------------------------------------------------*/

export class CartItemVM {
  CART_ITEM_EXTERNAL_ID: string;
  ITEM_EXTERNAL_ID: string;
  QUANTITY: number;
}


// CART ITEM INFORMATION RECEIVED FROM SERVER
export class POOJASTOREITEMINFO {
  POSTED_BY: string;
  POSTED_ON: string;
  UPDATED_BY: string;
  UPDATED_ON: string;
  ITEM_EXTERNAL_ID: string;
  CAT_ID: number;
  ITEM_NAME: string;
  PRICE: number;
  DISCOUNT_PRICE: number;
  DELIVERY: boolean;
  SINGLE_ITEM_WEIGHT: number;
  ITEM_AVAILABILITY: boolean;
  ITEM_STATUS: boolean;
  ITEM_DETAILS: string;
  ITEM_IMAGES_PATH: string;
  PUROHIT_PERCENTAGE: number;
  CANCEL_ORDER_IN_DAYS_PRIOR: number;
  NO_OF_DAYS_TO_DELIVERY: number;
  CATEGORY_ID: number;
  FINAL_PRICE: number;
  DISCOUNT_PERCENTAGE: number;
  ERROR_INFO?: ERRORINFO;
}


// CartItem
export class CartItemInfo {
  POOJASTORE_ITEM_INFO: POOJASTOREITEMINFO;
  CART_ITEM_EXTERNAL_ID: string;
  ITEM_EXTERNAL_ID: string;
  QUANTITY: number;
  constructor() {
    this.POOJASTORE_ITEM_INFO = new POOJASTOREITEMINFO();
  }
}


// User Address
export class UserAddressVM {
  ADDRESS_EXTERNAL_ID: string;
  FULL_NAME: string;
  MOBILE_NUMBER: string;
  PINCODE: string;
  FLAT_HOUSE_NO_BUILDING_APARTMENT: string;
  AREA_COLONY_STREET_SECTOR_VILLAGE: string;
  LANDMARK: string;
  TOWN_CITY: string;
  STATE: string;
  IS_ACTIVE: boolean;
  IS_DEFAULT: boolean;
  ADDRESS_TYPE: number;
  constructor() {
    this.ADDRESS_TYPE = 1;
    this.IS_ACTIVE = true;
  }
}

export class CheckoutItemVM {
  ITEM_EXTERNAL_ID: string;
  QUANTITY: number;
  ITEM_NAME: string;
}

export class InitializePayRequestVM {
  ITEMS: CheckoutItemVM[];
  COUPON_CODE: string;
  // PINCODE: number;
  ADDRESS_EXTERNAL_ID: string;
  constructor() {
    this.ITEMS = [];
  }
}

export enum GetAddressStatus {
  inActive = 0,
  active = 1,
  both = 2
}




export class CheckoutItemResponseVM extends CheckoutItemVM {
  ITEM_PRICE_INFO: ITEMPRICEINFO;
  ITEM_IMAGES_PATH: string;
  CATEGORY_ID: number;
  CATEGORY_NAME: string;
  DELIVERY: boolean;
}

export class CheckoutItemsResponseVM {
  COUPON_CODE_AVAILABLE: boolean;
  PRICE_SUMMARY_INFO: PRICESUMMARYINFO;
  ITEMS: CheckoutItemResponseVM[];
  GATEWAY_ORDER_INFO: GATEWAYORDERINFO;
  CHECKOUT_ITEM_EXTERNAL_ID: string;
  ORDER_NUMBER: string;
  ERRORS_INFO: any[];
}

export interface GATEWAYORDERINFO {
  KEY_ID: string;
  ORDER_ID: string;
  AMOUNT_IN_SUBUNITS: string;
}

// export interface POOJASTOREITEMINFO {
//   POSTED_BY: string;
//   POSTED_ON: string;
//   UPDATED_BY: string;
//   UPDATED_ON: string;
//   ITEM_EXTERNAL_ID: string;
//   CAT_ID: number;
//   ITEM_NAME: string;
//   PRICE: number;
//   DISCOUNT_PRICE: number;
//   DELIVERY: boolean;
//   SINGLE_ITEM_WEIGHT: number;
//   ITEM_AVAILABILITY: boolean;
//   ITEM_STATUS: boolean;
//   ITEM_DETAILS: string;
//   ITEM_IMAGES_PATH: string;
//   PUROHIT_PERCENTAGE: number;
//   CANCEL_ORDER_IN_DAYS_PRIOR: number;
//   NO_OF_DAYS_TO_DELIVERY: number;
//   CATEGORY_ID: number;
//   FINAL_PRICE: number;
//   DISCOUNT_PERCENTAGE: number;
// }

// export class ITEMPRICEINFO {
//   ITEM_AMOUNT: number;
//   ITEM_DISCOUNT_AMOUNT: number;
//   ITEM_DISCOUNT_PERCENTAGE: number;
//   ITEM_DELIVERY_CHARGES: number;
//   ITEM_TOTAL_AMOUNT: number;
//   TO_BE_CONSIDERED: boolean;
// }

export class CARTITEM {
  POOJASTORE_ITEM_INFO: POOJASTOREITEMINFO;
  ITEM_PRICE_INFO: ITEMPRICEINFO;
  CART_ITEM_EXTERNAL_ID: string;
  ITEM_EXTERNAL_ID: string;
  QUANTITY: number;
  
}

export class ERRORINFO {
  CODE: number;
  DESC: string;
  UID: string;
}

export class PRICESUMMARYINFO {
  ITEMS_TOTAL_AMOUNT: number;
  ITEMS_DISCOUNT_TOTAL_AMOUNT: number;
  ITEMS_DISCOUNT_PECENTAGE: number;
  DELIVERY_CHARGES_TOTAL_AMOUNT: number;
  ORDER_TOTAL_AMOUNT: number;
  ITEMS_TOTAL_AMOUNT_AFTER_DISCOUNT: number;
}

export class UserCartVM {
  CART_ITEMS: CARTITEM[];
  PRICE_SUMMARY_INFO: PRICESUMMARYINFO;
  constructor() {
    this.CART_ITEMS = [];
  }
}


export enum OrderStatusCode {
  cFailed = 0,
  cProcessing = 1,
  cAccepted = 2,
  cCancelled = 3,
  cPacking = 4,
  cShipped = 5,
  cDelivered = 6,
  cReturned = 7,
  cRefund = 8
}


// Razor pay response formats
export class RazorPayResponse {
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
  http_status_code: number;
}

// Payment response
export class PAYMENTDATA {
  orderId: string;
  paymentId: string;
  signature: string;
  userContact: string;
  userEmail: string;
}

export class PAYMENTRESPONSE {
  GATEWAY_RESPONSE: string;
  PAYMENT_DATA: PAYMENTDATA;
  ERROR_INFO: RazorPayPaymentFailureResponse 
}

export class CheckoutOrderInfoVM {
  CHECKOUT_ITEM_EXTERNAL_ID: string;
  INTERNAL_ORDER_ID: string;
  IS_SUCCESS: boolean;
  ORDER_TOTAL_AMOUNT: number;
  PAYMENT_RESPONSE: PAYMENTRESPONSE;
}


// Payment response failure

export class Metadata {
  payment_id: string;
  order_id: string;
}

export class Error {
  code: string;
  description: string;
  source: string;
  step: string;
  reason: string;
  metadata: Metadata;
}

export class RazorPayPaymentFailureResponse {
  error: Error;
  http_status_code: number;
}


export class PaymentStatusResposeVM {
  CODE: number;
  ORDER_TOTAL_AMOUNT: number;
  DELIVERY_ADDRESS: string;
  ORDER_STATUS: number;
  ORDERED_DATE: string;
  CHECKOUT_ITEM_EXTERNAL_ID: string;
  TRANSACTION_EXTERNAL_ID: string;
}

export class GetOrderItemsVM {
  ORDER_STATUS_CODES: number[];
  ORDER_NUMBER?: string;
  USER_EMAIL?:string;
  USER_PHONE?: string;
  USER_NAME?: string;
  TAKE: number;
  SKIP: number;
  constructor() {
    this.ORDER_STATUS_CODES  = [];
  }
}


export class ITEMPRICEINFO {
  ITEM_AMOUNT: number;
  ITEM_DISCOUNT_AMOUNT: number;
  ITEM_DISCOUNT_PERCENTAGE: number;
  ITEM_DELIVERY_CHARGES: number;
  ITEM_TOTAL_AMOUNT: number;
  TO_BE_CONSIDERED: boolean;
  ITEM_TOTAL_AMOUNT_WITH_DELIVERY_CHARGES: number;
}

export class ITEMINFO {
  POSTED_BY: string;
  POSTED_ON: string;
  UPDATED_BY: string;
  UPDATED_ON: string;
  ITEM_EXTERNAL_ID: string;
  CAT_ID: number;
  ITEM_NAME: string;
  PRICE: number;
  DISCOUNT_PRICE: number;
  DELIVERY: boolean;
  SINGLE_ITEM_WEIGHT: number;
  ITEM_AVAILABILITY: boolean;
  ITEM_STATUS: boolean;
  ITEM_DETAILS: string;
  ITEM_IMAGES_PATH: string;
  PUROHIT_PERCENTAGE: number;
  CANCEL_ORDER_IN_DAYS_PRIOR: number;
  NO_OF_DAYS_TO_DELIVERY: number;
  CATEGORY_ID: number;
  FINAL_PRICE: number;
  DISCOUNT_PERCENTAGE: number;
}

export class STATUSTRACK {
  ORDER_STATUS_CODE_ID: number;
  ORDER_STATUS_CODE_NAME: string;
  CHANGED_BY: string;
  CHANGED_BY_NAME: string;
  CHANGED_ON: string;
  NOTES: string;
}

export class DELIVERYADDRESS {
  USER_ID: string;
  CREATED_ON: string;
  UPDATED_ON: string;
  ADDRESS_EXTERNAL_ID: string;
  FULL_NAME: string;
  MOBILE_NUMBER: string;
  PINCODE: string;
  FLAT_HOUSE_NO_BUILDING_APARTMENT: string;
  AREA_COLONY_STREET_SECTOR_VILLAGE: string;
  LANDMARK: string;
  TOWN_CITY: string;
  STATE: string;
  IS_ACTIVE: boolean;
  IS_DEFAULT: boolean;
  ADDRESS_TYPE: number;
}

export class ORDERINFO {
  PRICE_SUMMARY_INFO: PRICESUMMARYINFO;
  ORDER_EXTERNAL_ID: string;
  ORDER_NUMBER: string;
  RAZORPAY_ORDER_ID: string;
  GATEWAY_PAYMENT_ID: string;
  PAYMENT_METHOD: string;
  DELIVERY_ADDRESS: DELIVERYADDRESS;
  DATE_ORDER_PLACED: string;
  COUPON_CODE: string;
}

export class ITEM {
  ITEM_PRICE_INFO: ITEMPRICEINFO;
  ITEM_INFO: ITEMINFO;
  STATUS_TRACK: STATUSTRACK[];
  ORDER_STATUS_CODE_ID: number;
  ORDER_STATUS_NAME: string;
  USER_ID: string;
  USER_NAME: string;
  UPDATED_ON: string;
  UPDATED_BY: string;
  UPDATED_BY_NAME: string;
  ORDER_INFO: ORDERINFO;
  ORDER_ITEM_EXTERNAL_ID: string;
  QUANTITY: number;
}

export class GetOrdersAdminResponseVM {
  ITEMS: ITEM[];
  TOTAL_RECORDS: number;
}

export class UpdateOrderStatusVM {
  ORDER_ITEM_EXTERNAL_ID: string;
  STATUS: number;
  COMMENTS: string;
}

export class CartItemCountResponse {
  COUNT: number;
}


// Firebase URI shortner
export interface AndroidInfo {
  androidPackageName: string;
}

export interface IosInfo {
  iosBundleId: string;
}

export interface DynamicLinkInfo {
  domainUriPrefix: string;
  link: string;
  androidInfo?: AndroidInfo;
  iosInfo?: IosInfo;
}

export interface DynamicLinkInfoRequest {
  dynamicLinkInfo: DynamicLinkInfo;
}

export interface DynamicLinksResponse {
  shortLink: string;
  previewLink: string;
}