<div class="card p-shadow-3 form-layout-container">
  <form #yatraForm="ngForm" (ngSubmit)="saveYatraDetails(yatraForm)">
    <div class="p-fluid p-container">
      <p-fileUpload
        name=""
        [showUploadButton]="false"
        (onSelect)="onUpload($event)"
        accept="image/*"
        maxFileSize="1000000"
        customUpload="true"
      >
        <ng-template pTemplate="content">
          <ul *ngIf="uploadedFiles.length">
            <li *ngFor="let file of uploadedFiles">
              {{ file.name }} - {{ file.size }} bytes
            </li>
          </ul>
        </ng-template>
      </p-fileUpload>
      <div class="p-field">
        <label for="">Package Name</label>
        <input
          id=""
          type="text"
          name="packageName"
          [(ngModel)]="yatra.PACKAGE_NAME"
          minlength="10"
          maxlength="500"
          aria-describedby=""
          pInputText
          required
        />
        <!-- <small id="username2-help" class="p-invalid">Username is not available.</small> -->
      </div>
      <div class="p-field">
        <label for="address">Places</label>
        <textarea
          id="address"
          type="text"
          name="yatraPlaces"
          [(ngModel)]="yatra.PLACES"
          rows="4"
          minlength="10"
          maxlength="2000"
          pInputTextarea
          required
        ></textarea>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col">
          <label for="">Days</label>
          <input
            id=""
            type="number"
            min="1"
            max="365"
            name="noOfDays"
            [(ngModel)]="yatra.NO_OF_DAYS_TOUR"
            pInputText
            required
          />
        </div>
        <div class="p-field p-col">
          <label for="">Price</label>
          <input
            id=""
            type="number"
            name="yatraPrice"
            min="1"
            [(ngModel)]="yatra.PRICE"
            pInputText
            required
          />
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col">
          <label for="">Boarding point</label>
          <input
            id=""
            type="text"
            name="boardingPoint"
            [(ngModel)]="yatra.BOARDING_POINT"
            pInputText
            required
          />
        </div>
        <div class="p-field p-col">
          <label for="">Contact No</label>
          <input
            id=""
            type="text"
            minlength="10"
            maxlength="10"
            name="contactNumber"
            [(ngModel)]="yatra.CONTACT_NO"
            pInputText
            required
          />
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col">
          <label for="">Travels Name</label>
          <input
            id=""
            type="text"
            name="travelName"
            [(ngModel)]="yatra.TRAVELS_NAME"
            minlength="5"
            maxlength="500"
            pInputText
            required
          />
        </div>
        <div class="p-field p-col">
          <label for="">Travels Address</label>
          <input
            id=""
            type="text"
            name="travelAddress"
            [(ngModel)]="yatra.TRAVELS_ADDRESS"
            pInputText
          />
        </div>
      </div>
      <div class="p-field p-col-6">
        <label>Travel Date</label>
        <p-calendar 
          name="travelDate" 
          dateFormat="dd-mm-yy" 
          [ngModel]="yatra.JOURNEY_DATES"
          (onSelect)="handleJourneyDate($event)">
        </p-calendar>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-lg-6">
          <label for="newsPublishType">Publiish Type</label>
          <p-dropdown
            id="newsPublishType"
            name="newsPublishType"
            [(ngModel)]="yatra.PUBLISH_TYPE"
            #newsPublishType="ngModel"
            [options]="PublishTypes"
            aria-describedby="newsPublishType-help"
            placeholder="Select publish type"
            [showClear]="false"
            required
          ></p-dropdown>
          <small
            id="newsPublishType-help"
            *ngIf="newsPublishType.valid === false && newsPublishType.touched"
            class="p-invalid"
            >Select publish type</small
          >
        </div>
        <div class="p-field p-col-12 p-lg-6" *ngIf="yatra.PUBLISH_TYPE === 2">
          <label>Pickup date*</label>
          <p-calendar
            id="newsPublishDate"
            name="newsPublishDate"
            (ngModel)="(yatra.RELEASE_DATE)"
            #newsPublishDate="ngModel"
            required
          ></p-calendar>
          <small
            id="newsPublishDate-help"
            *ngIf="newsPublishDate.valid === false && newsPublishDate.touched"
            class="p-invalid"
            >Pickup publish date</small
          >
        </div>
      </div>

      <div class="p-fluid p-form-grid p-grid">
        <div class="p-field p-col-12 p-lg-6">
          <label for="newsStatus">Post status</label>
          <p-dropdown
            id="newsStatus"
            name="newsStatus"
            [(ngModel)]="yatra.YATRA_STATUS"
            #newsStatus="ngModel"
            [options]="PostStatus"
            placeholder="Select post status"
            [showClear]="false"
            required
          >
          </p-dropdown>
          <small
            id="newsStatus-help"
            *ngIf="newsStatus.valid === false && newsStatus.touched"
            class="p-invalid"
            >Pickup news status</small
          >
        </div>
        <div *ngIf="yatra.YATRA_STATUS === 3" class="p-field p-col-12 p-lg-6">
          <label for="rejectedRemarks">Remarks</label>
          <input
            id="rejectedRemarks"
            name="rejectedRemarks"
            [(ngModel)]="yatra.REJECTED_REMARKS"
            #rejectedRemarks="ngModel"
            type="text"
            pInputText
          />
        </div>
      </div>

      <!-- <div class="p-fluid p-grid p-form-grid">
          <div class="p-field p-col-12 p-lg-6">
            <label for="newsPoint">Points</label>
            <input id="newsPoints" name="newsPoints" type="number" #newsPoints="ngModel" [(ngModel)]="yatra.POINTS"
              aria-describedby="newsPoints-help" pInputText required />
            <small id="newsPoints-help" *ngIf="newsPoints.valid === false && newsPoints.touched" class="p-invalid">News
              points required</small>
          </div>
        </div> -->
      <div class="p-fluid p-grid p-form-grid">
        <div class="p-field p-col-12 p-lg-6">
          <label for="targetAudience">Target Audience</label>
          <p-dropdown
            id="targetAudience"
            name="targetAudience"
            #targetAudience="ngModel"
            [(ngModel)]="yatra.AUD_TYPE_ID"
            [options]="TargetAudience"
            placeholder="Target Audience"
            [showClear]="false"
            required
          ></p-dropdown>
          <small
            id="targetAudience-help"
            *ngIf="targetAudience.valid === false && targetAudience.touched"
            class="p-invalid"
            >News points required</small
          >
        </div>
        <div *ngIf="yatra.AUD_TYPE_ID === 2" class="p-field p-col-12 p-lg-6">
          <label for="">Audience Location</label>
          <app-autocomplete-location
            [address]="yatra.GEO_LOC_ADDRESS"
            adressType="geocode"
            (setAddress)="setAudienceLocation($event)"
          >
          </app-autocomplete-location>
        </div>
      </div>
      <button
        pButton
        pRipple
        type="submit"
        [label]="submitting === true ? 'Please wait' : 'Save'"
        style="max-width: 150px"
        class="p-button-raised btn-theme p-button-text"
        [disabled]="yatraForm.valid === false || submitting === true"
      ></button>
    </div>
  </form>
</div>
