import { HttpErrorResponse } from '@angular/common/http';
import { error } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MESSAGE_SERVICE_KEY } from '../constants';
import { ApiResponse, Category, CustomResponse, GetNewsVM, NewsVM, PurposeType, ResultType } from '../models/avbhakthi.model';
import { AppHttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(
    private http: AppHttpService,
    private messageService: MessageService
  ) { }
  getNews(count: number, cat: Category, purpose: PurposeType, include: boolean, resultType: ResultType, lastNewsId: string = ""): Observable<NewsVM[]> {

    let getNewsReq = new GetNewsVM();
    getNewsReq.CATEGORY = cat;
    getNewsReq.NO_OF_NEWS = count;
    getNewsReq.DISPLAY_PURPOSE = purpose;
    getNewsReq.IS_INCLUDE = include;
    getNewsReq.RESULT_TYPE = resultType;
    getNewsReq.LAST_NEWS_ID = lastNewsId;
    return this.http.getNews(getNewsReq).pipe(
      catchError(this.handleError)
    );
  }
  postNews(formData: FormData, isNew: boolean = true): Observable<ApiResponse> {
    if (isNew) {
      return this.http.postNews(formData).pipe(
        catchError(this.handleError)
      );
    } else {
      return this.http.updateNews(formData).pipe(
        catchError(this.handleError)
      );
    }

  }
  handleError(error: HttpErrorResponse) {
    // debugger;
    if (error.error instanceof ErrorEvent) {
       this.messageService.add({ severity: 'error', summary: error.error.message, key: MESSAGE_SERVICE_KEY})
    } else {
      // console.log(error);
      this.messageService.add({ severity: 'error', summary: 'Something bad happened please try later', key: MESSAGE_SERVICE_KEY})
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
}
