import { stringify } from '@angular/compiler/src/util';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WindowRefService } from 'src/app/common/window-ref.service';
import { cSuccessResponseCode } from 'src/app/constants';
import { EPoojaStoreService } from 'src/app/e-store/e-pooja-store.service';
import { CheckoutItemsResponseVM, OrderStatusCode, PaymentStatusResposeVM } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css']
})
export class OrderConfirmationComponent implements OnInit, AfterViewInit {

  public paymentResponse: PaymentStatusResposeVM;
  public checkOutItemResponse: CheckoutItemsResponseVM;
  
  constructor(
    private poojaStoreService: EPoojaStoreService,
    private _windowRef: WindowRefService,
    private router: Router
  ) {
    let obj = this.poojaStoreService.getPaymentStatusObject();
    this.checkOutItemResponse = this.poojaStoreService.getCheckOutResponse();
    if(obj) {
      this.paymentResponse = obj;
    } else {
      this.router.navigate['/app/check-out']
    }
    this.poojaStoreService.fetchCartItems();
    
  }
  ngAfterViewInit(): void {
    let razorPayWindows = this._windowRef.nativeWindow.document.getElementsByClassName('razorpay-container');
    if(razorPayWindows.length > 0) {
      for(let i=0; i< razorPayWindows.length; i++) {
        razorPayWindows[i].remove();
      }
    }
  }

  ngOnInit(): void {
    
    
  }

  // To convert order success information to boolean
  statuCodeToBoolean(status: number): boolean {
    if (status === cSuccessResponseCode)
      return true;
    return false;
  }

  // To convert order status to string
  orderStatusCodeToString(code: OrderStatusCode) {
    switch (code) {
      case OrderStatusCode.cFailed:
        return 'Failed';
      case OrderStatusCode.cProcessing:
        return 'Processing';
      case OrderStatusCode.cAccepted:
        return 'Accepted';
      case OrderStatusCode.cCancelled:
        return 'Cancelled';
      case OrderStatusCode.cPacking:
        return 'Packing';
      case OrderStatusCode.cShipped:
        return 'Shipped';
      case OrderStatusCode.cDelivered:
        return 'Delivered';
      case OrderStatusCode.cReturned:
        return 'Returned';
      case OrderStatusCode.cRefund:
        return 'Refund';
      default:
        return 'Unknown';
    }
  }

  removeEscapeSequenceFromString(str: string) {
    if(str)
      return str.replace(/\\n/g,'');
    return '';
  }

}
