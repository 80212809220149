<div class="order-status-container p-card p-mx-auto p-pb-3">
    <!-- Header information -->
    <div class="payment-status-header">
        <!--  -->
        <div *ngIf="statuCodeToBoolean(paymentResponse?.CODE) === true">
            <div class="header-emoji-container">
                <i class="far fa-smile-beam p-text-success" style="font-size: 15rem;"></i>
            </div>
            <h2 class="p-text-success p-my-0 header-title-text">GREAT!</h2>
            <p class="p-my-1 line-height-1">Your order has been placed.</p>
            <p class="p-my-1 line-height-1">We will reach out to you shortly with your order</p>
        </div>
        <div *ngIf="statuCodeToBoolean(paymentResponse?.CODE) !== true">
            <div class="header-emoji-container failed">
                <i class="far fa-frown-open p-text-danger" style="font-size: 15rem;"></i>
            </div>
            <h2 class="p-my-0 header-title-text" style="color:#e43e3e;">UH OH!</h2>
            <p class="p-my-1 line-height-1">Your transaction couldn't be made.</p>
            <p class="p-my-1 line-height-1">Please retry</p>
        </div>

    </div>
    <!-- Payment details body -->
    <div class="payment-details p-px-4">
        <div class="p-fluid p-grid">
            <div class="p-col-12 p-p-0">
                <h3 class="p-my-0">ORDER DETAILS</h3>
            </div>
            <!-- 1. Order number -->
            <div class="p-col-6 p-p-0 ">
                Order number
            </div>
            <div class="p-col-6 p-text-right p-p-0 font-family-roboto">
                {{checkOutItemResponse?.ORDER_NUMBER}}
            </div>
            <!-- /End of order number -->

            <!-- 2. Amount -->
            <div class="p-col-6 p-p-0">
                Amount
            </div>
            <div class="p-col-6 p-text-right p-p-0 font-family-roboto">
                &#8377;{{paymentResponse?.ORDER_TOTAL_AMOUNT | number}}/-
            </div>
            <!-- /End of amount -->

            <!-- Date -->
            <div class="p-col-6 p-p-0">
                Date
            </div>
            <div class="p-col-6 p-text-right p-p-0 font-family-roboto">
                {{paymentResponse?.ORDERED_DATE}}
            </div>
            <!-- /End of date -->

            <!-- Address -->
            <div class="p-col-6 p-p-0">
                Address
            </div>
            <div class="p-col-6 p-text-right p-p-0 font-family-roboto">
                {{removeEscapeSequenceFromString(paymentResponse?.DELIVERY_ADDRESS)}}
            </div>
            <!-- /End of address -->

            <!-- Status -->
            <div class="p-col-6 p-p-0">
                Status
            </div>
            <div class="p-col-6 p-text-right p-p-0 font-family-roboto">
                {{orderStatusCodeToString(paymentResponse?.ORDER_STATUS)}}
            </div>
            <!-- /End of status -->
        </div>


    </div>
    <!-- /End of payment details body -->

    <!-- Payment footer -->
    <div class="payment-status-footer p-text-center">
        <button [routerLink]="['/dashboard/orders']" pButton
            class="p-button-raised p-button-text btn-add-category p-button" style="width: auto !important;">
            View Orders
        </button>
        <br />
        <a [routerLink]="['/app']">Go to home</a>
    </div>
</div>