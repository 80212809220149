<form
  #poojaItemForm="ngForm"
  novalidate
  (ngSubmit)="saveItemDetails(poojaItemForm)"
>
  <!-- (ngSubmit)="saveNewsDetails(addVarthaForm)" -->
  <!-- Form container outer -->
  <div class="p-shadow-3 form-layout-container">
    <!-- Form container inner -->
    <div class="p-fluid p-container">
      <!-- Grid for form element -->
      <div class="p-grid">
        <!-- Upload files -->
        <div class="p-col-12">
          <p-fileUpload
            multiple="true"
            name="imageUpload"
            [showUploadButton]="false"
            (onSelect)="onUpload($event)"
            accept="image/*"
            maxFileSize="1000000"
            customUpload="true"
          >
            <ng-template pTemplate="content">
              <ul *ngIf="uploadedFiles.length">
                <li *ngFor="let file of uploadedFiles">
                  {{ file.name }} - {{ file.size }} bytes
                </li>
              </ul>
            </ng-template>
          </p-fileUpload>
        </div>
        <!-- Item Name -->
        <div class="p-col-12">
          <div class="p-field">
            <label for="itemName">Item name*</label>
            <input
              [(ngModel)]="poojaItem.ITEM_NAME"
              id="itemName"
              name="itemName"
              type="text"
              #itemName="ngModel"
              aria-describedby="itemName-help"
              pInputText
              required
              minlength="5"
              maxlength="100"
            />
            <small
              id="itemName-help"
              *ngIf="itemName.errors?.required === true && itemName.touched"
              class="p-error"
            >
              Item Name required
            </small>
            <small
              id="itemName-help"
              *ngIf="itemName.errors?.minlength || itemName.errors?.maxlength"
              class="p-error"
            >
              Item name between 5 to 100 characters only
            </small>
          </div>
        </div>
        <!-- Item Details -->
        <div class="p-col-12">
          <div class="p-field">
            <label for="itemDetails">Item Details*</label>
            <textarea
              [(ngModel)]="poojaItem.ITEM_DETAILS"
              id="itemDetails"
              name="itemDetails"
              type="text"
              rows="4"
              #itemDetails="ngModel"
              aria-describedby="itemDetails-help"
              pInputTextarea
              required
              minlength="10"
              maxlength="5000"
            ></textarea>
            <small
              id="itemDetails-help"
              *ngIf="
                itemDetails.errors?.required === true && itemDetails.touched
              "
              class="p-error"
              >Please enter Item Details</small
            >
            <small
              id="itemDetails-help"
              *ngIf="
                itemDetails.errors?.minlength || itemDetails.errors?.maxlength
              "
              class="p-error"
              >Item Details between 10 to 5000 characters</small
            >
          </div>
        </div>
        <!-- Price -->
        <div class="p-sm-12 p-md-12 p-lg-6">
          <label for="itemPrice">Price(&#8377;)*</label>
          <p-inputNumber
            [(ngModel)]="poojaItem.PRICE"
            mode="currency"
            inputId="itemPrice"
            [min]="0"
            currency="INR"
            currencyDisplay="code"
            locale="en-IN"
            #itemPrice="ngModel"
            name="itemPrice"
            required
          ></p-inputNumber>
          <small
            id="itemPrice-help"
            *ngIf="itemPrice.errors?.required === true && itemPrice.touched"
            class="p-error"
            >Please enter Item Price</small
          >
          <small
            id="itemDetails-help"
            *ngIf="itemPrice.errors?.minlength || itemPrice.errors?.maxlength"
            class="p-error"
            >Item price should be more than 0</small
          >
        </div>

        <!-- Discount Price -->
        <div class="p-sm-12 p-md-12 p-lg-6">
          <label for="itemDiscountPrice">Discount Price(&#8377;)*</label>
          <p-inputNumber
            [(ngModel)]="poojaItem.DISCOUNT_PRICE"
            mode="currency"
            inputId="itemDiscountPrice"
            [min]="0"
            currency="INR"
            currencyDisplay="code"
            locale="en-IN"
            #itemDiscountPrice="ngModel"
            name="itemDiscountPrice"
            required
          ></p-inputNumber>
          <small
            id="itemDiscountPrice-help"
            *ngIf="
              itemDiscountPrice.errors?.required === true &&
              itemDiscountPrice.touched
            "
            class="p-error"
            >Please enter Discount Price</small
          >
          <small
            id="itemDiscountPrice-help"
            *ngIf="
              itemDiscountPrice.errors?.minlength ||
              itemDiscountPrice.errors?.maxlength
            "
            class="p-error"
            >Discount price should be more than 0</small
          >
        </div>
        <!-- Purohit Percentage -->
        <div class="p-sm-12 p-md-12 p-lg-6">
          <label for="purohitPercentage">Purohit Percentage(&#x25;)*</label>
          <p-inputNumber
            [(ngModel)]="poojaItem.PUROHIT_PERCENTAGE"
            inputId="purohitPercentage"
            #purohitPercentage="ngModel"
            mode="decimal"
            locale="en-IN"
            [minFractionDigits]="2"
            [min]="0"
            [max]="100"
            suffix="%"
            [step]="0.25"
            name="purohitPercentage"
            required
          >
          </p-inputNumber>
          <small
            id="purohitPercentage-help"
            *ngIf="
              purohitPercentage.errors?.required === true &&
              purohitPercentage.touched
            "
            class="p-error"
            >Please enter Purohit Percentage</small
          >
          <small
            id="purohitPercentage-help"
            *ngIf="
              purohitPercentage.errors?.minlength ||
              purohitPercentage.errors?.maxlength
            "
            class="p-error"
            >Purohit Percentage should be ranges from 0 to 100</small
          >
        </div>
        <!-- No.of Days To Delivery -->
        <div class="p-sm-12 p-md-12 p-lg-6">
          <label for="noOfDaysToDelivery">No.of Days to Delivery*</label>
          <p-inputNumber
            [(ngModel)]="poojaItem.NO_OF_DAYS_TO_DELIVERY"
            inputId="noOfDaysToDelivery"
            #noOfDaysToDelivery="ngModel"
            locale="en-IN"
            [min]="0"
            suffix="days"
            name="noOfDaysToDelivery"
            required
          >
          </p-inputNumber>

          <small
            id="noOfDaysToDelivery-help"
            *ngIf="
              noOfDaysToDelivery.errors?.required === true &&
              noOfDaysToDelivery.touched
            "
            class="p-error"
            >Please enter No.of Days to Delivery</small
          >
          <small
            id="noOfDaysToDelivery-help"
            *ngIf="
              noOfDaysToDelivery.errors?.minlength ||
              noOfDaysToDelivery.errors?.maxlength
            "
            class="p-error"
            >Days should be more than 0</small
          >
        </div>
        <!-- Cancel order in days prior -->
        <div class="p-sm-12 p-md-12 p-lg-6">
          <label for="cancelOrderPrior">Cancel Order in Days Prior*</label>
          <p-inputNumber
            [(ngModel)]="poojaItem.CANCEL_ORDER_IN_DAYS_PRIOR"
            inputId="cancelOrderPrior"
            #cancelOrderPrior="ngModel"
            locale="en-IN"
            [min]="0"
            suffix="days"
            name="cancelOrderPrior"
            required
          >
          </p-inputNumber>

          <small
            id="cancelOrderPrior-help"
            *ngIf="
              cancelOrderPrior.errors?.required === true &&
              cancelOrderPrior.touched
            "
            class="p-error"
            >Please enter Cancel Order in Days Prior</small
          >
          <small
            id="cancelOrderPrior-help"
            *ngIf="
              cancelOrderPrior.errors?.minlength ||
              cancelOrderPrior.errors?.maxlength
            "
            class="p-error"
            >Days should be more than 0</small
          >
        </div>
        <!-- Item Availability -->
        <div class="p-sm-12 p-md-12 p-lg-6">
          <label>Item Availability*</label>
          <p-dropdown
            [options]="poojaItemAvailability"
            [(ngModel)]="poojaItem.ITEM_AVAILABILITY"
            placeholder="Select item availability"
            [showClear]="true"
            required
            name="itemAvailability"
            id="itemAvailability"
            #itemAvailability="ngModel"
          ></p-dropdown>
          <small
            id="itemAvailability-help"
            *ngIf="
              itemAvailability.errors?.required === true &&
              itemAvailability.touched
            "
            class="p-error"
            >Select Item Availability</small
          >
        </div>
        <!-- Item Status -->
        <div class="p-sm-12 p-md-12 p-lg-6">
          <label>Item Status*</label>
          <p-dropdown
            [options]="poojaItemStatus"
            [(ngModel)]="poojaItem.ITEM_STATUS"
            placeholder="Select item status"
            [showClear]="true"
            required
            name="poojItemStatus"
            id="poojItemStatus"
            #poojItemStatus="ngModel"
          ></p-dropdown>
          <small
            id="poojItemStatus-help"
            *ngIf="
              poojItemStatus.errors?.required === true && poojItemStatus.touched
            "
            class="p-error"
            >Select Item Status</small
          >
        </div>
        <!-- Item Category -->
        <div class="p-sm-12 p-md-12 p-lg-6">
          <label>Item Category*</label>
          <p-dropdown
            [options]="poojaItemCategories"
            [(ngModel)]="poojaItem.CATEGORY_ID"
            placeholder="Select item category"
            [showClear]="true"
            optionLabel="CATEGORY_NAME"
            optionValue="CATEGORY_ID"
            required
            name="poojaItemCategory"
            id="poojaItemCategory"
            #poojaItemCategory="ngModel"
          ></p-dropdown>
          <small
            id="poojaItemCategory-help"
            *ngIf="
              poojaItemCategory.errors?.required === true &&
              poojaItemCategory.touched
            "
            class="p-error"
            >Select Item Category</small
          >
        </div>

        <!-- Delivery charge type -->
        <div class="p-sm-12 p-md-12 p-lg-6">
          <label>Delivery*</label>
          <p-dropdown
            [options]="poojaItemDeliveryChargeTypes"
            [(ngModel)]="poojaItem.DELIVERY"
            placeholder="Select delivery charge type"
            [showClear]="true"
            required
            name="poojaItemDeliveryChargeType"
            id="poojaItemDeliveryChargeType"
            #poojaItemDeliveryChargeType="ngModel"
          ></p-dropdown>
          <small
            id="poojaItemDeliveryChargeType-help"
            *ngIf="
              poojaItemDeliveryChargeType.errors?.required === true &&
              poojaItemDeliveryChargeType.touched
            "
            class="p-error"
            >Select Delivery Charge type</small
          >
        </div>
        <!-- Delivery Charges -->
        <div class="p-sm-12 p-md-12 p-lg-6">
          <label for="singleItemWeight">Single Item Weight(Kgs)*</label>

          <p-inputNumber
            [(ngModel)]="poojaItem.SINGLE_ITEM_WEIGHT"
            mode="decimal"
            inputId="singleItemWeight"
            [min]="poojaItem.DELIVERY === false ? 1 : 0"
            locale="en-IN"
            #singleItemWeight="ngModel"
            name="singleItemWeight"
            [minFractionDigits]="3"
            suffix="Kgs"
            required
          ></p-inputNumber>
          <small
            id="singleItemWeight-help"
            *ngIf="
              singleItemWeight.errors?.required === true &&
              singleItemWeight.touched
            "
            class="p-error"
            >Please enter Single Item Weight</small
          >
          <small
            id="singleItemWeight-help"
            *ngIf="
              singleItemWeight.errors?.minlength ||
              singleItemWeight.errors?.maxlength
            "
            class="p-error"
            >Single Item Weight should be more than 0</small
          >
        </div>
        <div class="p-col-12">
          <button
            pButton
            pRipple
            type="submit"
            [label]="submitting === true ? 'Please wait' : 'Save'"
            style="max-width: 150px"
            class="p-button-raised btn-theme p-button-text"
            [disabled]="poojaItemForm.valid === false || submitting === true"
          ></button>
        </div>
      </div>
    </div>
  </div>
</form>
