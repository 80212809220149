import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuCategories } from 'src/app/common/menu.model';
import { MenuService } from 'src/app/common/menu.service';
import { NewsService } from 'src/app/common/news.service';
import { Category, NewsVM, PurposeType, ResultType } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'app-bhakthi-vartha-dashboard',
  templateUrl: './bhakthi-vartha-dashboard.component.html',
  styleUrls: ['./bhakthi-vartha-dashboard.component.css']
})
export class BhakthiVarthaDashboardComponent implements OnInit, OnDestroy {

  private serviceSubscriptions: Subscription;
  newsObj: NewsVM[];
  contentLoading: boolean = true;
  noMoreNews: boolean = false;
  constructor(
    private newsService: NewsService,
    private menuService: MenuService
  ) {
    this.serviceSubscriptions = new Subscription();
    this.menuService.setMenuItems(MenuCategories.all);
    this.newsObj = [];
    this.serviceSubscriptions.add(
    this.newsService
      .getNews(10, Category.bhakthiNews, PurposeType.forShowList, false, ResultType.after)
      .subscribe(data => {
        this.contentLoading = false;
        // console.log('Bhakthi for editor screen: ', data);
        if (data.length > 0) {

          this.newsObj = [...this.newsObj, ...data];
        } else {
          this.noMoreNews = true;
        }
      }, error => {
        // console.log(error);
        this.contentLoading = false;
      })
    );

  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
  }
  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {

    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !this.noMoreNews && !this.contentLoading) {
      // console.log('You have reached bottome of page');
      let lastNewsId = '';
      this.contentLoading = true;
      if (this.newsObj.length > 0 && this.newsObj.length > 0)
        lastNewsId = this.newsObj[this.newsObj.length - 1].NEWS_EXTERNAL_ID;
      this.serviceSubscriptions.add(
      this.newsService
        .getNews(10, Category.bhakthiNews, PurposeType.forShowList, false, ResultType.after, lastNewsId)
        .subscribe(data => {
          this.contentLoading = false;
          if (data.length > 0) {
            // this.latestNews = data;
            this.newsObj = [...this.newsObj, ...data];
          } else {
            this.noMoreNews = true;
          }
        }, error => {
          // console.log(error);
          this.contentLoading = false;
        })
      );
    }
  }

}
