<button
  pButton
  pRipple
  type="button"
  icon="pi pi-plus"
  label="ADD QUESTION"
  routerLink="./add"
  class="p-button-raised p-button-text btn-add-category"
></button>
<div class="p-fluid p-text-center">
  <dashboard-question-card
    *ngFor="let prasna of prasnaVinodhini"
    [question]="prasna"
  >
  </dashboard-question-card>
  <app-loader [enable]="contentLoading"></app-loader>
</div>
