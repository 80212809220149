import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-multi-select-embed',
  templateUrl: './multi-select-embed.component.html',
  styleUrls: ['./multi-select-embed.component.css']
})
export class MultiSelectEmbedComponent implements OnInit {
  // Inputs to component
  // These inputs need to provide by the user when he using component
  @Input() options: any[];
  @Input() width: string;
  @Input() height: string;
  @Input() dataKey: string;
  @Input() valKey: string;
  @Input() selected: any[] = [];

  // Output emmitters
  @Output() onChange: EventEmitter<any[]> = new EventEmitter();

  // Local variables
   // selectedValues: number[];


  constructor() {
    this.options = [];
    this.selected = [];
   }

  ngOnInit(): void {
    
  }

  // Property key
  get dtKey() {
    return this.dataKey || 'label';
  }

  // property val key
  get vlKey() {
    return this.valKey|| 'value';
  }

  // styles
  compStyles() {
    return { 
      'width': this.width || '100%' ,
      'height': this.height || '100%'
    }
  }

  optionSelected(event) {
    // // console.log('Event from multi select embed: ',this.selected);
    this.onChange.emit(this.selected);
  }

  // Handle clear input
  clear() {
    this.selected = [];
    this.onChange.emit(this.selected);
  }

}
