import { HttpBackend, HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IAppConfig } from '../models/app-config.model';


@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  static settings: IAppConfig;
  private http: HttpClient
  constructor(private handler: HttpBackend) { 
    // https://stackoverflow.com/questions/46469349/how-to-make-an-angular-module-to-ignore-http-interceptor-added-in-a-core-module/49013534#49013534
    // Issue with intializer dependencey
    this.http = new HttpClient(handler);
  }

  load() {
    const jsonFile = `assets/config/config.${environment.name}.json`;
    return new Promise<void>((resolve, reject) => {
        this.http.get(jsonFile).toPromise().then((response : IAppConfig) => {
          AppConfigService.settings = <IAppConfig>response;
           resolve();
        }).catch((response: any) => {
           reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
        });
    });
}
}
