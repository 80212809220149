import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ProfileService } from 'src/app/common/profile.service';
import { MESSAGE_SERVICE_KEY } from 'src/app/constants';
import { GetAddressStatus, UserAddressVM } from 'src/app/models/avbhakthi.model';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-add-modify-user-address',
  templateUrl: './add-modify-user-address.component.html',
  styleUrls: ['./add-modify-user-address.component.css']
})
export class AddModifyUserAddressComponent implements OnInit, OnDestroy{

  public address: UserAddressVM;
  public submitting: boolean = false;
  private serviceSubscriptions: Subscription;
  private redirect: boolean = false;
  addressTypeOptions = [
    {
      name: 'HOME',
      code: 1
    },
    {
      name: 'OFFICE/COMMERCIAL',
      code: 2
    }
  ]
  constructor(
    private profileService: ProfileService,
    private messageService: MessageService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) { 
    // Initializations
    this.serviceSubscriptions = new Subscription();
    this.address = new UserAddressVM();
  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(params => {
      let id = params.get('id');
      
      if (id) {
        this.serviceSubscriptions.add(
        this.profileService.getAddress(GetAddressStatus.both)
          .subscribe(data => {
            let filteredAddress = data.filter(a => a.ADDRESS_EXTERNAL_ID === id);
            if(filteredAddress.length > 0) {
              this.address = filteredAddress[0];
            }
          }));
      }
    });

    this.serviceSubscriptions.add(
    this.activeRoute.queryParamMap.subscribe(params => {
      let redirect = params.get('redirect')
      if(redirect){
        this.redirect = true;
      }
    })
    );
  }

  saveAddressDetails(form: NgForm) {
    if(form.valid) {
      let isNew = false;
      if (this.address.ADDRESS_EXTERNAL_ID) {
        isNew = false;
      } else {
        this.address.ADDRESS_EXTERNAL_ID = uuidv4();
        isNew = true;
      }
      this.submitting = true;
      this.address.MOBILE_NUMBER = '+91'+this.address.MOBILE_NUMBER;
      this.profileService.saveUserAddress(this.address, isNew).subscribe(data => {
        if (data.CODE === 100) {
          this.messageService.add({ severity: 'success', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
          // this.router.navigateByUrl('dashboard/pooja-store');
          if(this.redirect === true)
            this.router.navigate(['/app/check-out/pick-address']);
          else
          this.router.navigate(['/dashboard/user-addresses']);
        } else {
          this.messageService.add({ severity: 'error', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
        }
        this.submitting = false;
      }, error => {
        // console.log('Error: ', error);
        this.messageService.add({ severity: 'error', summary: error.error.DESC, key: MESSAGE_SERVICE_KEY});
        this.submitting = false;
        if(isNew) {
          this.address.ADDRESS_EXTERNAL_ID = '';
        }
      });
    }

   
  }

}
