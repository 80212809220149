import { Injectable } from '@angular/core';
import { ICustomWindow } from '../models/avbhakthi.model';


function getWindow(): any {
  return window;
}

@Injectable({
  providedIn: 'root'
})

export class WindowRefService {

  get nativeWindow(): ICustomWindow {
    return getWindow();
  }
  
}