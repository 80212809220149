<p-card styleClass="p-card-shadow guru-card-dashboard" >
  <div class="card-description" (click)="cardClicked()">
    <h2
      class="theme-color p-my-2 p-text-light guru-title"
      [innerHtml]="quote.TITLE"
    ></h2>
    <!-- <div class="card-description p-px-3 p-py-2 p-text-justify" [innerHtml]="quote.CONTENT"> -->
    <!-- {{quote.CONTENT}} -->
    <!-- </div> -->
    <div class="p-grid">
      <div class="p-col-6">
        <p class="p-my-1 p-text-light guru-subtitle p-text-left">
          <i class="pi pi-user"></i> {{ quote.POSTED_NAME }}
        </p>
      </div>
      <div class="p-col-6">
        <p class="p-text-right p-my-1 p-text-light guru-subtitle">
          <i class="pi pi-calendar"></i> {{ quote.RELEASE_DATE | dateIso }}
        </p>
      </div>
      <div class="p-col-12 p-text-center" style="font-size: 1.5rem">
        {{ quote.NEWS_STATUS | statusType }}
      </div>
    </div>
  </div>
</p-card>
