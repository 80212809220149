<div class="page-header full-bg">
  <div class="blur-bg page-header-content">
    <h1 class="p-text-center p-text-white page-title p-my-1">
      పురోహిత దర్శిని
    </h1>
    <hr class="special-line" />
    <h2 class="p-text-center page-sub-title p-my-1">
      మీరు ఇచ్చట గృహము మరియు దేవాలయమునందు జరుగు భక్తి, ఆధ్యాత్మిక, పూజా
      కార్యక్రమాలు చేయుటకు మీ సమీపంలో ఉన్న పురోహితులను తెలుసుకుని, వారిని నేరుగా
      సంప్రదించవచ్చును.
    </h2>
  </div>
</div>
<div class="p-fluid p-grid">
  <div #collapseSidebar class="p-col" style="max-width: 300px;position: relative;transition: 'all 400ms ease';" >
    <button 
      pButton 
      [icon]="toggleSidebar === true ? 'pi pi-chevron-right' : 'pi pi-chevron-left'" 
      class="btn-theme p-button-info p-shadow-2"
      (click)="toggleMe($event, collapseSidebar)"
      style="position: absolute;right:-20px;top:0px;border-radius: 0 !important;padding: 10px 4px;width:auto;border:none;"
      >

    </button>
    <app-purohit-filter
    [geoLocationAddress]="geoLocationAddress"
    (filterApplied)="filterApplied($event)"
    [filter]="purohitFilter"
    ></app-purohit-filter>
    
  </div>
  <div class="p-col">
    <div class="p-text-center" style="min-height: 400px">
      <app-loader *ngIf="isDataloading"></app-loader>
      <h2 class="p-text-center" *ngIf="purohitList.length === 0">No purohit details found with applied filter.</h2>
      <app-purohit-card *ngFor="let purohit of purohitList" [purohit]="purohit">
    
      </app-purohit-card>
    </div>
  </div>
</div>
