import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Category, NewsVM, PurposeType, ResultType } from '../models/avbhakthi.model';
import { SOCIALICONS } from '../constants';
import { MenuService } from '../common/menu.service';
import { MenuCategories } from '../common/menu.model';
import { ActivatedRoute } from '@angular/router';
import { NewsService } from '../common/news.service';
import { AppConfigService } from '../common/app-config.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bhakthi-details',
  templateUrl: './bhakthi-details.component.html',
  styleUrls: ['./bhakthi-details.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BhakthiDetailsComponent implements OnInit, OnDestroy {

  newsObj: NewsVM;
  private serviceSubscriptions: Subscription;
  readonly baseURL: string = AppConfigService.settings.serverURL;
  readonly socialIcons = SOCIALICONS;
  contentLoading: boolean = true;
  constructor(
    private menuService: MenuService,
    private route: ActivatedRoute,
    private newsService: NewsService
  ) {
    this.serviceSubscriptions = new Subscription();
    this.menuService.setMenuItems(MenuCategories.all);

  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe(); // Unsubcribing all services
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      let newsId = data.get('id');
      this.setNewsContent(newsId);
    })
  }
  get newsCategory(): typeof Category {
    return Category;
  }
  setNewsContent(newsId: string, resultType: ResultType = ResultType.after, include: boolean = true) {
    if (newsId) {
      this.serviceSubscriptions.add(this.newsService
        .getNews(1, Category.bhakthiNews, PurposeType.forMainScreen, include, resultType, newsId)
        .subscribe(data => {
          
          this.contentLoading = false;
          // Receving array of temples as response so taking 1st element from it
          if (data.length > 0) { 
            this.newsObj = data[0];
          } 
        }, error => {
          // console.log(error);
        }));
    }
  }
  getImageURL(): string {
    return this.baseURL + this.newsObj.IMAGE_URL.replace(/\\/g, '/');
  }

  // Function to handle next button event
  // Action: Fetches next news from api
  nextNews() {
    this.setNewsContent(this.newsObj.NEWS_EXTERNAL_ID, ResultType.after, false);
  }

  // Function to handle previous button event
  // Action: Fetches previous news from api
  previousNews() {
    this.setNewsContent(this.newsObj.NEWS_EXTERNAL_ID, ResultType.before, false);
  }

}
