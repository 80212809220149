<div class="p-fluid">
  <p-overlayPanel #op>
    <ng-template pTemplate>
      <share-buttons [include]="['facebook','twitter','whatsapp','pinterest']" [url]="sharableLink"></share-buttons>
    </ng-template>
  </p-overlayPanel>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 item-details-image-gallery">
      <p-galleria [(value)]="images" [responsiveOptions]="responsiveOptions" [numVisible]="4" thumbnailsPosition="left">
        <ng-template pTemplate="item" let-item>
          <div class="img-container">
            <img [src]="item.path" [alt]="item.alt" class="img-fluid" />
          </div>
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
          <div class="p-grid p-nogutter p-justify-center">
            <div class="thumbnail-container">
              <img class="img-fluid" [src]="item.path" />
            </div>
          </div>
        </ng-template>
      </p-galleria>
    </div>
    <div class="p-col-12 p-md-6 p-px-3" style="position: relative;">

      <div class="share-icon-container">

        <button pButton label="share" icon="pi pi-share-alt" class="p-button p-button-text p-button-plain p-p-1"
          styleClass="p-p-1" (click)="op.toggle($event)">
        </button>
      </div>
      <h1 class="product-name line-height-1 theme-color p-my-1" style="font-size: 1.5rem;max-width: calc(100% - 80px);">
        {{ poojaItem.ITEM_NAME }}
      </h1>
      <div>
        <i class="pi pi-tag product-category-icon"></i><span class="product-category">{{
          getCategoryName(poojaItem.CATEGORY_ID)
          }}</span>
      </div>
      <p class="product-price p-my-0">
        &#8377;<span class="theme-color p-mr-1 font-family-roboto">{{ poojaItem.FINAL_PRICE | number}}/-</span>
        <span class="font-family-roboto" style="font-size: 0.7rem; text-decoration: line-through ">&#8377;{{
          poojaItem.PRICE | number}}/-</span>
      </p>
      <div>
        <h2 class="p-my-1 p-text-light">Item Details</h2>
        <p class="p-mt-1 p-mb-4 line-height-1 p-pl-2">
          {{ poojaItem.ITEM_DETAILS }}
        </p>
        <span [class]="
            'product-badge status-' +
            (poojaItem.ITEM_AVAILABILITY === true ? 'instock' : 'outofstock')
          ">{{
          poojaItem.ITEM_AVAILABILITY === true ? "INSTOCK" : "OUT OF STOCK"
          }}</span>
      </div>
      <div class="p-mt-3" *ngIf="poojaItem.ITEM_AVAILABILITY === true">
        <div class="p-field p-grid">
          <label for="minmax-buttons" class="p-col" style="max-width: 65px">Quantity</label>
          <div class="p-col" style="max-width: 120px">
            <p-inputNumber mode="decimal" [showButtons]="true" inputId="minmax-buttons" [min]="1"
              name="poojaItemQuantity" [(ngModel)]="quantity">
            </p-inputNumber>
          </div>
        </div>
      </div>
      <div class="p-grid" *ngIf="poojaItem.ITEM_AVAILABILITY === true">
        <div class="p-col" style="max-width: 175px">
          <button pButton class="theme-color line-height-1 p-button-outlined" label="ADD TO CART" style="
              padding: 10px 20px;
              border-radius: 15px;
              background: white !important;
            " (click)="addToCart()"></button>
        </div>
        <div class="p-col" style="max-width: 175px">
          <button pButton class="btn-theme" label="BUY NOW" (click)="addToCart(true)"></button>
        </div>
      </div>
    </div>
  </div>
</div>