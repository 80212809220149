import { Component, OnInit, OnDestroy, ViewEncapsulation, Renderer2 } from '@angular/core';
import { MenuService } from '../common/menu.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { DomElementSchemaRegistry } from '@angular/compiler';
import { Router } from '@angular/router';
import { UserPointsVM, UserVM } from '../models/avbhakthi.model';
import { ProfileService } from '../common/profile.service';
import { AppConfigService } from '../common/app-config.service';
import { EPoojaStoreService } from '../e-store/e-pooja-store.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
  private serviceSubscriptions: Subscription;
 
  private isScrolled: boolean = false;
  readonly baseURL: string = AppConfigService.settings.serverURL;
  menuItems: MenuItem[];
  userProfile: UserVM = new UserVM();
  userPoints: UserPointsVM = new UserPointsVM();
  profilePicfound: boolean = true;
  cartItemsCount: number = 0;

  constructor(
    private menuService: MenuService,
    private router: Router,
    private profileService: ProfileService,
    private ePoojaStoreService: EPoojaStoreService
  ) {
    this.serviceSubscriptions = new Subscription();
    this.menuItems = [];
    this.serviceSubscriptions.add(this.menuService.items.subscribe(data => {
      this.menuItems = data;
    }));

    this.serviceSubscriptions.add(this.profileService.profileInfo.subscribe(user => {
      this.userProfile = user;
    }));
    this.serviceSubscriptions.add(this.profileService.userPoints.subscribe(points => {
      this.userPoints = points;
    }));

    window.onscroll = () => {
      // this.zone.run(() => {
      var header = document.getElementsByClassName('main-menu')[0];
      this.isScrolled = window.pageYOffset > 5 ? true : false; // there is another sticky on the top in my app
      // this.renderer.setElementClass(header, 'header_scrolled', this.isScrolled);
      if (this.isScrolled)
        header.classList.add('fixed-top');
      else
        header.classList.remove('fixed-top');
      // });
    }

    // Subciption for cart ITEMs to fetch count
    this.serviceSubscriptions.add(
      this.ePoojaStoreService.cartItemCount.subscribe(data=> {
        this.cartItemsCount = data?.COUNT;
      })
    );
  }


  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  toDashboard() {
    this.router.navigate(['dashboard']);
  }
  toHome() {
    this.router.navigate(['app']);
  }
  urlNotfound() {
    // console.log('User profiel pic not found');
    this.profilePicfound = !this.profilePicfound;
  }
  getProfilePic(){
    return this.baseURL + this.userProfile.IMAGE_PATH;
  }
}
