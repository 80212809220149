import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ProfileService } from 'src/app/common/profile.service';
import { TempleService } from 'src/app/common/temple.service';
import { MESSAGE_SERVICE_KEY, POST_STATUS, PUBLISH_TYPES, TARGET_AUDIENCE } from 'src/app/constants';
import { Category, ImageCategory, ImageInfoVM, Place, PurposeType, ResultType, TempleVM } from 'src/app/models/avbhakthi.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-add-modify-alaya-darshini',
  templateUrl: './add-modify-alaya-darshini.component.html',
  styleUrls: ['./add-modify-alaya-darshini.component.css']
})
export class AddModifyAlayaDarshiniComponent implements OnInit, OnDestroy{
  private serviceSubscriptions: Subscription;
  public templeObj: TempleVM;
  uploadedFiles: any[] = [];
  submitting: any = false;
  updateNews: boolean = false;
  isLocationRequired: boolean = true;
  constructor(
    private templeService: TempleService,
    private router: Router,
    private messageService: MessageService,
    private activeRoute: ActivatedRoute,
    private profileService: ProfileService
  ) {
    this.serviceSubscriptions = new Subscription();
    this.templeObj = new TempleVM();
  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
    // // console.log('Uuid from javascript: ', uuidv4());
    this.activeRoute.paramMap.subscribe(params => {
      let id = params.get('id');
      if (id) {
        this.serviceSubscriptions.add(
        this.templeService.getTemples(1, Category.alayaDarshini, PurposeType.forShowList, true, ResultType.after, id)
          .subscribe(data => {
            if (data.length > 0) {
              this.templeObj = data[0];
              this.updateNews = true;
            }
          }));
      }
    });
  }

  get PublishTypes() {
    return PUBLISH_TYPES;
  }

  get PostStatus() {
    return this.profileService.PostStatus;
  }

  get TargetAudience() {
    return TARGET_AUDIENCE;
  }
  saveNewsDetails(form: NgForm) {
    // console.log(form.value);
    // console.log(this.templeObj);
    if (form.valid) {
      let isNewTemple = true;
      var formData = new FormData();
      this.templeObj.CATEGORY = Category.alayaDarshini;
      if (this.templeObj.TEMPLE_EXTERNAL_ID) {
        if (this.uploadedFiles.length > 0) {
          formData.append('IS_IMG_CHANGED', 'true');
        } else {
          formData.append('IS_IMG_CHANGED', 'false');
        }
        isNewTemple = false;
      } else {
        this.templeObj.TEMPLE_EXTERNAL_ID = uuidv4();
      }


      for (let key in this.templeObj) {
        formData.append(key, this.templeObj[key]);
      }

      // Image file data
      if (this.uploadedFiles.length > 0) {
        this.uploadedFiles.forEach(file => {
          // formData.append('IMAGE_FILE[]', file);
          formData.append('IMAGE_FILES', file);
        });

      }


      // imageInfo
      let imageInfo = new FormData();
      let imageInfoObj = new ImageInfoVM();
      imageInfoObj.IMAGE_CATEGORY = ImageCategory.imgTemple;
      // for(let key in imageInfoObj)   {
      //   formData.append(key, imageInfoObj[key]);
      // }
      formData.append('imageInfo.IMAGE_CATEGORY', ImageCategory.imgNews.toString());
      formData.append('imageInfo.UID', uuidv4());

      this.submitting = true;

      
      this.serviceSubscriptions.add(
      this.templeService.postTemple(formData, isNewTemple).subscribe(data => {
        if (data.CODE === 100) {
          this.messageService.add({ severity: 'success', summary: data.DESC, key: MESSAGE_SERVICE_KEY });
          this.router.navigateByUrl('dashboard/alaya-darshini');
          
        } else {
          this.messageService.add({ severity: 'error', summary: data.DESC, key: MESSAGE_SERVICE_KEY });

        }
        this.submitting = false;
      }, error => {
        // console.log('Error: ', error);
        this.messageService.add({ severity: 'error', summary: JSON.stringify(error), key: MESSAGE_SERVICE_KEY });
        this.submitting = false;
        if(isNewTemple) {
          this.templeObj.TEMPLE_EXTERNAL_ID = null;
        }
      }));
    }
  }
  onUpload(event) {
    this.uploadedFiles = [];
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    // this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
  }
  setAudienceLocation(place: Place) {
    this.templeObj.AUD_LOC_LAT = place.lat;
    this.templeObj.AUD_LOC_LONG = place.lng;
    
  }

  setTempleLocation(place: Place) {
    this.templeObj.TEMPLE_GEO_LOC_ADDRESS = place.formattedAddress;
    this.templeObj.GEO_LOC_PLACE_ID = place.placeId;
    this.templeObj.LATITUDE = place.lat;
    this.templeObj.LONGITUDE = place.lng;
  }

  handleTimePickerEvent(evnt: Date, fType: number) {

    switch(fType) {
      case 1: 
        this.templeObj.TEMPLE_OPENING_TIME_MORNING = moment(evnt).format('HH:mm');
        break;
        case 2: 
        this.templeObj.TEMPLE_CLOSING_TIME_MORNING = moment(evnt).format('HH:mm');
        break;
        case 3: 
        this.templeObj.TEMPLE_OPENING_TIME_EVENING = moment(evnt).format('HH:mm');
        break;
        case 4: 
        this.templeObj.TEMPLE_CLOSING_TIME_EVENING = moment(evnt).format('HH:mm');
        break;
      
    }
    
    // attr = moment(evnt).format('HH:mm');
  }
}
