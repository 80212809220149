import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';


import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import {CarouselModule} from 'primeng/carousel';
import { HeaderComponent } from './header/header.component';
import { MenubarModule } from 'primeng/menubar';
import { NewsCardComponent } from './news-card/news-card.component';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { YatraCardComponent } from './yatra-card/yatra-card.component';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { QuestionCardComponent } from './question-card/question-card.component';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { BhakthiVarthaComponent } from './bhakthi-vartha/bhakthi-vartha.component';
import { YatraSandharshiniComponent } from './yatra-sandharshini/yatra-sandharshini.component';
import { GuruSubhashiniComponent } from './guru-subhashini/guru-subhashini.component';
import { GuruCardComponent } from './guru-card/guru-card.component';
import { FooterComponent } from './footer/footer.component';
import { PrasnaVinodhiniComponent } from './prasna-vinodhini/prasna-vinodhini.component';
import { RoutingModule } from './routing/routing.module';
import { BhakthiDetailsComponent } from './bhakthi-details/bhakthi-details.component';
import * as firebase from 'firebase/app';
import { AuthService } from './auth-service/auth.service';

//Prime components
import { InputTextModule } from 'primeng/inputtext';
import {ChipsModule} from 'primeng/chips';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { YatraDetailsComponent } from './yatra-details/yatra-details.component';
import { TableModule } from 'primeng/table';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { BhakthiVarthaDashboardComponent } from './dashboard/bhakthi-vartha-dashboard/bhakthi-vartha-dashboard.component';
import { AddModifyBhakthiVarthaComponent } from './dashboard/add-modify-bhakthi-vartha/add-modify-bhakthi-vartha.component';
import { PrasnaVinodhiniDashboardComponent } from './dashboard/prasna-vinodhini-dashboard/prasna-vinodhini-dashboard.component';
import { AddModifyPrasnaVinodhiniComponent } from './dashboard/add-modify-prasna-vinodhini/add-modify-prasna-vinodhini.component';
import { YatraSandharshiniDashboardComponent } from './dashboard/yatra-sandharshini-dashboard/yatra-sandharshini-dashboard.component';
import { AddModifyYatraSandharshiniComponent } from './dashboard/add-modify-yatra-sandharshini/add-modify-yatra-sandharshini.component';
import { GuruSubhashiniDashboardComponent } from './dashboard/guru-subhashini-dashboard/guru-subhashini-dashboard.component';
import { AddModifyGuruSubhashiniComponent } from './dashboard/add-modify-guru-subhashini/add-modify-guru-subhashini.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { SidebarModule } from 'primeng/sidebar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { UsersComponent } from './dashboard/users/users.component';
import { DashboardNewsCardComponent } from './dashboard/dashboard-news-card/dashboard-news-card.component';
import { DateIsoPipe } from './common/date-iso.pipe';
import { StatusTypePipe } from './common/status-type.pipe';
import { DashboardYatraCardComponent } from './dashboard/dashboard-yatra-card/dashboard-yatra-card.component';
import { DashboardQuestionCardComponent } from './dashboard/dashboard-question-card/dashboard-question-card.component';
import { DashboardGuruCardComponent } from './dashboard/dashboard-guru-card/dashboard-guru-card.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './common/httpconfig.interceptor';
import { AppHttpService } from './common/http.service';
import { LoaderComponent } from './loader/loader.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { NewsService } from './common/news.service';
import { AlayaDarshiniComponent } from './alaya-darshini/alaya-darshini.component';
import { TempleCardComponent } from './temple-card/temple-card.component';
import { TempleDetailsComponent } from './temple-details/temple-details.component';
import { SafePipePipe } from './common/safe-pipe.pipe';
import { DialogModule } from 'primeng/dialog';
import { AddModifyAlayaDarshiniComponent } from './dashboard/add-modify-alaya-darshini/add-modify-alaya-darshini.component';
import { AlayaDarshiniDashboardComponent } from './dashboard/alaya-darshini-dashboard/alaya-darshini-dashboard.component';
import { MainComponent } from './main/main.component';
import { RouterStateSnapshot } from '@angular/router';
import { AutocompleteLocationComponent } from './autocomplete-location/autocomplete-location.component';
import { DashboardTempleCardComponent } from './dashboard/temple-card/temple-card.component';
import { GMapModule } from 'primeng/gmap';
import { CardFooterComponent } from './card-footer/card-footer.component';
import { NearestTempleCardComponent } from './nearest-temple-card/nearest-temple-card.component';
import {MultiSelectModule} from 'primeng/multiselect'
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {SliderModule} from 'primeng/slider';
import {AvatarModule} from 'primeng/avatar';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {InputNumberModule} from 'primeng/inputnumber';
import {DataViewModule} from 'primeng/dataview';
import {GalleriaModule} from 'primeng/galleria';
import {BadgeModule} from 'primeng/badge';
import {StepsModule} from 'primeng/steps';
import {TimelineModule} from 'primeng/timeline';

import {AppConfigService} from './common/app-config.service';
import { ProfileService } from './common/profile.service';
import { RegisterPurohitComponent } from './dashboard/register-purohit/register-purohit.component';
import { PurohitDarshiniComponent } from './purohit-darshini/purohit-darshini.component';
import { PurohitFilterComponent } from './purohit-filter/purohit-filter.component';
import { PurohitCardComponent } from './purohit-card/purohit-card.component';
import { PurohitDetailsComponent } from './purohit-details/purohit-details.component';
import { PurohitApproveComponent } from './dashboard/purohit-approve/purohit-approve.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { MultiSelectEmbedComponent } from './multi-select-embed/multi-select-embed.component';
import { AddModifyPoojaItemComponent } from './dashboard/add-modify-pooja-item/add-modify-pooja-item.component';
import { PoojaStoreComponent } from './dashboard/pooja-store/pooja-store.component';
import { OtherInfoComponent } from './other-info/other-info.component';
import { PoojaStoreItemsComponent } from './dashboard/pooja-store-items/pooja-store-items.component';
import { EPoojaStoreComponent } from './e-pooja-store/e-pooja-store.component';
import { PoojaItemDetailsComponent } from './pooja-item-details/pooja-item-details.component';
import { CartComponent } from './cart/cart.component';
import { UserAddressesComponent } from './dashboard/user-addresses/user-addresses.component';
import { AddModifyUserAddressComponent } from './dashboard/add-modify-user-address/add-modify-user-address.component';
import { CheckOutComponent } from './check-out/check-out.component';
import { PickAddressComponent } from './check-out/pick-address/pick-address.component';
import { InitPaymentComponent } from './check-out/init-payment/init-payment.component';
import { PlaceOrderComponent } from './check-out/place-order/place-order.component';
import { OrderConfirmationComponent } from './check-out/order-confirmation/order-confirmation.component';
import { UserOrdersComponent } from './dashboard/user-orders/user-orders.component';
import { ConfirmUpdateOrdersComponent } from './dashboard/confirm-update-orders/confirm-update-orders.component';

export function initializeApp(appConfig: AppConfigService) {
  return () => appConfig.load();
}

firebase.initializeApp(environment.firebase);
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    NewsCardComponent,
    YatraCardComponent,
    QuestionCardComponent,
    HomeComponent,
    BhakthiVarthaComponent,
    YatraSandharshiniComponent,
    GuruSubhashiniComponent,
    GuruCardComponent,
    FooterComponent,
    PrasnaVinodhiniComponent,
    BhakthiDetailsComponent,
    YatraDetailsComponent,
    DashboardComponent,
    BhakthiVarthaDashboardComponent,
    AddModifyBhakthiVarthaComponent,
    PrasnaVinodhiniDashboardComponent,
    AddModifyPrasnaVinodhiniComponent,
    YatraSandharshiniDashboardComponent,
    AddModifyYatraSandharshiniComponent,
    GuruSubhashiniDashboardComponent,
    AddModifyGuruSubhashiniComponent,
    ProfileComponent,
    UsersComponent,
    DashboardNewsCardComponent,
    DateIsoPipe,
    StatusTypePipe,
    DashboardYatraCardComponent,
    DashboardQuestionCardComponent,
    DashboardGuruCardComponent,
    LoaderComponent,
    AlayaDarshiniComponent,
    TempleCardComponent,
    TempleDetailsComponent,
    SafePipePipe,
    AddModifyAlayaDarshiniComponent,
    AlayaDarshiniDashboardComponent,
    MainComponent,
    AutocompleteLocationComponent,
    DashboardTempleCardComponent,
    CardFooterComponent,
    NearestTempleCardComponent,
    RegisterPurohitComponent,
    PurohitDarshiniComponent,
    PurohitFilterComponent,
    PurohitCardComponent,
    PurohitDetailsComponent,
    PurohitApproveComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    RefundPolicyComponent,
    MultiSelectEmbedComponent,
    AddModifyPoojaItemComponent,
    PoojaStoreComponent,
    OtherInfoComponent,
    PoojaStoreItemsComponent,
    EPoojaStoreComponent,
    PoojaItemDetailsComponent,
    CartComponent,
    UserAddressesComponent,
    AddModifyUserAddressComponent,
    CheckOutComponent,
    PickAddressComponent,
    InitPaymentComponent,
    PlaceOrderComponent,
    OrderConfirmationComponent,
    UserOrdersComponent,
    ConfirmUpdateOrdersComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    CarouselModule,
    MenubarModule,
    CardModule,
    ButtonModule,
    CheckboxModule,
    RadioButtonModule,
    FormsModule,
    RoutingModule,
    InputTextModule,
    ToastModule,
    TableModule,
    SidebarModule,
    PanelMenuModule,
    HttpClientModule,
    InputTextareaModule,
    DropdownModule,
    CalendarModule,
    FileUploadModule,
    ChipsModule,
    DialogModule,
    GMapModule,
    AutoCompleteModule,
    ShareButtonsModule,
    ShareIconsModule,
    ShareButtonsPopupModule,
    OverlayPanelModule,
    MultiSelectModule,
    SliderModule,
    AvatarModule,
    VirtualScrollerModule,
    InputNumberModule,
    DataViewModule,
    GalleriaModule,
    BadgeModule,
    StepsModule,
    TimelineModule
  ],
  providers: [
    MessageService,
    NewsService,
    AuthService,
    AppHttpService,
    ProfileService,
    // RouterStateSnapshot,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { 
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
