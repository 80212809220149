<div [ngStyle]="compStyles()" class="multi-select-container">
  <div *ngFor="let option of options" class="p-field-checkbox">
    <p-checkbox
      name="group2"
      value="option[vlKey]"
      [value]="option[vlKey]"
      [(ngModel)]="selected"
      [inputId]="option[vlKey]"
      (onChange)="optionSelected($event)"
    ></p-checkbox>
    <label [for]="option[vlKey]">{{ option[dtKey] }}</label>
  </div>
</div>
