import { AfterViewInit, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/common/app-config.service';
// import { BASEURL } from 'src/app/constants';
import { TempleVM } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'dashboard-temple-card',
  templateUrl: './temple-card.component.html',
  styleUrls: ['./temple-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardTempleCardComponent implements OnInit, AfterViewInit {

  @Input() temple: TempleVM;
  // readonly socialIcons = SOCIALICONS;
  contentLoading: boolean = true;
  readonly baseURL: string = AppConfigService.settings.serverURL;
  templeImages: string[] = [];
  servicesModal: boolean = false;
  accommodattionModal: boolean = false;
  trvelModal: boolean = false;
  directionsModal: boolean = false;
  
  constructor(
    private router: Router
  ) {
    this.temple = new TempleVM();


  }
  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.templeImages = this.temple.IMAGE_URL.split('#');
  }
  getImageURL(): string {
    if (this.templeImages.length > 0)
      return this.baseURL+this.templeImages[0];
    return '';
  }
  cardClicked() {
    this.router.navigateByUrl('dashboard/alaya-darshini/add/' + this.temple.TEMPLE_EXTERNAL_ID);
  }
}
