<div class="p-shadow-3 form-layout-container">
  <form #questionForm="ngForm" (ngSubmit)="questionSubmit(questionForm)">
    <div class="p-fluid p-container">
      <div class="p-field">
        <label for="">Quetion Type</label>
        <p-dropdown
          [options]="QuestionTypes"
          name="questionType"
          [(ngModel)]="prasnaObj.QT_TYPE"
          placeholder="Select publish type"
          [showClear]="true"
          required
        ></p-dropdown>
      </div>
      <div class="p-field">
        <label for="">Your Question</label>
        <input
          id=""
          name="question"
          [(ngModel)]="prasnaObj.QUESTION"
          type="text"
          aria-describedby=""
          pInputText
          required
        />
        <!-- <small id="username2-help" class="p-invalid">Username is not available.</small> -->
      </div>
      <div class="p-fluid p-grid p-form-grid" *ngIf="prasnaObj.QT_TYPE === 103">
        <div class="p-col-12 p-lg-6">
          <p-checkbox
            value="1"
            name="mutliOption1"
            [(ngModel)]="prasnaObj.ANSWER"
          ></p-checkbox>
          <div class="p-field">
            <input
              id=""
              type="text"
              [(ngModel)]="prasnaObj.OPTION1"
              placeholder="Option 1"
              name="moption1"
              aria-describedby=""
              pInputText
              required
            />
            <!-- <small id="username2-help" class="p-invalid">Username is not available.</small> -->
          </div>
        </div>
        <div class="p-col-12 p-lg-6">
          <p-checkbox
            value="2"
            name="mutliOption2"
            [(ngModel)]="prasnaObj.ANSWER"
          ></p-checkbox>
          <div class="p-field">
            <input
              id=""
              type="text"
              name="moption2"
              [(ngModel)]="prasnaObj.OPTION2"
              placeholder="Option 2"
              aria-describedby=""
              pInputText
              required
            />
            <!-- <small id="username2-help" class="p-invalid">Username is not available.</small> -->
          </div>
        </div>
        <div class="p-col-12 p-lg-6">
          <p-checkbox
            value="3"
            name="mutliOption3"
            [(ngModel)]="prasnaObj.ANSWER"
          ></p-checkbox>
          <div class="p-field">
            <input
              id=""
              type="text"
              name="moption3"
              [(ngModel)]="prasnaObj.OPTION3"
              placeholder="Option 3"
              aria-describedby=""
              pInputText
              required
            />
            <!-- <small id="username2-help" class="p-invalid">Username is not available.</small> -->
          </div>
        </div>
        <div class="p-col-12 p-lg-6">
          <p-checkbox
            value="4"
            name="mutliOption4"
            [(ngModel)]="prasnaObj.ANSWER"
          ></p-checkbox>
          <div class="p-field">
            <input
              id=""
              type="text"
              name="moption4"
              [(ngModel)]="prasnaObj.OPTION4"
              placeholder="Option 4"
              aria-describedby=""
              pInputText
              required
            />
            <!-- <small id="username2-help" class="p-invalid">Username is not available.</small> -->
          </div>
        </div>
      </div>
      <div class="p-fluid p-grid p-form-grid" *ngIf="prasnaObj.QT_TYPE === 102">
        <div class="p-col-12 p-lg-6">
          <p-radioButton
            value="1"
            [(ngModel)]="prasnaObj.ANSWER"
            name="radioOption"
          ></p-radioButton>
          <div class="p-field">
            <input
              id=""
              type="text"
              name="roption1"
              [(ngModel)]="prasnaObj.OPTION1"
              placeholder="Option 1"
              aria-describedby=""
              pInputText
              required
            />
            <!-- <small id="username2-help" class="p-invalid">Username is not available.</small> -->
          </div>
        </div>
        <div class="p-col-12 p-lg-6">
          <p-radioButton
            value="2"
            [(ngModel)]="prasnaObj.ANSWER"
            name="radioOption"
          ></p-radioButton>
          <div class="p-field">
            <input
              id=""
              type="text"
              name="roption2"
              [(ngModel)]="prasnaObj.OPTION2"
              placeholder="Option 2"
              aria-describedby=""
              pInputText
              required
            />
            <!-- <small id="username2-help" class="p-invalid">Username is not available.</small> -->
          </div>
        </div>
        <div class="p-col-12 p-lg-6">
          <p-radioButton
            value="3"
            [(ngModel)]="prasnaObj.ANSWER"
            name="radioOption"
          ></p-radioButton>
          <div class="p-field">
            <input
              id=""
              type="text"
              name="roption3"
              [(ngModel)]="prasnaObj.OPTION3"
              placeholder="Option 3"
              aria-describedby=""
              pInputText
              required
            />
            <!-- <small id="username2-help" class="p-invalid">Username is not available.</small> -->
          </div>
        </div>
        <div class="p-col-12 p-lg-6">
          <p-radioButton
            value="4"
            [(ngModel)]="prasnaObj.ANSWER"
            name="radioOption"
          ></p-radioButton>
          <div class="p-field">
            <input
              id=""
              type="text"
              name="roption4"
              [(ngModel)]="prasnaObj.OPTION4"
              placeholder="Option 4"
              aria-describedby=""
              pInputText
              required
            />
            <!-- <small id="username2-help" class="p-invalid">Username is not available.</small> -->
          </div>
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-lg-6">
          <label for="newsPublishType">Publiish Type</label>
          <p-dropdown
            id="newsPublishType"
            name="newsPublishType"
            [(ngModel)]="prasnaObj.PUBLISH_TYPE"
            #newsPublishType="ngModel"
            [options]="PublishTypes"
            aria-describedby="newsPublishType-help"
            placeholder="Select publish type"
            [showClear]="false"
            required
          ></p-dropdown>
          <small
            id="newsPublishType-help"
            *ngIf="newsPublishType.valid === false && newsPublishType.touched"
            class="p-invalid"
            >Select publish type</small
          >
        </div>
        <div
          class="p-field p-col-12 p-lg-6"
          *ngIf="prasnaObj.PUBLISH_TYPE === 2"
        >
          <label>Pickup date*</label>
          <p-calendar
            id="newsPublishDate"
            name="newsPublishDate"
            (ngModel)="(prasnaObj.RELEASE_DATE)"
            #newsPublishDate="ngModel"
            required
          ></p-calendar>
          <small
            id="newsPublishDate-help"
            *ngIf="newsPublishDate.valid === false && newsPublishDate.touched"
            class="p-invalid"
            >Pickup publish date</small
          >
        </div>
      </div>

      <div class="p-fluid p-form-grid p-grid">
        <div class="p-field p-col-12 p-lg-6">
          <label for="newsStatus">Post status</label>
          <p-dropdown
            id="newsStatus"
            name="newsStatus"
            [(ngModel)]="prasnaObj.QUESTION_STATUS"
            #newsStatus="ngModel"
            [options]="PostStatus"
            placeholder="Select post status"
            [showClear]="false"
            required
          >
          </p-dropdown>
          <small
            id="newsStatus-help"
            *ngIf="newsStatus.valid === false && newsStatus.touched"
            class="p-invalid"
            >Pickup news status</small
          >
        </div>
        <div
          *ngIf="prasnaObj.QUESTION_STATUS === 3"
          class="p-field p-col-12 p-lg-6"
        >
          <label for="rejectedRemarks">Remarks</label>
          <input
            id="rejectedRemarks"
            name="rejectedRemarks"
            [(ngModel)]="prasnaObj.REJECTED_REMARKS"
            #rejectedRemarks="ngModel"
            type="text"
            pInputText
          />
        </div>
      </div>

      <div class="p-fluid p-grid p-form-grid">
        <div class="p-field p-col-12 p-lg-6">
          <label for="newsPoint">Points</label>
          <input
            id="newsPoints"
            name="newsPoints"
            type="number"
            #newsPoints="ngModel"
            [(ngModel)]="prasnaObj.POINTS"
            aria-describedby="newsPoints-help"
            pInputText
            required
          />
          <small
            id="newsPoints-help"
            *ngIf="newsPoints.valid === false && newsPoints.touched"
            class="p-invalid"
            >News points required</small
          >
        </div>
      </div>
      <!-- <div class="p-fluid p-grid p-form-grid">
                    <div class="p-field p-col-12 p-lg-6">
                      <label for="targetAudience">Target Audience</label>
                      <p-dropdown id="targetAudience" name="targetAudience" #targetAudience="ngModel"
                        [(ngModel)]="prasnaObj.AUD_TYPE_ID" [options]="TargetAudience" placeholder="Target Audience"
                        [showClear]="false" required></p-dropdown>
                      <small id="targetAudience-help" *ngIf="targetAudience.valid === false && targetAudience.touched"
                        class="p-invalid">News points required</small>
                    </div>
                    <div *ngIf="prasnaObj.AUD_TYPE_ID === 2" class="p-field p-col-12 p-lg-6">
                      <label for="">Audience Location</label>
                      <input type="text" pInputText />
                    </div>
                  </div> -->
      <button
        pButton
        pRipple
        type="submit"
        [label]="submitting === true ? 'Please wait' : 'Save'"
        style="max-width: 150px"
        class="p-button-raised btn-theme p-button-text"
        [disabled]="
          questionForm.valid === false ||
          submitting === true ||
          prasnaObj.ANSWER.length === 0
        "
      ></button>
    </div>
  </form>
</div>
