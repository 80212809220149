<form
  #addVarthaForm="ngForm"
  (ngSubmit)="saveNewsDetails(addVarthaForm)"
  novalidate
>
  <div class="p-shadow-3 form-layout-container">
    <div class="p-fluid p-container">
      <p-fileUpload
        name=""
        [showUploadButton]="false"
        (onSelect)="onUpload($event)"
        accept="image/*"
        maxFileSize="1000000"
        customUpload="true"
      >
        <ng-template pTemplate="content">
          <ul *ngIf="uploadedFiles.length">
            <li *ngFor="let file of uploadedFiles">
              {{ file.name }} - {{ file.size }} bytes
            </li>
          </ul>
        </ng-template>
      </p-fileUpload>
      <div class="p-field">
        <label for="newsTitle">News Title</label>
        <input
          [(ngModel)]="newsObj.TITLE"
          id="newsTitle"
          name="newsTitle"
          type="text"
          #newsTitle="ngModel"
          aria-describedby="newTititle-help"
          pInputText
          required
          minlength="10"
          maxlength="1000"
        />
        <small
          id="newTititle-help"
          *ngIf="newsTitle.valid === false && newsTitle.touched"
          class="p-invalid"
          >Invalid news title</small
        >
      </div>
      <div class="p-field">
        <label for="newsSubTitle">Temple Village/City</label>
        <input
          [(ngModel)]="newsObj.SUB_TITLE"
          id="newsSubTitle"
          name="newsSubTitle"
          type="text"
          #newsSubTitle="ngModel"
          aria-describedby="newsSubTitle-help"
          pInputText
          required
        />
        <small
          id="newsSubTitle-help"
          *ngIf="newsSubTitle.valid === false && newsSubTitle.touched"
          class="p-invalid"
          >Invalid temple name</small
        >
      </div>
      <div class="p-field">
        <label for="newsContent">News Content</label>
        <textarea
          [(ngModel)]="newsObj.CONTENT"
          id="newsContent"
          name="newsContent"
          type="text"
          rows="4"
          #newsContent="ngModel"
          aria-describedby="newsContent-help"
          pInputTextarea
          required
          minlength="150"
          maxlength="5000"
        ></textarea>
        <small
          id="newsContent-help"
          *ngIf="newsContent.valid === false && newsContent.touched"
          class="p-invalid"
          >New content could not be empty</small
        >
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-lg-6">
          <label for="newsPublishType">Publiish Type</label>
          <p-dropdown
            id="newsPublishType"
            name="newsPublishType"
            [(ngModel)]="newsObj.PUBLISH_TYPE"
            #newsPublishType="ngModel"
            [options]="PublishTypes"
            aria-describedby="newsPublishType-help"
            placeholder="Select publish type"
            [showClear]="false"
            required
          ></p-dropdown>
          <small
            id="newsPublishType-help"
            *ngIf="newsPublishType.valid === false && newsPublishType.touched"
            class="p-invalid"
            >Select publish type</small
          >
        </div>
        <div class="p-field p-col-12 p-lg-6" *ngIf="newsObj.PUBLISH_TYPE === 2">
          <label>Pickup date*</label>
          <p-calendar
            id="newsPublishDate"
            name="newsPublishDate"
            (ngModel)="(newsObj.RELEASE_DATE)"
            #newsPublishDate="ngModel"
            required
          ></p-calendar>
          <small
            id="newsPublishDate-help"
            *ngIf="newsPublishDate.valid === false && newsPublishDate.touched"
            class="p-invalid"
            >Pickup publish date</small
          >
        </div>
      </div>

      <div class="p-fluid p-form-grid p-grid">
        <div class="p-field p-col-12 p-lg-6">
          <label for="newsStatus">Post status</label>
          <p-dropdown
            id="newsStatus"
            name="newsStatus"
            [(ngModel)]="newsObj.NEWS_STATUS"
            #newsStatus="ngModel"
            [options]="PostStatus"
            placeholder="Select post status"
            [showClear]="false"
            required
          >
          </p-dropdown>
          <small
            id="newsStatus-help"
            *ngIf="newsStatus.valid === false && newsStatus.touched"
            class="p-invalid"
            >Pickup news status</small
          >
        </div>
        <div *ngIf="newsObj.NEWS_STATUS === 3" class="p-field p-col-12 p-lg-6">
          <label for="rejectedRemarks">Remarks</label>
          <input
            id="rejectedRemarks"
            name="rejectedRemarks"
            [(ngModel)]="newsObj.REJECTED_REMARKS"
            #rejectedRemarks="ngModel"
            type="text"
            pInputText
          />
        </div>
      </div>

      <div class="p-fluid p-grid p-form-grid">
        <div class="p-field p-col-12 p-lg-6">
          <label for="newsPoint">Points</label>
          <input
            id="newsPoints"
            name="newsPoints"
            type="number"
            #newsPoints="ngModel"
            [(ngModel)]="newsObj.POINTS"
            aria-describedby="newsPoints-help"
            pInputText
            required
          />
          <small
            id="newsPoints-help"
            *ngIf="newsPoints.valid === false && newsPoints.touched"
            class="p-invalid"
            >News points required</small
          >
        </div>
      </div>
      <div class="p-fluid p-grid p-form-grid">
        <div class="p-field p-col-12 p-lg-6">
          <label for="targetAudience">Target Audience</label>
          <p-dropdown
            id="targetAudience"
            name="targetAudience"
            #targetAudience="ngModel"
            [(ngModel)]="newsObj.AUD_TYPE_ID"
            [options]="TargetAudience"
            placeholder="Target Audience"
            [showClear]="false"
            required
          ></p-dropdown>
          <small
            id="targetAudience-help"
            *ngIf="targetAudience.valid === false && targetAudience.touched"
            class="p-invalid"
            >News points required</small
          >
        </div>
        <div *ngIf="newsObj.AUD_TYPE_ID === 2" class="p-field p-col-12 p-lg-6">
          <label for="">Audience Location</label>
          <!-- <input type="text" pInputText /> -->
          <app-autocomplete-location
            [address]="newsObj.GEO_LOC_ADDRESS"
            adressType="geocode"
            (setAddress)="setAudienceLocation($event)"
          >
          </app-autocomplete-location>
        </div>
      </div>

      <button
        pButton
        pRipple
        type="submit"
        [label]="submitting === true ? 'Please wait' : 'Save'"
        style="max-width: 150px"
        class="p-button-raised btn-theme p-button-text"
        [disabled]="addVarthaForm.valid === false || submitting === true"
      ></button>
    </div>
  </div>
</form>
