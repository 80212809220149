<p-card styleClass="p-card-shadow question-card">
  <div class="card-description">
    <h2 class="theme-color p-my-2 p-text-light question-title">
      {{ questionObj.QUESTION }}
    </h2>
    <h4 class="p-text-right p-my-1 p-text-light question-subtitle">
      <i class="pi pi-calendar"></i> {{ getDate(questionObj.RELEASE_DATE) }}
    </h4>

    <div class="p-grid">
      <div class="p-col-7">
        <!-- Multiple choice -->
        <ng-template
          [ngIf]="questionObj.QT_TYPE === questionType.multipleChoice"
        >
          <div class="p-field-radiobutton">
            <p-radioButton
              [name]="questionObj.QUESTION_EXTERNAL_ID"
              value="1"
              [(ngModel)]="questionObj.USER_ANSWER"
              inputId="option1"
              [disabled]="isQuestionAnswered"
            ></p-radioButton>
            <label
              [ngClass]="
                questionObj.CORRECT_STATUS === correctStatus.correct &&
                questionObj.USER_ANSWER == 1
                  ? 'p-text-success p-text-bold'
                  : questionObj.CORRECT_STATUS === correctStatus.notCorrect &&
                    questionObj.USER_ANSWER == 1
                  ? 'p-text-danger p-text-bold'
                  : questionObj.CORRECT_STATUS === correctStatus.notCorrect && questionObj.ANSWER == 1
                  ? 'p-text-success p-text-bold' : ''
              "
              >{{ questionObj.OPTION1 }}</label
            >
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              [name]="questionObj.QUESTION_EXTERNAL_ID"
              value="2"
              [(ngModel)]="questionObj.USER_ANSWER"
              inputId="option2"
              [disabled]="isQuestionAnswered"
            ></p-radioButton>
            <label
              [ngClass]="
                questionObj.CORRECT_STATUS === correctStatus.correct &&
                questionObj.USER_ANSWER == 2
                  ? 'p-text-success p-text-bold'
                  : questionObj.CORRECT_STATUS === correctStatus.notCorrect &&
                    questionObj.USER_ANSWER == 2
                  ? 'p-text-danger p-text-bold'
                  : questionObj.CORRECT_STATUS === correctStatus.notCorrect && questionObj.ANSWER == 2
                  ? 'p-text-success p-text-bold' : ''
              "
              >{{ questionObj.OPTION2 }}</label
            >
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              [name]="questionObj.QUESTION_EXTERNAL_ID"
              value="3"
              [(ngModel)]="questionObj.USER_ANSWER"
              inputId="option3"
              [disabled]="isQuestionAnswered"
            ></p-radioButton>
            <label
              [ngClass]="
                questionObj.CORRECT_STATUS === correctStatus.correct &&
                questionObj.USER_ANSWER == 3
                  ? 'p-text-success p-text-bold'
                  : questionObj.CORRECT_STATUS === correctStatus.notCorrect &&
                    questionObj.USER_ANSWER == 3
                  ? 'p-text-danger p-text-bold'
                  : questionObj.CORRECT_STATUS === correctStatus.notCorrect && questionObj.ANSWER == 3
                  ? 'p-text-success p-text-bold' : ''
              "
              >{{ questionObj.OPTION3 }}</label
            >
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              [name]="questionObj.QUESTION_EXTERNAL_ID"
              value="4"
              [(ngModel)]="questionObj.USER_ANSWER"
              inputId="option4"
              [disabled]="isQuestionAnswered"
            ></p-radioButton>
            <label
              [ngClass]="
                questionObj.CORRECT_STATUS === correctStatus.correct &&
                questionObj.USER_ANSWER == 4
                  ? 'p-text-success p-text-bold'
                  : questionObj.CORRECT_STATUS === correctStatus.notCorrect &&
                    questionObj.USER_ANSWER == 4
                  ? 'p-text-danger p-text-bold'
                  : questionObj.CORRECT_STATUS === correctStatus.notCorrect && questionObj.ANSWER == 4
                  ? 'p-text-success p-text-bold' : ''
              "
              >{{ questionObj.OPTION4 }}</label
            >
          </div>
        </ng-template>

        <!-- Multi select options -->
        <ng-template [ngIf]="questionObj.QT_TYPE === questionType.multiSelect">
          <div class="p-field-checkbox">
            <p-checkbox
              name="checkOption"
              value="1"
              [(ngModel)]="multiSelectAnswer"
              (onChange)="multiCheck()"
              inputId="checkOption1"
              [disabled]="isQuestionAnswered"
            ></p-checkbox>
            <label 
            [ngClass]="multiCheckErrorClass(1)"
            >{{ questionObj.OPTION1 }}</label>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox
              name="checkOption"
              value="2"
              [(ngModel)]="multiSelectAnswer"
              (onChange)="multiCheck()"
              inputId="checkOption2"
              [disabled]="isQuestionAnswered"
            ></p-checkbox>
            <label
            [ngClass]="multiCheckErrorClass(2)"
            >{{ questionObj.OPTION2 }}</label>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox
              name="checkOption"
              value="3"
              [(ngModel)]="multiSelectAnswer"
              (onChange)="multiCheck()"
              inputId="checkOption3"
              [disabled]="isQuestionAnswered"
            ></p-checkbox>
            <label
            [ngClass]="multiCheckErrorClass(3)"
            >{{ questionObj.OPTION3 }}</label>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox
              name="checkOption"
              value="4"
              [(ngModel)]="multiSelectAnswer"
              (onChange)="multiCheck()"
              inputId="checkOption4"
              [disabled]="isQuestionAnswered"
            ></p-checkbox>
            <label
            [ngClass]="multiCheckErrorClass(4)"
            >{{ questionObj.OPTION4 }}</label>
          </div>
        </ng-template>
      </div>
      <div class="p-col-5">
        <ng-template
          [ngIf]="questionObj.CORRECT_STATUS === correctStatus.correct"
        >
          <div class="p-text-center">
            <p class="p-text-success">Congrats! You got</p>
            <div class="points-box">
              <p class="pts">{{ questionObj.POINTS }}</p>
              <p class="pts-text">POINTS</p>
            </div>
          </div>
        </ng-template>
        <ng-template
          [ngIf]="questionObj.CORRECT_STATUS === correctStatus.notCorrect"
        >
          <div class="p-text-center">
            <p class="p-text-danger">Alas! You lost</p>
            <div class="points-box">
              <p class="pts">{{ questionObj.POINTS }}</p>
              <p class="pts-text">POINTS</p>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="p-col-12" *ngIf="!isQuestionAnswered">
        <button
          pButton
          label="YOUR ANSWER"
          [disabled]="questionObj.USER_ANSWER === undefined"
          class="btn-theme"
          (click)="submitAnswer()"
        ></button>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer"> </ng-template>
</p-card>
