import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { PurohitDarshiniService } from '../common/purohit-darshini.service';
import { Place, PurohitCategory, PurohitFilter } from '../models/avbhakthi.model';

@Component({
  selector: 'app-purohit-filter',
  templateUrl: './purohit-filter.component.html',
  styleUrls: ['./purohit-filter.component.css']
})
export class PurohitFilterComponent implements OnInit, OnDestroy {

  @Input() filter: PurohitFilter;

  @Input() geoLocationAddress: string;
  @Output() filterApplied: EventEmitter<PurohitFilter> = new EventEmitter();
  @Output() clearFilter: EventEmitter<null> = new EventEmitter();

  serviceSubscriptions: Subscription;
  isLocationPicked: boolean = false;

  purohitCategories: PurohitCategory[];
  selectedPurohitCategories: number[] = [];

  purohitQualifications: any[];
  selectedPurohitQualifications: any[] = [];

  constructor(
    private purohitService: PurohitDarshiniService
  ) {
    this.serviceSubscriptions = new Subscription();
    this.filter = new PurohitFilter();
    this.purohitCategories = this.selectedPurohitCategories = [];
    this.purohitQualifications = [];

    this.serviceSubscriptions.add(this.purohitService.purohitCategories().subscribe(data => {
      this.purohitCategories = data;
    })
    );

    this.serviceSubscriptions.add(this.purohitService.purohitQualifications().subscribe(data => {
      this.purohitQualifications = data.map(item => {
        return {
          label: item,
          value: item
        }
      });
    }));
  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
  }

  // Handle location change
  locationChanged(evnt: Place) {
    if (evnt.lat && evnt.lng) {
      this.filter.LATITUDE = evnt.lat;
      this.filter.LONGITUDE = evnt.lng;
      this.isLocationPicked = true;
      this.geoLocationAddress = evnt.formattedAddress;
    } else {
      this.filter.LATITUDE = this.filter.LONGITUDE = 0;
      this.isLocationPicked = false;
    }


  }
  // Handle Overlay panel
  togglePanel(evnt: any, panal: any) {
    panal.toggle(evnt);
  }

  // Handle filter event
  applyFilter(evnt:any) {
    this.filterApplied.emit(this.filter);
  }

  // Handle purohit categories
  categoriesChange(categories) {
    this.filter.CATEGORY_IDS = categories;
  }

  // Handle purohit qualifications
  qualificationsChange(qualifications) {
    this.filter.QUALIFICATIONS = qualifications;
  }

  // Clearing filter
  clearAllFilter(elements: any[]) {
    elements.forEach(ele => {
      ele.clear();
    })
    this.filter = new PurohitFilter();
    this.applyFilter(null);
  }
}
