<p-menubar responsive="true" styleClass="main-menu">
    <ng-template pTemplate="start">
      <a  style="cursor: pointer">
        <img
          class=" menu-logo"
          src="assets/img/advitha-varshini-logo.png"
          style="max-height:65px"
        />
      </a>
    </ng-template>
  </p-menubar>
  <router-outlet></router-outlet>
  <app-footer [externLinks]="true"></app-footer>