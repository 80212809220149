import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { BookmarkVM, Category, GuruVM, LikeDislikeVM, LikeStatusType } from '../models/avbhakthi.model';
import { SOCIALICONS } from '../constants';
import * as moment from 'moment';
import { CommonHttpService } from '../services/common-http.service';

@Component({
  selector: 'app-guru-card',
  templateUrl: './guru-card.component.html',
  styleUrls: ['./guru-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GuruCardComponent implements OnInit {
  readonly socialIcons = SOCIALICONS;
  @Input() guruObj: GuruVM;

  constructor(
    private commonHttp: CommonHttpService
  ) {

  }

  ngOnInit(): void {
  }
  get newsCategory(): typeof Category {
    return Category;
  }
  getDate(dateTime: string): string {
    return moment(dateTime).format('MM-DD-YYYY');
  }
  
}
