<div class="page-header full-bg">
  <div class="blur-bg page-header-content">
    <h1 class="p-text-center p-text-white page-title p-my-1">
      భక్తి వార్త విశేషిణి
    </h1>
    <hr class="special-line" />
    <h2 class="p-text-center page-sub-title p-my-1">
      ఇందులో మీరు దేవాలయాలలో జరిగే పూజ కార్యక్రమాలను, ఉత్సవాల సమాచారంను. భక్తి
      ఆధ్యాత్మిక విశేషాలను తెలుసుకోవచ్చు. మీరు కోరుకున్న రోజున ఆ సమాచారాన్ని
      మళ్ళిచూడవచ్చు,మీ ఆత్మీయులతో పంచుకోవచ్చును.
    </h2>
  </div>
</div>
<div class="page-content p-text-center">
  <app-news-card *ngFor="let news of bhatkhiNews" [newsObj]="news">
  </app-news-card>
  <app-loader [enable]="contentLoading"></app-loader>
</div>
