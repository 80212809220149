import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { QuestionVM, QuestionType, CorrectStatus } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'dashboard-question-card',
  templateUrl: './dashboard-question-card.component.html',
  styleUrls: ['./dashboard-question-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardQuestionCardComponent implements OnInit {

  @Input() question: QuestionVM;
  multiSelectAnswer: string[];
  constructor(
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.multiSelectAnswer = this.question.ANSWER.split(',');
  }

  public get questionType(): typeof QuestionType {
    return QuestionType;
  }

  public get correctStatus(): typeof CorrectStatus {
    return CorrectStatus;
  }

  public get isQuestionAnswered(): boolean {
    return this.question.CORRECT_STATUS === this.correctStatus.notAttempted ? false : true;
  }
  cardClicked() {
    // console.log('Card clicked');
    this.router.navigateByUrl('dashboard/prasna-vinodhini/add/' + this.question.QUESTION_EXTERNAL_ID);
  }
}
