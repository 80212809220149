import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router, RouterStateSnapshot, RoutesRecognized } from '@angular/router';
import { AuthService } from '../auth-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurdService implements CanActivate {

  constructor(public auth: AuthService, public router: Router, private route: ActivatedRoute) { }
  canActivate(): boolean {
    // console.log('Activated route', this.route.snapshot.url)
    if (!this.auth.isUserLoggedIn) {
      this.router.navigate(['login'], {
        queryParams: {
          redirect: this.router.url
        }
      });
      return false;
    }
    return true;
  }
}
