import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Category, NewsVM, PurposeType, ResultType } from '../models/avbhakthi.model';
import { MenuService } from '../common/menu.service';
import { MenuCategories } from '../common/menu.model';
import { NewsService } from '../common/news.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bhakthi-vartha',
  templateUrl: './bhakthi-vartha.component.html',
  styleUrls: ['./bhakthi-vartha.component.css']
})
export class BhakthiVarthaComponent implements OnInit, OnDestroy{

  private serviceSubscriptions: Subscription;
  bhatkhiNews: NewsVM[];
  contentLoading: boolean = true;
  noMoreNews: boolean = false;
  contentFetching: boolean = false;

  constructor(
    private menuservice: MenuService,
    private newsService: NewsService
  ) {
    this.serviceSubscriptions = new Subscription();
    menuservice.setMenuItems(MenuCategories.bhakthiNews);
    this.bhatkhiNews = [];
    this.contentFetching = true;
    this.serviceSubscriptions.add(
    this.newsService
      .getNews(10, Category.bhakthiNews, PurposeType.forMainScreen, false, ResultType.after)
      .subscribe(data => {
        this.contentLoading = false;
        if (data.length > 0) {
          // this.latestNews = data;
          this.bhatkhiNews = [...this.bhatkhiNews, ...data];
        } else {
          this.noMoreNews = true;
        }
        this.contentFetching = false;
      }, error => {
        // console.log(error);
        this.contentFetching = false;
        this.contentLoading = false;
      }));

  }
  ngOnDestroy(): void {
    // Unsubscribing all services on destroy to prevent unwanted calls
    this.serviceSubscriptions.unsubscribe();
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {

    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !this.noMoreNews && !this.contentLoading) {
      // console.log('You have reached bottome of page');
      let lastNewsId = '';
      this.contentLoading = true;
      if (this.bhatkhiNews.length > 0 && this.bhatkhiNews.length > 0)
        lastNewsId = this.bhatkhiNews[this.bhatkhiNews.length - 1].NEWS_EXTERNAL_ID;
        
          this.contentFetching = true;
          this.serviceSubscriptions.add(
            this.newsService
              .getNews(10, Category.bhakthiNews, PurposeType.forMainScreen, false, ResultType.after, lastNewsId)
              .subscribe(data => {
                this.contentLoading = false;
                if (data.length > 0) {
                  // this.latestNews = data;
                  this.bhatkhiNews = [...this.bhatkhiNews, ...data];
                } else {
                  this.noMoreNews = true;
                }
                this.contentFetching = false;
              }, error => {
                this.contentFetching = false;
                // console.log(error);
                this.contentLoading = false;
              }));
        
        
    }
  }



  ngOnInit(): void {
  }

}
