import { Injectable } from '@angular/core';
import { AppHttpService } from '../common/http.service';
import { v4 as uuidv4 } from 'uuid';
import { HttpErrorResponse } from '@angular/common/http';
import { MESSAGE_SERVICE_KEY } from '../constants';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { ApiResponse, CartItemCountResponse, CartItemInfo, CartItemVM, CheckoutItemsResponseVM, CheckoutOrderInfoVM, GetOrderItemsVM, GetOrdersAdminResponseVM, InitializePayRequestVM, ITEM, PaymentStatusResposeVM, UpdateOrderStatusVM, UserCartVM } from '../models/avbhakthi.model';
import { catchError, map } from 'rxjs/operators';
import { MenuService } from '../common/menu.service';
import { MenuCategories } from '../common/menu.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EPoojaStoreService {

  _cartItems: BehaviorSubject<UserCartVM>;
  cartItems: Observable<UserCartVM>;
  private couponCode: string = '';

  // Cart Item Counts
  _cartItemCount: BehaviorSubject<CartItemCountResponse>;
  cartItemCount: Observable<CartItemCountResponse>;

  private serviceSubscriptions: Subscription;
  private paymentApiRequest: InitializePayRequestVM;

  // Payment Confirmation information
  confirmPaymentResonse: PaymentStatusResposeVM;

  // Init payamentResponse
  checkoutPaymentResponse: CheckoutItemsResponseVM ;

  constructor(
    private http: AppHttpService,
    private messageService: MessageService,
    private menuService: MenuService,
    private router: Router
  ) { 
    // Initialization
    this.menuService.setMenuItems(MenuCategories.all);
    this._cartItems = new BehaviorSubject(null);
    this.cartItems = this._cartItems.asObservable();
    this.checkoutPaymentResponse = new CheckoutItemsResponseVM();
    //Cart item count initialization
    this._cartItemCount = new BehaviorSubject(null);
    this.cartItemCount = this._cartItemCount.asObservable();

    
    this.serviceSubscriptions = new Subscription();
    this.paymentApiRequest = new InitializePayRequestVM();
    this.refreshCartItemCount();
  }

  
  setCouponCode(couponCode: string) {
    this.couponCode = couponCode;
    this.paymentApiRequest.COUPON_CODE = couponCode;
  }

  get CouponCode(): string{
    return this.couponCode;
  }

  // To handle user cart
  addItemToCart(itemExternalId: string, quantity: number, reloadCartItems: boolean = false) {
    // TODO: Need to verify items in cart as per request
    let checkForCartItem = this.checkForCartItemExist(itemExternalId);
    if(typeof checkForCartItem === 'boolean') { // If type is boolean then add item to cart
      return this.addItem({
        CART_ITEM_EXTERNAL_ID: uuidv4(),
        QUANTITY: quantity,
        ITEM_EXTERNAL_ID: itemExternalId
      }).pipe(
        map((evnt) => {
          if(reloadCartItems) // When viewing cart we need to verify cart items, so fetching cart items in necessery
            this.fetchCartItems();
          else 
            this.refreshCartItemCount(); // This call refresh number of items in cart
          return evnt;
        }, catchError(err => this.handleError(err)) )
      );
    } else  { // Update cart item
      return this.updateItem({
        CART_ITEM_EXTERNAL_ID: (checkForCartItem as CartItemInfo).CART_ITEM_EXTERNAL_ID,
        QUANTITY: quantity,
        ITEM_EXTERNAL_ID: itemExternalId
      }).pipe(
        map((evnt) => {
          if(reloadCartItems) // When viewing cart we need to verify cart items, so fetching cart items in necessery
            this.fetchCartItems();
          else 
            this.refreshCartItemCount(); // This call refresh number of items in cart
          return evnt;
        }, catchError(err => this.handleError(err)) )
      );
    }
  }

  checkForCartItemExist(itemExternalId: string): boolean | CartItemInfo {
    if(this._cartItems.value) {
      let filteredItems = this._cartItems.value.CART_ITEMS.filter(item => item.ITEM_EXTERNAL_ID === itemExternalId);
      return filteredItems.length === 0 ? false : filteredItems[0];
    }
    return false;
    
  }

  // Append item to cart
  addItem(item: CartItemVM) {
    return this.http.addCartItem(item);
  }

  // Update cart item
  updateItem(item: CartItemVM) {
    return this.http.updateCartItem(item);
  }

  // To remove item from Cart
  removeCartItem(itemExternalId: string) {
   return this.http.removeCartItem(itemExternalId).pipe(
      map((evnt)=> {
        this.fetchCartItems();
        return evnt;
      }, catchError(err => this.handleError(err)))
    )
  }

  // Fetch cart items
  fetchCartItems() {
    this.refreshCartItemCount();
    this.serviceSubscriptions.add(
      this.http.getCartItems().subscribe(data => {
        this._cartItems.next(data);
      }, error => {
        this.handleError(error);
      })
    );
  }

  refreshCartItemCount() {
    this.serviceSubscriptions.add(
      this.http.getCartItemCount().subscribe(data => {
        
        this._cartItemCount.next(data);
      }, error => {
        this.handleError(error);
        
      })
    )
  }

  // Set Item Values in InitializePayRequestVM
  setItemsToPaymentRequest() {
    // Clearing any previous values
    this.paymentApiRequest.ITEMS = [];
    this._cartItems.value.CART_ITEMS.forEach(item => {
      this.paymentApiRequest.ITEMS.push(
        {
          ITEM_EXTERNAL_ID: item.ITEM_EXTERNAL_ID, 
          ITEM_NAME: item.POOJASTORE_ITEM_INFO.ITEM_NAME,
          QUANTITY: item.QUANTITY
        }
      );
    })
  }

  // Set address external ID to paymentApiRequest
  setDeliveryAddress(addressExternalId: string, pincode: number) {
    this.paymentApiRequest.ADDRESS_EXTERNAL_ID = addressExternalId;
    // this.paymentApiRequest.PINCODE = pincode;
  }

  initPaymentApi(): Observable<CheckoutItemsResponseVM>  {
    // If user directly navigate then we have to move cart
    if(this.paymentApiRequest.ITEMS.length === 0 || typeof this.paymentApiRequest.ADDRESS_EXTERNAL_ID === 'undefined') {
      this.router.navigate(['app/check-out/cart'])
      return null;
    } else {
      return this.http.initPaymentRequest(this.paymentApiRequest).pipe(
        map((evnt)=> {
          this.checkoutPaymentResponse = evnt;
          return evnt;
        }, catchError(err => this.handleError(err)))
      );
    }
    
  }

  // Place order request for calculating order totals and delivery charges
  placeOrderApi(): Observable<CheckoutItemsResponseVM>  {
    // If user directly navigate then we have to move cart
    if(this.paymentApiRequest.ITEMS.length === 0 || typeof this.paymentApiRequest.ADDRESS_EXTERNAL_ID === 'undefined') {
      this.router.navigate(['app/check-out/cart'])
      return null;
    } else {
      return this.http.placeOrderApi(this.paymentApiRequest);
    }
    
  }

  // Confirm payment api call
  confirmPayment(req: CheckoutOrderInfoVM) {
    return this.http.confirmPaymentApi(req).pipe(
      map((evnt)=> {
        this.confirmPaymentResonse = evnt;
        return evnt;
      }, catchError(err => this.handleError(err)))
    )
  }

  getPaymentStatusObject(): PaymentStatusResposeVM {
    return this.confirmPaymentResonse;
  }

  // Get list of order Items
  getOrderedItems(req: GetOrderItemsVM):Observable<GetOrdersAdminResponseVM> {
    return this.http.getOrders(req).pipe(
      map((evnt)=> {
        return evnt;
      }, catchError(err => this.handleError(err)))
    )
  }

  getCheckOutResponse() : CheckoutItemsResponseVM {
    return this.checkoutPaymentResponse;
  } 
  
  // Get list of order Items
  getOrderedItemById(req: string):Observable<ITEM> {
    return this.http.getOrderItemByID(req).pipe(
      map((evnt)=> {
        return evnt;
      }, catchError(err => this.handleError(err)))
    )
  }

  // Update order item status
  updateOrderStatus(req: UpdateOrderStatusVM):Observable<ApiResponse> {
    return this.http.updateOrderStatus(req).pipe(
      map((evnt)=> {
        return evnt;
      }, catchError(err => this.handleError(err)))
    )
  }

  
  

  handleError(error: HttpErrorResponse) {
    // debugger;
    if (error.error instanceof ErrorEvent) {
      this.messageService.add({ severity: 'error', summary: error.error.message, key: MESSAGE_SERVICE_KEY})
    } else {
      // console.log(error);
      this.messageService.add({ severity: 'error', summary: 'Something bad happened please try later', key: MESSAGE_SERVICE_KEY})
    }
    return throwError(
      'Something bad happened; please try again later.');
  }



}
