import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { BookmarkVM, Category, LikeDislikeVM, LikeStatusType, NewsVM } from '../models/avbhakthi.model';
import { SOCIALICONS } from '../constants';
import { Router } from '@angular/router';
import { CommonHttpService } from '../services/common-http.service';
import { AppConfigService } from '../common/app-config.service';
@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NewsCardComponent implements OnInit {

  @Input() newsObj: NewsVM;
  readonly baseURL: string = AppConfigService.settings.serverURL;
  readonly socialIcons = SOCIALICONS;
  readonly detailRouterLink: string = 'app/bhakhivartha-viseshini/details/';
  constructor(
    private router: Router,
    private commonHttp: CommonHttpService
  ) {
    // this.newsObj = {
    //       "POSTED_BY": "08d5ce95-afb0-17f1-b112-3ae42d0159b3",
    //       "POSTED_NAME": "jyothirlingam ",
    //       "POSTED_ON": "2020-02-25 06:59:51",
    //       "APPROVED_ON": "2020-05-17 11:09:35",
    //       "SUMMARY_INFO": {
    //           "LIKE_STATUS": 2,
    //           "IS_BOOKMARKED": false,
    //           "IS_REMAINDER": false,
    //           "NO_OF_LIKES": 2,
    //           "NO_OF_DIS_LIKES": 0
    //       },
    //       "NEWS_EXTERNAL_ID": "145e6f07-11ed-4ef3-8da1-8e2e7ee3ed31",
    //       "CATEGORY": 1111,
    //       "IMAGE_URL": "Sandbox/News//2020/May/13/IMG_1833513239.jpg",
    //       "TITLE": "new 24022020 Edit Wleocme to jungle ljkal as;ldfj;a sdfjaslfd ",
    //       "SUB_TITLE": "om namasivaya",
    //       "CONTENT": "కరోనా మహమ్మారితో దేశం వణికిపోతున్న వేళ.. దండకారణ్యం కాల్పుల మోతతో మార్మోగుతోంది. మహారాష్ట్రలోని గడ్చిరోలి జిల్లాలో పోలీసులకు, మావోయిస్టులకు మధ్య ఎన్‌కౌంటర్ కొనసాగుతోంది. గ్యారపట్టి గ్రామంలోని బాతంగడ్ మండలం పోయరకోటి-కోపర్సి అటవీ ప్రాంతంలో.. పోలీసులు కూంబింగ్ చేస్తున్నారు. ఈ క్రమంలో పోలీసులను గమనించిన మావోయిస్టులు వారిపై కాల్పులకు దిగారు. దీంతో వెంటనే అప్రమత్తమైన పోలీసులు ఎదురుకాల్పులకు దిగారు. ఈ ఘటనలో ఇద్దరు పోలీసులు ప్రాణాలు కోల్పోగా.. మరికొందరు తీవ్రంగా గాయపడ్డారు. ఇంకా ఎన్ కౌంటర్ కొనసాగుతున్నట్లు సమాచారం. మరిన్ని వివరాలు తెలియాల్సి ఉంది.",
    //       "PUBLISH_TYPE": 1,
    //       "RELEASE_DATE": "2020-02-25 06:59:51",
    //       "NEWS_STATUS": 2,
    //       "POINTS": 1,
    //       "AUD_TYPE_ID": 1,
    //       "AUD_LOC_LONG": 0.0,
    //       "AUD_LOC_LAT": 0.0,
    //       "REJECTED_REMARKS": ""
    //   };
  }

  ngOnInit(): void {
  }

  get newsCategory(): typeof Category {
    return Category;
  }
  getImageURL(): string {
    return this.baseURL + this.newsObj.IMAGE_URL.replace(/\\/g, '/');
  }

  cardClicked() {
    // console.log('Card clicked');
    this.router.navigateByUrl(this.detailRouterLink + this.newsObj.NEWS_EXTERNAL_ID);
  }

}
