import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { } from 'googlemaps';
import { Place } from '../models/avbhakthi.model';

@Component({
  selector: 'app-autocomplete-location',
  templateUrl: './autocomplete-location.component.html',
  styleUrls: ['./autocomplete-location.component.css']
})
export class AutocompleteLocationComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() adressType: string;
  @Output() setAddress: EventEmitter<Place> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;
  @Input() address: string;
  @Input() isRequired: boolean;
  autocompleteInput: string;
  queryWait: boolean;


  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // // console.log('Auto complete changes', changes);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
    if (this.address) {
      this.autocompleteInput = this.address;
    }
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        componentRestrictions: { country: 'IN' },
        types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: any) {
    let placeObj = new Place();
    placeObj.lat = place.geometry.location.lat();
    placeObj.lng = place.geometry.location.lng();
    placeObj.placeId = place.place_id;
    placeObj.formattedAddress = place.formatted_address;
    this.setAddress.emit(placeObj);
  }

  // Handle when place change not triggered
  placeChangedText(event: Event, element: HTMLInputElement) {
    if (element.value) {
      // No action neeeded
    } else {
      // Clearing auto complete if no data found
      this.clear();
    }
  }

  // Clear location infomration
  // Handle clear input
  clear() {
    this.address = '';
    let placeObj = new Place();
      placeObj.lat = 0;
      placeObj.lng = 0;
      placeObj.placeId = '';
      placeObj.formattedAddress = '';
      this.setAddress.emit(placeObj);
  }
}
