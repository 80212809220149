import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuCategories } from 'src/app/common/menu.model';
import { MenuService } from 'src/app/common/menu.service';
import { QuestionService } from 'src/app/common/question.service';
import { Category, PurposeType, QuestionVM, ResultType } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'app-prasna-vinodhini-dashboard',
  templateUrl: './prasna-vinodhini-dashboard.component.html',
  styleUrls: ['./prasna-vinodhini-dashboard.component.css']
})
export class PrasnaVinodhiniDashboardComponent implements OnInit, OnDestroy{

  private serviceSubscriptions: Subscription;
  prasnaVinodhini: QuestionVM[];
  noMoreNews = false;
  contentLoading: boolean = true;
  constructor(
    private menuService: MenuService,
    private questionService: QuestionService
  ) {
    this.serviceSubscriptions = new Subscription();
    // this.menuService.setMenuItems(MenuCategories.prasnaVinodini);
    this.menuService.setMenuItems(MenuCategories.all);
    this.prasnaVinodhini = [];
    this.serviceSubscriptions.add(
    this.questionService
      .getQuestions(10, Category.bhakthiNews, PurposeType.forShowList, false, ResultType.after)
      .subscribe(data => {
        this.contentLoading = false;
        if (data.length > 0) {
          // this.latestNews = data;
          this.prasnaVinodhini = [...this.prasnaVinodhini, ...data];
        } else {
          this.noMoreNews = true;
        }
      }, error => {
        // console.log(error);
        this.contentLoading = false;
      })
    );

  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
  }
  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {

    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !this.noMoreNews && !this.contentLoading) {
      // console.log('You have reached bottome of page');
      let lastNewsId = '';
      this.contentLoading = true;
      if (this.prasnaVinodhini.length > 0 && this.prasnaVinodhini.length > 0)
        lastNewsId = this.prasnaVinodhini[this.prasnaVinodhini.length - 1].QUESTION_EXTERNAL_ID;
      
      this.serviceSubscriptions.add(
      this.questionService
        .getQuestions(10, Category.bhakthiNews, PurposeType.forMainScreen, false, ResultType.after, lastNewsId)
        .subscribe(data => {
          this.contentLoading = false;
          if (data.length > 0) {
            // this.latestNews = data;
            this.prasnaVinodhini = [...this.prasnaVinodhini, ...data];
          } else {
            this.noMoreNews = true;
          }
        }, error => {
          // console.log(error);
          this.contentLoading = false;
        })
      );
    }
  }

}
