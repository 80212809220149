import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/common/app-config.service';
import { PoojaStoreService } from 'src/app/common/pooja-store.service';
import { ApiResponse, PoojaItemCategory, PoojaStoreFilter, PostPoojaStoreItemVM, PurposeType } from 'src/app/models/avbhakthi.model';
import { MenuCategories } from '../common/menu.model';
import { MenuService } from '../common/menu.service';
import { E_VIKRAYINI_FILTER_PRICE_LIST, MESSAGE_SERVICE_KEY } from '../constants';
import { EPoojaStoreService } from '../e-store/e-pooja-store.service';

@Component({
  selector: 'app-e-pooja-store',
  templateUrl: './e-pooja-store.component.html',
  styleUrls: ['./e-pooja-store.component.css']
})
export class EPoojaStoreComponent implements OnInit {

  storeItems: PostPoojaStoreItemVM[];
  filter: PoojaStoreFilter;
  private serviceSubscriptions: Subscription;
  sortOptions: SelectItem[];
  sortOrder: number;
  sortField: string;
  isDataloading: boolean = false;
  toggleSidebar: boolean = false;
  poojaItemCategories: PoojaItemCategory[];
  rangeValues: number[] = [0, 0];
  noOfRowsPage: number = 10;
  totalRecords: number = 0;
  readonly priceRangeList: any[] = E_VIKRAYINI_FILTER_PRICE_LIST;
  minRangeList: any[] = [];
  maxRangeList: any[] = [];

  readonly baseURL: string = AppConfigService.settings.serverURL;

  constructor(
    private poojaStoreService: PoojaStoreService,
    private router: Router,
    private menuService: MenuService,
    private cartService: EPoojaStoreService,
    private messageService: MessageService
  ) {
    this.poojaItemCategories = [];
    // Setup menu active
    this.menuService.setMenuItems(MenuCategories.eVikrayini);

    // Initialize store items
    this.storeItems = [];
    // Initialize service subscription
    this.serviceSubscriptions = new Subscription();
    // Initialize pooja store filter
    this.filter = new PoojaStoreFilter();
    this.filter.DISPLAY_PURPOSE = PurposeType.forMainScreen;
    
    this.minRangeList = this.maxRangeList = this.priceRangeList;

    // Fetching poojaItemCategories
    this.serviceSubscriptions.add(
      this.poojaStoreService.poojaItemCategories().subscribe(data => {
        this.poojaItemCategories = data;
        // Fetch details, Inital skip is 0
        this.fetchPoojaItems(0, this.noOfRowsPage);
      })
    );


  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {

    this.sortOptions = [
      { label: 'Price High to Low', value: '!PRICE' },
      { label: 'Price Low to High', value: 'PRICE' }
    ];

  }

  // Handle sort change
  onSortChange(event) {
    let value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  // To fetch pooja items, Takes input skip value which will help for paginator
  // To fetch pooja items, Takes input skip value which will help for paginator
  fetchPoojaItems(skip: number, take: number) {
    this.filter.SKIP = skip;
    this.filter.TAKE = take;
    this.serviceSubscriptions.add(
      this.poojaStoreService.poojaItems(this.filter).subscribe(data => {
        this.storeItems = [...data.ITEMS];
        // if(this.totalRecords === 0) {
          this.totalRecords = data.TOTAL_RECORDS;
        // }
      })
    );
  }

  // Get image path
  getImageURL(product: PostPoojaStoreItemVM): string {
    let imagePath = '';
    if (product.ITEM_IMAGES_PATH) {
      let imagesArray = product.ITEM_IMAGES_PATH.split('#');
      if (imagesArray.length > 0) {
        imagePath = imagesArray[0];
      }
    }
    return this.baseURL + imagePath;
  }

  // Get CategoryName
  getCategoryName(id: number): string {
    let categoriesFiltered = this.poojaItemCategories.filter(item => item.CATEGORY_ID === id);
    return categoriesFiltered.length > 0 ? categoriesFiltered[0].CATEGORY_NAME : '';
  }

  // To get discount percentage
  getDiscountPercent(actual: number, discount: number) {
    if (actual > 0 && discount > 0)
      return Math.round(discount / actual * 100)
    else return 0;
  }

  // Handle item details click
  itemClicked(id: string) {
    this.router.navigateByUrl('/app/pooja-store/details/' + id);
  }
  // Handle hide event
  toggleMe(event: Event, element: HTMLElement) {
    this.toggleSidebar = !this.toggleSidebar;
    if (this.toggleSidebar) {
      element.style.marginLeft = '-300px';
    } else {
      element.style.marginLeft = '0px';
    }
  }

  // Change Categories
  categoriesChange(event) {
    this.filter.CATEGORY_IDS = event;
  }

  // Clear filters
  clearAllFilter(elements: any[], form: NgForm) {
    form.resetForm();
    this.rangeValues = [0, 0];
    elements.forEach(ele => {
      ele.clear();
    })
    this.storeItems = [];
    this.fetchPoojaItems(0, this.noOfRowsPage);
  }

  // Price range changed
  priceRangeChanged(event) {
    // Check values exists in event
    /*{
    "originalEvent": {
        "isTrusted": true
    },
    "values": [
        11148,
        28040
          ]
      }
     */
    if (event.values) {
      if (event.values.length === 2) {
        this.filter.PRICE_RANGE.LOWER = event.values[0];
        this.filter.PRICE_RANGE.UPPER = event.values[1];
      }
    }
  }

  // Apply Filters
  applyFilters(form: NgForm) {
    this.storeItems = [];
    this.fetchPoojaItems(0, this.noOfRowsPage);
  }

  // Handle item add to cart
  addToCart(item: PostPoojaStoreItemVM) {
    this.serviceSubscriptions.add(
      this.cartService.addItemToCart(item.ITEM_EXTERNAL_ID, 1)
      .subscribe(data => {
        if(data.CODE === 100) {
          this.messageService.add({ severity: 'success', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
        }
        
      })
    );
  }

  lazyLoadData(event: any) {
    if(event.first === 0)
      this.fetchPoojaItems(event.first, event.rows);
    else 
    this.fetchPoojaItems(event.first/this.noOfRowsPage, event.rows);
  }

  // Handle min price range change
  minPriceChange(event: any) {
    this.maxRangeList = this.priceRangeList.filter(item => item.value >= +event.value )
    // this.filter.PRICE_RANGE.UPPER = this.maxRangeList[0].value;
    if(this.filter.PRICE_RANGE.LOWER > this.filter.PRICE_RANGE.UPPER) {
      this.filter.PRICE_RANGE.UPPER = this.maxRangeList[0].value;
    }
  }

  // Handle max price range change
  maxPriceChange(event: any) {
    this.minRangeList = this.priceRangeList.filter(item => item.value <= +event.value )
    if(this.filter.PRICE_RANGE.LOWER > this.filter.PRICE_RANGE.UPPER) {
      this.filter.PRICE_RANGE.LOWER = this.minRangeList[0].value;
    }
  }

}
