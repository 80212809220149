import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/common/app-config.service';
import { PoojaStoreService } from 'src/app/common/pooja-store.service';
import { EPoojaStoreService } from 'src/app/e-store/e-pooja-store.service';
import { GetOrderItemsVM, GetOrdersAdminResponseVM, PoojaItemCategory } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'app-user-orders',
  templateUrl: './user-orders.component.html',
  styleUrls: ['./user-orders.component.css']
})
export class UserOrdersComponent implements OnInit, OnDestroy {
  readonly baseURL: string = AppConfigService.settings.serverURL;
  private subscriptions: Subscription;
  orderedItemsList: GetOrdersAdminResponseVM;
  poojaItemCategories: PoojaItemCategory[] = [];
  noOfRowsPage: number = 10;
  totalRecords: number = 0;
  filter: GetOrderItemsVM = new GetOrderItemsVM();
  constructor(
    private eCartService: EPoojaStoreService,
    private poojaStoreService: PoojaStoreService,
    private messageService: MessageService,
  ) {
    this.orderedItemsList = new GetOrdersAdminResponseVM();
    this.orderedItemsList.ITEMS = [];
    this.subscriptions = new Subscription();
    this.filter.ORDER_STATUS_CODES = [];
    this.filter.SKIP = 0;
    this.filter.TAKE = this.noOfRowsPage;
    this.subscriptions.add(
      this.eCartService.getOrderedItems({
        ORDER_STATUS_CODES:[],
        TAKE: 10,
        SKIP: 0
      }).subscribe(data => {
        // console.log('Customer list of orders: ', data);
        this.orderedItemsList.ITEMS = [...data.ITEMS];
      })
    );

    // Fetching poojaItemCategories
    this.subscriptions.add(
      this.poojaStoreService.poojaItemCategories().subscribe(data => {
        this.poojaItemCategories = data;
      })
    );
   }

   // To get ImageURL
  getImageURL(imgPath: string): string {
    let imagePath = '';
    if (imgPath) {
      let imagesArray = imgPath.split('#');
      if (imagesArray.length > 0) {
        imagePath = imagesArray[0];
      }
    }
    return this.baseURL + imagePath;
  }

  // Get CategoryName
  getCategoryName(id: number): string {
    let categoriesFiltered = this.poojaItemCategories.filter(item => item.CATEGORY_ID === id);
    return categoriesFiltered.length > 0 ? categoriesFiltered[0].CATEGORY_NAME : '';
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
  }

  // Fetch orders items
  fetchOrders() {
    this.subscriptions.add(
      this.eCartService.getOrderedItems(this.filter).subscribe(data => {
        this.orderedItemsList.ITEMS = [...data.ITEMS];
        this.totalRecords = data.TOTAL_RECORDS;
      })
    );
  }

  // Lazy loading
  lazyLoadData(event: any) {
    if(event.first === 0){
      this.filter.TAKE = event.rows;
      this.filter.SKIP = event.first;
      this.fetchOrders();
    }
    else  {
      this.filter.TAKE = event.rows;
      this.filter.SKIP = event.first/this.noOfRowsPage;
      this.fetchOrders();
    }
    
  }

}
