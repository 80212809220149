<div class="page-header full-bg">
  <div class="blur-bg page-header-content">
    <h1 class="p-text-center p-text-white page-title p-my-1">
      ప్రశ్న వినోదిని
    </h1>
    <hr class="special-line" />
    <h2 class="p-text-center page-sub-title p-my-1">
      ప్రతి రోజు భక్తి ఆద్యాత్మిక పురాణాల్లో ఒక ప్రశ్న అడగబడును. మీరు చెప్పిన
      సమాధానం సరైనదో కాదో తెలుసుకొనవచ్చును. అలాగే మీరు చెప్పిన సమాధనంకు
      పాయిoట్స్ యాడ్ చేయబడును.
    </h2>
  </div>
</div>
<div class="prasna-tabs-container">
  <div class="prasna-tab p-shadow-2 text-center">
    <div class="tab-header">
      <i class="fab fa-product-hunt theme-color"></i>
    </div>
    <div class="tab-title">
      <h2 class="p-text-light">YOUR TOTAL POINTS</h2>
    </div>
    <div class="tab-content">
      {{ userPoints.TOTAL_POINTS }}
    </div>
  </div>
  <div class="prasna-tab p-shadow-2 text-center">
    <div class="tab-header">
      <i class="fas fa-comment-alt text-green"></i>
    </div>
    <div class="tab-title">
      <h2 class="p-text-light">CORRECT</h2>
    </div>
    <div class="tab-content">
      {{ userPoints.NO_OF_CORRECT }}
    </div>
  </div>
  <div class="prasna-tab p-shadow-2 text-center">
    <div class="tab-header">
      <i class="fas fa-comment-alt text-red"></i>
    </div>
    <div class="tab-title">
      <h2 class="p-text-light">INCORRECT</h2>
    </div>
    <div class="tab-content">
      {{ userPoints.NO_OF_INCORRECT }}
    </div>
  </div>
</div>
<div class="questions-container">
  <app-question-card
    *ngFor="let question of prasnaVinodhini"
    [questionObj]="question"
  ></app-question-card>
</div>
<div class="p-fluid p-text-center">
  <app-loader [enable]="contentLoading"></app-loader>
</div>
