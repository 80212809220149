<div class="page-header full-bg">
  <div class="blur-bg page-header-content">
    <h1 class="p-text-center p-text-white page-title p-my-1">ఆలయ దర్శిని</h1>
    <hr class="special-line" />
    <h2 class="p-text-center page-sub-title p-my-1">
      మన దేవాలయాలు ఏక్కడున్నాయి, ఎలా వెళ్ళాలి. వాటి యొక్క ప్రాముఖ్యత, ఫోటోస్,
      వీడియోలు, ఆలయాల చిరునామా, పూజ, అర్చన, అభిషేక కార్యక్రమాలు.
    </h2>
  </div>
</div>
<div class="page-content p-text-center">
  <app-temple-card
    *ngFor="let temple of temples"
    [templeObj]="temple"
  ></app-temple-card>
  <app-loader [enable]="contentLoading"></app-loader>
</div>
