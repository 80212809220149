import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '../common/app-config.service';
import {  SOCIALICONS } from '../constants';
import { BookmarkVM, Category, LikeDislikeVM, LikeStatusType, TempleVM } from '../models/avbhakthi.model';
import { CommonHttpService } from '../services/common-http.service';

@Component({
  selector: 'app-temple-card',
  templateUrl: './temple-card.component.html',
  styleUrls: ['./temple-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TempleCardComponent implements OnInit {

  @Input() templeObj: TempleVM;
  @Input() isNearestTemple: boolean = false;

  readonly baseURL: string = AppConfigService.settings.serverURL;
  readonly socialIcons = SOCIALICONS;
  readonly detailRouterLink: string = 'app/alaya-darshini/details/';
  constructor(
    private router: Router,
    private commonHttp: CommonHttpService
  ) {

  }

  ngOnInit(): void {
  }
  get newsCategory(): typeof Category {
    return Category;
  }
  getImageURL(): string {
    if (this.templeObj.IMAGE_URL.split('#').length > 0) {
      // return this.baseURL + this.templeObj.IMAGE_URL.split('#')[0].replace(/\\/g, '/');
      return this.baseURL+this.templeObj.IMAGE_URL.split('#')[0];
    }

    return "";
  }


  cardClicked() {
    // console.log('Card clicked');
    this.router.navigateByUrl(this.detailRouterLink + this.templeObj.TEMPLE_EXTERNAL_ID);
  }
}
