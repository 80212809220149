<div class="p-fluid" style="max-width: 300px">
  
    <div class="p-grid">
      <div class="p-col-12">
        <h2 class="theme-color p-mb-0 line-height-1">Location</h2>
        <hr />
        <!-- Location selector -->
        <app-autocomplete-location
          [address]="geoLocationAddress"
          (setAddress)="locationChanged($event)"
          #autoComplete
        >
        </app-autocomplete-location>
      </div>
      <!-- *ngIf="isLocationPicked === true" -->
      <div class="p-col-12">
        <label>Range(km): </label>
        <h3 class="p-my-1 p-text-center line-height-1">
          {{ filter.NO_OF_KILLOMETERS }}
        </h3>
        <div class="p-grid">
          <div
            class="p-col p-p-0 p-text-right"
            style="max-width: 30px; margin-top: -15px"
          >
            0
          </div>
          <div class="p-col p-py-0">
            <p-slider
              name="distanceRange"
              [(ngModel)]="filter.NO_OF_KILLOMETERS"
            ></p-slider>
          </div>
          <div class="p-col p-p-0" style="max-width: 30px; margin-top: -15px">
            100
          </div>
        </div>
      </div>
      <div class="p-col-12 p-py-0">
        <h2 class="theme-color p-mb-0 line-height-1">Category</h2>
        <hr />
        <app-multi-select-embed
          [options]="purohitCategories"
          dataKey="CATEGORY_NAME"
          valKey="PUROHIT_CATEGORY_ID"
          height="200px"
          (onChange)="categoriesChange($event)"
          #category
        >
        </app-multi-select-embed>
      </div>
      <div class="p-col-12">
        <h2 class="theme-color p-mb-0 line-height-1">Qualification</h2>
        <hr />
        <app-multi-select-embed
          [options]="purohitQualifications"
          height="200px"
          (onChange)="qualificationsChange($event)"
          #qualification
        >
        </app-multi-select-embed>
      </div>
      
      <div class="p-col-6">
        <button
          pButton
          pRipple
          type="button"
          class="btn-theme"
          label="Apply"
          (click)="applyFilter($event)"
        ></button>
      </div>
      <div class="p-col-6">
        <button
          pButton
          pRipple
          type="button"
          class="btn-theme"
          label="Clear"
          (click)="clearAllFilter([autoComplete,category,qualification])"
        ></button>
      </div>
    </div>
  
</div>
