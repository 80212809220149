import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '../common/app-config.service';
import { DataService } from '../common/data.service';
import { PurohitListVM } from '../models/avbhakthi.model';



@Component({
  selector: 'app-purohit-card',
  templateUrl: './purohit-card.component.html',
  styleUrls: ['./purohit-card.component.css']
})
export class PurohitCardComponent implements OnInit {
  baseURL: string = AppConfigService.settings.serverURL;
  @Input() purohit: PurohitListVM;
  constructor(
    private router: Router,
    private data: DataService
  ) { }

  ngOnInit(): void {
  }

  // Handle routing
  clickedMe() {
    this.data.storage = this.purohit;
    this.router.navigate(['app/purohit-darshini/details']);
  }
}
