<p-card styleClass="p-card-shadow guru-card">
  <div class="card-description">
    <h2
      class="theme-color p-my-2 p-text-light guru-title"
      [innerHtml]="guruObj.TITLE"
    ></h2>
    <div
      class="card-description p-px-3 p-py-2 p-text-justify"
      [innerHtml]="guruObj.CONTENT"
    >
      <!-- {{guruObj.CONTENT}} -->
    </div>
    <p class="p-text-right p-my-1 p-text-light guru-subtitle">
      <i class="pi pi-calendar"></i> {{ getDate(guruObj.RELEASE_DATE) }}
    </p>
  </div>
  <ng-template pTemplate="footer">
    <app-card-footer
      [category]="newsCategory.guruSubhashini"
      [externalId]="guruObj.NEWS_EXTERNAL_ID"
      [summeryInfo]="guruObj.SUMMARY_INFO"
    >

    </app-card-footer>
    <!-- <p-button
      (click)="likeDisLike(true)"
      class="p-button-text"
      [label]="guruObj.SUMMARY_INFO.NO_OF_LIKES.toString()"
      styleClass="p-button-text theme-color"
    >
      <img
        class="social-icon"
        [src]="socialIcons['LIKED'][guruObj.SUMMARY_INFO.LIKE_STATUS]"
      />
    </p-button>
    <p-button
      (click)="likeDisLike(false)"
      class="p-button-text"
      [label]="guruObj.SUMMARY_INFO.NO_OF_DIS_LIKES.toString()"
      styleClass="p-button-text theme-color"
    >
      <img
        class="social-icon"
        [src]="socialIcons['DISLIKED'][guruObj.SUMMARY_INFO.LIKE_STATUS]"
      />
    </p-button>
    <p-button class="p-button-text" styleClass="p-button-text">
      <img class="social-icon" [src]="socialIcons['SHARE']" />
    </p-button>
    <p-button
      (click)="bookmark()"
      class="p-button-text"
      styleClass="p-button-text"
    >
      <img
        class="social-icon"
        [src]="socialIcons['BOOKMARKED'][guruObj.SUMMARY_INFO.IS_BOOKMARKED]"
      />
    </p-button> -->
  </ng-template>
</p-card>
