<div class="p-fluid">
  <div class="p-grid content-wrapper">
    <div class="p-col-12 p-md-5 p-p-0 panel-left full-bg">
      <div class="blur-bg white">
        <div class="vertical-center p-text-center">
          <div class="p-mx-auto login-form-container">
            <img src="assets/img/advitha-varshini-logo.png" class="img-fluid" />
            <div class="p-fluid p-grid">
              <div class="p-col p-px-0" style="max-width: 90px;">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon p-p-0"><i class="pi pi-plus"></i></span>
                  <input type="text" pInputText placeholder="91" maxlength="2" [(ngModel)]="phoneNumber.country"
                    [disabled]="loginCodeSent" />
                </div>
              </div>
              <div class="p-col-8 p-px-0">
                <div class="p-inputgroup">
                  <input type="text" pInputText placeholder="mobile number" maxlength="10"
                    [(ngModel)]="phoneNumber.line" [disabled]="loginCodeSent" />
                </div>
              </div>
              <div class="p-col-12">
                <div id="recaptcha-container"></div>
              </div>
              <div class="p-col-12 p-text-left">
                <button pButton class="btn-theme" style="width: auto" [label]="
                    submitting == true
                      ? 'Please wait'
                      : 'Send verification code'
                  " (click)="sendLoginCode()" [disabled]="
                    !(
                      phoneNumber.country &&
                      phoneNumber.line &&
                      phoneNumber.line.length === 10
                    ) || submitting || loginCodeSent
                  "></button>
              </div>
            </div>

            <div *ngIf="confirmationResult" class="p-fluid p-grid">
              <div class="p-col-4">
                <input type="text" name="code" placeholder="code" pInputText [(ngModel)]="verificationCode" />
              </div>
              <div class="p-col-4">
                <button class="btn-theme" (click)="verifyLoginCode()" style="width: auto" label="Verify"
                  pButton></button>
              </div>
            </div>
          </div>
        </div>
        <h2 class="p-text-light p-mb-0 copyright-text" style="position: absolute;
        bottom: 10px;
        right: 10px;
        left: 10px;
        font-size: 0.8rem;">
          Advaitha Varshini &#169; {{ getYear() }}. All Rights Reserved.|<a
            [routerLink]="['/other-info/privacy-policy']">Privacy Policy</a>|<a [routerLink]="['/other-info/terms-of-service']">Terms
            Of Service</a>|<a [routerLink]="['/other-info/refund-policy']">Refund Policy</a>|
        </h2>
      </div>

    </div>
    <div class="p-md-7 p-p-0 panel-right p-d-none p-d-md-block">
      <p-carousel [value]="sliderContent" contentClass="content-class" dotsContainerClass="custom-indicators"
        [numVisible]="1" [numScroll]="1" [autoplayInterval]="5000" [circular]="true">
        <!-- [circular]="true" [autoplayInterval]="3000" -->
        <ng-template let-slide pTemplate="item">
          <div class="slider-item full-bg" [ngStyle]="{
              'background-image': 'url(' + slide['BACKGROUND-IMAGE'] + ')'
            }">
            <div class="blur-bg position-relative">
              <div class="slider-content vertical-center">
                <div class="p-text-center">
                  <img src="{{ slide['ICON'] }}" class="slider-logo mr-auto my-4" />
                  <h2 class="telugu-text mb-0 p-title">{{ slide["TITLE"] }}</h2>
                  <hr class="special-line mt-1" align="center" />
                  <h5 class="telugu-text p-description">
                    {{ slide["DESCRIPTION"] }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>
</div>