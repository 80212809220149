<p-card styleClass="p-card-shadow temple-card" *ngIf="isNearestTemple === false">
  <p-header (click)="cardClicked()">
    <div
      class="full-bg card-header-bg"
      [ngStyle]="{ 'background-image': 'url(' + getImageURL() + ')' }"
    ></div>
  </p-header>
  <div
    class="p-card-title"
    [innerHtml]="templeObj.TEMPLE_NAME"
    (click)="cardClicked()"
  >
    <!-- {{templeObj.PACKAGE_NAME}} -->
  </div>
  <div class="card-description" (click)="cardClicked()">
    <div class="p-grid">
      <div class="p-col-12">
        <p class="p-mt-1 p-mb-1 p-text-left">
          <!-- {{ templeObj.CITY }}<br />
          {{ templeObj.DISTRICT }}-{{ templeObj.PINCODE }}<br />
          {{ templeObj.STATE }}<br /> -->
          {{templeObj.TEMPLE_GEO_LOC_ADDRESS}}
        </p>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <app-card-footer
      [category]="newsCategory.alayaDarshini"
      [externalId]="templeObj.TEMPLE_EXTERNAL_ID"
      [summeryInfo]="templeObj.SUMMARY_INFO"
    >

    </app-card-footer>
  </ng-template>
</p-card>

<!-- Nearest temple card -->
<p-card styleClass="p-card-shadow temple-card" *ngIf="isNearestTemple === true">
  <p-header (click)="cardClicked()">
    <div class="p-grid">
      <div
      class="full-bg p-col-6"
      [ngStyle]="{ 'background-image': 'url(' + getImageURL() + ')' }"
      style="height: 180px;"
    ></div>
    <div class="p-col-6">
      <h3 class="p-text-center theme-border b-r-15 line-height-1 p-py-1">Timings</h3>
      <p class="p-px-2 p-text-bold p-m-0 line-height-1">Morning</p>
      <p class="p-px-2 p-m-0">{{templeObj.TEMPLE_OPENING_TIME_MORNING}} - {{templeObj.TEMPLE_CLOSING_TIME_MORNING}}</p>
      <p class="p-px-2 p-text-bold p-m-0 line-height-1">Evening</p>
      <p class="p-px-2 p-m-0">{{templeObj.TEMPLE_OPENING_TIME_EVENING}} - {{templeObj.TEMPLE_CLOSING_TIME_EVENING}}</p>
    </div>
    </div>
    
  </p-header>
  <div
    class="p-card-title"
    style="font-weight: 500;"
    [innerHtml]="templeObj.TEMPLE_NAME"
    (click)="cardClicked()"
  >
    <!-- {{templeObj.PACKAGE_NAME}} -->
  </div>
  <!-- <div class="card-description" (click)="cardClicked()">
    <div class="p-grid">
      <div class="p-col-12">
        <p class="p-mt-1 p-mb-1 p-text-center">
          {{ templeObj.CITY }}<br />
          {{ templeObj.DISTRICT }}-{{ templeObj.PINCODE }}<br />
          {{ templeObj.STATE }}<br />
        </p>
      </div>
    </div>
  </div> -->
</p-card>