<div class="user-details-bar p-text-right">
  <div class="user-details p-ml-auto">
    <div class="p-grid" style="cursor: pointer" >
      <img
        [src]="userProfile?.IMAGE_PATH"
        style="display: none"
        (error)="urlNotfound()"
      />
      <div class="p-col-2 p-p-1 p-text-left" (click)="toDashboard()">
        <div
          class="user-avatar full-bg"
          [ngStyle]="{
            'background-image': profilePicfound
              ? 'url(' + userProfile?.IMAGE_PATH + ')'
              : 'url(assets/img/user-logo.png)'
          }"
        ></div>
      </div>
      <div class="p-col-7 p-p-1 p-text-left" (click)="toDashboard()">
        <p class="p-m-0 username">
          {{ userProfile?.FIRST_NAME }} {{ userProfile?.LAST_NAME }}
        </p>
        <span class="p-tag theme-bg p-text-white"
          >Your points : {{ userPoints?.TOTAL_POINTS }}</span
        >
      </div>
      <div class="p-col-3 p-pt-1 p-text-center" [routerLink]="['/app/pooja-store/cart']">
        <div class="p-pt-3" style="border: solid 1px #ecececb3;">
          <i
          class="pi pi-shopping-cart p-mr-4 theme-color"
          severity="info"
          pBadge
          style="font-size: 1.6rem;padding-left:14px;"
          [value]="cartItemsCount"
        ></i>
        <p class="line-height-1 p-mx-1 p-mb-0 p-p-0 theme-color" style="margin-top: -10px;font-size: 1.2rem;">cart</p>
        </div>
        
      </div>
    </div>
  </div>
</div>
<p-menubar [model]="menuItems" responsive="true" styleClass="main-menu">
  <ng-template pTemplate="start">
    <a (click)="toHome()" style="cursor: pointer">
      <img
        class="img-fluid menu-logo"
        src="assets/img/advitha-varshini-logo.png"
      />
    </a>
  </ng-template>

  <div>end</div>
</p-menubar>
