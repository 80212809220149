import { Injectable, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { POST_STATUS, USERPROFILESTORAGENAME } from '../constants';
// import { BASEURL } from '../constants';
import { GetAddressStatus, ResultType, Role_Types, UserAddressVM, UserPointsVM, UserVM } from '../models/avbhakthi.model';
import { AppConfigService } from './app-config.service';
import { AppHttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {


  readonly baseURL: string = AppConfigService.settings.serverURL;
  
  private _profileInfo: BehaviorSubject<UserVM>;
  public profileInfo: Observable<UserVM>;

  private _userPoints: BehaviorSubject<UserPointsVM>;
  public userPoints: Observable<UserPointsVM>;
  constructor(
    private http: AppHttpService,
    private router: Router, 
    
  ) {
    this._profileInfo = new BehaviorSubject<UserVM>(null);
    this.profileInfo = this._profileInfo.asObservable();

    this._userPoints = new BehaviorSubject<UserPointsVM>(null);
    this.userPoints = this._userPoints.asObservable();

    if(localStorage) {
      let userProfileInfo = localStorage.getItem(USERPROFILESTORAGENAME);
      if(userProfileInfo) {
        this._profileInfo.next(JSON.parse(userProfileInfo));
        this.refreshUserPofile();
      } else  {
        this.http.getProfile().subscribe(user => {
          if(user.IMAGE_PATH)
            user.IMAGE_PATH = this.getImageURL(user.IMAGE_PATH);
          this._profileInfo.next(user);
          localStorage.setItem(USERPROFILESTORAGENAME, JSON.stringify(user));
        }, error => {
          this.clearProfileInfo();
          this.router.navigateByUrl('profile');
          // console.log(error);
        } );
      }
    }

    

    this.http.getPoints().subscribe(points => {
      // // console.log('User points', points);
      this._userPoints.next(points);
    })
  }

  refreshUserPofile() {
    this.http.getProfile().subscribe(user => {
      if(user.IMAGE_PATH)
        user.IMAGE_PATH = this.getImageURL(user.IMAGE_PATH);
      this._profileInfo.next(user);
      localStorage.setItem(USERPROFILESTORAGENAME, JSON.stringify(user));
    }, error => {
      this.clearProfileInfo();
      this.router.navigateByUrl('profile');
      // console.log(error);
    } );
  }

  get UserRole(): Role_Types
  {
    return this._profileInfo.value?.Role_Type;
  }
  get PurohitPublicId(): string {
    return this._profileInfo.value?.PUROHIT_PUBLIC_ID;
  }
  getImageURL(url): string {
    if(url)
      return this.baseURL + url.replace(/\\/g, '/');
    return this.baseURL;
  }
  

  updateUserPoints(correct: boolean, points: number) {
    let userPoints = this._userPoints.value;
    userPoints.NO_OF_QTNS_ATTEMPTED += 1;
    if (correct) {
      userPoints.NO_OF_CORRECT += 1;
      userPoints.TOTAL_POINTS += points;
    } else {
      userPoints.NO_OF_INCORRECT += 1;
    }
    this._userPoints.next(userPoints);
  }

  get PostStatus() {
    if(this._profileInfo.value){
      switch(this._profileInfo.value.Role_Type){
        case Role_Types.admin:
        case Role_Types.supervisor:
        case Role_Types.superAdmin:
          return POST_STATUS;
        case Role_Types.reporter:
          let postStatusArr = [...POST_STATUS];
          postStatusArr.forEach(item => item.inactive = true); // Making all items as inactive
          postStatusArr[0].inactive = false;
          return postStatusArr;
      }
    }
    return [];
  }

  // To save user address details which can be used while placed orders
  saveUserAddress(address: UserAddressVM, isNew: boolean) {
    return this.http.addOrUpdateUserAddress(address,isNew);
  }

  // To get list of user address
  getAddress(addressStatus: GetAddressStatus){
    return this.http.getUserAddresses(addressStatus);
  }


  clearProfileInfo() {
    this._profileInfo.next(null);
  }

}
