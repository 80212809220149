<a
  pButton
  pRipple
  type="button"
  icon="pi pi-plus"
  label="ADD BHAKTHI NEWS"
  routerLink="./add"
  class="p-button-raised p-button-text btn-add-category p-ml-auto"
></a>

<div class="p-fluid p-text-center">
  <dashboard-news-card *ngFor="let news of newsObj" [news]="news">
  </dashboard-news-card>
  <app-loader [enable]="contentLoading"></app-loader>
</div>
