
<div class="p-fluid p-mx-auto" style="max-width: 1100px;">
  <button
  pButton
  pRipple
  type="button"
  icon="pi pi-plus"
  label="ADD ADDRESS"
  [routerLink]="['/dashboard/user-addresses/add']"
  [queryParams]="{redirect: true}"
  class="p-button-raised p-button-text btn-add-category"
></button>
  <div class="p-grid">
    <div
      *ngFor="let address of addressList"
      class="p-col-12 p-md-6 p-lg-4 p-my-3 p-xl-4 p-text-center"
    >
      <div
        class="address-content p-text-left p-mx-auto p-card p-px-3 p-py-2"
        (click)="addressPicked(address.ADDRESS_EXTERNAL_ID, +address.PINCODE)"
      >
        <div
          class="offer-badge"
          *ngIf="pickedAddressID === address.ADDRESS_EXTERNAL_ID"
        >
          <i class="pi pi-check" style="font-size: 1.25rem; color: white"></i>
        </div>
        <h2 class="p-text-bold line-height-1 p-m-0">{{ address.FULL_NAME }}</h2>
        <p class="line-height-1 p-m-0">
          {{ address.FLAT_HOUSE_NO_BUILDING_APARTMENT }},
        </p>
        <p class="line-height-1 p-m-0">
          {{ address.AREA_COLONY_STREET_SECTOR_VILLAGE }},
        </p>
        <p class="line-height-1 p-m-0">
          {{ address.LANDMARK }}, {{ address.TOWN_CITY }},
        </p>
        <p class="line-height-1 p-m-0">
          {{ address.STATE }} {{ address.PINCODE }},
        </p>
        <p class="line-height-1 p-m-0">Phone: {{ address.MOBILE_NUMBER }}</p>
      </div>
    </div>
    <div class="p-col-12">
      <div class="p-grid">
        <div class="p-col p-text-left">
          <button
          pButton
          pRipple
          type="button"
          icon="pi pi-chevron-left"
          label="BACK"
          [routerLink]="['/app/check-out/cart']"
          class="p-button-raised p-button-text btn-add-category"
          
        ></button>
        <!-- style="max-width: 150px" -->
        </div>
        <div class="p-col p-text-right">
          <button
          pButton
          pRipple
          type="button"
          icon="pi pi-chevron-right"
          label="PLACE ORDER"
          [routerLink]="['/app/check-out/place-order']"
          class="p-button-raised p-button-text btn-add-category p-ml-auto"
          
          iconPos="right"
        ></button>
        </div>
      </div>
      
      
    </div>
  </div>
</div>
