<div class="card">
  <p-dataView
    #dv
    [value]="storeItems"
    [paginator]="true"
    [rows]="noOfRowsPage"
    filterBy="ITEM_NAME"
    [sortField]="sortField"
    [sortOrder]="sortOrder"
    [totalRecords]="totalRecords"
    layout="grid"
    [lazy]="true" 
    (onLazyLoad)="lazyLoadData($event)"
  >
    <ng-template pTemplate="header">
      <h1 class="theme-color p-text-light p-mt-0 line-height-1">
        Pooja Store Items
      </h1>

      <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
        <!-- <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By Price" (onChange)="onSortChange($event)" styleClass="p-mb-2 p-mb-md-0"></p-dropdown> -->
        <span class="p-input-icon-left p-mb-2 p-mb-md-0">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="search"
            style="max-width: 200px; padding-left: 40px"
            placeholder="Search by name"
            (input)="dv.filter($event.target.value)"
          />
        </span>
        <button
          pButton
          class="btn-theme"
          icon="pi pi-plus"
          style="width: 150px"
          label="ADD ITEM"
          [routerLink]="[
                '/dashboard/pooja-store/add'
              ]"
        ></button>
        <!-- <p-dataViewLayoutOptions></p-dataViewLayoutOptions> -->
      </div>
    </ng-template>
    <ng-template let-product pTemplate="gridItem">
      <div class="p-col-12" [ngStyle]="product.ITEM_STATUS === false ? {'opacity':'0.6'}:''">
        <div class="product-list-item">
          <img [src]="getImageURL(product)" [alt]="product.ITEM_NAME" />
          <div class="product-list-detail">
            <div class="product-name line-height-1 theme-color">
              {{ product.ITEM_NAME }}
            </div>
            <i class="pi pi-tag product-category-icon"></i
            ><span class="product-category">{{
              getCategoryName(product.CATEGORY_ID)
            }}</span>
            <br />
            <span
              [class]="
                'product-badge status-' +
                (product.ITEM_AVAILABILITY === true ? 'instock' : 'outofstock')
              "
              >{{
                product.ITEM_AVAILABILITY === true ? "INSTOCK" : "OUT OF STOCK"
              }}</span
            >
            <!-- <div class="product-description">{{product.description}}</div> -->
            <!-- <p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating> -->
          </div>
          <div class="product-list-action p-text-right">
            <span class="product-price theme-color font-family-roboto"
              >&#8377;{{ product.PRICE | number}}/-</span
            >
            <button
              pButton
              type="button"
              [routerLink]="[
                '/dashboard/pooja-store/add/' + product.ITEM_EXTERNAL_ID
              ]"
              class="btn-theme p-ml-auto p-button-raised"
              style="border-radius: 1px !important; width: 2rem; height: 2rem"
              icon="pi pi-pencil"
            ></button>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>
</div>
