import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppConfigService } from '../common/app-config.service';
import { MenuCategories } from '../common/menu.model';
import { MenuService } from '../common/menu.service';
import { PoojaStoreService } from '../common/pooja-store.service';
import { MESSAGE_SERVICE_KEY } from '../constants';
import { EPoojaStoreService } from '../e-store/e-pooja-store.service';
import { CARTITEM, CartItemInfo, PoojaItemCategory, PRICESUMMARYINFO } from '../models/avbhakthi.model';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit, OnDestroy{

  readonly baseURL: string = AppConfigService.settings.serverURL;
  poojaItemCategories: PoojaItemCategory[] = [];
  private serviceSubscriptions: Subscription;
  cartHasError: boolean = false;

  // Below summery values are calculate when data fetched in cart
  noOfItemsInCart: number = 0;
  itemsSubTotal: number = 0;
  totalDiscount: number = 0;

  cartItems: CartItemInfo[] = [];
  priceSummeryInfo: PRICESUMMARYINFO;
  constructor(
    private poojaStoreService: PoojaStoreService,
    private cartService: EPoojaStoreService,
    private messageService: MessageService,
    private menuService: MenuService,
    private router: Router
  ) {
    this.menuService.setMenuItems(MenuCategories.all);
    // Initialize service subscription
    this.serviceSubscriptions = new Subscription();

    // fetch cart items
    this.cartService.fetchCartItems();
    this.priceSummeryInfo = new PRICESUMMARYINFO();
    // Fetching poojaItemCategories
    this.serviceSubscriptions.add(
      this.poojaStoreService.poojaItemCategories().subscribe(data => {
        this.poojaItemCategories = data;
      })
    );
    
  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
    
    this.serviceSubscriptions.add(
      this.cartService.cartItems.subscribe(data => {
        // this.cartItems = [...data];
        this.cartItems = data?.CART_ITEMS;
        this.priceSummeryInfo = data?.PRICE_SUMMARY_INFO;
        let nItems = 0;
        let sTotal = 0;
        let sDiscount = 0;
        let cartHasError = false;
        data?.CART_ITEMS.forEach(item => {
          nItems += item.QUANTITY;
          sTotal += item.QUANTITY * item.POOJASTORE_ITEM_INFO.FINAL_PRICE;
          sDiscount += item.QUANTITY * item.POOJASTORE_ITEM_INFO.DISCOUNT_PRICE;
          if(this.checkForError(item))
            cartHasError = true;
        });
        this.cartHasError = cartHasError;
        this.noOfItemsInCart = nItems;
        this.itemsSubTotal = sTotal;
        this.totalDiscount = sDiscount;
      })
    );
  }

  // Get image path
  getImageURL(product: CartItemInfo): string {
    let imagePath = '';
    if (product.POOJASTORE_ITEM_INFO.ITEM_IMAGES_PATH) {
      let imagesArray = product.POOJASTORE_ITEM_INFO.ITEM_IMAGES_PATH.split('#');
      if (imagesArray.length > 0) {
        imagePath = imagesArray[0];
      }
    }
    return this.baseURL + imagePath;
  }

  // Get CategoryName
  getCategoryName(id: number): string {
    let categoriesFiltered = this.poojaItemCategories.filter(item => item.CATEGORY_ID === id);
    return categoriesFiltered.length > 0 ? categoriesFiltered[0].CATEGORY_NAME : '';
  }
  
  // Handle item quantity changed
  itemQuantityChange(event, item: CartItemInfo) {
    // // console.log('Item quantity change: ', event)
      this.serviceSubscriptions.add(
        this.cartService.addItemToCart(item.ITEM_EXTERNAL_ID, event.value, true)
        .subscribe(data => {
          if(data.CODE === 100) {
            this.messageService.add({ severity: 'success', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
          }
        })
      );
    
  }

  // remove cart item
  removeCartItem(itemExternalId: string) {
    this.serviceSubscriptions.add(
      this.cartService.removeCartItem(itemExternalId).subscribe(data => {
        if(data.CODE === 100) {
          this.messageService.add({ severity: 'success', summary: data.DESC, key: MESSAGE_SERVICE_KEY})
        } else  {
          this.messageService.add({ severity: 'error', summary: data.DESC, key: MESSAGE_SERVICE_KEY})
        }
      })
    );
  }

  //Handle proceed 
  proceedToNext() {
    this.cartService.setItemsToPaymentRequest();
    this.router.navigate(['/app/check-out/pick-address']);
  }

  // Check cart Item has error
  checkForError(item: CARTITEM): boolean {
    if(item.POOJASTORE_ITEM_INFO.ERROR_INFO)
      return true;
    return false;
  }
}
