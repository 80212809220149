export enum MenuCategories {
    all,
    bhakthiNews,
    alayaDarshini,
    prasnaVinodini,
    yatraSandharshini,
    guruSubhashini,
    purihitDarshini,
    eVikrayini,
    removeAll
}