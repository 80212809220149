import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SharePopupService } from 'ngx-sharebuttons/popup/share-popup.service';
import { Subscription } from 'rxjs';
import { AppConfigService } from '../common/app-config.service';
import { SOCIALICONS } from '../constants';
import { BookmarkVM, Category, LikeDislikeVM, LikeStatusType, SUMMARYINFO } from '../models/avbhakthi.model';
import { CommonHttpService } from '../services/common-http.service';



// For share buttons
// https://ngx-sharebuttons.netlify.app/#/popup-buttons
@Component({
  selector: 'app-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.css']
})
export class CardFooterComponent implements OnInit, OnDestroy{

  private serviceSubscriptions: Subscription;
  private readonly webURL: string = AppConfigService.settings.webURL;
  readonly socialIcons = SOCIALICONS;
  @Input() category: Category;
  @Input() externalId: string;
  @Input() summeryInfo: SUMMARYINFO;
  @Input() isDetailed: boolean = false;
  constructor(
    private commonHttp: CommonHttpService
  ) { 
    this.serviceSubscriptions = new Subscription();
  }
  ngOnDestroy(): void {
    // Destroying all subscribed events
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
  }

  // Function to hanlde Like disklike events or buttons
  likeDisLike(isLike: boolean) {
    let req = new LikeDislikeVM();
    req.REF_ID = this.externalId;
    req.CATEGORY = this.category;
    if (isLike === true && this.summeryInfo.LIKE_STATUS !== LikeStatusType.liked) {
      if (this.summeryInfo.LIKE_STATUS === LikeStatusType.disliked) {
        this.summeryInfo.NO_OF_DIS_LIKES -= 1;
      }
      this.summeryInfo.NO_OF_LIKES += 1;
      this.summeryInfo.LIKE_STATUS = LikeStatusType.liked;
      // req.LIKE_STATUS = LikeStatusType.liked;
    } else if (isLike === false && this.summeryInfo.LIKE_STATUS !== LikeStatusType.disliked) {
      if (this.summeryInfo.LIKE_STATUS === LikeStatusType.liked) {
        this.summeryInfo.NO_OF_LIKES -= 1;
      }
      this.summeryInfo.NO_OF_DIS_LIKES += 1;
      this.summeryInfo.LIKE_STATUS = LikeStatusType.disliked;
      // req.LIKE_STATUS = LikeStatusType.liked;
    }
    req.LIKE_STATUS = isLike === true ? LikeStatusType.liked : LikeStatusType.disliked;
    this.commonHttp.postLikeDislikeStatus(req).subscribe(data => {
      // console.log(data);
    })
  }

  // Function to handle bookmark
  bookmark() {
    let req = new BookmarkVM();
    req.REF_ID = this.externalId;
    req.CATEGORY = this.category;
    req.IS_BOOKMARKED = !this.summeryInfo.IS_BOOKMARKED;
    this.summeryInfo.IS_BOOKMARKED = !this.summeryInfo.IS_BOOKMARKED;
    this.commonHttp.bookmarkStatus(req).subscribe(data => {
      // console.log(data);
    })
  }

  // Function to return bookmark icon
  bookmarkIcon(isBookMarked: boolean) {
    return isBookMarked.toString();
  }

  // Function to get sharable link based on Cateogry
  getShareLink() {
    let postUrl = this.webURL+'app/';
    switch(this.category){
      case Category.bhakthiNews:
        postUrl += 'bhakhivartha-viseshini/details/'
        break;
        case Category.alayaDarshini:
          postUrl += 'alaya-darshini/details/'
          break;
          case Category.yatraSandarshini:
        postUrl += 'yatra-sandharshini/details/'
        break;
        case Category.guruSubhashini:
        postUrl += 'guru-subhashini/'
        break;
        case Category.prasnaVinodhini:
        postUrl += 'prasna-vinodhini/'
        break;
    }
    postUrl += this.externalId;
    return postUrl;
  }
}
