import { error } from '@angular/compiler/src/util';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { NewsService } from 'src/app/common/news.service';
import { ProfileService } from 'src/app/common/profile.service';
import { MESSAGE_SERVICE_KEY, POST_STATUS, PUBLISH_TYPES, TARGET_AUDIENCE } from 'src/app/constants';
import { Category, ImageCategory, ImageInfoVM, NewsVM, Place, PurposeType, ResultType } from 'src/app/models/avbhakthi.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-add-modify-bhakthi-vartha',
  templateUrl: './add-modify-bhakthi-vartha.component.html',
  styleUrls: ['./add-modify-bhakthi-vartha.component.css']
})
export class AddModifyBhakthiVarthaComponent implements OnInit, OnDestroy {

  private serviceSubscriptions: Subscription;
  public newsObj: NewsVM;
  uploadedFiles: any[] = [];
  submitting: any = false;
  updateNews: boolean = false;
  constructor(
    private newsService: NewsService,
    private router: Router,
    private messageService: MessageService,
    private activeRoute: ActivatedRoute,
    private profileService: ProfileService
  ) {
    this.serviceSubscriptions = new Subscription();
    this.newsObj = new NewsVM();
  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
    // // console.log('Uuid from javascript: ', uuidv4());
    this.activeRoute.paramMap.subscribe(params => {
      let id = params.get('id');
      if (id) {
        this.serviceSubscriptions.add(
        this.newsService.getNews(1, Category.bhakthiNews, PurposeType.forShowList, true, ResultType.after, id)
          .subscribe(data => {
            if (data.length > 0) {
              this.newsObj = data[0];
              this.updateNews = true;
            }
          }));
      }
    });
  }

  get PublishTypes() {
    return PUBLISH_TYPES;
  }

  get PostStatus() {
    return this.profileService.PostStatus;
  }

  get TargetAudience() {
    return TARGET_AUDIENCE;
  }

  audienceLocationChanged() {

  }

  saveNewsDetails(form: NgForm) {
    // console.log(form.value);
    // console.log(this.newsObj);
    if (form.valid) {
      var formData = new FormData();
      this.newsObj.CATEGORY = Category.bhakthiNews;
      if (this.newsObj.NEWS_EXTERNAL_ID) {
        if (this.uploadedFiles.length > 0) {
          formData.append('IS_IMG_CHANGED', 'true');
        } else {
          formData.append('IS_IMG_CHANGED', 'false');
        }
      } else {
        this.newsObj.NEWS_EXTERNAL_ID = uuidv4();
      }
      for (let key in this.newsObj) {
        formData.append(key, this.newsObj[key]);
      }

      // Image file data
      if (this.uploadedFiles.length > 0)
        formData.append('IMAGE_FILE', this.uploadedFiles[0])

      // imageInfo
      let imageInfo = new FormData();
      let imageInfoObj = new ImageInfoVM();
      imageInfoObj.IMAGE_CATEGORY = ImageCategory.imgNews;
      // for(let key in imageInfoObj)   {
      //   formData.append(key, imageInfoObj[key]);
      // }
      formData.append('imageInfo.IMAGE_CATEGORY', ImageCategory.imgNews.toString());
      formData.append('imageInfo.UID', uuidv4());

      this.submitting = true;
      this.serviceSubscriptions.add(
      this.newsService.postNews(formData, !this.updateNews).subscribe(data => {
        if (data.CODE === 100) {
          this.messageService.add({ severity: 'success', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
          this.router.navigateByUrl('dashboard/bhakhivartha-viseshini');
          
        } else {
          this.messageService.add({ severity: 'error', summary: data.DESC, key: MESSAGE_SERVICE_KEY});

        }
        this.submitting = false;
      }, error => {
        // console.log('Error: ', error);
        this.messageService.add({ severity: 'error', summary: JSON.stringify(error), key: MESSAGE_SERVICE_KEY});
        this.submitting = false;
      }));
    }
  }
  onUpload(event) {
    this.uploadedFiles = [];
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    // this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
  }
  setAudienceLocation(place: Place) {
    this.newsObj.AUD_LOC_LAT = place.lat;
    this.newsObj.AUD_LOC_LONG = place.lng;
    this.newsObj.GEO_LOC_ADDRESS = place.formattedAddress;
    this.newsObj.GEO_LOC_PLACE_ID = place.placeId;
  }
}
