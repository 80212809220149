import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { APIKEYLOCALSTORAGENAME } from '../constants';
import { ProfileService } from '../common/profile.service';


@Injectable({
  providedIn: 'root'
})
/**
 * https://fireship.io/lessons/firebase-phone-authentication-with-angular-4-tutorial/
 * https://angular-templates.io/tutorials/about/firebase-authentication-with-angular
 * https://www.positronx.io/full-angular-7-firebase-authentication-system/
 * https://medium.com/@sunnysyed007/how-to-use-phone-authentication-in-angular-and-ionic-b7584eaa3236
 */
export class AuthService {
  userData: any; // Save logged in user data
  public _isAuthenticated$: BehaviorSubject<boolean>;
  public isAuthenticated: Observable<boolean>;
  private tokenValidityTime: number = 0;
  private authToken: string;
  constructor(
    // public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning

  ) {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */

    this._isAuthenticated$ = new BehaviorSubject<boolean>(false);
    this.isAuthenticated = this._isAuthenticated$.asObservable();

    if (localStorage) { // Retrieve session token for localStorage
      let sessionToken = localStorage.getItem(APIKEYLOCALSTORAGENAME);
      if (sessionToken) {
        this.authToken = sessionToken;
        let decoded = jwt_decode(sessionToken);
        if (decoded.exp) {
          this.tokenValidityTime = decoded.exp * 1000;
          if (new Date().getTime() < this.tokenValidityTime) {
            this._isAuthenticated$.next(true);
            this.setSessionToken(sessionToken);
            // Refreshing profile information
            // profileService.refreshUserPofile();
          } else {
            this._isAuthenticated$.next(false);
            this.refreshToken();
          }
        }
      }
    }

    if (window.indexedDB) {
      let dbOpenRequest = indexedDB.open('firebaseLocalStorageDb', 1);
      let isDbExist = true;
      dbOpenRequest.onupgradeneeded = function (e) {
        // e.target.transaction.abort();
        dbOpenRequest.transaction.abort();
        isDbExist = false;
      }
      dbOpenRequest.onsuccess = function () {
        if (isDbExist) {
          let db = dbOpenRequest.result;
          let transaction = db.transaction(['firebaseLocalStorage']);
          let objectStore = transaction.objectStore('firebaseLocalStorage');
          let request = objectStore.getAll();
          request.onsuccess = function (e) {
            // console.log('Firebase key found', request.result);
            if (request.result.length > 0) {

            } else {

            }
          }
        }
      }
    }

  }




  get isUserLoggedIn() {
    return this._isAuthenticated$.value;
  }

  getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.tokenValidityTime > new Date().getTime()) {
        resolve(this.authToken);
      } else {
        let token = this.afAuth.authState.subscribe(user => {
          if (user) { // If authstate exist
            this.userData = user;
            this._isAuthenticated$.next(true); // then update user as logged in
            user.getIdToken().then(token => {
              this.authToken = token;
              let decoded = jwt_decode(token);
              if (decoded.exp) {
                this.tokenValidityTime = decoded.exp * 1000;
              }
              resolve(this.authToken);
            });

          } else { // If authstate not found 
            this._isAuthenticated$.next(false); // Update user not logged in
            reject("Authentication failed");
          }
        }, error => {
          console.error('Firbease error: ', error);
          this._isAuthenticated$.next(false);
          reject("Authentication failed");
        });
      }
    });
  }

  // Set session token to localStorage
  setSessionToken(token: string) {
    if (localStorage) {
      localStorage.setItem(APIKEYLOCALSTORAGENAME, token);
      this.authToken = token;
      let decoded = jwt_decode(token);
      if (decoded.exp) {
        this.tokenValidityTime = decoded.exp * 1000;
        let _this = this; // Assigning this to temp variable to pass it into timeout

        // Below logic will automatically refresh user token beofe expires
        let refreshTokenTime = (this.tokenValidityTime - new Date().getTime()) - (5 * 1000); // 60*1000 is equal to 1min
        refreshTokenTime = refreshTokenTime < 0 ? 0 : refreshTokenTime;
        setTimeout(() => {
          _this.refreshToken();
        }, refreshTokenTime);

      }
    }
  }

  refreshToken() {
    // if (this.tokenValidityTime > new Date().getTime()) {
    //   // resolve(this.authToken);
    // } else {
      this.afAuth.authState.subscribe(user => {
        if (user) { // If authstate exist
          console.log('token refresh called');
          this.userData = user;
          this._isAuthenticated$.next(true); // then update user as logged in
          user.getIdToken().then(token => {
            this.setSessionToken(token);
            this.authToken = token;
            let decoded = jwt_decode(token);
            if (decoded.exp) {
              this.tokenValidityTime = decoded.exp * 1000;
            }
            // Refreshing profile information
            // this.profileService.refreshUserPofile();
          });

        } else { // If authstate not found 
          this._isAuthenticated$.next(false); // Update user not logged in
          // reject("Authentication failed");
        }
      }, error => {
        console.error('Firbease error: ', error);
        this._isAuthenticated$.next(false);
        // reject("Authentication failed");
      });
    // }
  }

  get accessToken() {
    return this.authToken;
  }

  logout() {
    return this.afAuth.signOut().then(() => {
      this._isAuthenticated$.next(false);
      localStorage.clear();
      location.reload();
      this.router.navigate(['login']);
    });
  }

}
