import { Pipe, PipeTransform } from '@angular/core';
import { StatusType } from '../models/avbhakthi.model';

@Pipe({
  name: 'statusType'
})
export class StatusTypePipe implements PipeTransform {

  transform(value: StatusType, ...args: unknown[]): string {

    let typeString: string = '';
    switch (value) {
      case StatusType.post:
        typeString = 'Post';
        break;
      case StatusType.publish:
        typeString = 'Publish';
        break;
      case StatusType.reject:
        typeString = 'Reject';
        break;
      case StatusType.pending:
        typeString = 'Pending';
        break;
      case StatusType.inActive:
        typeString = 'In-Active';
        break;
    }
    return typeString;
  }

}
