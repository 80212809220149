<div class="card">
  <h2 class="p-text-center theme-color">AVBhakthi Users</h2>
  <p-table
    #userDt
    [value]="users"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[10, 25, 50]"
    [globalFilterFields]="['FIRST_NAME', 'LAST_NAME', 'EMAIL', 'PHONENO']"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-ml-auto" style="max-width: 250px">
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input
            pInputText
            style="padding-left: 40px;"
            type="text"
            (input)="userDt.filterGlobal($event.target.value, 'contains')"
            placeholder="Search keyword"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>NAME</th>
        <th>PHONE</th>
        <th>EMAIL</th>
        <th>ADDRESS</th>
        <th>ROLE</th>
        <th>MANAGER</th>
        <th>ACTION</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr>
        <td>{{ user.FIRST_NAME + " " + user.LAST_NAME }}</td>
        <td>{{ user.PHONENO }}</td>
        <td>{{ user.EMAIL }}</td>
        <td>{{ user.CITY }}, {{ user.DISTRICT }}, {{ user.STATE }}</td>
        <td>
          <p-dropdown
            [options]="userRoles"
            [(ngModel)]="user.Role_Type"
            
          ></p-dropdown>
        </td>
        <td>
          <p-dropdown 
            [options]="userOptions" 
            [(ngModel)]="user.MGR_ID" 
            [filter]="true" 
            [showClear]="true" 
            placeholder="Select Manager"
            >
        </p-dropdown>
        </td>
        <td>
          <button
        pButton
        pRipple
        type="button"
        label="Save"
        style="max-width: 100px"
        icon="pi pi-check"
        class="p-button-raised btn-theme p-button-text"
        (click)="changeUserRole(user, $event)"
      ></button>
        </td>
      </tr>
    </ng-template>
    <!-- <ng-template pTemplate="paginatorleft">
            <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
        </ng-template> -->
    <!-- <ng-template pTemplate="paginatorright">
            <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
        </ng-template> -->
  </p-table>
</div>
