import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MESSAGE_SERVICE_KEY } from '../constants';
import { ApiResponse, PoojaStoreFilter } from '../models/avbhakthi.model';
import { AppHttpService } from './http.service';


@Injectable({
  providedIn: 'root'
})
export class PoojaStoreService {

  constructor(
    private http: AppHttpService,
    private messageService: MessageService
  ) { }

  poojaItemCategories() {
    return this.http.getPoojaItemCategories();
  }

  postPoojaItem(formData: FormData, isNew: boolean = true): Observable<ApiResponse> {
    if (isNew) {
      return this.http.addPoojaItem(formData).pipe(
        catchError(this.handleError)
      );
    } else {
      return this.http.updatePoojaItem(formData).pipe(
        catchError(this.handleError)
      );
    }
  }



  poojaItemById(id:string) {
    return this.http.getPoojaItemById(id).pipe(
        catchError(this.handleError)
      );;
  }

  poojaItems(fitler: PoojaStoreFilter) {
    return this.http.getPoojaItems(fitler).pipe(
      catchError(this.handleError)
    );
  }

  handleError(error: HttpErrorResponse) {
    // debugger;
    if (error.error instanceof ErrorEvent) {
       this.messageService.add({ severity: 'error', summary: error.error.message, key: MESSAGE_SERVICE_KEY})
    } else {
      // console.log(error);
      this.messageService.add({ severity: 'error', summary: 'Something bad happened please try later', key: MESSAGE_SERVICE_KEY})
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
}
