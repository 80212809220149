import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth-service/auth.service';
import { ProfileService } from 'src/app/common/profile.service';
import { PurohitStatus, Role_Types, UserPointsVM, UserVM } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  sideBarToggle: boolean = false;
  menuItems: MenuItem[];
  userProfile: UserVM;
  private serviceSubscription: Subscription;
  profilePicfound: boolean = true;
  private profileSubscription: Subscription;
  userPoints: UserPointsVM = new UserPointsVM();
  constructor(
    private profileService: ProfileService,
    private authService: AuthService
  ) {
    this.serviceSubscription = new Subscription();
    this.userProfile = new UserVM();
    
    this.serviceSubscription.add (this.profileService.profileInfo.subscribe(user => {
      if(user) {
        this.userProfile = user;

        switch(user.Role_Type) {
          case Role_Types.admin:
          case Role_Types.superAdmin: // For Admin and SuperAdmin
            this.menuItems = [
              {
                label: "Edit profile",
                styleClass: 'panel-menu-item',
                routerLink: 'profile',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              
              {
                label: "Confirm orders",
                styleClass: 'panel-menu-item',
                routerLink: 'confirm-orders',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'Users',
                styleClass: 'panel-menu-item',
                routerLink: 'users',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'Address List',
                styleClass: 'panel-menu-item',
                routerLink: 'user-addresses',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'Approve Purohit',
                styleClass: 'panel-menu-item',
                routerLink: 'purohit-darshini',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'Bhakthi News',
                styleClass: 'panel-menu-item',
                routerLink: 'bhakhivartha-viseshini',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'Yatra Sandharshini',
                styleClass: 'panel-menu-item',
                routerLink: 'yatra-sandharshini',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
        
              },
              {
                label: 'Temples',
                styleClass: 'panel-menu-item',
                routerLink: 'alaya-darshini',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'Questions',
                styleClass: 'panel-menu-item',
                routerLink: 'prasna-vinodhini',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'Guru Subhashini',
                styleClass: 'panel-menu-item',
                routerLink: 'guru-subhashini',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'e-Vikrayini',
                styleClass: 'panel-menu-item',
                routerLink: 'pooja-store',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'Logout',
                styleClass: 'panel-menu-item',
                command: (evnt) => {
                  this.authService.logout();
                }
              }
            ]
            break;
          case Role_Types.supervisor: // For supervisor and reporter
          case Role_Types.reporter:
            this.menuItems = [
              {
                label: "Edit profile",
                styleClass: 'panel-menu-item',
                routerLink: 'profile',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'Address List',
                styleClass: 'panel-menu-item',
                routerLink: 'user-addresses',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: "Your orders",
                styleClass: 'panel-menu-item',
                routerLink: 'orders',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'Bhakthi News',
                styleClass: 'panel-menu-item',
                routerLink: 'bhakhivartha-viseshini',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'Yatra Sandharshini',
                styleClass: 'panel-menu-item',
                routerLink: 'yatra-sandharshini',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
        
              },
              {
                label: 'Temples',
                styleClass: 'panel-menu-item',
                routerLink: 'alaya-darshini',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'Questions',
                styleClass: 'panel-menu-item',
                routerLink: 'prasna-vinodhini',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'Guru Subhashini',
                styleClass: 'panel-menu-item',
                routerLink: 'guru-subhashini',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'Logout',
                styleClass: 'panel-menu-item',
                command: (evnt) => {
                  this.authService.logout();
                }
              }
            ]
            break;

          case Role_Types.user:
            case Role_Types.purohit: 
            // For normal User
            this.menuItems = [
              {
                label: "Edit profile",
                styleClass: 'panel-menu-item',
                routerLink: 'profile',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'Address List',
                styleClass: 'panel-menu-item',
                routerLink: 'user-addresses',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: "Your orders",
                styleClass: 'panel-menu-item',
                routerLink: 'orders',
                command: (evnt) => {
                  this.sideBarToggle = !this.sideBarToggle
                }
              },
              {
                label: 'Logout',
                styleClass: 'panel-menu-item',
                command: (evnt) => {
                  this.authService.logout();
                }
              }
            ]
            break;
            case Role_Types.poojaStoreManager:
              this.menuItems = [
                {
                  label: "Edit profile",
                  styleClass: 'panel-menu-item',
                  routerLink: 'profile',
                  command: (evnt) => {
                    this.sideBarToggle = !this.sideBarToggle
                  }
                },
                {
                  label: 'Address List',
                  styleClass: 'panel-menu-item',
                  routerLink: 'user-addresses',
                  command: (evnt) => {
                    this.sideBarToggle = !this.sideBarToggle
                  }
                },
                // {
                //   label: "Your orders",
                //   styleClass: 'panel-menu-item',
                //   routerLink: 'orders',
                //   command: (evnt) => {
                //     this.sideBarToggle = !this.sideBarToggle
                //   }
                // },
                {
                  label: "Confirm orders",
                  styleClass: 'panel-menu-item',
                  routerLink: 'confirm-orders',
                  command: (evnt) => {
                    this.sideBarToggle = !this.sideBarToggle
                  }
                },

                {
                  label: 'Logout',
                  styleClass: 'panel-menu-item',
                  command: (evnt) => {
                    this.authService.logout();
                  }
                }
              ]
              break;
        }
      }

      // Enable register as Purohit if User not registered as Purohit
      // This is available for Normal User only
// && user.Role_Type === Role_Types.user
      if((user.Role_Type === Role_Types.user|| user.Role_Type === Role_Types.purohit)) {
        this.menuItems.splice(1, 0, {
          label: user.PUROHIT_STATUS === PurohitStatus.notRegistered ? "Register as Purohit" : 'Purohit details',
          styleClass: 'panel-menu-item',
          routerLink:   'register-purohit' , 
          command: (evnt) => {
            this.sideBarToggle = !this.sideBarToggle
          }
        });
      }
      
      

    }));
    this.serviceSubscription.add(this.profileService.userPoints.subscribe(points => {
      this.userPoints = points;
    })
    );
  }

  ngOnInit(): void {

    
  }
  ngOnDestroy() {
    
    this.serviceSubscription.unsubscribe();
  }
  urlNotfound() {
    // console.log('User profiel pic not found');
    this.profilePicfound = !this.profilePicfound;
  }
}
