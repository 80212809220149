<button
  pButton
  pRipple
  type="button"
  icon="pi pi-plus"
  label="ADD ADDRESS "
  routerLink="./add"
  class="p-button-raised p-button-text btn-add-category"
></button>
<div class="p-fluid">
    <div class="p-grid">
        <div *ngFor="let address of addressList" class="p-col-12 p-md-6 p-lg-4 p-my-2 p-xl-3  p-text-center">
            <div class="address-content p-text-left p-mx-auto p-card p-px-3 p-py-2" >
                <h2 class="p-text-bold line-height-1 p-m-0">{{address.FULL_NAME}}</h2>
                <p class="line-height-1 p-m-0">{{address.FLAT_HOUSE_NO_BUILDING_APARTMENT}},</p>
                <p class="line-height-1 p-m-0">{{address.AREA_COLONY_STREET_SECTOR_VILLAGE}},</p>
                <p class="line-height-1 p-m-0">{{address.LANDMARK}}, {{address.TOWN_CITY}},</p>
                <p class="line-height-1 p-m-0">{{address.STATE}} {{address.PINCODE}},</p>
                <p class="line-height-1 p-m-0">Phone: {{address.MOBILE_NUMBER}}</p>
                <div class="address-footer">
                    <a [routerLink]="['/dashboard/user-addresses/add/'+address.ADDRESS_EXTERNAL_ID]" >Edit</a>|
                    <a  (click)="makeAddressActiveOrInactive(address)">{{address.IS_ACTIVE ? 'Remove' : 'Revoke'}}</a>|
                    <a  (click)="setAsDefaultAddress(address)" *ngIf="!address.IS_DEFAULT && address.IS_ACTIVE">Set as Default</a>
                </div>
            </div>
            
        </div>
    </div>
    </div>