import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { MenuCategories } from 'src/app/common/menu.model';
import { MenuService } from 'src/app/common/menu.service';
import { TempleService } from 'src/app/common/temple.service';
import { Category, Place, PurposeType, ResultType, TempleVM } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'app-alaya-darshini-dashboard',
  templateUrl: './alaya-darshini-dashboard.component.html',
  styleUrls: ['./alaya-darshini-dashboard.component.css']
})
export class AlayaDarshiniDashboardComponent implements OnInit, OnDestroy {

  private serviceSubscriptions: Subscription;
  contentLoading: boolean = true;
  noMoreNews: boolean = false;
  temples: TempleVM[] = [];
  constructor(
    private templeService: TempleService,
    private menuService: MenuService
  ) {
    this.serviceSubscriptions = new Subscription();
    this.menuService.setMenuItems(MenuCategories.all);
    this.serviceSubscriptions.add(
    this.templeService.getTemples(10, Category.alayaDarshini, PurposeType.forShowList, false, ResultType.after)
      .subscribe(data => {
        // console.log(data);
        this.contentLoading = false;
        this.temples = data;
        this.contentLoading = false;
      }, error => {
        this.contentLoading = false;
      })
    );
  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {

    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !this.noMoreNews && !this.contentLoading) {
      // console.log('You have reached bottome of page');
      let lastNewsId = '';
      this.contentLoading = true;
      if (this.temples.length > 0 && this.temples.length > 0)
        lastNewsId = this.temples[this.temples.length - 1].TEMPLE_EXTERNAL_ID;
        this.serviceSubscriptions.add(
      this.templeService
        .getTemples(10, Category.alayaDarshini, PurposeType.forShowList, false, ResultType.after, lastNewsId)
        .subscribe(data => {
          this.contentLoading = false;
          if (data.length > 0) {
            // this.latestNews = data;
            this.temples = [...this.temples, ...data];
          } else {
            this.noMoreNews = true;
          }
        }, error => {
          // console.log(error);
          this.contentLoading = false;
        })
        );
    }
  }

  ngOnInit(): void {
  }

}
