import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProfileService } from 'src/app/common/profile.service';
import { PostPurohitVM, PurohitCategory, PurohitStatus, UserVM } from 'src/app/models/avbhakthi.model';
import { PurohitDarshiniService } from 'src/app/common/purohit-darshini.service';
import { NgForm } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { MESSAGE_SERVICE_KEY } from 'src/app/constants';
import * as moment from 'moment';


@Component({
  selector: 'app-register-purohit',
  templateUrl: './register-purohit.component.html',
  styleUrls: ['./register-purohit.component.css']
})
export class RegisterPurohitComponent implements OnInit, OnDestroy, OnChanges {
  private serviceSubscription: Subscription;
  user: UserVM;
  purohitDetails: PostPurohitVM;
  purohitCategories: PurohitCategory[];
  purohitSelectedCategories: PurohitCategory[] = [];
  purohitQulifications: string[];
  submitting: boolean = false;
  purohitNameValidating:boolean = false;
  constructor(
    private profileService: ProfileService,
    private purohitService: PurohitDarshiniService,
    private router: Router,
    private messageService: MessageService,
  ) {
    this.purohitDetails = new PostPurohitVM();
    this.purohitDetails.SELECTED_CATEGOIRES = [];
    this.purohitQulifications = [];
    
    this.serviceSubscription = new Subscription();
    this.purohitCategories = [];

    // Subcribing to user details
    this.serviceSubscription.add(this.profileService.profileInfo.subscribe(user => {
      if (user) {
        this.user = user;
      }
    })
    );

    // Subscription to purohit categories
    this.serviceSubscription.add(this.purohitService.purohitCategories().subscribe(data => {

      this.purohitCategories = data;
      this.loadPurohitDetails();
    }));
  }


  ngOnChanges(changes: SimpleChanges): void {
    // // console.log(changes);
  }

  // Handle Purohit categories change event
  categoriesChange(event) {
    // console.log(this.purohitDetails.SELECTED_CATEGOIRES);
    // EMPTY SELECTED_CATEGORIES
    // this.purohitDetails.SELECTED_CATEGOIRES = [];
    // Adding purohit details
      // event.value.forEach(data => {
      //   this.purohitDetails.SELECTED_CATEGOIRES.push(data.PUROHIT_CATEGORY_ID);
      // })
  }

  // To load purohit details
  loadPurohitDetails() {
    // Subscription to purohit details
    this.serviceSubscription.add(this.purohitService.purohitDetails().subscribe(data => {
      // Changing categories information to view model
      if (data.SELECTED_CATEGOIRES){// Checking for SELECTED_CATEGOIRES empty or not
        // this.purohitSelectedCategories = data.SELECTED_CATEGOIRES.map(cat => { return cat.PUROHIT_CATEGORY_ID });

        data.SELECTED_CATEGOIRES = data.SELECTED_CATEGOIRES.map(item => {
          return item.PUROHIT_CATEGORY_ID;
        })
      }
      if(data.DOB) {
        let momentDate = moment(data.DOB);
        data.DOB = new Date(momentDate.year(),momentDate.month(), momentDate.day());
      }
        this.purohitDetails = data;
    }, error => {
      // console.log(error.error);
    })
    );
  }

  // Save puroohit information to sever
  savePurohitDetails(form: NgForm) {
    if (form.valid) { // Only when form is valid
      this.submitting = true;
      let isNew = false;
      if (!this.purohitDetails.PUROHIT_ID) { // If purohit id not present, then we are considering as new purohit
        this.purohitDetails.PUROHIT_ID = uuidv4();
        isNew = true;
      }
      this.purohitService.postPurohitDetails(isNew, this.purohitDetails).subscribe(data => {
        this.submitting = false;
        if(data.CODE ==  100) { // CODE 100 is for success
          this.profileService.refreshUserPofile();
          this.messageService.add({ severity: 'success', summary: data.DESC, key: MESSAGE_SERVICE_KEY });
        } else { // Else sending message as error
          this.messageService.add({ severity: 'error', summary: data.DESC, key: MESSAGE_SERVICE_KEY });
          if (isNew) { // One error if purohit isNew we are clearing PUROHIT_ID to avoid update call
            this.purohitDetails.PUROHIT_ID = null;
          }
        }
      }, error => {
        this.submitting = false;
        this.messageService.add({ severity: 'error', summary: error.error.DESC, key: MESSAGE_SERVICE_KEY });
        if (isNew) {// One error if purohit isNew we are clearing PUROHIT_ID to avoid update call
          this.purohitDetails.PUROHIT_ID = null;
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.serviceSubscription.unsubscribe();
  }


  // Handle purohit PublicName verification
  purohitPublicIdChange(event, value) {
    this.purohitNameValidating = true;
    this.serviceSubscription.add(this.purohitService.checkPurohitName(value).subscribe(response => {
      this.purohitNameValidating = false;
      if( typeof(response) === "boolean" && response === true  ) {
        // this.purohitDetails.PUROHIT_PUBLIC_ID = null;
      } else  {
        this.purohitDetails.PUROHIT_PUBLIC_ID = null;
      }
    }, error => {
      this.purohitNameValidating = false;
      this.purohitDetails.PUROHIT_PUBLIC_ID = null;
    })
    );
  }

  // Returning ENUM to Template
  get purohitStatus(): typeof PurohitStatus {
    return PurohitStatus;
  }

  ngOnInit(): void {
  }

}
