import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NewsVM } from 'src/app/models/avbhakthi.model';
// import { BASEURL } from 'src/app/constants';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/common/app-config.service';

@Component({
  selector: 'dashboard-news-card',
  templateUrl: './dashboard-news-card.component.html',
  styleUrls: ['./dashboard-news-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardNewsCardComponent implements OnInit {

  readonly baseURL: string = AppConfigService.settings.serverURL;
  @Input() news: NewsVM;
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }
  cardClicked() {
    // console.log('Card clicked');
    this.router.navigateByUrl('dashboard/bhakhivartha-viseshini/add/' + this.news.NEWS_EXTERNAL_ID);
  }

  getImageURL(): string {
    return this.baseURL + this.news.IMAGE_URL.replace(/\\/g, '/');
  }
}
