<button
  pButton
  pRipple
  type="button"
  icon="pi pi-plus"
  label="ADD QUOTE "
  routerLink="./add"
  class="p-button-raised p-button-text btn-add-category"
></button>

<div class="p-fluid p-text-center">
  <dashboard-guru-card *ngFor="let quote of guruSubhashini" [quote]="quote">
  </dashboard-guru-card>
  <app-loader [enable]="contentLoading"></app-loader>
</div>
