<p-card styleClass="p-card-shadow yatra-card" (click)="cardClicked()">
  <p-header (click)="cardClicked()">
    <div
      class="full-bg card-header-bg"
      [ngStyle]="{ 'background-image': 'url(' + getImageURL() + ')' }"
    ></div>
  </p-header>
  <div class="p-card-title" (click)="cardClicked()">
    {{ yatra.PACKAGE_NAME }}
  </div>
  <div class="card-description" (click)="cardClicked()">
    <div class="p-grid">
      <div class="p-col-6">
        <p class="p-mt-1 p-mb-1">
          <i class="pi pi-calendar"></i> From : <br />
          {{ yatra.JOURNEY_DATES  }}
        </p>
      </div>
      <div class="p-col-6">
        <p class="p-mt-1 p-mb-1">{{ yatra.NO_OF_DAYS_TOUR }} Day('s)</p>
      </div>
      <div class="p-col-12 p-py-1 yatra-places">
        <p class="p-mt-1 p-mb-1">
          Starting from: <span [innerHtml]="yatra.BOARDING_POINT"></span>
        </p>
      </div>
      <div class="p-col-12 p-py-1">
        <p
          class="p-mt-1 p-mt-1 line-height-1 p-mb-1 p-text-center"
          style="font-size: 1.4rem"
        >
          Rs: {{ yatra.PRICE }}/-
        </p>
      </div>
      <div class="p-col-12 p-text-center" style="font-size: 1.5rem">
        {{ yatra.YATRA_STATUS | statusType }}
      </div>
    </div>
  </div>
</p-card>
