import { Input, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppConfigService } from '../common/app-config.service';
import { DataService } from '../common/data.service';
import { PurohitListVM } from '../models/avbhakthi.model';

@Component({
  selector: 'app-purohit-details',
  templateUrl: './purohit-details.component.html',
  styleUrls: ['./purohit-details.component.css']
})
export class PurohitDetailsComponent implements OnInit, OnDestroy {

  baseURL = AppConfigService.settings.serverURL;
  purohitCategories: string[] = [];
  private serviceSubscriptions: Subscription;
  @Input() purohit: PurohitListVM;
  constructor(
    private data: DataService,
    private router: Router
  ) {
    this.serviceSubscriptions = new Subscription();
    if(data.storage) {
      this.purohit = data.storage as PurohitListVM;
      this.purohitCategories = this.purohit?.SELECTED_CATEGORY.split(',');
    } else {
      this.router.navigateByUrl('app/purohit-darshini');
    }
    
    // this.serviceSubscriptions.add(
    //   this.activeRoute.queryParams.subscribe(params => {
    //     this.purohit = params["purohit"] as PurohitListVM;
    //     this.purohitCategories = this.purohit?.SELECTED_CATEGORY.split(',');
    //   })
    // );
   
   
   }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
  }

  getPurohitDirections() {
    return  `https://www.google.com/maps/dir/?api=1&destination=${this.purohit.LATITUDE},${this.purohit.LONGITUDE}`
  }
}
