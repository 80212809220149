import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nearest-temple-card',
  templateUrl: './nearest-temple-card.component.html',
  styleUrls: ['./nearest-temple-card.component.css']
})
export class NearestTempleCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
