import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateIso'
})
export class DateIsoPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return moment(value).format('MM-DD-YYYY');
    //return null;
  }

}
