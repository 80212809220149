import { Component, HostListener, OnInit } from '@angular/core';
import { MenuCategories } from 'src/app/common/menu.model';
import { MenuService } from 'src/app/common/menu.service';
import { YatraService } from 'src/app/common/yatra.service';
import { Category, PurposeType, ResultType, YatraVM } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'app-yatra-sandharshini-dashboard',
  templateUrl: './yatra-sandharshini-dashboard.component.html',
  styleUrls: ['./yatra-sandharshini-dashboard.component.css']
})
export class YatraSandharshiniDashboardComponent implements OnInit {

  yatraSandharshini: YatraVM[];
  noMoreNews = false;
  contentLoading: boolean = true;
  constructor(
    private menuService: MenuService,
    private yatraService: YatraService
  ) {
    this.menuService.setMenuItems(MenuCategories.all);
    this.yatraSandharshini = [];
    this.yatraService
      .getYatras(10, Category.bhakthiNews, PurposeType.forShowList, false, ResultType.after)
      .subscribe(data => {
        this.contentLoading = false;
        if (data.length > 0) {
          // this.latestNews = data;
          this.yatraSandharshini = [...this.yatraSandharshini, ...data];
        } else {
          this.noMoreNews = true;
        }
      }, error => {
        // console.log(error);
        this.contentLoading = false;
      });
    // this.menuService.setMenuItems(MenuCategories.yatraSandharshini);
  }

  ngOnInit(): void {
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {

    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !this.noMoreNews && !this.contentLoading) {
      // console.log('You have reached bottome of page');
      let lastNewsId = '';
      this.contentLoading = true;
      if (this.yatraSandharshini.length > 0 && this.yatraSandharshini.length > 0)
        lastNewsId = this.yatraSandharshini[this.yatraSandharshini.length - 1].YATRA_EXTERNAL_ID;
      this.yatraService
        .getYatras(10, Category.bhakthiNews, PurposeType.forMainScreen, false, ResultType.after, lastNewsId)
        .subscribe(data => {
          this.contentLoading = false;
          if (data.length > 0) {
            // this.latestNews = data;
            this.yatraSandharshini = [...this.yatraSandharshini, ...data];
          } else {
            this.noMoreNews = true;
          }
        }, error => {
          // console.log(error);
          this.contentLoading = false;
        });
    }
  }
}
