import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AppConfigService } from 'src/app/common/app-config.service';
import { PoojaStoreService } from 'src/app/common/pooja-store.service';
import { PoojaItemCategory, PoojaStoreFilter, PostPoojaStoreItemVM, PurposeType } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'app-pooja-store',
  templateUrl: './pooja-store.component.html',
  styleUrls: ['./pooja-store.component.css']
})
export class PoojaStoreComponent implements OnInit, OnDestroy {

  storeItems: PostPoojaStoreItemVM[];
  filter: PoojaStoreFilter;
  private serviceSubscriptions: Subscription;
  sortOptions: SelectItem[];
  sortOrder: number;
  sortField: string;
  totalRecords: number = 0;
  poojaItemCategories: PoojaItemCategory[];
  noOfRowsPage: number = 10;

  readonly baseURL: string = AppConfigService.settings.serverURL;

  constructor(
    private poojaStoreService: PoojaStoreService
  ) {
    this.poojaItemCategories = [];



    // Initialize store items
    this.storeItems = [];
    // Initialize service subscription
    this.serviceSubscriptions = new Subscription();
    // Initialize pooja store filter
    this.filter = new PoojaStoreFilter();
    this.filter.DISPLAY_PURPOSE = PurposeType.forShowList;

    // Fetching poojaItemCategories
    this.serviceSubscriptions.add(
      this.poojaStoreService.poojaItemCategories().subscribe(data => {
        this.poojaItemCategories = data;
        // Fetch details, Inital skip is 0
        this.fetchPoojaItems(0, this.noOfRowsPage);
      })
    );


  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {

    this.sortOptions = [
      { label: 'Price High to Low', value: '!PRICE' },
      { label: 'Price Low to High', value: 'PRICE' }
    ];

  }

  // Handle sort change
  onSortChange(event) {
    let value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  // To fetch pooja items, Takes input skip value which will help for paginator
  fetchPoojaItems(skip: number, take: number) {
    this.filter.SKIP = skip;
    this.filter.TAKE = take;
    this.serviceSubscriptions.add(
      this.poojaStoreService.poojaItems(this.filter).subscribe(data => {
        this.storeItems = [...data.ITEMS];
        if(this.totalRecords === 0) {
          this.totalRecords = data.TOTAL_RECORDS;
        }
      })
    );
  }

  // Get image path
  getImageURL(product: PostPoojaStoreItemVM): string {
    let imagePath = '';
    if (product.ITEM_IMAGES_PATH) {
      let imagesArray = product.ITEM_IMAGES_PATH.split('#');
      if (imagesArray.length > 0) {
        imagePath = imagesArray[0];
      }
    }
    return this.baseURL + imagePath;
  }

  // Get CategoryName
  getCategoryName(id: number): string {
    let categoriesFiltered = this.poojaItemCategories.filter(item => item.CATEGORY_ID === id);
    return categoriesFiltered.length > 0 ? categoriesFiltered[0].CATEGORY_NAME : '';
  }

  lazyLoadData(event: any) {
    if(event.first === 0)
      this.fetchPoojaItems(event.first, event.rows);
    else 
    this.fetchPoojaItems(event.first/this.noOfRowsPage, event.rows);
  }
}
