<div class="header-image full-bg">
  <div class="blur-bg page-header-content">
    <h1 class="p-text-center p-text-white page-title p-my-1" style="color: white;">తాజా సమాచారం</h1>
    <hr class="special-line" />
  </div>
 
</div>
<!-- <div class="home-navigation-menu p-shadow-2">
  <div class="home-nav-container">
    <div class="home-nav-item" *ngFor="let menuItem of homeNavItems">
      <div class="header-menu-item">
        <img
          src=""
          class="header-menu-icon img-fluid"
          [src]="menuItem['ICON']"
        />
        <a class="telugu-text header-menu-link theme-color">{{
          menuItem["TITLE"]
        }}</a>
      </div>
    </div>
  </div>
</div> -->

<div class="latest-news row-space">
 
</div>
<div class="bhakthi-news-container p-text-center row-space">
  <h1 class="telugu-text p-text-center p-mb-1 p-text-light">
    భక్తి వార్త విశేషిణి
  </h1>
  <hr class="special-line" />
  <app-news-card *ngFor="let news of latestNews" [newsObj]="news">
  </app-news-card>
</div>

<div class="bhakthi-news-container p-text-center row-space">
  <h1 class="telugu-text p-text-center p-mb-1 p-text-light">
    ఆలయ దర్శిని
  </h1>
  <hr class="special-line" />
  <app-temple-card
  *ngFor="let temple of latestTemples"
  [templeObj]="temple"
></app-temple-card>
</div>

<div class="bhakthi-news-container p-text-center row-space">
  <h1 class="telugu-text p-text-center p-mb-1 p-text-light">యాత్ర సందర్శిని</h1>
  <hr class="special-line" />
  <app-yatra-card *ngFor="let yatra of latestYatras" [yatraObj]="yatra">
  </app-yatra-card>
</div>

<div class="prasna-container p-text-center row-space p-mx-auto ">
  <h1 class="telugu-text p-text-center p-mb-1 p-text-light">ప్రశ్న వినోదిని</h1>
  <hr class="special-line" />
  <div class="p-grid">
    <div class="p-col-4 question-grid-left full-bg">
      <img
        src="assets/img/home/prasna-vinodini-left-bg.png"
        class="img-fluid question-grid-left-img"
      />
    </div>
    <div class="p-col-8">
      <app-question-card [questionObj]="latestQuestion"></app-question-card>
    </div>
  </div>
</div>
<!-- <share-popup-button></share-popup-button> -->
<div class="guru-container row-space">
  <div class="p-grid guru-container-row full-bg p-pb-5 p-pt-2">
    <div class="p-col-12">
      <h1 class="telugu-text p-mb-1 p-mt-1 p-text-center p-text-light">
        గురు సుభాషిణి
      </h1>
      <hr class="special-line" />
    </div>
    <div class="p-md-5 p-sm-none"></div>
    <div class="p-sm-12 p-md-7">
      <div class="guru-quote-container border-lr">
        <h2
          class="telugu-text line-height-1 border-lr p-text-left p-my-1 p-text-light"
        >
          జీవితం ఒక తరంపాటే ఉంటుంది.<br />మంచి పేరు చిరకాలం ఉంటుంది
        </h2>
      </div>
    </div>
  </div>
</div>
