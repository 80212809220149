<app-header></app-header>
<div class="main-wrapper">
  <button
    pButton
    pRipple
    type="button"
    icon="pi pi-bars"
    class="p-button-rounded p-button-secondary btn-theme p-button-raised sidebar-toggle"
    (click)="sideBarToggle = !sideBarToggle"
  ></button>
  <p-sidebar
    [(visible)]="sideBarToggle"
    [baseZIndex]="10000"
    styleClass="sidebar"
  >
    <div class="p-fluid side-menu-content">
      <div class="p-col-12">
        <img
          [src]="userProfile?.IMAGE_PATH"
          style="display: none"
          (error)="urlNotfound()"
        />
        <div
          class="p-mx-auto full-bg user-avatar"
          [ngStyle]="{
            'background-image': profilePicfound
              ? 'url(' + userProfile?.IMAGE_PATH + ')'
              : 'url(assets/img/user-logo.png)'
          }"
        ></div>
      </div>
      <div class="p-col-12">
        <h2 class="p-my-1 line-height-1">{{ userProfile?.PHONENO }}</h2>
        <h3 class="p-my-1 line-height-1">Your points</h3>
        <h3 class="p-my-1 line-height-1">{{ userPoints?.TOTAL_POINTS }}</h3>
      </div>
      <div class="p-col-12 p-px-0">
        <p-panelMenu
          [model]="menuItems"
          styleClass="sidebar-panel-menu"
          [multiple]="false"
        >
        </p-panelMenu>
      </div>
    </div>
  </p-sidebar>

  <div class="p-fluid dashboard-content-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>

<app-footer></app-footer>
