// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// 208.73.202.106 or dev.api.avbhakthi.com
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCmivAmmv17IquWoa0wd0cbeX57YL8cXqc",
    authDomain: "advitha-varshini-debug.firebaseapp.com",
    databaseURL: "https://advitha-varshini-debug.firebaseio.com",
    projectId: "advitha-varshini-debug",
    storageBucket: "advitha-varshini-debug.appspot.com",
    messagingSenderId: "920793828755"
  },
  name: 'dev'
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
