import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/common/app-config.service';
import { MESSAGE_SERVICE_KEY } from 'src/app/constants';
import { EPoojaStoreService } from 'src/app/e-store/e-pooja-store.service';
import { CartItemInfo, CheckoutItemsResponseVM } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.css']
})
export class PlaceOrderComponent implements OnInit, OnDestroy{

  isProgress: boolean = true;
  orderTotal: CheckoutItemsResponseVM;
  private serviceSubscriptions: Subscription;
  readonly baseURL: string = AppConfigService.settings.serverURL;
  constructor(
    private cartService: EPoojaStoreService,
    private messageService: MessageService
  ) { 
    // Initialization
    this.serviceSubscriptions = new Subscription();
    this.orderTotal = new CheckoutItemsResponseVM();
    
  }
  

  ngOnInit(): void {
    this.serviceSubscriptions.add(
      this.cartService.placeOrderApi().subscribe(data => {
        this.isProgress = false;
        this.orderTotal = data;
      },
      error => {
        this.isProgress = false;
        this.messageService.add({ severity: 'error', summary: error.error.DESC, key: MESSAGE_SERVICE_KEY})
      })
    );
  }

  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  getImageURL(imgPath: string): string {
    let imagePath = '';
    if (imgPath) {
      let imagesArray = imgPath.split('#');
      if (imagesArray.length > 0) {
        imagePath = imagesArray[0];
      }
    }
    return this.baseURL + imagePath;
  }
}
