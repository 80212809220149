import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppConfigService } from '../common/app-config.service';
import { TempleService } from '../common/temple.service';
import { SOCIALICONS } from '../constants';
import { Category, PurposeType, ResultType, TempleVM } from '../models/avbhakthi.model';

@Component({
  selector: 'app-temple-details',
  templateUrl: './temple-details.component.html',
  styleUrls: ['./temple-details.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TempleDetailsComponent implements OnInit, OnDestroy {

  private serviceSubscriptions: Subscription;
  temple: TempleVM;
  readonly socialIcons = SOCIALICONS;
  contentLoading: boolean = true;
  templeImages: string[] = [];
  servicesModal: boolean = false;
  accommodattionModal: boolean = false;
  trvelModal: boolean = false;
  directionsModal: boolean = false;
  nearestTemples: TempleVM[] = [];
  readonly baseURL: string = AppConfigService.settings.serverURL;
  constructor(
    private route: ActivatedRoute,
    private templeService: TempleService
  ) {
    this.serviceSubscriptions = new Subscription();
    this.temple = new TempleVM();

  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      let id = data.get('id');
      this.setTempleDetails(id);
    })
  }
  get newsCategory(): typeof Category {
    return Category;
  }

  setTempleDetails(id: string, resultType: ResultType = ResultType.after, include: boolean = true) {
    if (id) {
      this.serviceSubscriptions.add(
      this.templeService
        .getTemples(1, Category.alayaDarshini, PurposeType.forMainScreen, include, resultType, id)
        .subscribe(data => {
          this.contentLoading = false;
          if (data.length > 0) {
            // this.latestNews = data;
            this.temple = data[0];
            if (this.temple.IMAGE_URL) {
              let templeImagesUrls = this.temple.IMAGE_URL.split('#').map(item => {
                return this.baseURL + item;
              }) 
              this.templeImages = templeImagesUrls;

            } else {
              this.templeImages = [];
              this.templeImages.push('assets/img/no-image-data.png');
            }

          } else {
            // this.noMoreNews = true;
          }
        }, error => {
          // console.log(error);
        })
      );

        // clearing nearest temples
        this.nearestTemples = [];
        this.serviceSubscriptions.add(
        this.templeService.getNearestTemples(id).subscribe(data => {
          this.nearestTemples = data;
          // console.log('Nearest temples', data);
        })
        );
    }
  }
  nextTemple() {
    this.setTempleDetails(this.temple.TEMPLE_EXTERNAL_ID, ResultType.after, false);
  }
  prevTemple() {
    this.setTempleDetails(this.temple.TEMPLE_EXTERNAL_ID, ResultType.before, false);
  }

  getTempleDirectionLink() {
    return `https://www.google.com/maps/dir/?api=1&destination=${this.temple.LATITUDE},${this.temple.LONGITUDE}`;
  }
}
