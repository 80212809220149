/*
    For application related constants
*/

export const APIKEYLOCALSTORAGENAME = 'session_token';
export const USERPROFILESTORAGENAME = 'profile_info';
export const MESSAGE_SERVICE_KEY = 'GLOBALMESSAGE';

//------------------ LOGIN FORM ----------------------//

export const LOGINPAGESLIDER = [
    {
        "TITLE": "భక్తి వార్త విశేషిణి",
        "BACKGROUND-IMAGE": "assets/img/home/bhakti-vartha-cover.jpg",
        "DESCRIPTION": "ఇందులో మీరు దేవాలయాలలో జరిగే పూజ కార్యక్రమాలను, ఉత్సవాల సమాచారంను. భక్తి ఆధ్యాత్మిక విశేషాలను తెలుసుకోవచ్చు. మీరు కోరుకున్న రోజున ఆ సమాచారాన్ని మళ్ళిచూడవచ్చు,మీ ఆత్మీయులతో పంచుకోవచ్చును",
        "ICON": "assets/img/home/bhakthi-vartha-theme-logo.png"
    },
    {
        "TITLE": "ఆలయ దర్శిని",
        "BACKGROUND-IMAGE": "assets/img/home/aalaya-darshini-cover.jpg",
        "DESCRIPTION": "మన దేవాలయాలు ఏక్కడున్నాయి, ఎలా వెళ్ళాలి. వాటి యొక్క ప్రాముఖ్యత, ఫోటోస్, వీడియోలు, ఆలయాల చిరునామా, పూజ, అర్చన, అభిషేక కార్యక్రమాలు.",
        "ICON": "assets/img/home/alaya-darshini-theme-logo.png"
    },
    {
        "TITLE": "ప్రశ్న వినోదిని",
        "BACKGROUND-IMAGE": "assets/img/home/prasna-vinodini-cover-bg.jpg",
        "DESCRIPTION": "ప్రతి రోజు భక్తి ఆద్యాత్మిక పురాణాల్లో ఒక ప్రశ్న అడగబడును. మీరు చెప్పిన సమాధానం సరైనదో కాదో తెలుసుకొనవచ్చును. అలాగే మీరు చెప్పిన సమాధనంకు పాయిoట్స్ యాడ్ చేయబడును.",
        "ICON": "assets/img/home/prasna-vinodini-theme-logo.png"
    },
    {
        "TITLE": "యాత్ర సందర్శిని",
        "BACKGROUND-IMAGE": "assets/img/home/yatra-sandarhini-cover.jpg",
        "DESCRIPTION": "ఇందులో మీరు తీర్థయాత్రల సమాచారమును చూడవచ్చును. నచ్చినవాటికి కాల్ చేసి సమాచారమును తెలుసుకోవచ్చును.అలానే బుక్ చేసుకోవచ్చును,మీ ఆత్మీయులతో పంచుకోవచ్చును.",
        "ICON": "assets/img/home/yatra-sandarshini-theme-logo.png"
    },
    {
        "TITLE": "గురు సుభాషిణి",
        "BACKGROUND-IMAGE": "assets/img/home/guru-subhashini-cover-bg.jpg",
        "DESCRIPTION": "ఇందులో మీరు పద్యాలు వాటి అర్ధాలు, ఆధ్యాత్మిక విశేషములు,అలానే గురువులు చేసిన వ్యాఖ్యానాలు,మరికొన్ని భక్తి విశేషములు తెలుసుకోవచ్చు. మీ ఆత్మీయులతో పంచుకోవచ్చును.",
        "ICON": "assets/img/home/guru-subhashini-theme-logo.png"
    },
    {
        "TITLE": "పురోహిత దర్శిని",
        "BACKGROUND-IMAGE": "assets/img/home/pourohitha-darsini-cover.jpg",
        "DESCRIPTION": "మీరు ఇచ్చట గృహము మరియు దేవాలయమునందు జరుగు భక్తి, ఆధ్యాత్మిక, పూజా కార్యక్రమాలు చేయుటకు మీ సమీపంలో ఉన్న పురోహితులను తెలుసుకుని, వారిని నేరుగా సంప్రదించవచ్చును.",
        "ICON": "assets/img/home/purohit-dharisisini-logo.png"
    },
    {
        "TITLE": "e-విక్రయని",
        "BACKGROUND-IMAGE": "assets/img/home/e-vikraini-cover.jpg",
        "DESCRIPTION": "మీరు ఇచ్చట గృహము మరియు దేవాలయము నందు జరుగు భక్తి, ఆధ్యాత్మిక, పూజా కార్యక్రమాలు చేయుటకు కావలసిన పూజా సామాగ్రిని ఆన్లైన్ ప్రక్రియ ద్వారా విక్రయించబడును.",
        "ICON": "assets/img/home/e-vikrayani-logo.png"
    }
]

//------------------ COMMON ----------------------//

// export const BASEURL = "https://avbhakthi.com/"; // base URL of application

export const SOCIALICONS = {
    "LIKED": {
        0: "assets/img/like-line.png",
        1: "assets/img/like-fill.png",
        2: "assets/img/like-line.png"
    },
    "DISLIKED": {
        0: "assets/img/unlike-line.png",
        1: "assets/img/unlike-line.png",
        2: "assets/img/unlike-fill.png"
    },
    "SHARE": "assets/img/share.png",
    "REMAINDER": "",
    "BOOKMARKED": {
        'true': "assets/img/bookmark-fill.png",
        'false': "assets/img/bookmark-line.png"
    }
}

export const PUBLISH_TYPES = [
    {
        label: "Immediate",
        value: 1
    }, {
        label: "Specifidate",
        value: 2
    }
];

export const POST_STATUS = [
    {
        label: 'Post',
        value: 1,
        canActivateinactive: false
    },
    {
        label: 'Publish',
        value: 2,
        inactive: false
    },
    {
        label: 'Reject',
        value: 3,
        inactive: false
    },
    {
        label: 'Pending',
        value: 4,
        inactive: false
    },
    {
        label: 'Inactive',
        value: 5,
        inactive: false
    },
];

export const TARGET_AUDIENCE = [
    {
        label: 'All',
        value: 1
    },
    {
        label: 'Specific Geolocations',
        value: 2
    }
];

export const QUESTION_TYPES = [
    {
        label: 'Multiple Choice',
        value: 102
    },
    {
        label: 'Mutli select',
        value: 103
    }
];

export const USER_ROLES = [
    {
        label: 'Super Admin',
        value: 1001
    },
    {
        label: 'Admin',
        value: 2002
    },
    {
        label: 'Supervisor',
        value: 3003
    },
    {
        label: 'Reporter',
        value: 4004
    },
    {
        label: 'User',
        value: 5005
    },
    {
        label: 'Pooja Store manager',
        value: 7007
    }
]


// Constants
export const cSuccessResponseCode = 100;
export const cAlreadySomeUsersAttempted = 115;
export const cAlreadyUserAnswered = 116;
export const cAlreadyRegisteredCode = 125;
export const cFailureResponseCode = 150;
export const cValidatorFailedResponseCode = 175;
export const cRequestedObjectNotFoundCode = 199;
export const cUnAuthorizedAccessCode = 200;

export const cAuthorizationTokenExpired = 301;
export const cAuthorizationHeaderMissed = 302;
export const cAuthorizationBearerKeywordMissed = 303;
export const cAuthorizationTokenNotProvided = 304;
export const cAuthorizationUnknown = 305;
export const cUserNotFound = 306;
export const cPurohitNotFound = 307;
export const cPurohitCategoryNotFound = 308;
export const cPurohitIsNotaUserRole = 309;
export const cPurohitPublicIDAlreadyExisted = 310;
export const cPoojaStoreCategoryNotFound = 311;
export const cPoojaStoreItemNotFound = 312;
export const cCheckoutItemsNotProvided = 313;
export const cCheckoutItemNotFound = 314;
export const cCheckoutItemOutOfStock = 315;
export const cCouponCodeNotAvailable = 316;
export const cCouponCodeAvailable = 317;
export const cDeliveryChargesNotabletoCalculate = 318;
export const cCartItemNotFound = 319;
export const cPaymentIdNotProvided = 320;
export const cCheckoutItemExternalIDNotProvided = 321;
export const cPaymentSignatureIsInvalid = 322;
export const cCheckoutItemExternalIDNotFound = 323;
export const cCheckoutItemExternalIDAlreadyProcessed = 324;
export const cPaymentInfoNotFetchedFromGateway = 325;
export const cCheckoutPaymentError = 326;
export const cOrderCreationFailed = 327;
export const cOrderPaymentFailed = 328;
export const cPaymentConfirmationCheckingFailed = 329;

// Price array for E-Vikrayini filters

export const E_VIKRAYINI_FILTER_PRICE_LIST = [
    {
        label:'0',
        value: 0
    },
    {
        label: '100',
        value :100
    },
    {
        label:'200',
        value: 200
    },
    {
        label: '500',
        value: 500
    },
    {
        label: '1000',
        value: 1000
    },
    {
        label: '2000',
        value: 2000
    },
    {
        label: '5000',
        value: 5000
    },
    {
        label: '10000',
        value: 10000
    },
    {
        label: '20000',
        value: 20000
    },

    {
        label: '50000',
        value: 50000
    },
]