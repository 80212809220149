<footer class="p-shadow-1 bg-footer footer">
  <!-- <fa name="fas fa-address-card"></fa> -->
  <div class="playstore-link p-grid">
    <div class="p-col p-text-right line-height-1">
      <h2>Get the app now</h2>
    </div>
    <div class="p-col p-text-left">
      <a
        href="https://play.google.com/store/apps/details?id=com.avbhakthi.android"
        target="_blank"
      >
        <img
          class="play-store-icon"
          src="assets/img/av-app-get-it-on-store.png"
        />
      </a>
    </div>
  </div>
  <div class="p-text-center">
    <h2 class="p-text-center p-line-height-1 p-my-0">ADVAITHA VARSHINI SERVICES PVT. LTD.</h2>
    <h4 class="p-text-center p-line-height-1 p-my-0 p-text-light">H.No.10.6A Main Road, Kanagala Village, Cherukupalli Mandal, Guntur District</h4>
    <h4 class="p-text-center p-line-height-1 p-my-0 p-text-light">Andhra Pradesh, India. PIN-522259</h4>
  </div>
  <div class="footer-social-icons">
    
    <a
      href="https://www.facebook.com/avbhakthi/"
      class="footer-social-link p-shadow-1"
      ><i class="fab fa-facebook-square"></i
    ></a>
    <a
      href="https://twitter.com/AV_Bhakthi"
      class="footer-social-link p-shadow-1"
      ><i class="fab fa-twitter-square"></i
    ></a>
    <a
      href="https://www.youtube.com/channel/UCEHtF_YmDbuDKLCEyi0Ogiw"
      class="footer-social-link p-shadow-1"
      ><i class="fab fa-youtube-square"></i
    ></a>
  </div>
  <h2 class="p-text-light p-mb-0 copyright-text" *ngIf="externLinks === false">
    Advaitha Varshini &#169; {{ getYear() }}. All Rights Reserved.|<a
      [routerLink]="['/app/privacy-policy']"
      >Privacy Policy</a
    >|<a [routerLink]="['/app/terms-of-service']">Terms Of Service</a>|<a
      [routerLink]="['/app/refund-policy']"
      >Refund Policy</a
    >|
  </h2>

  <h2 class="p-text-light p-mb-0 copyright-text" *ngIf="externLinks === true">
    Advaitha Varshini &#169; {{ getYear() }}. All Rights Reserved.|<a
      [routerLink]="['/other-info/privacy-policy']"
      >Privacy Policy</a
    >|<a [routerLink]="['/other-info/terms-of-service']">Terms Of Service</a>|<a
      [routerLink]="['/other-info/refund-policy']"
      >Refund Policy</a
    >|
  </h2>
</footer>
