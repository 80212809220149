import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Category, InterestStatusType, PurposeType, ResultType, YatraInterestRequest, YatraVM } from '../models/avbhakthi.model';
import {  MESSAGE_SERVICE_KEY, SOCIALICONS } from '../constants';
import { ActivatedRoute } from '@angular/router';
import { YatraService } from '../common/yatra.service';
import {MessageService} from 'primeng/api';
import * as moment from 'moment';
import { AppConfigService } from '../common/app-config.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-yatra-details',
  templateUrl: './yatra-details.component.html',
  styleUrls: ['./yatra-details.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class YatraDetailsComponent implements OnInit, OnDestroy{

  private serviceSubscriptions: Subscription;
  yatra: YatraVM;
  readonly socialIcons = SOCIALICONS;
  contentLoading: boolean = true;
  readonly baseURL: string = AppConfigService.settings.serverURL;
  constructor(
    private route: ActivatedRoute,
    private yatraService: YatraService,
    private messageService: MessageService
  ) {
    this.serviceSubscriptions = new Subscription();
    this.yatra = new YatraVM();

  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      let id = data.get('id');
      this.setYatraDetails(id);
    })
  }
  get newsCategory(): typeof Category {
    return Category;
  }
  getImageURL(): string {
    if (this.yatra.IMAGE_URL)
      return this.baseURL + this.yatra.IMAGE_URL.replace(/\\/g, '/');
    return '';
  }

  setYatraDetails(id: string, resultType: ResultType = ResultType.after, include: boolean = true) {
    if (id) {
      this.serviceSubscriptions.add(
      this.yatraService
        .getYatras(1, Category.bhakthiNews, PurposeType.forMainScreen, include, resultType, id)
        .subscribe(data => {
          this.contentLoading = false;
          if (data.length > 0) {
            // this.latestNews = data;
            this.yatra = data[0];
          } else {
            // this.noMoreNews = true;
          }
        }, error => {
          // console.log(error);
        })
      );
    }
  }
  nextYatra() {
    this.setYatraDetails(this.yatra.YATRA_EXTERNAL_ID, ResultType.after, false);
  }
  prevYatra() {
    this.setYatraDetails(this.yatra.YATRA_EXTERNAL_ID, ResultType.before, false);
  }

  saveYatraInterest(currentStatus: InterestStatusType) {
    
    let req: YatraInterestRequest = {
      REF_ID: this.yatra.YATRA_EXTERNAL_ID,
      CATEGORY: Category.yatraSandarshini,
      INTEREST_STATUS: currentStatus === InterestStatusType.notIntrested || currentStatus === InterestStatusType.notResponded ? InterestStatusType.intrested : InterestStatusType.notIntrested,
      POSTED_ON : moment().format('YYYY-MM-DD HH:mm:ss')
    };
    this.yatra.INTREST_STATUS = req.INTEREST_STATUS;
    this.yatraService.saveYatraStatus(req).subscribe(data => {
      if(data.CODE === 100) {
        this.yatra.INTREST_STATUS = req.INTEREST_STATUS;
        if(this.yatra.INTREST_STATUS === InterestStatusType.intrested) {
          this.messageService.add({severity:'success', summary:'Thank you. Our team will contact you soon.', detail:'AVBhakthi.com', key: MESSAGE_SERVICE_KEY});
        }
      } else {
        this.yatra.INTREST_STATUS= currentStatus;
      }
    });

  }

  get InterestStatus(): typeof InterestStatusType {
    return InterestStatusType;
  }
}
