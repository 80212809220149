<form
  #userProfileForm="ngForm"
  (ngSubmit)="saveUserDetails(userProfileForm)"
  novalidate
>
  <div class="p-shadow-3 form-layout-container">
    <div class="p-fluid p-container">
      <p-fileUpload
        name=""
        [showUploadButton]="false"
        (onSelect)="onUpload($event)"
        accept="image/*"
        maxFileSize="1000000"
        customUpload="true"
      >
        <ng-template pTemplate="content">
          <ul *ngIf="uploadedFiles.length">
            <li *ngFor="let file of uploadedFiles">
              {{ file.name }} - {{ file.size }} bytes
            </li>
          </ul>
        </ng-template>
      </p-fileUpload>
      <div class="p-field">
        <label for="firstName">First Name</label>
        <input
          [(ngModel)]="user.FIRST_NAME"
          id="firstName"
          name="firstName"
          type="text"
          #firstName="ngModel"
          aria-describedby="firstName-help"
          pInputText
          required
        />
        <small
          id="firstName-help"
          *ngIf="firstName.valid === false && firstName.touched"
          class="p-invalid"
          >Invalid First name</small
        >
      </div>
      <div class="p-field">
        <label for="lastName">Last Name</label>
        <input
          [(ngModel)]="user.LAST_NAME"
          id="lastName"
          name="lastName"
          type="text"
          #lastName="ngModel"
          aria-describedby="lastName-help"
          pInputText
          required
        />
        <small
          id="lastName-help"
          *ngIf="lastName.valid === false && lastName.touched"
          class="p-invalid"
          >Invalid last name</small
        >
      </div>
      <div class="p-field">
        <label for="email">Email</label>
        <input
          [(ngModel)]="user.EMAIL"
          id="email"
          name="email"
          type="email"
          #email="ngModel"
          aria-describedby="email-help"
          pInputText
          required
        />
        <small
          id="email-help"
          *ngIf="email.valid === false && email.touched"
          class="p-invalid"
          >Invalid email address</small
        >
      </div>
      <div class="p-grid">
        <div class="p-col-12 p-md-5">
          <label for="">User Location</label>
          <app-autocomplete-location
            [address]="user.GEO_LOC_ADDRESS"
            adressType="geocode"
            (setAddress)="userLocationChanged($event)"
          >
          </app-autocomplete-location>
        </div>
        <div class="p-col-12 p-md-7">
          <p-gmap
            #gmap
            [options]="options"
            [overlays]="overlays"
            [style]="{ width: '100%', height: '320px' }"
          ></p-gmap>
        </div>
      </div>
      <div class="p-field">
        <label for="city">City/Village</label>
        <input
          [(ngModel)]="user.CITY"
          id="city"
          name="city"
          type="text"
          #city="ngModel"
          aria-describedby="city-help"
          pInputText
          required
        />
        <small
          id="city-help"
          *ngIf="city.valid === false && city.touched"
          class="p-invalid"
          >Invalid city name</small
        >
      </div>
      <div class="p-field">
        <label for="district">District</label>
        <input
          [(ngModel)]="user.DISTRICT"
          id="district"
          name="district"
          type="text"
          #district="ngModel"
          aria-describedby="district-help"
          pInputText
          required
        />
        <small
          id="district-help"
          *ngIf="district.valid === false && district.touched"
          class="p-invalid"
          >Invalid district name</small
        >
      </div>
      <div class="p-field">
        <label for="state">State</label>
        <input
          [(ngModel)]="user.STATE"
          id="state"
          name="state"
          type="text"
          #state="ngModel"
          aria-describedby="state-help"
          pInputText
          required
        />
        <small
          id="state-help"
          *ngIf="state.valid === false && state.touched"
          class="p-invalid"
          >Invalid state</small
        >
      </div>
      <div class="p-field">
        <label for="pincode">Pincode</label>
        <input
          [(ngModel)]="user.PINCODE"
          id="pincode"
          name="pincode"
          type="text"
          #pincode="ngModel"
          aria-describedby="pincode-help"
          pattern="[0-9]{6}"
          maxlength="6"
          pInputText
          required
        />
        <small
          id="pincode-help"
          *ngIf="pincode.valid === false && pincode.touched"
          class="p-invalid"
          >Invalid pincode</small
        >
      </div>

      <button
        pButton
        pRipple
        type="submit"
        [label]="submitting === true ? 'Please wait' : 'Save'"
        style="max-width: 150px"
        class="p-button-raised btn-theme p-button-text"
        [disabled]="userProfileForm.valid === false || submitting === true"
      ></button>
    </div>
  </div>
</form>
