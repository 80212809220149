<div class="page-header full-bg">
  <div class="blur-bg page-header-content">
    <h1 class="p-text-center p-text-white page-title p-my-1">e-విక్రయని</h1>
    <hr class="special-line" />
    <h2 class="p-text-center page-sub-title p-my-1">
      మీరు ఇచ్చట గృహము మరియు దేవాలయము నందు జరుగు భక్తి, ఆధ్యాత్మిక, పూజా కార్యక్రమాలు చేయుటకు కావలసిన పూజా సామాగ్రిని ఆన్లైన్ ప్రక్రియ ద్వారా విక్రయించబడును.
    </h2>
  </div>
</div>
<div class="p-fluid">
  <div class="p-grid">
    <div class="p-col" #collapseSidebar style="max-width: 300px; position: relative; transition: 'all 400ms ease'">
      <button pButton [icon]="
          toggleSidebar === true ? 'pi pi-chevron-right' : 'pi pi-chevron-left'
        " class="btn-theme p-button-info p-shadow-2" (click)="toggleMe($event, collapseSidebar)" style="
          position: absolute;
          right: -20px;
          top: 0px;
          border-radius: 0 !important;
          padding: 10px 4px;
          width: auto;
          border: none;
        "></button>
      <form novalidate #eStoreFilterForm="ngForm" (ngSubmit)="applyFilters(eStoreFilterForm)">
        <div class="p-field">
          <label for="textItemName" class="theme-color p-mb-0">Item Name</label>
          <hr />
          <input type="text" placeholder="Item name" pInputText name="textItemName" id="textItemName"
            [(ngModel)]="filter.ITEM_NAME" />
        </div>
        <div class="p-field">
          <label class="p-mb-0"><span class="theme-color p-mr-1">Price(Rs):</span>
          </label>
          <hr class="p-mb-1" />
          <div class="p-grid">
            <div class="p-col-6 p-px-0">
              <label class="p-m-0 line-height-1">Min</label>
              <p-dropdown [options]="minRangeList" name="minPrice" [(ngModel)]="filter.PRICE_RANGE.LOWER"
                [style]="{'max-width':'100%'}" (onChange)="minPriceChange($event)" editable="true" optionLabel="label"
                optionValue="value"></p-dropdown>
            </div>
            <div class="p-col-6 p-px-0">
              <label class="p-m-0 line-height-1">Max</label>
              <p-dropdown [options]="maxRangeList" name="maxPrice" [(ngModel)]="filter.PRICE_RANGE.UPPER"
                [style]="{'max-width':'100%'}" (onChange)="maxPriceChange($event)" editable="true" optionLabel="label"
                optionValue="value"></p-dropdown>
            </div>
          </div>
          <!-- <div class="p-grid">
            <div class="p-col p-p-0 p-text-right" style="max-width: 60px; margin-top: -15px">
              {{ rangeValues !== null ? rangeValues[0]: 0 }}
            </div>
            <div class="p-col p-py-0">
              <p-slider [(ngModel)]="rangeValues" [min]="0" [max]="50000" [range]="true" name="priceRange"
                (onSlideEnd)="priceRangeChanged($event)"></p-slider>
              
            </div>
            <div class="p-col p-p-0" style="max-width: 60px; margin-top: -15px">
              {{rangeValues !== null ? rangeValues[1]: 0}}
            </div>
          </div> -->
        </div>
        <div class="p-field">
          <label class="theme-color p-mb-0">Category</label>
          <hr />
          <app-multi-select-embed [options]="poojaItemCategories" dataKey="CATEGORY_NAME" valKey="CATEGORY_ID"
            height="200px" #category name="poojaItemCategories" (onChange)="categoriesChange($event)">
            <!-- (onChange)="categoriesChange($event)" -->
          </app-multi-select-embed>
        </div>
        <div class="p-grid">
          <div class="p-col-6">
            <button pButton pRipple type="submit" class="btn-theme" label="Apply"></button>
          </div>
          <div class="p-col-6">
            <button pButton pRipple type="button" class="btn-theme" label="Clear"
              (click)="clearAllFilter([category], eStoreFilterForm)"></button>
          </div>
        </div>
      </form>
      <!-- / End of Filter form -->
    </div>
    <div class="p-col p-px-0">
      <div class="card">
        <p-dataView #dv [value]="storeItems" [paginator]="true" [rows]="noOfRowsPage" filterBy="ITEM_NAME"
          [sortField]="sortField" [sortOrder]="sortOrder" layout="grid" [lazy]="true" [totalRecords]="totalRecords"
          (onLazyLoad)="lazyLoadData($event)">
          <!-- <ng-template pTemplate="header">
            <h1 class="theme-color p-text-light p-mt-0 line-height-1">
              Pooja Store
            </h1>
          </ng-template> -->

          <ng-template let-product pTemplate="gridItem">
            <div class="p-col-12 p-md-12 p-lg-6 p-xl-3">
              <div class="product-grid-item card">
                <div class="product-grid-item-top" [routerLink]="[
                '/app/pooja-store/details/' + product.ITEM_EXTERNAL_ID
              ]" >
                  <div class="flex-50">
                    <i class="pi pi-tag product-category-icon"></i>
                    <span class="product-category">{{
                      getCategoryName(product.CATEGORY_ID)
                      }}</span>
                  </div>
                  <div class="flex-50">
                    <span [class]="
                    'product-badge status-' +
                    (product.ITEM_AVAILABILITY === true
                      ? 'instock'
                      : 'outofstock')
                  ">{{
                      product.ITEM_AVAILABILITY === true
                      ? "INSTOCK"
                      : "OUT OF STOCK"
                      }}</span>
                  </div>

                </div>
                <div class="product-grid-item-content" [routerLink]="[
                '/app/pooja-store/details/' + product.ITEM_EXTERNAL_ID
              ]" >
                  <div class="img-container">
                    <div class="offer-badge" *ngIf="product.DISCOUNT_PERCENTAGE > 0">
                      {{product.DISCOUNT_PERCENTAGE}}%
                    </div>
                    <img [src]="getImageURL(product)" [alt]="product.ITEM_NAME" />
                  </div>

                  <div class="product-name line-height-1 theme-color">
                    {{ product.ITEM_NAME }}
                  </div>
                  <div class="product-description">
                    {{ product.ITEM_DETAILS }}
                  </div>
                  <!-- <p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating> -->
                </div>
                <div class="product-grid-item-bottom">
                  <p class="product-price p-my-0 font-family-roboto">
                    <span class="theme-color p-mr-1">&#8377;{{
                      product.FINAL_PRICE | number
                      }}/-</span>
                    <br />
                    <span style="font-size: 0.7rem; text-decoration: line-through">&#8377;{{ product.PRICE  | number }}/-</span>
                  </p>
                  <p-button styleClass="btn-theme no-round" icon="pi pi-shopping-cart"
                    [disabled]="product.ITEM_AVAILABILITY === false" (onClick)="addToCart(product)"></p-button>
                </div>
              </div>
            </div>
          </ng-template>
        </p-dataView>
      </div>
    </div>
  </div>
</div>