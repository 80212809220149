<!-- <app-login></app-login> -->
<!-- <app-header></app-header> -->

<!-- <app-news-card></app-news-card>
<app-yatra-card></app-yatra-card> -->

<!-- <app-question-card></app-question-card> -->
<!-- <app-header></app-header> -->
<!-- <app-news-card></app-news-card>
<app-question-card></app-question-card>
<app-guru-card></app-guru-card> -->
<!-- <app-home></app-home> -->
<!-- <app-prasna-vinodhini></app-prasna-vinodhini> -->
<router-outlet></router-outlet>
<p-toast position="bottom-center" key="GLOBALMESSAGE"></p-toast>
<!-- <app-bhakthi-vartha></app-bhakthi-vartha> -->
<!-- <app-yatra-sandharshini></app-yatra-sandharshini> -->
<!-- <app-guru-subhashini></app-guru-subhashini> -->
<!-- <app-footer></app-footer> -->