<h1 class="p-text-center">Refund Policy</h1>
<!-- Begin of section : SECTION  -->
<section class="p-fluid content-section">
  <h2 class="section-head">Returns</h2>
  <p class="section-content">
    Our policy lasts 7 days. If 7 days have gone by since your purchase,
    unfortunately we can’t offer you a refund or exchange.
  </p>
  <p class="section-content">
    To be eligible for a return, your item must be unused and in the same
    condition that you received it. It must also be in the original packaging.
  </p>
  <p class="section-content">
    Several types of goods are exempt from being returned. Perishable goods such
    as food, flowers, newspapers or magazines cannot be returned. We also do not
    accept products that are intimate or sanitary goods, hazardous materials, or
    flammable liquids or gases.
  </p>
  <p class="section-content p-mb-1">Additional non-returnable items:</p>
  <ul>
    <li>Gift cards</li>
    <li>Downloadable software products</li>
    <li>Some health and personal care items</li>
  </ul>
  <p class="section-content">
    To complete your return, we require a receipt or proof of purchase.
  </p>
  <p class="section-content">
    Please do not send your purchase back to the manufacturer.
  </p>
  <p class="section-content">
    There are certain situations where only partial refunds are granted:<br />
    Book with obvious signs of use<br />
    CD, DVD, VHS tape, software, video game, cassette tape, or vinyl record that
    has been opened.<br />
    Any item not in its original condition, is damaged or missing parts for
    reasons not due to our error.<br />
    Any item that is returned more than 7 days after delivery
  </p>
</section>
<!-- End of section -->

<!-- Begin of section : SECTION  -->
<section class="p-fluid content-section">
  <h2 class="section-head">Refunds</h2>
  <p class="section-content">
    Once your return is received and inspected, we will send you an email to
    notify you that we have received your returned item. We will also notify you
    of the approval or rejection of your refund.
  </p>
  <p class="section-content">
    If you are approved, then your refund will be processed, and a credit will
    automatically be applied to your credit card or original method of payment,
    within a certain amount of days.
  </p>
</section>
<!-- End of section -->

<!-- Begin of section : SECTION  -->
<section class="p-fluid content-section">
  <h2 class="section-head">Late or missing refunds</h2>
  <p class="section-content">
    If you haven’t received a refund yet, first check your bank account again.
  </p>
  <p class="section-content">
    Then contact your credit card company, it may take some time before your
    refund is officially posted.
  </p>
  <p class="section-content">
    Next contact your bank. There is often some processing time before a refund
    is posted. If you’ve done all of this and you still have not received your
    refund yet, please contact us at <b>advaithavarshini@gmail.com.</b>
  </p>
</section>
<!-- End of section -->

<!-- Begin of section : SECTION  -->
<section class="p-fluid content-section">
  <h2 class="section-head">Sale items</h2>
  <p class="section-content">
    Only regular priced items may be refunded, unfortunately sale items cannot
    be refunded.
  </p>
</section>
<!-- End of section -->

<!-- Begin of section : SECTION  -->
<section class="p-fluid content-section">
  <h2 class="section-head">Exchanges (if applicable)</h2>
  <p class="section-content">
    We only replace items if they are defective or damaged. If you need to
    exchange it for the same item, send us an email at
    <b>advaithavarshini@gmail.com</b>.
  </p>
</section>
<!-- End of section -->

<!-- Begin of section : SECTION  -->
<section class="p-fluid content-section">
  <h2 class="section-head">Gifts</h2>
  <p class="section-content">
    If the item was marked as a gift when purchased and shipped directly to you,
    you’ll receive a gift credit for the value of your return. Once the returned
    item is received, a gift certificate will be mailed to you.
  </p>
  <p class="section-content">
    If the item wasn’t marked as a gift when purchased, or the gift giver had
    the order shipped to themselves to give to you later, we will send a refund
    to the gift giver and he will find out about your return.
  </p>
</section>
<!-- End of section -->

<!-- Begin of section : SECTION  -->
<section class="p-fluid content-section">
  <h2 class="section-head">Shipping</h2>
  <p class="section-content">
    To return your product, you should mail your product to the address which
    your received via email.
  </p>
  <p class="section-content">
    You will be responsible for paying for your own shipping costs for returning
    your item. Shipping costs are non-refundable. If you receive a refund, the
    cost of return shipping will be deducted from your refund.
  </p>
  <p class="section-content">
    Depending on where you live, the time it may take for your exchanged product
    to reach you, may vary.
  </p>
</section>
<!-- End of section -->
