import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuCategories } from '../common/menu.model';
import { MenuService } from '../common/menu.service';
import { PurohitDarshiniService } from '../common/purohit-darshini.service';
import { PurohitFilter, PurohitListVM } from '../models/avbhakthi.model';

@Component({
  selector: 'app-purohit-darshini',
  templateUrl: './purohit-darshini.component.html',
  styleUrls: ['./purohit-darshini.component.css']
})
export class PurohitDarshiniComponent implements OnInit, OnDestroy {

  geoLocationAddress: string = '';
  purohitFilter: PurohitFilter;
  purohitList: PurohitListVM[];
  isDataloading: boolean = false;
  toggleSidebar: boolean = false;

  private serviceSubscriptions: Subscription;
  constructor(
    private menuService: MenuService,
    private purohitService: PurohitDarshiniService
  ) {
    this.purohitList = [];
    this.serviceSubscriptions = new Subscription();
    this.purohitFilter = new PurohitFilter();
    this.menuService.setMenuItems(MenuCategories.purihitDarshini);
    this.fetchPurohitDetail(this.purohitFilter);
   }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
  }

  // Fetch purohit list by taking Filter as input
  fetchPurohitDetail(filter: PurohitFilter) {
    this.isDataloading= true;
    this.purohitList = [];
    this.serviceSubscriptions.add(
      this.purohitService.purohitList(filter).subscribe(data => {
        // // console.log(data);
        this.purohitList = data;
        this.isDataloading= false;
      }, error => {
        this.isDataloading= false;
      })
    )
  }


  // Handle filter
  filterApplied(event: PurohitFilter){
    this.fetchPurohitDetail(event);
  }

  // Handle hide event
  toggleMe(event: Event, element: HTMLElement) {
    this.toggleSidebar = !this.toggleSidebar;
    if(this.toggleSidebar) {
      element.style.marginLeft = '-300px';
    } else {
      element.style.marginLeft = '0px';
    }
  }
}
