import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuCategories } from 'src/app/common/menu.model';
import { MenuService } from 'src/app/common/menu.service';
import { NewsService } from 'src/app/common/news.service';
import { Category, GuruVM, NewsVM, PurposeType, ResultType } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'app-guru-subhashini-dashboard',
  templateUrl: './guru-subhashini-dashboard.component.html',
  styleUrls: ['./guru-subhashini-dashboard.component.css']
})
export class GuruSubhashiniDashboardComponent implements OnInit, OnDestroy {
  private serviceSubscriptions: Subscription;
  guruSubhashini: NewsVM[];
  contentLoading: boolean = true;
  noMoreNews: boolean = false;
  constructor(
    private menuService: MenuService,
    private newsService: NewsService
  ) {
    this.serviceSubscriptions = new Subscription();
    // menuService.setMenuItems(MenuCategories.guruSubhashini);
    this.menuService.setMenuItems(MenuCategories.all);
    this.guruSubhashini = [];
    this.serviceSubscriptions.add(
    this.newsService
      .getNews(10, Category.guruSubhashini, PurposeType.forShowList, false, ResultType.after)
      .subscribe(data => {
        this.contentLoading = false;
        if (data.length > 0) {
          // this.latestNews = data;
          this.guruSubhashini = [...this.guruSubhashini, ...data];
        } else {
          this.noMoreNews = true;
        }
      }, error => {
        // console.log(error);
        this.contentLoading = false;
      })
    );

  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
  }
  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {

    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !this.noMoreNews && !this.contentLoading) {
      // console.log('You have reached bottome of page');
      let lastNewsId = '';
      this.contentLoading = true;
      if (this.guruSubhashini.length > 0 && this.guruSubhashini.length > 0)
        lastNewsId = this.guruSubhashini[this.guruSubhashini.length - 1].NEWS_EXTERNAL_ID;
      
        this.serviceSubscriptions.add(
      this.newsService
        .getNews(10, Category.bhakthiNews, PurposeType.forMainScreen, false, ResultType.after, lastNewsId)
        .subscribe(data => {
          this.contentLoading = false;
          if (data.length > 0) {
            // this.latestNews = data;
            this.guruSubhashini = [...this.guruSubhashini, ...data];
          } else {
            this.noMoreNews = true;
          }
        }, error => {
          // console.log(error);
          this.contentLoading = false;
        })
        );
    }
  }

}
