<p-card styleClass="p-card-shadow news-card" (click)="cardClicked()">
  <p-header (click)="cardClicked()">
    <div
      class="full-bg card-header-bg"
      [ngStyle]="{ 'background-image': 'url(' + getImageURL() + ')' }"
    ></div>
  </p-header>
  <div class="p-card-title" (click)="cardClicked()">
    {{ news.TITLE }}
  </div>
  <div class="p-card-subtitle" (click)="cardClicked()">
    <!-- {{news.SUB_TITLE}} -->
    <div class="p-grid">
      <div class="p-col-6 p-px-0">
        <p class="p-mt-1 p-mb-1 v-flow-hidden">
          <i class="pi pi-user p-mr-1" style="font-size: 0.75rem"></i
          >{{ news.POSTED_NAME }}
        </p>
      </div>
      <div class="p-col-6 p-px-0">
        <p class="p-mt-1 p-mb-1 v-flow-hidden">
          <i class="pi pi-calendar p-mr-1" style="font-size: 0.75rem"></i
          >{{ news.POSTED_ON | dateIso }}
        </p>
      </div>
    </div>
  </div>
  <div
    class="card-description p-text-center"
    style="font-size: 1.5rem"
    (click)="cardClicked()"
  >
    {{ news.NEWS_STATUS | statusType }}
  </div>
</p-card>
