import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ProfileService } from 'src/app/common/profile.service';
import { MESSAGE_SERVICE_KEY } from 'src/app/constants';
import { GetAddressStatus, UserAddressVM } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'app-user-addresses',
  templateUrl: './user-addresses.component.html',
  styleUrls: ['./user-addresses.component.css']
})
export class UserAddressesComponent implements OnInit, OnDestroy{

  addressList: UserAddressVM[] = [];
  private serviceSubscriptions: Subscription;
  constructor(
    private profileService: ProfileService,
    private messageService: MessageService
  ) {
    // initializations
    this.serviceSubscriptions = new Subscription();

   }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  // To fetch and update user addres list on UI
  fetchAddressList() {
    this.serviceSubscriptions.add(
      this.profileService.getAddress(GetAddressStatus.both).subscribe(data => {
        this.addressList = data;
      }, error => {
        this.messageService.add({ severity: 'error', summary: error.error.DESC, key: MESSAGE_SERVICE_KEY});
      })
    );
  }

  ngOnInit(): void {
    this.fetchAddressList();
  }

  // handle set as default address
  setAsDefaultAddress(address: UserAddressVM) {
    address.IS_DEFAULT = true;
    this.sendDataToServer(address);
  }


  // hanlde deactivate address or set as default
  makeAddressActiveOrInactive(address: UserAddressVM) {
    address.IS_ACTIVE = !address.IS_ACTIVE;
    this.sendDataToServer(address);
  }

  // Send changes to server
  sendDataToServer(address: UserAddressVM) {
    this.profileService.saveUserAddress(address, false).subscribe(data => {
      if (data.CODE === 100) {
        this.messageService.add({ severity: 'success', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
      } else {
        this.messageService.add({ severity: 'error', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
      }
      this.fetchAddressList();
    }, error => {
      // console.log('Error: ', error);
      this.messageService.add({ severity: 'error', summary: error.error.DESC, key: MESSAGE_SERVICE_KEY});
      
    });
  }

}
