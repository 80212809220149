import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppConfigService } from '../common/app-config.service';
import { AppHttpService } from '../common/http.service';
import { MenuCategories } from '../common/menu.model';
import { MenuService } from '../common/menu.service';
import { PoojaStoreService } from '../common/pooja-store.service';
import { ProfileService } from '../common/profile.service';
import { MESSAGE_SERVICE_KEY } from '../constants';
import { EPoojaStoreService } from '../e-store/e-pooja-store.service';
import { ImgGaleria, PoojaItemCategory, PostPoojaStoreItemVM, Role_Types } from '../models/avbhakthi.model';

@Component({
  selector: 'app-pooja-item-details',
  templateUrl: './pooja-item-details.component.html',
  styleUrls: ['./pooja-item-details.component.css']
})
export class PoojaItemDetailsComponent implements OnInit, OnDestroy {

  poojaItem: PostPoojaStoreItemVM;
  private serviceSubscriptions: Subscription;
  readonly baseURL: string = AppConfigService.settings.serverURL;
  private readonly webURL: string = AppConfigService.settings.webURL;
  private readonly firebaseDomainUriPrefix: string = AppConfigService.settings.domainUriPrefix;
  private readonly androidPackageName: string = AppConfigService.settings.androidPackageName;
  poojaItemCategories: PoojaItemCategory[];
  sharableLink: string = "";
  quantity: number = 1;

  images: any[] = []

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  constructor(
    private poojaStoreService: PoojaStoreService,
    private messageService: MessageService,
    private activeRoute: ActivatedRoute,
    private route: Router,
    private cartService: EPoojaStoreService,
    private menuService: MenuService,
    private http: AppHttpService,
    private profile: ProfileService,
    private eKartService: EPoojaStoreService
  ) {
    // this.images = [];
    this.menuService.setMenuItems(MenuCategories.all);
    this.serviceSubscriptions = new Subscription();
    this.poojaItem = new PostPoojaStoreItemVM();
    this.poojaItemCategories = [];


    // Fetching poojaItemCategories
    this.serviceSubscriptions.add(
      this.poojaStoreService.poojaItemCategories().subscribe(data => {
        this.poojaItemCategories = data;

      })
    );
    

  }
  ngOnDestroy(): void {
    // Unsubscribing all subscriptions
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.serviceSubscriptions.add(
      this.activeRoute.queryParamMap.subscribe(params => {
        let purohitCode = params.get('purohitCode');
        if(purohitCode) {
          this.eKartService.setCouponCode(purohitCode);
        }
      })
    );
    this.serviceSubscriptions.add(
      this.activeRoute.paramMap.subscribe(data => {
        // Getting pooja-item details
        let id = data.get('id');
        if (id) {
          this.getShareLink(id);
          this.serviceSubscriptions.add(
            this.poojaStoreService.poojaItemById(id).subscribe(data => {
              this.poojaItem = data;
              // Filling images for left side image gallery
              if (data.ITEM_IMAGES_PATH) {
                let imgBuffer = [];
                data.ITEM_IMAGES_PATH.split('#').forEach((path, index) => {

                  imgBuffer.push(new ImgGaleria(this.baseURL + path, data.ITEM_NAME + 'index'))
                })
                this.images = [...imgBuffer];

              }
            }, error => {
              this.messageService.add({ severity: 'error', summary: 'Invalid pooja item id', key: MESSAGE_SERVICE_KEY });
              this.route.navigateByUrl('/app/pooja-store');
            })
          );
        } else {
          this.messageService.add({ severity: 'error', summary: 'Invalid pooja item id', key: MESSAGE_SERVICE_KEY });
          this.route.navigateByUrl('/app/pooja-store');
        }
      })
    );
  }
  // Get CategoryName
  getCategoryName(id: number): string {
    let categoriesFiltered = this.poojaItemCategories.filter(item => item.CATEGORY_ID === id);
    return categoriesFiltered.length > 0 ? categoriesFiltered[0].CATEGORY_NAME : '';
  }
  // Handle item add to cart
  addToCart(buyNow: boolean = false) {
    this.serviceSubscriptions.add(
      this.cartService.addItemToCart(this.poojaItem.ITEM_EXTERNAL_ID, this.quantity)
        .subscribe(data => {
          if (data.CODE === 100) {
            this.messageService.add({ severity: 'success', summary: data.DESC, key: MESSAGE_SERVICE_KEY });
            if(buyNow)
            {
              this.route.navigate(['/app/pooja-store/cart']);
            }
          }
        })
    );
  }

  // Function to get sharable link based on Cateogry
  async getShareLink(itemExternalId: string){
    let postUrl = this.webURL+'app/pooja-store/details/';
    postUrl += itemExternalId;
    // Append purohitCode if user type is purohit
    if(this.profile.UserRole === Role_Types.purohit) {
      postUrl+='?purohitCode='+this.profile.PurohitPublicId;
    }
    let httpData =  await this.http.getShortUrl({
      dynamicLinkInfo: {
        domainUriPrefix: this.firebaseDomainUriPrefix,
        link: postUrl,
        androidInfo: {
          androidPackageName: this.androidPackageName
        }
      }
    }).toPromise();
    this.sharableLink = httpData.shortLink;
  }

}
