import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { GuruVM } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'dashboard-guru-card',
  templateUrl: './dashboard-guru-card.component.html',
  styleUrls: ['./dashboard-guru-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardGuruCardComponent implements OnInit {

  @Input() quote: GuruVM;
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  cardClicked() {
    // console.log('Card clicked');
    this.router.navigateByUrl('dashboard/guru-subhashini/add/' + this.quote.NEWS_EXTERNAL_ID);
  }
}
