<p-card styleClass="p-card-shadow temple-card">
  <p-header (click)="cardClicked()">
    <div
      class="full-bg card-header-bg"
      [ngStyle]="{ 'background-image': 'url(' + getImageURL() + ')' }"
    ></div>
  </p-header>
  <div
    class="p-card-title"
    [innerHtml]="temple.TEMPLE_NAME"
    (click)="cardClicked()"
  >
    <!-- {{temple.PACKAGE_NAME}} -->
  </div>
  <div class="card-description" (click)="cardClicked()">
    <div class="p-grid">
      <div class="p-col-12" style="height:75px; overflow: hidden;">
        <p class="p-mt-1 p-mb-1">
          {{temple.TEMPLE_GEO_LOC_ADDRESS}}
        </p>
      </div>
      <div class="p-col-12 p-text-center" style="font-size: 1.5rem">
        {{ temple.TEMPLE_STATUS | statusType }}
      </div>
    </div>

  </div>
</p-card>


<!-- <div
    class="card-description p-text-center"
    style="font-size: 1.5rem"
    (click)="cardClicked()"
  >
    {{ news.NEWS_STATUS | statusType }}
  </div> -->