import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ProfileService } from 'src/app/common/profile.service';
import { QuestionService } from 'src/app/common/question.service';
import { MESSAGE_SERVICE_KEY, POST_STATUS, PUBLISH_TYPES, QUESTION_TYPES, TARGET_AUDIENCE } from 'src/app/constants';
import { Category, ImageCategory, ImageInfoVM, PurposeType, QuestionVM, ResultType } from 'src/app/models/avbhakthi.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-add-modify-prasna-vinodhini',
  templateUrl: './add-modify-prasna-vinodhini.component.html',
  styleUrls: ['./add-modify-prasna-vinodhini.component.css']
})
export class AddModifyPrasnaVinodhiniComponent implements OnInit, OnDestroy {

  private serviceSubscriptions: Subscription;
  prasnaObj: QuestionVM;
  submitting: any = false;
  uploadedFiles: any[] = [];
  updateQuestion: boolean = false;
  constructor(
    private questionService: QuestionService,
    private router: Router,
    private messageService: MessageService,
    private activeRoute: ActivatedRoute,
    private profileService: ProfileService
  ) {
    this.serviceSubscriptions = new Subscription();
    this.prasnaObj = new QuestionVM();
  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
    // // console.log('Uuid from javascript: ', uuidv4());
    this.serviceSubscriptions.add(
    this.activeRoute.paramMap.subscribe(params => {
      let id = params.get('id');
      if (id) {
        this.questionService.getQuestions(1, Category.bhakthiNews, PurposeType.forShowList, true, ResultType.after, id)
          .subscribe(data => {
            if (data.length > 0) {
              this.prasnaObj = data[0];
              this.updateQuestion = true;
            }
          })
      }
    }));
  }
  get PublishTypes() {
    return PUBLISH_TYPES;
  }

  get PostStatus() {
    return this.profileService.PostStatus;
  }

  get TargetAudience() {
    return TARGET_AUDIENCE;
  }

  get QuestionTypes() {
    return QUESTION_TYPES;
  }


  questionSubmit(form: NgForm) {
    // console.log(form.value);
    // console.log(this.prasnaObj);
    if (form.valid) {
      var formData = new FormData();
      this.prasnaObj.CATEGORY = Category.prasnaVinodhini;
      if (this.prasnaObj.QUESTION_EXTERNAL_ID) {
        if (this.uploadedFiles.length > 0) {
          formData.append('IS_IMG_CHANGED', 'true');
        } else {
          formData.append('IS_IMG_CHANGED', 'false');
        }
      } else {
        this.prasnaObj.QUESTION_EXTERNAL_ID = uuidv4();
      }
      for (let key in this.prasnaObj) {
        formData.append(key, this.prasnaObj[key]);
      }

      // Image file data
      if (this.uploadedFiles.length > 0)
        formData.append('IMAGE_FILE', this.uploadedFiles[0])

      // imageInfo
      let imageInfo = new FormData();
      let imageInfoObj = new ImageInfoVM();
      imageInfoObj.IMAGE_CATEGORY = ImageCategory.imgNews;
      // for(let key in imageInfoObj)   {
      //   formData.append(key, imageInfoObj[key]);
      // }
      // formData.append('imageInfo.IMAGE_CATEGORY', ImageCategory.imgQuestions.toString());
      // formData.append('imageInfo.UID', uuidv4());

      this.submitting = true;
      this.serviceSubscriptions.add(
      this.questionService.postQuestion(formData, !this.updateQuestion).subscribe(data => {
        if (data.CODE === 100) {
          this.messageService.add({ severity: 'success', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
          this.router.navigateByUrl('dashboard/prasna-vinodhini');
          
        } else {
          this.messageService.add({ severity: 'error', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
        }
        this.submitting = false;
      }, error => {
        // console.log('Error: ', error);
        this.messageService.add({ severity: 'error', summary: JSON.stringify(error), key: MESSAGE_SERVICE_KEY});
        this.submitting = false;
      })
      );
    }
  }
}
