<p-card styleClass="p-card-shadow news-card">
  <p-header (click)="cardClicked()">
    <div
      class="full-bg card-header-bg"
      [ngStyle]="{ 'background-image': 'url(' + getImageURL() + ')' }"
    ></div>
  </p-header>
  <div class="p-card-title" [innerHtml]="newsObj.TITLE" (click)="cardClicked()">
    <!-- {{newsObj.TITLE}} -->
  </div>
  <div
    class="p-card-subtitle"
    [innerHtml]="newsObj.SUB_TITLE"
    (click)="cardClicked()"
  >
    <!-- {{newsObj.SUB_TITLE}} -->
  </div>
  <div
    class="card-description"
    [innerHtml]="newsObj.CONTENT"
    (click)="cardClicked()"
  >
    <!-- {{newsObj.CONTENT}} -->
  </div>

  <ng-template pTemplate="footer">
    <app-card-footer
      [category]="newsCategory.bhakthiNews"
      [externalId]="newsObj.NEWS_EXTERNAL_ID"
      [summeryInfo]="newsObj.SUMMARY_INFO"
    >

    </app-card-footer>
    
  </ng-template>
</p-card>
