import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAppConfig } from '../models/app-config.model';
import { ApiResponse, BookmarkVM, CartItemCountResponse, CartItemInfo, CartItemVM, CheckoutItemsResponseVM, CheckoutOrderInfoVM, CustomResponse, DynamicLinkInfoRequest, DynamicLinksResponse, GetAddressStatus, GetNewsVM, GetOrderItemsVM, GetOrdersAdminResponseVM, GetQuestionsVM, GetTemplesVM, GetYatraVM, InitializePayRequestVM, ITEM, LikeDislikeVM, NewsVM, PoojaItemCategory, PoojaStoreFilter, PoojaStoreResponse, PostPoojaStoreItemVM, PostPurohitVM, PurohitCategory, PurohitFilter, PurohitListVM, QuestionAnswerVM, QuestionVM, UpdateOrderStatusVM, UpdateUserRoleMGR, UserAddressVM, UserCartVM, UserPointsVM, UserVM, YatraInterestRequest, YatraVM, } from '../models/avbhakthi.model';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AppHttpService {
  private readonly baseURL: string = AppConfigService.settings.apiURL;
  private readonly firebaseKey: string = AppConfigService.settings.firebaseKey;

  constructor(
    private http: HttpClient,

  ) {

  }

  /******************************
   * Common HTTP services
   ******************************/
  postLikeDisLike(request: LikeDislikeVM) {
    return this.http.post<CustomResponse>(this.baseURL + 'common/likedislike', request);
  }

  postBookmarkStatus(request: BookmarkVM) {
    return this.http.post<CustomResponse>(this.baseURL + 'common/bookmark', request);
  }

  /******************************
   * News HTTP services
   ******************************/
  getNews(request: GetNewsVM): Observable<NewsVM[]> {

    return this.http.post<NewsVM[]>(this.baseURL + 'news/getNews', request);
  }


  postNews(formData: any): Observable<ApiResponse> {
    // return this.http.post<CustomResponse>(this.baseURL+'news/addNews', formData, this.getUploadOptions());
    return this.http.post<ApiResponse>(this.baseURL + 'news/addNews', formData, { headers: this.getUploadOptions() });
  }

  updateNews(formData: any): Observable<ApiResponse> {
    // return this.http.post<CustomResponse>(this.baseURL+'news/addNews', formData, this.getUploadOptions());
    return this.http.post<ApiResponse>(this.baseURL + 'news/updateNews', formData, { headers: this.getUploadOptions() });
  }

  /******************************
   * Yatra HTTP services
   ******************************/
  getYatras(request: GetYatraVM): Observable<YatraVM[]> {

    return this.http.post<YatraVM[]>(this.baseURL + 'yatra/getYatra', request);
  }
  postYatra(formData: any): Observable<ApiResponse> {
    // return this.http.post<CustomResponse>(this.baseURL+'news/addNews', formData, this.getUploadOptions());
    return this.http.post<ApiResponse>(this.baseURL + 'yatra/addYatra', formData, { headers: this.getUploadOptions() });
  }

  updateYatra(formData: any): Observable<ApiResponse> {
    // return this.http.post<CustomResponse>(this.baseURL+'news/addNews', formData, this.getUploadOptions());
    return this.http.post<ApiResponse>(this.baseURL + 'yatra/updateYatra', formData, { headers: this.getUploadOptions() });
  }

  saveYatraInterest(req: YatraInterestRequest): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseURL + 'yatra/yatraInterest', req);
  }
  /******************************
     * Question HTTP services
     ******************************/
  getQuestions(request: GetQuestionsVM): Observable<QuestionVM[]> {

    return this.http.post<QuestionVM[]>(this.baseURL + 'questions/getQuestions', request);
  }
  postQuestion(formData: any): Observable<ApiResponse> {
    // return this.http.post<CustomResponse>(this.baseURL+'news/addNews', formData, this.getUploadOptions());
    return this.http.post<ApiResponse>(this.baseURL + 'questions/addQuestion', formData, { headers: this.getUploadOptions() });
  }

  updateQuestion(formData: any): Observable<ApiResponse> {
    // return this.http.post<CustomResponse>(this.baseURL+'news/addNews', formData, this.getUploadOptions());
    return this.http.post<ApiResponse>(this.baseURL + 'questions/updateQuestion', formData, { headers: this.getUploadOptions() });
  }

  saveQuestionAnswer(formData: QuestionAnswerVM): Observable<ApiResponse> {
    // return this.http.post<CustomResponse>(this.baseURL+'news/addNews', formData, this.getUploadOptions());
    return this.http.post<ApiResponse>(this.baseURL + 'questions/saveAnswer', formData);
  }

  /******************************
   * Gurushubhashini HTTP services
   ******************************/
  // getQuotes(request: GetQuestionsVM): Observable<QuestionVM[]>{

  //   return this.http.post<QuestionVM[]>(this.baseURL + 'questions/getQuestions', request);
  // }

  /******************************
   * Temples HTTP services
   ******************************/
  getTemples(request: GetTemplesVM): Observable<any[]> {

    return this.http.post<any[]>(this.baseURL + 'temple/getTemples', request);
  }


  getNearestTemples(templeExternalId: string): Observable<any[]> {

    return this.http.get<any[]>(this.baseURL + 'temple/getNearestTemplesByID?templeExternalID=' + templeExternalId);
  }

  postTemple(formData: any, isNew: boolean): Observable<ApiResponse> {
    // return this.http.post<CustomResponse>(this.baseURL+'news/addNews', formData, this.getUploadOptions());
    if (isNew)
      return this.http.post<ApiResponse>(this.baseURL + 'temple/addTemple', formData, { headers: this.getUploadOptions() });
    else
      return this.http.post<ApiResponse>(this.baseURL + 'temple/updateTemple', formData, { headers: this.getUploadOptions() });
  }



  getUploadOptions = (): HttpHeaders => {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'multipart/form-data');
    // return {
    //   headers: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // };
    return headers;
  }

  /******************************
   * Users HTTP services
   ******************************/

  getUsers(): Observable<any[]> {
    return this.http.post<any[]>(this.baseURL + 'user/getUsers', null);
  }

  updateUserRole(req: UpdateUserRoleMGR) {
    return this.http.post<any>(this.baseURL + 'user/updateRoleMGR', req);
  }

  getProfile(): Observable<UserVM> {
    return this.http.post<UserVM>(this.baseURL + 'user/getProfile', null);
  }

  getPoints(): Observable<UserPointsVM> {
    return this.http.post<UserPointsVM>(this.baseURL + 'user/getPoints', null);
  }

  saveUserDetails(user: FormData, isNew: boolean) {
    if (isNew) {
      return this.http.post<any>(this.baseURL + 'user/registration', user, { headers: this.getUploadOptions() });
    } else {
      return this.http.post<any>(this.baseURL + 'user/updateProfile', user, { headers: this.getUploadOptions() });
    }
  }

  getUserAddresses(listType: GetAddressStatus) {
    return this.http.get<UserAddressVM[]>(this.baseURL + 'user/getAddress?addressStatus='+listType);
  }

  addOrUpdateUserAddress(address: UserAddressVM, isNew: boolean) {
    if (isNew) {
      return this.http.post<any>(this.baseURL + 'user/addAddress', address);
    } else {
      return this.http.post<any>(this.baseURL + 'user/updateAddress', address);
    }
  }

  /***************************** */
  /** 
   *  API calls related to purohit darshini 
  */
  /***************************** */

  getPurohitCategories() {
    return this.http.post<PurohitCategory[]>(this.baseURL + 'master/getPurohitCategories', null);
  }

  getPurhoitDetails() {
    return this.http.post<any>(this.baseURL + 'purohit/getPurohit', null);
  }

  registerPurohit(purhotiDetails: PostPurohitVM) {
    return this.http.post<any>(this.baseURL + 'purohit/registerPurohit', purhotiDetails);
  }

  updatePurohit(purhotiDetails: PostPurohitVM) {
    return this.http.post<any>(this.baseURL + 'purohit/updatePurohit', purhotiDetails);
  }

  checkForPurohitPublicName(publicName: string) {
    return this.http.get<any>(this.baseURL + 'purohit/validatePurohitPublicName?purohitPublicID=' + publicName);
  }

  getPurohitQualifications() {
    return this.http.post<string[]>(this.baseURL + 'purohit/getPurohitQualifications', null);
  }
  getPurohitList(filter: PurohitFilter) {
    return this.http.post<any[]>(this.baseURL + 'purohit/getPurohitList', filter);
  }

  getPurohitApproveList() {
    return this.http.get<PurohitListVM[]>(this.baseURL + 'purohit/getPendingApprovalPurohitList');
  }

  aprovePurohit(purohitId: string) {
    return this.http.get<any>(this.baseURL + 'purohit/approvePurohit?purohitId=' + purohitId);
  }

  /*********************************************/
  /** 
   *  API calls related to Pooja Store
  **/
  /*********************************************/

  getPoojaItemCategories() {
    return this.http.post<PoojaItemCategory[]>(this.baseURL + 'master/getPoojaCategories', null);
  }

  addPoojaItem(poojItem: FormData) {
    return this.http.post<any>(this.baseURL + 'poojastore/addItem', poojItem)
  }

  updatePoojaItem(poojItem: FormData) {
    return this.http.post<any>(this.baseURL + 'poojastore/updateItem', poojItem)
  }

  getPoojaItemById(id: string) {
    return this.http.get<PostPoojaStoreItemVM>(this.baseURL + 'poojastore/getItemById?item_external_id=' + id);
  }

  getPoojaItems(filter: PoojaStoreFilter) {
    return this.http.post<PoojaStoreResponse>(this.baseURL + 'poojastore/getItems', filter)
  }

  /*********************************************/
  /** 
   *  API calls related to Pooja Store eKart
  **/
  /*********************************************/

  getCartItems() {
    return this.http.post<UserCartVM>(this.baseURL + 'cart/getItems', null);
  }

  addCartItem(request: CartItemVM) {
    return this.http.post<ApiResponse>(this.baseURL + 'cart/additem', request)
  }
  updateCartItem(request: CartItemVM) {
    return this.http.post<ApiResponse>(this.baseURL + 'cart/updateItem', request)
  }

  removeCartItem(id: string) {
    return this.http.get<ApiResponse>(this.baseURL + 'cart/removeItem?itemExternalID=' + id);
  }

  initPaymentRequest(request: InitializePayRequestVM) {
    return this.http.post<CheckoutItemsResponseVM>(this.baseURL + 'order/initializeCheckout', request)
  }

  placeOrderApi(request: InitializePayRequestVM) {
    return this.http.post<CheckoutItemsResponseVM>(this.baseURL + 'order/calculateOrderTotal', request)
  }

  confirmPaymentApi(request: CheckoutOrderInfoVM) {
    return this.http.post<any>(this.baseURL+ 'order/confirmPayment', request)
  }

  // ORDERS related to API calls
  getOrders(req: GetOrderItemsVM) {
    return this.http.post<GetOrdersAdminResponseVM>(this.baseURL + 'order/getOrderItems', req);
  }

  // Update ITEM status
  updateOrderStatus(req: UpdateOrderStatusVM) {
    return this.http.post<ApiResponse>(this.baseURL + 'order/updateOrderItemStatus', req);
  }

  // GetOrderItem Details by ID
  getOrderItemByID(itemExternalId: string) {
    return this.http.get<ITEM>(this.baseURL + 'order/getOrderItemById?orderItemExternalId=' + itemExternalId);
  }

  // Get number of items in cart
  getCartItemCount() {
    return this.http.get<CartItemCountResponse>(this.baseURL + 'cart/getItemsCount');
  }


  //  Minify URL request
  getShortUrl(req: DynamicLinkInfoRequest) {
    return this.http.post<DynamicLinksResponse>('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key='+this.firebaseKey, req);
  }
  
}
