import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../auth-service/auth.service';
import { error } from '@angular/compiler/src/util';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { MESSAGE_SERVICE_KEY } from '../constants';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService, 
        private router: Router,
        private messageService: MessageService
        ) { }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        //handle your auth error or rethrow
        if (err.status === 401 || err.status === 403) {
            //navigate /delete cookies or whatever
            this.messageService.add({severity:'error', summary: err.message, detail: err.status.toString(), key: MESSAGE_SERVICE_KEY});
            this.router.navigateByUrl('/login');
            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            return of(err.message); // or EMPTY may be appropriate here
        }
        return throwError(err);
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const token: string = this.authService.accessToken;
        
        if (token) {
            if(!request.url.includes('firebase'))
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
       
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    //  // console.log('event--->>>', event);
                }
                
                return event;
            },catchError(x=> this.handleAuthError(x)))); 
    }
}