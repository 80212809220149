<div class="card p-mx-auto" style="max-width: 1100px">
  <div class="p-text-center p-p-4 p-text-center" *ngIf="cartItems?.length === 0">
    <h1>Your cart is empty</h1>
  </div>
  
  <p-dataView
    #dv
    [value]="cartItems"
    [paginator]="false"
    filterBy="ITEM_NAME"
    layout="grid"
    *ngIf="cartItems?.length > 0"
  >
    <ng-template pTemplate="header">
      <h1 class="theme-color p-text-light p-mt-0 line-height-1">Your cart</h1>
    </ng-template>
    <ng-template let-product pTemplate="gridItem">
      <div
        class="p-col-12"
        [ngStyle]="
          product.POOJASTORE_ITEM_INFO.ITEM_STATUS === false
            ? { opacity: '0.6' }
            : ''
        "
      >
        <div class="product-list-item">
          <img
            [src]="getImageURL(product)"
            [alt]="product.POOJASTORE_ITEM_INFO.ITEM_NAME"
          />
          <div class="product-list-detail">
            <div class="product-name line-height-1 theme-color">
              {{ product.POOJASTORE_ITEM_INFO.ITEM_NAME }}
            </div>
            <i class="pi pi-tag product-category-icon"></i
            ><span class="product-category">{{
              getCategoryName(product.POOJASTORE_ITEM_INFO.CATEGORY_ID)
            }}</span>
            <br />
            <span
              [class]="
                'product-badge status-' +
                (product.POOJASTORE_ITEM_INFO.ITEM_AVAILABILITY === true
                  ? 'instock'
                  : 'outofstock')
              "
              >{{
                product.POOJASTORE_ITEM_INFO.ITEM_AVAILABILITY === true
                  ? "INSTOCK"
                  : "OUT OF STOCK"
              }}</span
            >
            <div class="p-my-2">
              <p-inputNumber
                mode="decimal"
                [showButtons]="true"
                inputId="minmax-buttons"
                [min]="1"
                (onInput)="itemQuantityChange($event, product)"
                [ngModel]="product.QUANTITY"
                [inputStyle]="{width:'50px'}"
              >
              </p-inputNumber>
            </div>
            <div>
              <button
                pButton
                class="p-button p-py-0 p-button-plain p-button-outlined"
                icon="pi pi-trash"
                (click)="removeCartItem(product.ITEM_EXTERNAL_ID)"
              ></button>
              <p class="p-text-danger" *ngIf="checkForError(product) === true">Note*: {{ product.POOJASTORE_ITEM_INFO?.ERROR_INFO?.DESC }}</p>
            </div>
          </div>
          <div class="product-list-action p-text-right">
            <span class="product-price theme-color line-height-1 font-family-roboto"
              >&#8377;{{ product.ITEM_PRICE_INFO.ITEM_TOTAL_AMOUNT | number}}/-</span
            >
            <span class="line-height-1 font-family-roboto" style="text-decoration: line-through"
              >&#8377;{{ product.ITEM_PRICE_INFO.ITEM_AMOUNT | number}}/-</span
            >
            <span>
              <img
                src="/assets/img/delivery-icon.png"
                style="
                  box-shadow: none;
                  max-width: 25px;
                  margin: 0rem;
                  vertical-align: middle;
                "
                title="delivery-icon"
                alt="delivery-icon"
                class="img-fluid"
              />
              <span
                class="p-ml-1 font-family-roboto"
                [ngClass]="
                  product.POOJASTORE_ITEM_INFO.DELIVERY ? 'p-text-success p-text-bold' : ''
                "
                >{{ product.POOJASTORE_ITEM_INFO.DELIVERY ? 'Free': '&#8377;'+ (product.ITEM_PRICE_INFO.ITEM_DELIVERY_CHARGES | number )   +'/-' }}</span
              >
            </span>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="p-grid">
        <div class="p-col-12 p-text-right">
          <h2 class="p-my-1 font-family-roboto">
            Subtotal({{ noOfItemsInCart }} items):
            <span class="theme-color">&#8377;{{ priceSummeryInfo?.ITEMS_TOTAL_AMOUNT_AFTER_DISCOUNT | number}}/-</span>
          </h2>
          <h4 class="p-my-1 font-family-roboto" style="text-decoration: line-through font-family-roboto">
            &#8377;{{ priceSummeryInfo?.ITEMS_TOTAL_AMOUNT | number}}/-
          </h4>
          <h4 class="p-my-1 font-family-roboto" >
            Delivery
            +&#8377;{{ priceSummeryInfo?.DELIVERY_CHARGES_TOTAL_AMOUNT | number}}/-
          </h4>
          <h2 class="p-my-1 font-family-roboto" >
            <span class="theme-color">&#8377;{{ priceSummeryInfo?.ORDER_TOTAL_AMOUNT | number}}/-</span>
          </h2>
        </div>
        <div class="p-col-12 p-text-right">
          <button
            (click)="proceedToNext()"
            pButton
            class="btn-theme"
            style="font-size: 1.5rem; padding-left: 50px; padding-right: 50px"
            label="PROCEED"
            [disabled]="cartHasError"
          ></button>
          <p class="p-text-danger" *ngIf="cartHasError">Note*: Check one or more items in cart has error</p>
        </div>
      </div>
    </ng-template>
  </p-dataView>
</div>
