<p-card styleClass="p-card-shadow question-card" >
  <div class="card-description" (click)="cardClicked()">
    <h2 class="theme-color p-my-2 p-text-light question-title">
      {{ question.QUESTION }}
    </h2>
    <h4 class="p-text-right p-my-1 p-text-light question-subtitle">
      <i class="pi pi-calendar"></i> {{ question.RELEASE_DATE | dateIso }}
    </h4>

    <div class="p-grid">
      <div class="p-col-7">
        <!-- Multiple choice -->
        <ng-template [ngIf]="question.QT_TYPE === questionType.multipleChoice">
          <div class="p-field-radiobutton">
            <p-radioButton
              name="radioOption"
              value="1"
              [(ngModel)]="question.ANSWER"
              inputId="option1"
              [disabled]="true"
            ></p-radioButton>
            <label for="option1">{{ question.OPTION1 }}</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="radioOption"
              value="2"
              [(ngModel)]="question.ANSWER"
              inputId="option2"
              [disabled]="true"
            ></p-radioButton>
            <label for="option2">{{ question.OPTION1 }}</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="radioOption"
              value="3"
              [(ngModel)]="question.ANSWER"
              inputId="option3"
              [disabled]="true"
            ></p-radioButton>
            <label for="option3">{{ question.OPTION3 }}</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="radioOption"
              value="4"
              [(ngModel)]="question.ANSWER"
              inputId="option4"
              [disabled]="true"
            ></p-radioButton>
            <label for="option4">{{ question.OPTION4 }}</label>
          </div>
        </ng-template>

        <!-- Multi select options -->
        <ng-template [ngIf]="question.QT_TYPE === questionType.multiSelect">
          <div class="p-field-checkbox">
            <p-checkbox
              name="checkOption"
              value="1"
              [(ngModel)]="multiSelectAnswer"
              inputId="checkOption1"
              [disabled]="true"
            ></p-checkbox>
            <label for="checkOption1">{{ question.OPTION1 }}</label>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox
              name="checkOption"
              value="2"
              [(ngModel)]="multiSelectAnswer"
              inputId="checkOption2"
              [disabled]="true"
            ></p-checkbox>
            <label for="checkOption2">{{ question.OPTION2 }}</label>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox
              name="checkOption"
              value="3"
              [(ngModel)]="multiSelectAnswer"
              inputId="checkOption3"
              [disabled]="true"
            ></p-checkbox>
            <label for="checkOption1">{{ question.OPTION3 }}</label>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox
              name="checkOption"
              value="4"
              [(ngModel)]="multiSelectAnswer"
              inputId="checkOption4"
              [disabled]="true"
            ></p-checkbox>
            <label for="checkOption4">{{ question.OPTION4 }}</label>
          </div>
        </ng-template>
      </div>
      <div class="p-col-5">
        <!-- <ng-template [ngIf]="question.CORRECT_STATUS === correctStatus.correct"> -->
        <div class="p-text-center">
          <!-- <p class="p-text-success">Congrats! You got</p> -->
          <div class="points-box">
            <p class="pts">{{ question.POINTS }}</p>
            <p class="pts-text">POINTS</p>
          </div>
        </div>
        <!-- </ng-template> -->
      </div>
      <div class="p-col-12 p-text-center">
        {{ question.QUESTION_STATUS | statusType }}
      </div>
      <div class="p-col-12" *ngIf="!true">
        <button pButton label="YOUR ANSWER" class="btn-theme"></button>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer"> </ng-template>
</p-card>
