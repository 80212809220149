import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MESSAGE_SERVICE_KEY } from 'src/app/constants';
import { Category, ImageCategory, ImageInfoVM, PoojaItemCategory, PostPoojaStoreItemVM } from 'src/app/models/avbhakthi.model';
import { v4 as uuidv4 } from 'uuid';
import { PoojaStoreService } from 'src/app/common/pooja-store.service';

@Component({
  selector: 'app-add-modify-pooja-item',
  templateUrl: './add-modify-pooja-item.component.html',
  styleUrls: ['./add-modify-pooja-item.component.css']
})
export class AddModifyPoojaItemComponent implements OnInit, OnDestroy{

  submitting: any = false;
  poojaItem: PostPoojaStoreItemVM;
  updatePoojaItem: boolean = false;

  private serviceSubscriptions: Subscription;
  
  poojaItemAvailability: any[] = [
    {label: 'Available', value: true},
    {label: 'Not-Available', value: false},
  ];
  poojaItemStatus: any[] = [
    {label: 'Active', value: true},
    {label: 'In-Active', value: false},
  ];

  poojaItemDeliveryChargeTypes: any[] = [
    {label: 'FREE', value: true},
    {label: 'CHARGE', value: false},
  ];

  poojaItemCategories: PoojaItemCategory[] = [];
  uploadedFiles: any[] = [];
  constructor(
    private router: Router,
    private messageService: MessageService,
    private poojaStoreService: PoojaStoreService,
    private activeRoute: ActivatedRoute
  ) { 
    this.poojaItem = new PostPoojaStoreItemVM();
    this.serviceSubscriptions = new Subscription();
    this.serviceSubscriptions.add(
      this.poojaStoreService.poojaItemCategories().subscribe(data => {
        this.poojaItemCategories = data;
      })
    );
  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }

  ngOnInit(): void {

    this.activeRoute.paramMap.subscribe(params => {
      let id = params.get('id');

      // Fetching pooja item informtation if its for modify
      if (id) {
        this.updatePoojaItem = true;
        this.serviceSubscriptions.add(
          this.poojaStoreService.poojaItemById(id).subscribe(data => {
            this.poojaItem = data;
          })
        );
      }
    });
  }


  // Handle file upload event
  onUpload(event) {
    this.uploadedFiles = [];
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }


  // Handle item save
  saveItemDetails(form: NgForm) {
    // // console.log(form.value);
    // // console.log(this.poojaItem);
    if (form.valid) {
      var formData = new FormData();
      this.poojaItem.CAT_ID = Category.poojaStore;
      if (this.poojaItem.ITEM_EXTERNAL_ID) {
        if (this.uploadedFiles.length > 0) {
          formData.append('IS_IMG_CHANGED', 'true');
        } else {
          formData.append('IS_IMG_CHANGED', 'false');
        }
      } else {
        this.poojaItem.ITEM_EXTERNAL_ID = uuidv4();
      }
      for (let key in this.poojaItem) {
        formData.append(key, this.poojaItem[key]);
      }

      // Image file data
      if (this.uploadedFiles.length > 0) {
        this.uploadedFiles.forEach(file => {
          // formData.append('IMAGE_FILE[]', file);
          formData.append('IMAGE_FILES', file);
        });

      } 

      // imageInfo
      let imageInfo = new FormData();
      let imageInfoObj = new ImageInfoVM();
      imageInfoObj.IMAGE_CATEGORY = ImageCategory.imgPoojaStore;
      formData.append('imageInfo.IMAGE_CATEGORY', ImageCategory.imgPoojaStore.toString());
      formData.append('imageInfo.UID', uuidv4());

      this.submitting = true;
      this.serviceSubscriptions.add(
      this.poojaStoreService.postPoojaItem(formData, !this.updatePoojaItem).subscribe(data => {
        if (data.CODE === 100) {
          this.messageService.add({ severity: 'success', summary: data.DESC, key: MESSAGE_SERVICE_KEY});
          this.router.navigateByUrl('dashboard/pooja-store');
          
        } else {
          this.messageService.add({ severity: 'error', summary: data.DESC, key: MESSAGE_SERVICE_KEY});

        }
        this.submitting = false;
      }, error => {
        // console.log('Error: ', error);
        this.messageService.add({ severity: 'error', summary: JSON.stringify(error), key: MESSAGE_SERVICE_KEY});
        this.submitting = false;
      }));
    }
  }
}
