<form
  #userAddressForm="ngForm"
  (ngSubmit)="saveAddressDetails(userAddressForm)"
  novalidate
>
  <!-- (ngSubmit)="" -->
  <!-- (ngSubmit)="saveNewsDetails(addVarthaForm)" -->
  <!-- Form container outer -->
  <div class="p-shadow-3 form-layout-container">
    <!-- Form container inner -->
    <div class="p-fluid p-container">
      <div class="p-grid">
        <!-- Full name -->
        <div class="p-col-12">
          <div class="p-field">
            <label for="fullName">Full Name (First and Last name)*</label>
            <input
              [(ngModel)]="address.FULL_NAME"
              id="fullName"
              name="fullName"
              type="text"
              #fullName="ngModel"
              aria-describedby="fullName-help"
              pInputText
              required
              minlength="5"
              maxlength="100"
            />
            <small
              id="fullName-help"
              *ngIf="fullName.errors?.required === true && fullName.touched"
              class="p-error"
            >
              Full name is required
            </small>
            <small
              id="fullName-help"
              *ngIf="fullName.errors?.minlength || fullName.errors?.maxlength"
              class="p-error"
            >
              Full name between 5 to 100 characters only
            </small>
          </div>
        </div>
        <!-- /End of full name -->
        <!-- Mobile number -->
        <div class="p-col-12">
          <div class="p-field">
            <label for="mobileNumber">Mobile Number*</label>
            <input
              [(ngModel)]="address.MOBILE_NUMBER"
              id="mobileNumber"
              name="mobileNumber"
              type="text"
              #mobileNumber="ngModel"
              aria-describedby="mobileNumber-help"
              pInputText
              required
              pattern="[6789][0-9]{9}"
            />
            <small
              id="mobileNumber-help"
              *ngIf="
                mobileNumber.errors?.required === true && mobileNumber.touched
              "
              class="p-error"
            >
              Mobile Number required
            </small>
            <small
              id="mobileNumber-help"
              *ngIf="mobileNumber.errors?.pattern"
              class="p-error"
            >
              <!-- Mobile number must be in format +(country code) (mobile number) -->
              Moibile number must contains 10 digit
            </small>
          </div>
        </div>
        <!-- / End of mobile number -->

        <!-- Mobile number -->
        <!-- <div class="p-col-12">
          <div class="p-field">
            <label for="mobileNumber">Mobile Number*</label>
            <input
              [(ngModel)]="address.PINCODE"
              id="mobileNumber"
              name="mobileNumber"
              type="text"
              #mobileNumber="ngModel"
              aria-describedby="mobileNumber-help"
              pInputText
              required
              minlength="10"
              maxlength="10"
            />
            <small
              id="mobileNumber-help"
              *ngIf="mobileNumber.errors?.required === true && mobileNumber.touched"
              class="p-error"
            >
              Mobile Number required
            </small>
            <small
              id="mobileNumber-help"
              *ngIf="mobileNumber.errors?.minlength || mobileNumber.errors?.maxlength"
              class="p-error"
            >
              Invalid mobile number
            </small>
          </div>
        </div> -->

        <!-- / End of mobile number -->
        <!-- Pincode -->
        <div class="p-col-12">
          <div class="p-field">
            <label for="pincode">Pincode*</label>
            <input
              [(ngModel)]="address.PINCODE"
              id="pincode"
              name="pincode"
              type="text"
              #pincode="ngModel"
              aria-describedby="pincode-help"
              pInputText
              required
              minlength="6"
              maxlength="6"
              pattern="[0-9]{6}"
            />
            <small
              id="pincode-help"
              *ngIf="pincode.errors?.required === true && pincode.touched"
              class="p-error"
            >
              Pincode is required
            </small>
            <small
              id="pincode-help"
              *ngIf="
                pincode.errors?.minlength ||
                pincode.errors?.maxlength ||
                pincode.errors?.pattern
              "
              class="p-error"
            >
              Invalid pincode
            </small>
          </div>
        </div>
        <!-- /End of pincode -->

        <!-- FlatNo -->
        <div class="p-col-12">
          <div class="p-field">
            <label for="flatNo"
              >Falt/House No, Building, Copany, Apartment*</label
            >
            <input
              [(ngModel)]="address.FLAT_HOUSE_NO_BUILDING_APARTMENT"
              id="flatNo"
              name="flatNo"
              type="text"
              #flatNo="ngModel"
              aria-describedby="flatNo-help"
              pInputText
              required
              minlength="5"
              maxlength="100"
            />
            <small
              id="flatNo-help"
              *ngIf="flatNo.errors?.required === true && flatNo.touched"
              class="p-error"
            >
              Please enter details
            </small>
            <small
              id="flatNo-help"
              *ngIf="flatNo.errors?.minlength || flatNo.errors?.maxlength"
              class="p-error"
            >
              Enter details between 5 to 100 characters only
            </small>
          </div>
        </div>
        <!-- /End of plat no -->
        <!-- Area, colony, street, sector, Village -->
        <div class="p-col-12">
          <div class="p-field">
            <label for="areaDetails"
              >Area, Colony, Street, Sector, Village*</label
            >
            <input
              [(ngModel)]="address.AREA_COLONY_STREET_SECTOR_VILLAGE"
              id="areaDetails"
              name="areaDetails"
              type="text"
              #areaDetails="ngModel"
              aria-describedby="areaDetails-help"
              pInputText
              required
              minlength="5"
              maxlength="100"
            />
            <small
              id="areaDetails-help"
              *ngIf="
                areaDetails.errors?.required === true && areaDetails.touched
              "
              class="p-error"
            >
              Enter details
            </small>
            <small
              id="areaDetails-help"
              *ngIf="
                areaDetails.errors?.minlength || areaDetails.errors?.maxlength
              "
              class="p-error"
            >
              Enter details between 5 to 100 characters only
            </small>
          </div>
        </div>
        <!-- /End of Area details -->
        <!-- Landmark -->
        <div class="p-col-12">
          <div class="p-field">
            <label for="landMark">Landmark*</label>
            <input
              [(ngModel)]="address.LANDMARK"
              id="landMark"
              name="landMark"
              type="text"
              #landMark="ngModel"
              aria-describedby="landMark-help"
              pInputText
              required
              minlength="5"
              maxlength="100"
            />
            <small
              id="landMark-help"
              *ngIf="landMark.errors?.required === true && landMark.touched"
              class="p-error"
            >
              Landmark details required
            </small>
            <small
              id="landMark-help"
              *ngIf="landMark.errors?.minlength || landMark.errors?.maxlength"
              class="p-error"
            >
              Landmark length between 5 to 100 characters only
            </small>
          </div>
        </div>
        <!-- /End of LANDMARK -->
        <!-- Towncity -->
        <div class="p-col-12">
          <div class="p-field">
            <label for="townCity">Town/City*</label>
            <input
              [(ngModel)]="address.TOWN_CITY"
              id="townCity"
              name="townCity"
              type="text"
              #townCity="ngModel"
              aria-describedby="townCity-help"
              pInputText
              required
              minlength="5"
              maxlength="100"
            />
            <small
              id="townCity-help"
              *ngIf="townCity.errors?.required === true && townCity.touched"
              class="p-error"
            >
              Town city details required
            </small>
            <small
              id="townCity-help"
              *ngIf="townCity.errors?.minlength || townCity.errors?.maxlength"
              class="p-error"
            >
              Town city details between 5 to 100 characters only
            </small>
          </div>
        </div>
        <!-- /End of Towncity -->
        <!-- State -->
        <div class="p-col-12">
          <div class="p-field">
            <label for="state">State*</label>
            <input
              [(ngModel)]="address.STATE"
              id="state"
              name="state"
              type="text"
              #state="ngModel"
              aria-describedby="state-help"
              pInputText
              required
              minlength="5"
              maxlength="100"
            />
            <small
              id="state-help"
              *ngIf="state.errors?.required === true && state.touched"
              class="p-error"
            >
              State required
            </small>
            <small
              id="state-help"
              *ngIf="state.errors?.minlength || state.errors?.maxlength"
              class="p-error"
            >
              State between 5 to 100 characters only
            </small>
          </div>
        </div>
        <!-- /End of state -->
        <!-- ADDRESS TYPE -->
        <div class="p-col-12">
          <div class="p-field">
            <label for="state">Address Type*</label>
            <p-dropdown
              [options]="addressTypeOptions"
              [(ngModel)]="address.ADDRESS_TYPE"
              optionLabel="name"
              name="addressType"
              optionValue="code"
              required
            ></p-dropdown>
          </div>
        </div>
        <!-- /ADDRESS TYPE -->
        <div class="p-col-12">
          <button
            pButton
            pRipple
            type="submit"
            [label]="submitting === true ? 'Please wait' : 'Save'"
            style="max-width: 150px"
            class="p-button-raised btn-theme p-button-text"
            [disabled]="userAddressForm.valid === false || submitting === true"
          ></button>
        </div>
      </div>
    </div>
  </div>
</form>
