import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ProfileService } from 'src/app/common/profile.service';
import { MESSAGE_SERVICE_KEY } from 'src/app/constants';
import { EPoojaStoreService } from 'src/app/e-store/e-pooja-store.service';
import { GetAddressStatus, UserAddressVM } from 'src/app/models/avbhakthi.model';

@Component({
  selector: 'app-pick-address',
  templateUrl: './pick-address.component.html',
  styleUrls: ['./pick-address.component.css']
})
export class PickAddressComponent implements OnInit, OnDestroy {
  addressList: UserAddressVM[] = [];
  private serviceSubscriptions: Subscription;
  pickedAddressID: string = '';
  constructor(
    private profileService: ProfileService,
    private messageService: MessageService,
    private cartService: EPoojaStoreService
  ) { 
    // initializations
    this.serviceSubscriptions = new Subscription();
  }
  ngOnDestroy(): void {
    this.serviceSubscriptions.unsubscribe();
  }
  
  ngOnInit(): void {
    this.serviceSubscriptions.add(
      this.profileService.getAddress(GetAddressStatus.active).subscribe(data => {
        this.addressList = data;
        this.addressList.forEach(ad => {
          if(ad.IS_DEFAULT) {
            this.addressPicked(ad.ADDRESS_EXTERNAL_ID, +ad.PINCODE);
          }
        })
      }, error => {
        this.messageService.add({ severity: 'error', summary: error.error.DESC, key: MESSAGE_SERVICE_KEY});
      })
    );
  }

  // To handle address pick
  addressPicked(id: string, pincode: number) {
    this.pickedAddressID = id;
    this.cartService.setDeliveryAddress(this.pickedAddressID, pincode);
  }

}
