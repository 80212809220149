<p-card styleClass="p-card-shadow yatra-card">
  <p-header (click)="cardClicked()">
    <div
      class="full-bg card-header-bg"
      [ngStyle]="{ 'background-image': 'url(' + getImageURL() + ')' }"
    ></div>
  </p-header>
  <div
    class="p-card-title"
    [innerHtml]="yatraObj.PACKAGE_NAME"
    (click)="cardClicked()"
  >
    <!-- {{yatraObj.PACKAGE_NAME}} -->
  </div>
  <div class="card-description" >
    <div class="p-grid" (click)="cardClicked()">
      <div class="p-col-6">
        <p class="p-mt-1 p-mb-1">
          <i class="pi pi-calendar"></i> From : <br />
          {{ yatraObj.JOURNEY_DATES  }}
        </p>
      </div>
      <div class="p-col-6">
        <p class="p-mt-1 p-mb-1">{{ yatraObj.NO_OF_DAYS_TOUR }} Day('s)</p>
      </div>
      <div class="p-col-12 p-py-1 text-no-wrap yatra-place">
        <p class="p-mt-1 p-mb-1">
          Starting from: <span [innerHtml]="yatraObj.BOARDING_POINT" class="theme-color"></span>
        </p>
      </div>
      <div class="p-col-12 p-py-1">
        <p
          class="p-mt-1 p-mt-1 line-height-1 p-mb-1 p-text-center"
          style="font-size: 1.4rem"
        >
          Rs: {{ yatraObj.PRICE }}/-
        </p>
      </div>
      
    </div>
    <div class="p-grid">
      <div class="p-col-6 p-py-1 p-text-center">
        <button class="btn book-now-btn btn-theme p-font-weight-light"
        (click)="saveYatraInterest(yatraObj.INTREST_STATUS)"
        >
          {{(yatraObj.INTREST_STATUS == InterestStatus.notResponded || yatraObj.INTREST_STATUS == InterestStatus.notIntrested) ? 'BOOK' : 'BOOKED' }}
        </button>
      </div>
      <div class="p-col-6 p-py-1 p-text-center">
        <a
          href="tel:9505858565"
          class="btn book-now-btn btn-theme p-font-weight-light"
          >CALL NOW</a
        >
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <app-card-footer
      [category]="newsCategory.yatraSandarshini"
      [externalId]="yatraObj.YATRA_EXTERNAL_ID"
      [summeryInfo]="yatraObj.SUMMARY_INFO"
    >

    </app-card-footer>
    <!-- <p-button
      (click)="likeDisLike(true)"
      class="p-button-text"
      [label]="yatraObj.SUMMARY_INFO.NO_OF_LIKES.toString()"
      styleClass="p-button-text theme-color"
    >
      <img
        class="social-icon"
        [src]="socialIcons['LIKED'][yatraObj.SUMMARY_INFO.LIKE_STATUS]"
      />
    </p-button>
    <p-button
      (click)="likeDisLike(false)"
      class="p-button-text"
      [label]="yatraObj.SUMMARY_INFO.NO_OF_DIS_LIKES.toString()"
      styleClass="p-button-text theme-color"
    >
      <img
        class="social-icon"
        [src]="socialIcons['DISLIKED'][yatraObj.SUMMARY_INFO.LIKE_STATUS]"
      />
    </p-button>
    <p-button class="p-button-text" styleClass="p-button-text">
      <img class="social-icon" [src]="socialIcons['SHARE']" />
    </p-button>
    <p-button
      (click)="bookmark()"
      class="p-button-text"
      styleClass="p-button-text"
    >
      <img
        class="social-icon"
        [src]="socialIcons['BOOKMARKED'][yatraObj.SUMMARY_INFO.IS_BOOKMARKED]"
      />
    </p-button> -->
  </ng-template>
</p-card>
