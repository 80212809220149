<div class="page-header full-bg">
  <div class="blur-bg page-header-content">
    <h1 class="p-text-center p-text-white page-title p-my-1">గురు సుభాషిణి</h1>
    <hr class="special-line" />
    <h2 class="p-text-center page-sub-title p-my-1">
      ఇందులో మీరు పద్యాలు వాటి అర్ధాలు, ఆధ్యాత్మిక విశేషములు,అలానే గురువులు
      చేసిన వ్యాఖ్యానాలు,మరికొన్ని భక్తి విశేషములు తెలుసుకోవచ్చు. మీ ఆత్మీయులతో
      పంచుకోవచ్చును.
    </h2>
  </div>
</div>
<div class="page-content p-text-center p-mt-1">
  <app-guru-card *ngFor="let quote of guruSubhashini" [guruObj]="quote">
  </app-guru-card>
  <app-loader [enable]="contentLoading"></app-loader>
</div>
