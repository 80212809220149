import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pooja-store-items',
  templateUrl: './pooja-store-items.component.html',
  styleUrls: ['./pooja-store-items.component.css']
})
export class PoojaStoreItemsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
