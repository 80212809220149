<button
  pButton
  pRipple
  type="button"
  icon="pi pi-plus"
  label="ADD TEMPLE "
  routerLink="./add"
  class="p-button-raised p-button-text btn-add-category"
></button>

<div class="p-fluid p-text-center">
  <dashboard-temple-card *ngFor="let temple of temples" [temple]="temple">
  </dashboard-temple-card>
  <app-loader [enable]="contentLoading"></app-loader>
</div>
