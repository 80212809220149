<div class="p-shadow-3 form-layout-container">
  <div class="p-fluid p-container">
    <div class="p-text-center">
      <div class="rounded-icon">
        <img
          [src]="user.IMAGE_PATH"
          style="max-width: 150px"
          class="img-fluid"
        />
      </div>
      <p
        class="p-text-bold p-my-1"
        [ngClass]="
          user.PUROHIT_STATUS === purohitStatus.notRegistered
            ? 'p-text-danger'
            : user.PUROHIT_STATUS === purohitStatus.pendingApproval
            ? 'p-text-info'
            : 'p-text-success'
        "
      >
        {{
          user.PUROHIT_STATUS === purohitStatus.notRegistered
            ? "Not Registered"
            : user.PUROHIT_STATUS === purohitStatus.pendingApproval
            ? "Pending for approval"
            : "Approved"
        }}
      </p>
      <form
        #purohitRegisterForm="ngForm"
        (ngSubmit)="savePurohitDetails(purohitRegisterForm)"
      >
        <div class="p-grid p-text-left">
          <div class="p-field p-col-12 p-md-6 p-mb-0">
            <label for="purohitPublicName">Purohit Public Name*</label>
            <input
              id="purohitPublicName"
              name="purohitPublicName"
              type="username"
              aria-describedby="purohitPublicName-help"
              pInputText
              [(ngModel)]="purohitDetails.PUROHIT_PUBLIC_ID"
              #purohitPublicId="ngModel"
              (change)="purohitPublicIdChange($event, purohitPublicId.value)"
              [disabled]="purohitDetails.IS_APPROVED === 1"
              required
            />
            <small
              id="purohitPublicName-help"
              class="p-error"
              *ngIf="
                purohitPublicId.touched === true &&
                purohitPublicId.valid === false
              "
            >
              Purohit public name must be Unique and Required
            </small>

            <small
              id="purohitPublicName-valid"
              class="p-info"
              *ngIf="purohitNameValidating"
            >
              Please wait checking for purohit name availability.
            </small>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mb-0">
            <label for="dateOfBirth">Date of birth</label>
            <p-calendar
              id="dateOfBirth"
              name="dateOfBirth"
              inputId="basic"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [(ngModel)]="purohitDetails.DOB"
              yearRange="1971:2030"
              dateFormat="dd-mm-yy"
              #dateOfBirth="ngModel"
            ></p-calendar>
            <small
              id="dateOfBirth-help"
              class="p-error"
              *ngIf="dateOfBirth.valid === false"
            >
              Enter valid date of birth
            </small>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mb-0">
            <label for="experience">Experience(years)*</label>
            <input
              id="experience"
              name="experience"
              type="number"
              aria-describedby="experience-help"
              pInputText
              [(ngModel)]="purohitDetails.EXPERIENCE"
              #experience="ngModel"
              required
            />
            <small
              *ngIf="experience.touched === true && experience.valid === false"
              id="experience-help"
              class="p-error"
              >Experience is required.</small
            >
          </div>
          <div class="p-field p-col-12 p-md-6 p-mb-0">
            <label for="qualification">Qualification</label>
            <input
              id="qualification"
              name="qualification"
              styleClass="qualification-chips"
              [(ngModel)]="purohitDetails.QUALIFICATION"
              #qualification="ngModel"
              pInputText
            />
            <small
              *ngIf="qualification.valid === false"
              id="qualification-help"
              class="p-error"
              >Enter your qualification ',' seperated</small
            >
          </div>
          <div class="p-field p-col-12 p-md-6 p-mb-0">
            <label for="knownLanguages">Known Languages*</label>
            <input
              id="knownLanguages"
              name="knownLanguages"
              type="username"
              aria-describedby="knownLanguages-help"
              pInputText
              [(ngModel)]="purohitDetails.KNOWN_LANGUAGES"
              #knownLanguages="ngModel"
              required
            />
            <small
              *ngIf="
                knownLanguages.valid === false &&
                knownLanguages.touched === true
              "
              id="knownLanguages-help"
              class="p-error"
              >Known languages could not be empty</small
            >
          </div>
          <div class="p-field p-col-12 p-md-6 p-mb-0">
            <label for="aboutPurohit">About yourself</label>
            <input
              id="aboutPurohit"
              name="aboutPurohit"
              type="username"
              aria-describedby="aboutPurohit-help"
              [(ngModel)]="purohitDetails.ABOUT"
              #about="ngModel"
              pInputText
            />
            <small
              *ngIf="about.touched === true && about.valid === false"
              id="aboutPurohit-help"
              class="p-error"
              >Enter a brief discussion about your self.</small
            >
          </div>
          <div class="p-field p-col-12 p-mb-0">
            <label for="category">Category</label>
            <!-- [(ngModel)]="selectedCountries" [suggestions]="filteredCountries" (completeMethod)="filterCountry($event)" -->
            <!-- <p-autoComplete  
            id="category"
            name="category"
            field="name" 
            [multiple]="true"
            [(ngModel)]="purohitDetails.SELECTED_CATEGOIRES"
            #selectedCategoires="ngModel"
            [suggestions]="filteredCategories"
            (completeMethod)="filterCategory($event)"
            [dropdown]="true"
            dataKey="code"
            required
            >
            </p-autoComplete> -->

            <p-multiSelect
              #selectedCategoires="ngModel"
              name="category"
              [options]="purohitCategories"
              [(ngModel)]="purohitDetails.SELECTED_CATEGOIRES"
              defaultLabel="Select Category"
              scrollHeight="250px"
              display="chip"
              optionLabel="CATEGORY_NAME"
              optionValue="PUROHIT_CATEGORY_ID"
            >
            </p-multiSelect>

            <!-- <p-multiSelect 
                #selectedCategoires="ngModel" 
                name="category"
                [options]="purohitCategories" 
                [(ngModel)]="purohitDetails.SELECTED_CATEGOIRES" 
                defaultLabel="Select categories" 
                optionLabel="CATEGORY_NAME"   
                optionValue="PUROHIT_CATEGORY_ID"
                dataKey="PUROHIT_CATEGORY_ID"
                display="chip"
                (onChange)="categoriesChange($event)"
                ></p-multiSelect> -->
            <small
              *ngIf="
                selectedCategoires.valid === false &&
                selectedCategoires.touched === true
              "
              id="category-help"
              class="p-error"
              >Select atleast one category.</small
            >
          </div>
          <button
            pButton
            pRipple
            type="submit"
            [label]="submitting === true ? 'Please wait' : 'Save'"
            style="max-width: 150px"
            class="p-button-raised btn-theme p-button-text"
            [disabled]="
              purohitRegisterForm.valid === false || submitting === true
            "
          ></button>
        </div>
      </form>
    </div>
  </div>
</div>
