import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { YatraVM } from 'src/app/models/avbhakthi.model';

import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/common/app-config.service';

@Component({
  selector: 'dashboard-yatra-card',
  templateUrl: './dashboard-yatra-card.component.html',
  styleUrls: ['./dashboard-yatra-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardYatraCardComponent implements OnInit {

  readonly baseURL: string = AppConfigService.settings.serverURL;
  @Input() yatra: YatraVM;
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }
  getImageURL(): string {
    return this.baseURL + this.yatra.IMAGE_URL.replace(/\\/g, '/');
  }

  cardClicked() {
    // console.log('Card clicked');
    this.router.navigateByUrl('dashboard/yatra-sandharshini/add/' + this.yatra.YATRA_EXTERNAL_ID);
  }

}
