import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';

@Component({
  selector: 'app-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.css']
})
export class CheckOutComponent implements OnInit {

  items: MenuItem[];
  constructor(
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.items = [{
      label: 'Cart',
      routerLink: 'cart'
    },
    {
      label: 'Address',
      routerLink: 'pick-address'
    },
    {
      label: 'Order',
      routerLink: 'place-order'
    },
    {
      label: 'Payment',
      routerLink: 'init-payment'
    },
    {
      label: 'Confirmation',
      routerLink: 'confirmation'
    }
    ];
  }

}
